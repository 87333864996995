"use strict";
import Vue from "vue"

const emitter = new Vue({
  data () {
    return {

    }
  },
  methods:{
    getUserName () {
      return localStorage.getItem(process.env.VUE_APP_USER_NAME)
    },
    getUserRoles () {
      return localStorage.getItem(process.env.VUE_APP_USER_ROLES).split(',')
    },
    disabled (method) {
      if (method === 'add' || method === 'edit') return false
      return true
    },
    hasRole (role) {
      if (!role) return true
      let roles = role.constructor === Array ? role : [role]
      const userRoles = localStorage.getItem(process.env.VUE_APP_USER_ROLES).split(',')
      return roles.some(r => {
        if (r === '*') return true
        return userRoles.includes(r)
      })
    },
    hasAction (actions, type) {
      return actions.map(v => v.code).includes(type)
    },
    isAdmin () {
      return process.env.NODE_ENV === 'development'
    }
  }
})

const myPlugin = {
  install (Vue) {
    Vue.prototype.$auth = emitter
  }
}

Vue.use(myPlugin)

export default myPlugin
