<template>
  <div class="mt-4">
    <h4>สรุป</h4>

    <ul class="list-group">
      <li class="list-group-item" 
        v-for="(topic, idx) in summary" 
        :key="topic.name">
        <ListReportKpiProgress
          class="my-1"
          :percent="topic.percent"
          :name="`${idx+1}. ${topic.name}`"
          :remarks="topic.remarks">
        </ListReportKpiProgress>
      </li>
    </ul>
  </div>
</template>

<script>
import ListReportKpiProgress from './ListReportKpiProgress'

export default {
  components: {
    ListReportKpiProgress
  },
  props: {
    contacts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    summary () {
      return this.contacts
      .flatMap(c => c.kpis)
      .reduce((t,kpi) => {
        const found = t.find(v => v.name === kpi.name)
        if (!found) {
          t.push({
            name: kpi.name,
            percents: [kpi.percent],
            remarks: [...kpi.remarks]
          })
        } else {
          found.percents.push(kpi.percent)
          if (kpi.remarks.length > 0) {
            found.remarks = [...found.remarks, ...kpi.remarks]
          }
        }

        return t
      }, [])
      .map(kpi => {
        return {
          name: kpi.name,
          percent: this.$form.round(kpi.percents.reduce((t,v) => t += v, 0) / kpi.percents.length, 0),
          remarks: kpi.remarks
        }
      })
    }
  }
}
</script>

<style scoped>

</style>