import { capitalize } from '@/libs/form'

export default ({profileType, title, subTitle, group}) => {
  const templateType = 'Announcement'
  return [
    {
      path: `profile/${profileType}`,
      name: `Profile${capitalize(profileType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        profileType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
