import gql from 'graphql-tag'


export const USER_MANUAL = (templateType) => gql`query USER_MANUAL ($inventoryStockType: String!) {
  userManual: detail${templateType}UserManual (inventoryStockType: $inventoryStockType) {
    id type code name
    contents {
      id order
      title content
      imageContent imageContentPreview
      tableContent isPageBreak
      children {
        id order
        title content
        imageContent imageContentPreview
        tableContent isPageBreak
        children {
          id order
          title content
          imageContent imageContentPreview
          tableContent isPageBreak
        }
      }
    }
  }
}`
