<template>
  <div class="col-10 col-md-6 col-lg-5 mt-4">
    <h2 class="text-center my-4">{{appName}}</h2>
  </div>
</template>

<script>
import { verifyGoogle } from '@/plugins/axios'
import { onLogin } from '@/plugins/apollo'

export default {
  data() {
    return {
      appName: process.env.VUE_APP_NAME
    }
  },
  methods: {
    verify () {
      const verifyCode = this.$route.query.verifyCode

      verifyGoogle({verifyCode})
      .then(() => {
        return onLogin(this.$apollo.provider.clients.defaultClient)
      })
      .then(() => {
        this.$router.push({name: 'Home'})
      })
      .catch(err => {
        this.$toasted.global.error(err)
        this.$router.push({name: 'Login'})
      })

    }
  },
  created () {
    this.verify()
  }
}
</script>

<style lang="css" scoped>

</style>
