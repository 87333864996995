import gql from 'graphql-tag'


const inventoryConfigResponse = `
  id type inventoryType name regexExp isDefault
  options {expiredDay docs}
  paperId paper {id type code name}
  deviceId device {id type code name}
  children {
    id type inventoryType name isDefault parentId
  }
`

export const LIST_INVENTORY_CONFIG_MENU = (templateType) => gql`query LIST_INVENTORY_CONFIG_MENU ($inventoryType: String!) {
  menus: listInventory${templateType}InventoryConfigMenu (inventoryType: $inventoryType)
}`

export const LIST_INVENTORY_CONFIG = (templateType) => gql`query LIST_INVENTORY_CONFIG ($inventoryType: String!, $q: FilterInput) {
  items: listInventory${templateType}InventoryConfig (inventoryType: $inventoryType, q: $q) {${inventoryConfigResponse}}
}`

export const CREATE_INVENTORY_CONFIG = (templateType) => gql`mutation CREATE_INVENTORY_CONFIG ($inventoryType: String!, $inventoryConfigId: Int, $input: InventoryConfigInput!) {
  item: createInventory${templateType}InventoryConfig (inventoryType: $inventoryType, inventoryConfigId: $inventoryConfigId, input: $input) {${inventoryConfigResponse}}
}`

export const CREATE_INVENTORY_CONFIG_CHILDREN = (templateType) => gql`mutation CREATE_INVENTORY_CONFIG_CHILDREN ($inventoryType: String!, $input: InventoryConfigInput!) {
  item: createInventory${templateType}InventoryConfig (inventoryType: $inventoryType, input: $input) {
    id type inventoryType name isDefault parentId
  }
}`

export const DESTROY_INVENTORY_CONFIG = (templateType) => gql`mutation DESTROY_INVENTORY_CONFIG ($inventoryType: String!, $inventoryConfigId: Int!) {
  item: destroyInventory${templateType}InventoryConfig (inventoryType: $inventoryType, inventoryConfigId: $inventoryConfigId) {id}
}`

export const DP_DEVICES = (templateType) => gql`query DP_DEVICES ($inventoryType: String!, $q: FilterInput) {
  items: listInventory${templateType}InventoryConfigDropdownDevice (inventoryType: $inventoryType, q: $q) {
    id type code name
  }
}`

export const DP_PAPERS = (templateType) => gql`query DP_PAPERS ($inventoryType: String!, $q: FilterInput) {
  items: listInventory${templateType}InventoryConfigDropdownPaper (inventoryType: $inventoryType, q: $q) {
    id type code name
  }
}`
