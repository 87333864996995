<template>
  <sgv-table
    :items="logs"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">
    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.level">
          <router-link
            class="text-decoration-none no-wrap"
            :to="toDetail(item)">
            {{item.level}}
          </router-link>
        </td>
        <td v-if="hidden.name">
          {{item.name}}
          <div v-if="item.contact" class="text-primary">
            <small>
              {{item.contact.code}} ({{item.contact.name}})
            </small>
          </div>
          <div v-if="item.dueAt" class="text-danger">
            <small>{{item.dueAt | date}}</small>
          </div>
        </td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import { LIST_VALIDATION_LOG } from '../graph/validation'

export default {
  props: {
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      detailView: `${this.group}Detail`,
      headers: [
        {text: 'ระดับ', value: 'level'},
        {text: 'รายละเอียด', value: 'name'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['level', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      logs: []
    }
  },
  methods: {
    getLogs () {
      this.$apollo.mutate({
        mutation: LIST_VALIDATION_LOG(this.templateType),
        variables: {contactType: this.contactType}
      })
      .then(res => {
        this.logs = res.data.logs
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    toDetail (item) {
      return {
        name: `Contact${this.$form.capitalize(item.contact.type)}Detail`,
        params: {contactId: item.contact.id}
      }
    },
  },
  created () {
    this.getLogs()
  }
}
</script>

<style lang="css" scoped>
</style>
