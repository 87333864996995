<template>
  <div class="">
    <div>
      <div class="form-inline">
        <sgv-input-date 
          class="mr-2"
          v-model="accruedDate"
          isNull>
        </sgv-input-date>

        <sgv-input-select
          class="mr-2"
          :options="viewTypes"
          v-model="selectedView"
          select="value">
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-select>

        <button 
          class="btn btn-warning" 
          @click="fetchData">
          ค้นหา
        </button>
      </div>
    </div>

    <ListCategoryByDoc
      v-if="selectedView === 'doc'"
      class="mt-3"
      ref="table"
      :docType="docType"
      :templateType="templateType"
      :group="group"
      :title="title"
      :accruedDate="accruedDate">
    </ListCategoryByDoc>

    <ListCategoryByContact
      v-if="selectedView === 'contact'"
      class="mt-3"
      ref="table"
      :docType="docType"
      :templateType="templateType"
      :group="group"
      :title="title"
      :accruedDate="accruedDate">
    </ListCategoryByContact>
  </div>
</template>

<script>
import ListCategoryByDoc from './ListCategoryByDoc.vue'
import ListCategoryByContact from './ListCategoryByContact.vue'
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Doc${this.$form.capitalize(this.docType)}Detail`,
      rKey: `Doc${this.$form.capitalize(this.docType)}ListCategory`,
      rFields: ['accruedDate', 'selectedView'],
      accruedDate: null,
      viewTypes: [
        {text: 'เรียงตามเอกสาร', value: 'doc'},
        {text: 'เรียงตามผู้ติดต่อ', value: 'contact'},
      ],
      selectedView: 'doc'
    }
  },
  methods: {
    fetchData () {
      this.$refs.table.fetchData()
    }
  },
  watch: {
    accruedDate: 'setRetaining',
    selectedView: 'setRetaining'
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ListCategoryByDoc,
    ListCategoryByContact
  }
}
</script>

<style lang="css" scoped>
</style>
