<template>
  <span>
    <button
      :disabled="!canSave"
      type="button"
      :class="{'btn-secondary': !canSave, 'btn-success': canSave}"
      class="btn btn-xs"
      @click="toggle = !toggle">
      บันทึก
    </button>

    <b-modal
      v-model="toggle"
      title="ฟอร์มบันทึก">
      <div class="form-row">
        <sgv-input-radio
          class="col-12 mb-2"
          :options="item.choices"
          select="value"
          inline
          v-model="formData.actualValue">
          <div slot="label">
            {{item.name}}
          </div>
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-radio>

        <sgv-input-text
          placeholder="หมายเหตุ (ถ้ามี)"
          v-model="formData.remark"
          class="col-12">
        </sgv-input-text>
      </div>

      <div slot="modal-footer" class="col-12 px-0">
        <button
          type="button"
          @click="addData(item.id)"
          class="btn btn-block btn-success">
          บันทึก
        </button>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { CREATE_MEASUREMENT } from './graph'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    canSave: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        actualValue: this.item.actualValue,
        remark: ''
      },
      showAll: false
    }
  },
  methods: {
    serializeInput (item) {
      return {
        actualValue: item.actualValue,
        remark: item.remark,
      }
    },
    addData (parentId) {
      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_MEASUREMENT(this.templateType),
        variables: {
          docType: this.docType,
          parentId,
          input
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.toggle = false
        this.$toasted.global.success('เพิ่มสำเร็จ')
      })
      .catch(this.$toasted.global.error)
    }
  },
  watch: {
    toggle (value) {
      if (!value) {
        this.formData.actualValue = this.item.actualValue
        this.formData.remark = ''
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
