import { capitalize } from '@/libs/form'
import { TEMPLATE_TYPE } from '../config'


export default ({docType, title, subTitle, group}) => {
  const templateType = TEMPLATE_TYPE
  return [
    {
      path: `doc/${docType}/docConfig`,
      name: `Doc${capitalize(docType)}DocConfig`,
      component: () => import('../DocConfig.vue'),
      props: () => ({
        templateType,
        docType,
        title,
        subTitle,
        group
      }),
      children: [
        {
          path: 'userManual',
          name: `Doc${capitalize(docType)}DocConfigUserManual`,
          component: () => import('../DocConfigUserManual.vue'),
          props: () => ({
            templateType,
            docType,
          }),
        },
        {
          path: 'reportGroup',
          name: `Doc${capitalize(docType)}DocConfigReportGroup`,
          component: () => import('@/views/dox_core/components/DocConfigListReport.vue'),
          props: () => ({
            templateType,
            docType,
            reportType: 'reportGroup'
          }),
        },
        {
          path: 'printItemPdf',
          name: `Doc${capitalize(docType)}DocConfigPrintItemPdf`,
          component: () => import('../DocConfigPrintItemPdf.vue'),
          props: () => ({
            templateType,
            docType,
          }),
        },
        {
          path: 'printLedgerPdf',
          name: `Doc${capitalize(docType)}DocConfigPrintLedgerPdf`,
          component: () => import('../DocConfigPrintLedgerPdf.vue'),
          props: () => ({
            templateType,
            docType,
          }),
        }
      ]
    },
    {
      path: `doc/${docType}/category/:categoryId`,
      name: `Doc${capitalize(docType)}CategoryDetail`,
      component: () => import(`@/views/dox_core/components/CategoryDetail.vue`),
      props: (route) => ({
        categoryId: Number(route.params.categoryId),
        templateType,
        docType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `doc/${docType}`,
      name: `Doc${capitalize(docType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}/:docId`,
      name: `Doc${capitalize(docType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        docId: Number(route.params.docId),
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
  ]
}
