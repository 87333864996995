import Vue from 'vue'
import Router from 'vue-router'
import BaseRouter from '@/views/base_template/Base.vue'
import Section from '@/views/base_template/Section.vue'
import Views from '@/views'
import authRouter from './auth'


export const setup = require(`../../setup/${process.env.VUE_APP_SETUP}/index.js`).default

const getSection = ({title, group, sections}) => {
  const data = sections.reduce((t,section) => {
    const comp = Views[section.template]

    if (!comp) {
      console.log(section.template);
      throw new Error('TEMPLATE_NOT_FOUND')
    }

    const router = comp({
      title: section.title,
      subTitle: title,
      group: group,
      ...section.options
    })

    t.tabs.push({
      text: section.title,
      to: {name: router.defaultView},
      role: router.requiedRoles,
    })

    router.routes.forEach(r => {
      t.children.push(r)
    })

    return t
  }, {tabs: [], children: []})

  const children = [
    {
      path: group,
      name: group,
      component: Section,
      props: {
        group: group,
        options: data.tabs
      },
      children: data.children
    }
  ]

  const roles = data.tabs
  .filter(r => r.role)
  .flatMap(r => r.role)

  return {children, roles}
}

const getNavigation = (routers) => {
  return routers.reduce((t, router) => {
    if (router.header) {
      const header = {
        text: router.title,
        to: '#',
        header: true,
        role: []
      }

      const headerSections = router.sections.map(s => {
        const {children, roles} = getSection(s)

        header.role.push(roles)

        return {
          base: {
            text: s.title,
            to:  {path: `/${s.group}`},
            group: s.group,
            role: roles
          },
          sections: children
        }
      })

      header.role = header.role.flatMap(r => r)

      t.bases.push(header)

      headerSections.forEach(s => {
        t.bases.push(s.base)
        t.sections.push(s.sections)
      })

    } else {
      const {children, roles} = getSection(router)

      t.bases.push({
        text: router.title,
        to:  {path: `/${router.group}`},
        group: router.group,
        role: roles
      })

      t.sections.push(children)
    }

    return t
  }, {bases: [], sections: []})
}

const {bases, sections} = getNavigation(setup.routers)

Vue.use(Router)

var router = new Router({
  mode: 'history',
  routes: [
    ...authRouter,
    {
      path: '/',
      component: BaseRouter,
      name: 'Home',
      redirect: {name: setup.home},
      meta: {requiresAuth: true},
      props: {options: bases},
      children: sections.flatMap(s => s)
    },
    {
      path: '*',
      redirect: {name: 'Home'}
    }
  ]
})

router.beforeEach( async (to, from, next) => {
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    next()
    return
  }

  const expiresIn = localStorage.getItem(process.env.VUE_APP_AUTH_EXPIRES_IN)
  const token = localStorage.getItem(process.env.VUE_APP_AUTH_ACCESS_TOKEN)
  const userName = localStorage.getItem(process.env.VUE_APP_USER_NAME)

  if (!expiresIn || !token || !userName) next({name: 'Logout'})
  let expired = new Date(expiresIn)
  let current = new Date()
  if (current > expired) next({name: 'Logout'})
  next()
})

export default router
