import { capitalize } from '@/libs/form'
import Report from '../Report.vue'


export default ({inventoryType, group, title, subTitle}) => {
  const templateType = 'General'
  return [
    {
      path: `inventory/report/${inventoryType}`,
      name: `Inventory${capitalize(inventoryType)}Report`,
      component: Report,
      props: () => ({
        templateType,
        group,
        inventoryType,
        title,
        subTitle
      })
    }
  ]
}
