import gql from 'graphql-tag'

export const LIST_DOC = (templateType) => gql`query LIST_DOC ($contactType: String!, $contactId: Int!, $q: FilterInput) {
  docs: listContact${templateType}Doc (contactType: $contactType, contactId: $contactId, q: $q) {
    id type code name remark
    createdBy createdAt createdUser {id name}
    approvedBy approvedAt approvedUser {id name}
    closedBy closedAt closedUser {id name}
  }
}`
