import Auth from '@/views/auth_template/Auth.vue'
import Login from '@/views/auth_template/Login.vue'
import Verify from '@/views/auth_template/Verify.vue'
import Logout from '@/views/auth_template/Logout.vue'
import Confirm from '@/views/auth_template/Confirm.vue'
import Register from '@/views/auth_template/Register.vue'

export default [
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      },
      {
        path: 'verify',
        name: 'Verify',
        component: Verify
      },
      {
        path: 'confirm',
        name: 'Confirm',
        component: Confirm
      },
      {
        path: 'logout',
        name: 'Logout',
        component: Logout
      },
    ]
  }
]
