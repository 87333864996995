<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6 col-sm-4"
        v-model="startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6 col-sm-4"
        v-model="endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-text
        class="col-6 col-sm-4"
        v-model="search"
        label="ค่าใช้จ่าย">
      </sgv-input-text>
    </div>

    รายงาน
    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          label="สรุป"
          inline
          v-model="isSummary">
        </sgv-input-check>

        <sgv-input-check
          label="รายเอกสาร"
          inline
          v-model="isDoc">
        </sgv-input-check>
      </div>
    </div>

    <ListReportPrinter
      class="mb-3"
      :docType="docType"
      :templateType="templateType"
      @search="fetchData"
      @print="printData"
      @download="downloadData">
      ค้นหา
    </ListReportPrinter>

    <div v-if="isFetching">
      กำลังค้นหา...
    </div>

    <div v-else>
      <ListReportInventorySummary
        class="mt-3"
        v-if="report.summary.length > 0"
        :items="report.summary">
      </ListReportInventorySummary>

      <ListReportInventoryDoc
        class="mt-3"
        v-if="report.doc.length > 0"
        :items="report.doc">
      </ListReportInventoryDoc>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import { LIST_REPORT_INVENTORY } from '../graph/report'
import ListReportPrinter from './ListReportPrinter'
import ListReportInventorySummary from './ListReportInventorySummary'
import ListReportInventoryDoc from './ListReportInventoryDoc'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      report: {
        summary: [],
        doc: []
      },
      startDate: '',
      endDate: '',
      isFetching: false,
      isSummary: false,
      isDoc: false,
      search: '',
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportInventory`,
      rFields: [
        'startDate', 'endDate', 'search',
        'isSummary', 'isDoc'
      ],
      url: `/doc/${this.docType}/print/report/inventory`
    }
  },
  validations: {
    startDate: {required},
    endDate: {required}
  },
  methods: {
    serializeInput () {
      const filter = {
        startDate: this.startDate,
        endDate: this.endDate,
        search: this.search
      }

      const options = {}

      if (this.isSummary) {
        options.isSummary = true
      }

      if (this.isDoc) {
        options.isDoc = true
      }

      return {filter, options}
    },
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()

      this.report.summary = []
      this.report.doc = []

      this.isFetching = true

      this.$apollo.query({
        query: LIST_REPORT_INVENTORY(this.templateType),
        variables: {
          ...this.serializeInput(),
          docType: this.docType
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.report.summary = res.data.report.summary
        this.report.doc = res.data.report.doc
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isFetching = false
      })
    },
    downloadData () {
      const input = this.serializeInput()
      this.$axios.get(this.url, {
        params: {
          ...input.filter,
          ...input.options
        },
        responseType: 'blob'
      })
      .then(res => {
        let filename = `${this.docType}_report_inventory.pdf`
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      }).catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    printData (deviceId) {
      if (!deviceId) {
        this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
        return
      }

      const input = this.serializeInput()

      this.$axios.get(this.url, {
        params: {
          ...input.filter,
          ...input.options,
          deviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ส่งไปยังเครื่องพิมพ์")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถพิมพ์หรือบันทึกไฟล์ได้")
      })
    }
  },
  components: {
    ListReportPrinter,
    ListReportInventorySummary,
    ListReportInventoryDoc
  }
}
</script>

<style lang="css" scoped>
</style>
