import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { split, Observable, ApolloLink } from 'apollo-link'
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from 'graphql-ws'
import { getMainDefinition } from 'apollo-utilities'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { refreshToken } from '@/plugins/axios'
import router from '../router'


const ACCESS_TOKEN = process.env.VUE_APP_AUTH_ACCESS_TOKEN

const promiseToObservable = (promiseFunc) => {
  return new Observable((subscriber) => {
    promiseFunc.then(
      (value) => {
        if (subscriber.closed) return
        subscriber.next(value)
        subscriber.complete()
      },
      err => subscriber.error(err)
    )
  })
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) =>
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', message)
    )
  }

  if (networkError && networkError.statusCode === 401) {
    return promiseToObservable(
      refreshToken()
        .catch((err) => {
          console.log('Error on refeshig token', err)
          router.push({ name: 'Logout' }).catch(() => { })
        })
    )
      .flatMap(resp => {
        if (resp) {
          const { accessToken } = resp
          const oldHeaders = operation.getContext().headers

          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${accessToken}`,
            }
          })
        }

        return forward(operation)
      })

  } else if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : ''
    }
  }
})

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql'
})

const wsClient = createClient({
  url: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  retryAttempts: Infinity,
  shouldRetry: () => true,
  retryWait: (retries) => {
    if (retries < 30) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), 1000)
      })
    }

    return new Promise(resolve => {
      setTimeout(() => resolve(), 5000)
    })
  },
  connectionParams: () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN)
    if (!accessToken) return {}
    return {
      Authorization: `Bearer ${accessToken}`
    }
  }
})

// Create the subscription websocket link
const wsLink = new GraphQLWsLink(wsClient)

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink, authLink, link
  ]),
  cache: new InMemoryCache()
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

export async function onLogin(apolloClient) {
  try {
    await apolloClient.resetStore()
    return Promise.resolve(null)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
    return Promise.reject(e)
  }
}

export async function onLogout(apolloClient) {
  try {
    await apolloClient.resetStore()
    return Promise.resolve(null)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
    return Promise.reject(e)
  }
}

Vue.use(VueApollo)