<template>
  <div>
    <div class="form-row">
      <sgv-input-date
        label="วันที่จบเอกสาร"
        v-model="closedDate"
        class="col-12 col-md-4"
        :validations="[
          {text: 'required!', value: $v.closedDate.$dirty && $v.closedDate.$error}
        ]">
      </sgv-input-date>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CLOSE_DOC } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      closedDate: '',
    }
  },
  validations: {
    closedDate: {required}
  },
  methods: {
    closeData () {
      this.$v.closedDate.$touch()
      if (this.$v.closedDate.$invalid) return

      this.$apollo.mutate({
        mutation: CLOSE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          closedDate: this.closedDate
        }
      })
      .then(() => {
        this.$emit('update:method', 'info')
        this.$toasted.global.success("จบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css">
</style>
