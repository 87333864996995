<template>
  <a
    :href="url"
    target="_blank"
    class="text-decoration-none pointer text-info">
    <fa icon="map-marked-alt"></fa> Google Map
  </a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
