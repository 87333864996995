<template>
  <div class="">
    <sgv-input-select
      label="ป้ายสต๊อกสินค้า"
      placeholder="เลือกเครื่องพิมพ์"
      :options="devices"
      v-model="deviceId"
      select="id">
      <template slot-scope="option">
        {{option.name}}
      </template>
    </sgv-input-select>

    <div class="input-group">
      <input
        type="number"
        class="form-control"
        placeholder="จำนวน"
        v-model.number="qty"
        @keyup.enter="printData">
      <div class="input-group-append">
        <span @click="printData" class="input-group-text pointer">
          <fa icon="print" class="text-warning"></fa>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LIST_LABEL_STOCK_DEVICE,
  PRINT_LABEL_STOCK
} from './graph/stock'

export default {
  props: {
    stockId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      devices: [],
      deviceId: null,
      qty: '',
    }
  },
  apollo: {
    devices: {
      query () {
        return LIST_LABEL_STOCK_DEVICE(this.templateType)
      },
      variables () {
        return {inventoryType: this.inventoryType}
      },
      fetchPolicy: 'network-only',
      result (res) {
        const devices = res.data.devices

        if (devices.length === 1) {
          this.deviceId = devices[0].id
        }
      }
    }
  },
  methods: {
    printData () {
      if (this.qty > 0 && this.deviceId) {
        this.$apollo.mutate({
          mutation: PRINT_LABEL_STOCK(this.templateType),
          variables: {
            inventoryType: this.inventoryType,
            stockId: this.stockId,
            deviceId: this.deviceId,
            qty: this.qty
          }
        })
        .then(() => {
          this.qty = ''
          this.$toasted.global.success('ส่งข้อมูลไปยังเครื่องพิมพ์...')
        })
        .catch(err => {
          this.$toasted.global.error(err)
        })
      } else {
        this.$toasted.global.error("ระบุข้อมูลไม่ถูกต้อง")
      }
    }
  },
}
</script>

<style lang="css" scoped>
</style>
