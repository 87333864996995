import gql from 'graphql-tag'


export const LIST_STOCK = (templateType) => gql`query LIST_STOCK ($docType: String!, $inventoryId: Int!) {
  stocks: listDoc${templateType}AuditedStock (docType: $docType, inventoryId: $inventoryId) {
    id inventoryId remaining isAudited isLedger
  }
}`

export const CHECK_STOCK = (templateType) => gql`mutation CHECK_STOCK ($docType: String!, $stockId: Int!) {
  checkStock: checkDoc${templateType}Stock (docType: $docType, stockId: $stockId)
}`

export const CLEAR_STOCK = (templateType) => gql`mutation CLEAR_STOCK ($docType: String!, $inventoryId: Int!) {
  clearStock: clearDoc${templateType}Stock (docType: $docType, inventoryId: $inventoryId)
}`
