export default {
  routers: [
    {
      title: 'ฝ่ายผลิต',
      header: true,
      sections: [
        {
          title: 'งานรีด',
          group: 'ProductionExtrusion',
          sections: [
            {
              title: 'แผนการผลิต',
              template: 'ProcedurePlanning',
              options: { procedureType: 'extrusion' }
            },
            {
              title: 'ใบสั่งผลิตสินค้า',
              template: 'DocProductionorder',
              options: { docType: 'prx' }
            },
            {
              title: 'การผลิต',
              template: 'ProcedureGeneral',
              options: { procedureType: 'extrusion' }
            },
            {
              title: 'ควบคุมสินค้า',
              template: 'ProcedureInventory',
              options: { procedureType: 'extrusion' }
            },
            {
              title: 'ควบคุมผลิต',
              template: 'ProcedureControl',
              options: { procedureType: 'extrusion' }
            },
            {
              title: 'ควบคุมคุณภาพ',
              template: 'ProcedureQuality',
              options: { procedureType: 'extrusion' }
            }
          ],
        },
        {
          title: 'งานผสม',
          group: 'ProductionCompounding',
          sections: [
            {
              title: 'แผนการผลิต',
              template: 'ProcedurePlanning',
              options: { procedureType: 'compounding' }
            },
            {
              title: 'ใบสั่งผลิตวัตถุดิบ',
              template: 'DocProductionorder',
              options: { docType: 'pmx' }
            },
            {
              title: 'การผลิต',
              template: 'ProcedureGeneral',
              options: { procedureType: 'compounding' }
            },
            {
              title: 'ควบคุมวัตถุดิบ',
              template: 'ProcedureInventory',
              options: { procedureType: 'compounding' }
            },
            {
              title: 'ควบคุมผลิต',
              template: 'ProcedureControl',
              options: { procedureType: 'compounding' }
            },
            {
              title: 'ควบคุมคุณภาพ',
              template: 'ProcedureQuality',
              options: { procedureType: 'compounding' }
            }
          ],
        },
        {
          title: 'รายงานสต๊อก',
          group: 'ReportInventoryStock',
          sections: [
            {
              title: 'คิ้วขอบกระเบื้อง',
              template: 'InventoryStock',
              options: { inventoryStockType: 'cornertile' }
            },
            {
              title: 'จมูกบันได',
              template: 'InventoryStock',
              options: { inventoryStockType: 'stairnosing' }
            },
            {
              title: 'เซี้ยมและเซาะร่อง',
              template: 'InventoryStock',
              options: { inventoryStockType: 'groove' }
            },
            {
              title: 'บัวและตัวจบ',
              template: 'InventoryStock',
              options: { inventoryStockType: 'cornice' }
            },
            {
              title: 'SPC',
              template: 'InventoryStock',
              options: { inventoryStockType: 'spc' }
            },
            {
              title: 'วัตถุดิบหลัก',
              template: 'InventoryStock',
              options: { inventoryStockType: 'rawMaterial' }
            },
            {
              title: 'เม็ดพลาสติก',
              template: 'InventoryStock',
              options: { inventoryStockType: 'compound' }
            },
            {
              title: 'แม่สี',
              template: 'InventoryStock',
              options: { inventoryStockType: 'pigment' }
            },
            {
              title: 'เศษตอก/เส้น (SC)',
              template: 'InventoryStock',
              options: { inventoryStockType: 'rawScrap' }
            },
            {
              title: 'เศษพร้อมใช้ (RDY)',
              template: 'InventoryStock',
              options: { inventoryStockType: 'rdyScrap' }
            },
          ],
        }
      ]
    },
  ]
}
