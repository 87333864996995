import gql from 'graphql-tag'


export const DP_BRANCHS = (templateType) => gql`query DP_BRANCHS ($contactType: String!, $q: FilterInput) {
  contacts: listContact${templateType}DropdownBranch (contactType: $contactType, q: $q) {
    id type code name branch
  }
}`

export const DP_LOGISTICS = (templateType) => gql`query DP_LOGISTICS ($contactType: String!, $q: FilterInput) {
  contacts: listContact${templateType}DropdownLogistic (contactType: $contactType, q: $q) {
    id type code name branch
  }
}`

export const DP_VEHICLES = (templateType) => gql`query DP_VEHICLES ($contactType: String!, $q: FilterInput) {
  inventories: listContact${templateType}DropdownVehicle (contactType: $contactType, q: $q) {
    id type code name
  }
}`

export const DP_COUNTRY_AREAS = (templateType) => gql`query DP_COUNTRY_AREAS ($contactType: String!, $q: FilterInput) {
  items: listContact${templateType}DropdownCountryArea (contactType: $contactType, q: $q) {
    id name
  }
}`

export const DP_COUNTRY_AREA_CONTACTS = (templateType) => gql`query DP_COUNTRY_AREA_CONTACTS ($contactType: String!, $q: FilterInput) {
  items: listContact${templateType}DropdownCountryAreaContact (contactType: $contactType, q: $q) {
    id code name
  }
}`
