<template>
  <div class="col-10 col-md-6 col-lg-5 mt-4">
    <h2 class="text-center my-4">{{appName}}</h2>

    <sgv-input-text
      label="รหัสลงทะเบียน"
      placeholder="รหัสลงทะเบียน"
      v-model="formData.verifyCode"
      :validations="[
        {text: 'โปรดระบุรหัสลงทะเบียน', value: $v.formData.verifyCode.$dirty && $v.formData.verifyCode.$error},
      ]">
    </sgv-input-text>

    <button
      type="button"
      class="btn btn-block btn-outline-primary"
      @click="register">
      ลงทะเบียน
    </button>

    <button
      type="button"
      class="btn btn-block btn-outline-danger"
      @click="toLogin">
      กลับ
    </button>

  </div>
</template>

<script>
import { register } from '@/plugins/axios'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      formData: {
        verifyCode: ''
      },
      appName: process.env.VUE_APP_NAME
    }
  },
  validations: {
    formData: {
      verifyCode: {required}
    }
  },
  methods: {
    register () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const formData = {
        verifyCode: this.formData.verifyCode,
        token: this.$route.query.token
      }

      register(formData)
      .then(res => {
        this.$toasted.global.success(res.data.message)
        this.toLogin()
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    toLogin () {
      this.$router.push({name: 'Login'})
    }
  }
}
</script>

<style lang="css" scoped>

</style>
