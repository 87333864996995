<template>
  <div class="row">
    <div class="col-12 mb-1">
      {{item.name}}
    </div>

    <div class="col-12">
      <div class="form-row">
        <sgv-input-currency
          class="col-10 mb-0"
          v-model="totalPrice">
        </sgv-input-currency>

        <button
          class="btn btn-success form-group col mb-0"
          @click="addOrder">
          <fa icon="plus"></fa>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { CREATE_ORDER_PAYABLE } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      totalPrice: this.item.remaining
    }
  },
  methods: {
    addOrder () {
      this.$apollo.mutate({
        mutation: CREATE_ORDER_PAYABLE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: [
            {
              orderId: this.item.id,
              totalPrice: this.totalPrice
            }
          ]
        }
      })
      .then(() => {
        this.emitUpdated()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitUpdated () {
      this.$emit('updated', null)
    },
  },
  watch: {
    'item.remaining': {
      handler (v) {
        this.totalPrice = v
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
