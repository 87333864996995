<template>
  <div>
    <div class="form-row">
      <sgv-input-date
        label="วันที่สัมภาษณ์งาน"
        v-model="approvedDate"
        class="col-12 col-md-4"
        :validations="[
          {text: 'required!', value: $v.approvedDate.$dirty && $v.approvedDate.$error}
        ]">
      </sgv-input-date>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { APPROVE_DOC } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      approvedDate: '',
    }
  },
  validations: {
    approvedDate: {required}
  },
  methods: {
    approveData () {
      this.$v.approvedDate.$touch()
      if (this.$v.approvedDate.$invalid) return

      this.$apollo.mutate({
        mutation: APPROVE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          approvedDate: this.approvedDate
        }
      })
      .then(() => {
        this.$emit('update:method', 'info')
        this.$toasted.global.success("อนุมัติสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css">
</style>
