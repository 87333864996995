<template>
  <div class="card">
    <div class="card-body pb-1">
      <div class="card-title">
        <span class="text-success">{{item.code}}</span>
        <div class="">
          <small>{{item.name}}</small>
        </div>
      </div>

      <div class="row mb-0">
        <div
          class="col-12"
          v-for="order in item.orders.filter(v => v.remaining !== 0)"
          :key="order.id">
          <DetailOrderPayableAvailableCardOrder
            class="form-row"
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :item="order"
            @updated="emitUpdated">
          </DetailOrderPayableAvailableCardOrder>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import DetailOrderPayableAvailableCardOrder from './DetailOrderPayableAvailableCardOrder'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    emitUpdated () {
      this.$emit('updated', null)
    },
  },
  components: {
    DetailOrderPayableAvailableCardOrder
  }
}
</script>

<style lang="css" scoped>
</style>
