import { capitalize } from '@/libs/form'

export default ({userType, title, subTitle, group}) => {
  const templateType = 'Device'
  return [
    {
      path: `user/${userType}`,
      name: `User${capitalize(userType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        userType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `user/${userType}/:userId`,
      name: `User${capitalize(userType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        userId: Number(route.params.userId),
        templateType,
        userType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
