var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-date',{staticClass:"col-6 col-md-4",attrs:{"label":"เริ่ม","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.startDate.$dirty && _vm.$v.startDate.$error}
      ]},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('sgv-input-date',{staticClass:"col-6 col-md-4",attrs:{"label":"ถึง","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.endDate.$dirty && _vm.$v.endDate.$error}
      ]},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('sgv-input-text',{staticClass:"col-4",attrs:{"label":"ตัวกรอง"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.dataList}},[_vm._v("ค้นหา")]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-sm-6"},[_vm._v(" รายการที่นับแล้ว: "+_vm._s(_vm.itemsByAudited.length)+" "),_c('span',{staticClass:"text-danger"},[_vm._v("("+_vm._s(_vm.itemsByStockDiff.length)+")")]),_vm._v(" รายการ "),_c('ul',_vm._l((_vm.itemsByAudited),function(item,i){return _c('li',{key:i,staticClass:"mb-1"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.toDetail(item)}},[_vm._v(" "+_vm._s(item.code)+" ("+_vm._s(item.name)+") ")]),_c('div',[_c('small',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm._f("date")(item.auditedAt))+" | "+_vm._s(item.auditedUser.name)+" "),(item.docStockCount)?_c('span',[_vm._v(" | "),_c('span',{class:{
                  'text-success': !item.docStockCount.isStockDiff,
                  'text-danger': item.docStockCount.isStockDiff
                  }},[_vm._v(" "+_vm._s(item.docStockCount.code)+" ")])]):_vm._e(),(item.docStockCount && item.docStockCount.isStockDiff && item.docStockCount.remark)?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(item.docStockCount.remark)+" ")]):_vm._e()])])],1)}),0)]),_c('div',{staticClass:"col-12 col-sm-6"},[_vm._v(" รายการที่ยังไม่ได้นับ: "+_vm._s(_vm.itemsByNone.length)+" รายการ "),_c('ul',_vm._l((_vm.itemsByNone),function(item,i){return _c('li',{key:i},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.toDetail(item)}},[_vm._v(" "+_vm._s(item.code)+" ("+_vm._s(item.name)+") ")])],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }