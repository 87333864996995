<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6 col-md-4"
        v-model="startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6 col-md-4"
        v-model="endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-text
        class="col-4"
        v-model="search"
        label="ตัวกรอง">
      </sgv-input-text>
    </div>

    <button type="button" class="btn btn-primary" @click="dataList">ค้นหา</button>

    <div class="row mt-3">
      <div class="col-12 col-sm-6">
        รายการที่นับแล้ว: {{itemsByAudited.length}} <span class="text-danger">({{ itemsByStockDiff.length }})</span> รายการ
        <ul>
          <li
            v-for="(item, i) in itemsByAudited"
            :key="i"
            class="mb-1">
            <router-link
              class="text-decoration-none"
              :to="toDetail(item)">
              {{item.code}} ({{item.name}})
            </router-link>
            <div>
              <small class="text-primary">
                {{item.auditedAt | date}} | {{item.auditedUser.name}}
                <span v-if="item.docStockCount">
                  | 
                  <span :class="{
                    'text-success': !item.docStockCount.isStockDiff,
                    'text-danger': item.docStockCount.isStockDiff
                    }">
                    {{ item.docStockCount.code }}
                  </span>
                </span>
                <div 
                  v-if="item.docStockCount && item.docStockCount.isStockDiff && item.docStockCount.remark"
                  class="text-danger">
                  {{ item.docStockCount.remark }}
                </div>
              </small>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-12 col-sm-6">
        รายการที่ยังไม่ได้นับ: {{itemsByNone.length}} รายการ
        <ul>
          <li
            v-for="(item, i) in itemsByNone"
            :key="i">
            <router-link
              class="text-decoration-none"
              :to="toDetail(item)">
              {{item.code}} ({{item.name}})
            </router-link>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import { LIST_REPORT_STOCK_AUDITED_BY_INTERVAL } from '../graph/report'


export default {
  mixins: [retainMixin],
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      startDate: '',
      endDate: '',
      search: '',
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}ReportStockAuditedByInterval`,
      detailView: `Inventory${this.$form.capitalize(this.inventoryType)}Detail`,
      rFields: ['startDate', 'endDate', 'search'],
    }
  },
  validations: {
    startDate: {required},
    endDate: {required}
  },
  computed: {
    itemsByAudited () {
      return this.items.filter(v => {
        let c = v.code.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        let n = v.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        let d = this.$date.format(v.auditedAt).date
        return (c || n) && v.auditedAt && d >= this.startDate && d <= this.endDate
      })
    },
    itemsByStockDiff () {
      return this.itemsByAudited.filter(v => v?.docStockCount?.isStockDiff)
    },
    itemsByNone () {
      return this.items.filter(v => {
        let c = v.code.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        let n = v.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        let d = this.$date.format(v.auditedAt).date
        return (c || n) && (!v.auditedAt || d < this.startDate || d > this.endDate)
      })
    }
  },
  methods: {
    dataList () {
      if (!this.isRetainingLoaded) return
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()
      this.items = []
      this.$apollo.query({
        query: LIST_REPORT_STOCK_AUDITED_BY_INTERVAL(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          startDate: this.startDate,
          endDate: this.endDate
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.rows
      })
      .catch(this.$toasted.global.error)
    },
    toDetail (item) {
      return {
        name: this.detailView,
        params: {inventoryId: item.id},
        query: {redirect: this.$route.name}
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
