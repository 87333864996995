import gql from 'graphql-tag'


export const LIST_REPORT_TRAINGING = (templateType) => gql`query LIST_REPORT_TRAINGING ($docType: String!, $startDate: DateTime!, $endDate: DateTime!) {
  items: listDoc${templateType}ReportTraining (docType: $docType, startDate: $startDate, endDate: $endDate)
}`

export const SYNC_REPORT_TRAINGING = (templateType) => gql`mutation SYNC_REPORT_TRAINGING ($docType: String!, $month: String!) {
  result: syncDoc${templateType}ReportTraining (docType: $docType, month: $month)
}`
