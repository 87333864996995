<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12 col-md-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <DetailFormInventoryInput
        v-if="method === 'add'"
        label="สินค้า"
        :docType="docType"
        :templateType="templateType"
        class="col-12 col-md-8"
        select="id"
        v-model="formData.inventoryId"
        :validations="[
          {text: 'required!', value: v.formData.inventoryId.$dirty && v.formData.inventoryId.$error}
        ]">
        <template slot-scope="option">
          {{option.inventory.code}} ({{option.inventory.name}})
        </template>
      </DetailFormInventoryInput>

      <sgv-input-text
        v-else
        disabled
        class="col-12 col-md-8"
        label="สินค้า"
        :value="`${formData.inventory.code} (${formData.inventory.name})`">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12 col-md-6"
        :rows="3"
        auto-grow
        label="ปัญหา (สติ๊กเกอร์)"
        v-model="formData.remark"
        :validations="[
          {text: 'required!', value: v.formData.remark.$dirty && v.formData.remark.$error}
        ]">
      </sgv-input-textarea>

      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12 col-md-6"
        :rows="3"
        auto-grow
        label="สาเหตุ"
        v-model="formData.content"
        :validations="[
          {text: 'required!', value: v.formData.content.$dirty && v.formData.content.$error}
        ]">
      </sgv-input-textarea>

      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12 col-md-6"
        :rows="3"
        auto-grow
        label="การแก้ไขปัญหา"
        v-model="formData.revisionPlan">
      </sgv-input-textarea>

      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12 col-md-6"
        :rows="3"
        auto-grow
        label="การป้องกันสาเหตุ"
        v-model="formData.protectionPlan">
      </sgv-input-textarea>
    </div>

    <CategoryCheck
      :docId="docId"
      :docType="docType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      :method="method"
      :isClosed="!!formData.closedAt || !!formData.approvedAt"
      v-model="formData.categories"
      @updated="emitUpdated">
    </CategoryCheck>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-4"
        label="สร้าง"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="อนุมัติ"
        :value="getState('approved')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="จบ"
        :value="getState('closed')">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
import DetailFormInventoryInput from './DetailFormInventoryInput'
import CategoryCheck from '@/views/doc_core/components/CategoryCheck'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
    configs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {

    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  components: {
    DetailFormInventoryInput,
    CategoryCheck
  }
}
</script>

<style lang="css">
</style>
