<template>
  <div class="">
    <div class="row">
      <div class="col-12" v-if="toggle">
        <div class="form-row">
          <sgv-input-number
            class="col-6"
            :label="item.name"
            v-model="item.targetValue"
            :precision="2">
          </sgv-input-number>

          <DropdownUnit
            class="col-6"
            select="id"
            v-model="item.unitId"
            label="หน่วย"
            :inventoryType="inventoryType"
            :templateType="templateType">
          </DropdownUnit>
        </div>
      </div>

      <div v-else class="col-12">
        <div class="form-row">
          <sgv-input-number
            disabled
            class="col-6"
            :value="item.targetValue"
            :precision="2">
            <label slot="label">
              {{item.name}}
              <span
                v-if="sample !== 0"
                class="text-info">
                ({{sample * item.targetValue | comma}})
              </span>
            </label>
          </sgv-input-number>

          <sgv-input-text
            disabled
            class="col-6"
            label="หน่วย"
            :value="item.unit ? item.unit.name: ''">
          </sgv-input-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownUnit from './DropdownUnit'

export default {
  props: {
    toggle: {
      type: Boolean,
      required: true
    },
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    sample: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {

    }
  },
  components: {
    DropdownUnit
  }
}
</script>

<style lang="css" scoped>
</style>
