<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">ค้างสต๊อก</h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <Category
            :inventoryType="inventoryType"
            :templateType="templateType"
            :options="categories"
            v-model="categoryId">
          </Category>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :rKey="rKey"
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot="first-row" slot-scope="{hidden}">
        <tr
          class="text-info"
          v-for="(qty, unit) in qtyByGroup(items)"
          :key="unit">
          <td v-if="hidden.code">ยอดรวม</td>
          <td v-if="hidden.qty" class="text-right">
            {{qty | comma(-1)}}
          </td>
          <td v-if="hidden.unit" class="text-center">
            {{unit}}
          </td>
          <td v-if="hidden.approvedAt"></td>
          <td v-if="hidden.doc"></td>
        </tr>
      </template>

      <template slot-scope="{item, hidden}">
        <tr class="">
          <td
            v-if="hidden.code"
            @click="toDetail(item.id)"
            class="pointer no-wrap">
            {{item.code}}
            <div class="text-primary">
              <small>{{item.name}}</small>
            </div>
          </td>
          <td v-if="hidden.qty" class="text-right">
            {{item.qty | comma(-1)}}
          </td>
          <td v-if="hidden.unit" class="text-center">
            {{item.unit.name}}
          </td>
          <td v-if="hidden.approvedAt" class="text-danger">
            {{item.approvedAt | date}}
          </td>
          <td v-if="hidden.doc">
            {{item.doc.code}}
            <div class="text-primary">
              <small>{{item.doc.name}}</small>
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import Category from '@/views/inventory_core/components/Category.vue'
import { LIST_DEADSTOCK } from './graph/deadstock'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-ค้างสต๊อก`
    }
  },
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      detailView: `Inventory${this.$form.capitalize(this.inventoryType)}Detail`,
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}DeadStock`,
      rFields: ['filter', 'options', 'categoryId'],
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'หน่วย', value: 'unit'},
        {text: 'วันที่', value: 'approvedAt'},
        {text: 'เอกสาร', value: 'doc'},
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'qty', 'unit', 'approvedAt', 'doc'],
        column: null,
        search: null,
        toolbar: null,
      },
      rowsPerPage: [
        { text: 'ทั้งหมด', value: null },
      ],
      items: [],
      categoryId: 0,
      categories: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_DEADSTOCK(this.templateType)
      },
      variables () {
        this.setRetaining()
        return {
          inventoryType: this.inventoryType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}

      if (this.categoryId === -1) {
        filter.params.isActive = false
        delete filter.params.categoryId
      } else {
        filter.params.isActive = true
        filter.params.categoryId = this.categoryId
      }

      return filter
    },
    toDetail (id) {
      this.$router.push({
        name: this.detailView,
        params: {inventoryId: id},
        query: {redirect: this.rKey}
      })
    },
    qtyByGroup (arr) {
      return arr.reduce((t,v) => {
        if (!t[v.unit.name]) t[v.unit.name] = 0
        t[v.unit.name] += +v.qty
        return t
      }, {})
    }
  },
  created () {
    this.$store.commit(
      'path/setCurrent',
      {to: this.$route, group: this.group}
    )
  },
  components: {
    Category
  }
}
</script>

<style lang="css" scoped>
</style>
