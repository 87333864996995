import gql from 'graphql-tag'


export const LIST_DROPDOWN_UNIT = (templateType) => gql`query LIST_DROPDOWN_UNIT ($inventoryCostType: String!, $q: FilterInput) {
  items: listInventory${templateType}DropdownUnit (inventoryCostType: $inventoryCostType, q: $q) {
    id name
  }
}`

export const LIST_DROPDOWN_INVENTORY = (templateType) => gql`query LIST_DROPDOWN_INVENTORY ($inventoryCostType: String!, $q: FilterInput) {
  items: listInventory${templateType}DropdownInventory (inventoryCostType: $inventoryCostType, q: $q) {
    id type code name
  }
}`

export const LIST_DROPDOWN_ORDER = (templateType) => gql`query LIST_DROPDOWN_ORDER ($inventoryCostType: String!, $inventoryCostId: Int, $q: FilterInput) {
  items: listInventory${templateType}DropdownOrder (inventoryCostType: $inventoryCostType, inventoryCostId: $inventoryCostId, q: $q) {
    id name
    inventoryCostId inventoryCost {id type code name}
  }
}`
