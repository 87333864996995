<template>
  <span class="">
    <small>
      <fa
        icon="info-circle"
        class="text-secondary pointer"
        @click="showManual">
      </fa>
    </small>

    <b-modal
      :id="uid"
      title="คู่มือการใช้งาน"
      footer-class="d-none">
      <SgvManualContent
        v-for="content in paper.contents"
        :key="content.id"
        :level="1"
        :paperId="paper.id"
        :content="content">
      </SgvManualContent>
    </b-modal>
  </span>
</template>

<script>
import SgvManualContent from './SgvManualContent.vue'

export default {
  name: 'SgvManual',
  props: {
    graph: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      uid: this.$form.uid(),
      paper: ''
    }
  },
  methods: {
    showManual () {
      this.$apollo.query({
        query: this.graph
      })
      .then(res => {
        this.paper = res.data.userManual
        this.$bvModal.show(this.uid)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  components: {
    SgvManualContent
  }
}
</script>

<style lang="css" scoped>
</style>
