import gql from 'graphql-tag'


const orderResponse = `
  id type totalPrice
  ledgers {
    id docId orderId amount
    accountId account {id code name}
  }
  parentId parent {
    id docId doc {
      id type code name closedAt
      contactId contact {id type code name}
    }
  }
`

export const LIST_ORDER_STUDENT_LOAN = (templateType) => gql`query LIST_ORDER_STUDENT_LOAN ($docType: String!, $docId: Int!, $q: FilterInput) {
  orders: listDoc${templateType}OrderStudentLoan (docType: $docType, docId: $docId, q: $q) {${orderResponse}}
}`

export const LIST_ORDER_STUDENT_LOAN_PAYABLE = (templateType) => gql`query LIST_ORDER_STUDENT_LOAN_PAYABLE ($docType: String!, $search: String, $startDate: DateTime, $endDate: DateTime) {
  docs: listDoc${templateType}OrderStudentLoanPayable (docType: $docType, search: $search, startDate: $startDate, endDate: $endDate) {
    id type code name remark contactId
    orders {
      id type name total paid pending remaining
      account {
        id code name
      }
    }
  }
}`


export const CREATE_ORDER_STUDENT_LOAN = (templateType) => gql`mutation CREATE_ORDER_STUDENT_LOAN ($docType: String!, $docId: Int!, $input: [Doc${templateType}OrderPayableInput]!) {
  orders: createDoc${templateType}OrderStudentLoan (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const DESTROY_ORDER_STUDENT_LOAN = (templateType) => gql`mutation DESTROY_ORDER_STUDENT_LOAN ($docType: String!, $docId: Int!, $parentIds: [Int!]!) {
  destroyOrders: destroyDoc${templateType}OrderStudentLoan (docType: $docType, docId: $docId, parentIds: $parentIds) {id}
}`
