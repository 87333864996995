import { capitalize } from '@/libs/form'

export default ({bankStatementType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `bankStatement/${bankStatementType}/`,
      name: `BankStatement${capitalize(bankStatementType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        bankStatementType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
