import _ from 'lodash'
import shortid from 'shortid'


export const uid = () => {
	return shortid.generate()
}

export const isNumber = (value) => {
	return !isNaN(parseFloat(value))
}

export const isNumberInRange = (value, minValue, maxValue) => {
	if (+value >= +minValue && +value <= +maxValue) return true

	return false
}

export const overlap = (arr1, arr2) => {
	if (typeof arr1 === 'string') arr1 = [arr1]
	if (typeof arr2 === 'string') arr2 = [arr2]
	return arr2.some(v => arr1.includes(v))
}

export const round = (num, precision = 2) => {
	return _.round(num, precision)
}

export const zeroFill = (number, width) => {
	width -= number.toString().length
	if (width > 0) {
		return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number
	}
	return number + ""
}

export const thaiNumberToText = (num) => {
	num = num.replace(/๐/gi, '0')
	num = num.replace(/๑/gi, '1')
	num = num.replace(/๒/gi, '2')
	num = num.replace(/๓/gi, '3')
	num = num.replace(/๔/gi, '4')
	num = num.replace(/๕/gi, '5')
	num = num.replace(/๖/gi, '6')
	num = num.replace(/๗/gi, '7')
	num = num.replace(/๘/gi, '8')
	num = num.replace(/๙/gi, '9')
	return arabicNumberToText(num)
}

export const arabicNumberToText = (value) => {
	var num = checkNumber(value)
	var numArray = new Array("ศูนย์", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า", "สิบ")
	var digitArray = new Array("", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน")
	var bahtText = ""
	if (isNaN(num)) {
		return "ข้อมูลนำเข้าไม่ถูกต้อง"
	} else {
		if ((num - 0) > 9999999.9999) {
			return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้"
		} else {
			num = num.split(".")
			if (num[1].length > 0) {
				num[1] = num[1].substring(0, 2)
			}
			let numLen = num[0].length - 0
			for (let i = 0; i < numLen; i++) {
				let tmp = num[0].substring(i, i + 1) - 0
				if (tmp != 0) {
					if ((i == (numLen - 1)) && (tmp == 1)) {
						bahtText += "เอ็ด"
					} else
						if ((i == (numLen - 2)) && (tmp == 2)) {
							bahtText += "ยี่"
						} else
							if ((i == (numLen - 2)) && (tmp == 1)) {
								bahtText += ""
							} else {
								bahtText += numArray[tmp]
							}
					bahtText += digitArray[numLen - i - 1]
				}
			}
			bahtText += "บาท"
			if ((num[1] == "0") || (num[1] == "00")) {
				bahtText += "ถ้วน"
			} else {
				let decimalLen = num[1].length - 0
				for (let i = 0; i < decimalLen; i++) {
					let tmp = num[1].substring(i, i + 1) - 0
					if (tmp != 0) {
						if ((i == (decimalLen - 1)) && (tmp == 1)) {
							bahtText += "เอ็ด"
						} else
							if ((i == (decimalLen - 2)) && (tmp == 2)) {
								bahtText += "ยี่"
							} else
								if ((i == (decimalLen - 2)) && (tmp == 1)) {
									bahtText += ""
								} else {
									bahtText += numArray[tmp]
								}
						bahtText += digitArray[decimalLen - i - 1]
					}
				}
				bahtText += "สตางค์"
			}
			return bahtText
		}
	}
}

export const checkNumber = (num) => {
	var decimal = false
	num = num.toString()
	num = num.replace(/ |,|บาท|฿/gi, '')
	for (var i = 0; i < num.length; i++) {
		if (num[i] == '.') {
			decimal = true
		}
	}
	if (decimal == false) {
		num = num + '.00'
	}
	return num
}

export const addComma = (v, decimal = 2) => {
	if (typeof v === 'string') {
		v = v.replace(',', '')
	}
	var num = parseFloat(v)
	var nStr

	if (decimal === -1) {
		nStr = num.toString()
	} else {
		nStr = num.toFixed(decimal)
	}

	nStr += ''
	var x = nStr.split('.')
	var x1 = x[0]
	var x2 = x.length > 1 ? '.' + x[1] : ''
	var rgx = /(\d+)(\d{3})/
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + ',' + '$2')
	}
	return x1 + x2
}

export const normDiscount = (str) => {
	if (str) {
		return str.replace(/\+/g, '%')
	}
	return str
}

export const capitalize = (s) => {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
}

export const isJsonString = (txt) => {
	try {
		JSON.parse(txt)
	} catch (e) {
		return false
	}
	return true
}

export const delay = async (ms) => {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve()
		}, ms)
	})
}