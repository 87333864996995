<template>
  <div class="row justify-content-start mb-3">
    <div class="col-12 mb-3">
      <div class="form-row">
        <sgv-input-text
          :focus="barcodeFocus"
          class="col-6 col-sm-4"
          label="รหัส"
          placeholder="สแกนบาร์โค๊ด..."
          v-model="barcode"
          @keyup.enter="setAudit">
        </sgv-input-text>
      </div>

      <button class="btn btn-outline-primary mr-2" @click="clearAudit">รีเซต</button>
      <button
        v-if="!isLoading && currentItems.length === 0 && $auth.hasRole(`doc:${docType}:approve`)"
        class="btn btn-outline-success mr-2"
        @click="closeData">
        บันทึกนับสต๊อก
      </button>
    </div>

    <div class="col-6" v-if="currentItems">
      ในระบบ: {{totalCurrent}}
      <ul>
        <li v-for="(item, i) in currentItems" :key="i">
          ID: {{item.id}} (<span class="text-success">{{item.remaining}}</span>)
        </li>
      </ul>
    </div>

    <div class="col-6" v-if="actualItems">
      ปัจจุบัน: {{totalActual}}
      <ul>
        <li v-for="(item, i) in actualItems" :key="i">
          ID: {{item.id}} (<span class="text-success">{{item.remaining}}</span>)
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  LIST_STOCK,
  CHECK_STOCK,
  CLEAR_STOCK,
  CLOSE_DOC
} from './graph'
import round from 'lodash/round'

export default {
  name: 'StockAudit',
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      barcode: '',
      barcodeFocus: false,
      isLoading: true,
      items: []
    }
  },
  computed: {
    currentItems () {
      return this.items.filter(v => !v.isAudited)
    },
    actualItems () {
      return this.items.filter(v => v.isAudited)
    },
    totalCurrent () {
      return round(this.currentItems.reduce((t, v) => {
        return t + Number(v.remaining)
      }, 0), 2)
    },
    totalActual () {
      return round(this.actualItems.reduce((t, v) => {
        return t + Number(v.remaining)
      }, 0), 2)
    },
  },
  methods: {
    classifyBarcode (txt) {
      const arr = txt.split(',')
      const type = arr[0]
      let value

      if (type === 'stockId') {
        arr.shift()
        value = arr.join(',')
      } else if (this.$form.isNumber(txt)) {
        value = txt
      } else {
        this.$toasted.global.error('บาร์โค๊ดไม่ถูกต้อง')
      }

      return Number(value)
    },
    fetchData () {
      this.$apollo.query({
        query: LIST_STOCK(this.templateType),
        variables: {
          docType: this.docType,
          inventoryId: this.inventoryId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.stocks
      })
      .catch(err => {
        this.items = []
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    setAudit (event) {
      const barcode = this.classifyBarcode(event.target.value)
      this.barcode = ''
      this.barcodeFocus = false
      if (!this.$form.isNumber(barcode)) {
        return this.$toasted.global.error('ไม่ใช่ข้อมูลตัวเลข')
      }

      this.$apollo.mutate({
        mutation: CHECK_STOCK(this.templateType),
        variables: {
          docType: this.docType,
          stockId: barcode
        }
      })
      .then(() => {
        let found = this.items.find(v => v.id === barcode)
        if (!found) {
          this.$toasted.global.error('สินค้าไม่ตรงรุ่น')
        } else if (found.isAudited) {
          this.$toasted.global.success('ค้นเจอไปแล้ว')
        } else {
          found.isAudited = true
          this.$toasted.global.success('ผ่าน')
        }
        this.barcodeFocus = true
      })
      .catch(err => {
        this.barcodeFocus = true
        this.$toasted.global.error(err)
      })
    },
    clearAudit () {
      this.$apollo.mutate({
        mutation: CLEAR_STOCK(this.templateType),
        variables: {
          docType: this.docType,
          inventoryId: this.inventoryId
        }
      })
      .then(() => {this.fetchData()})
      .catch(this.$toasted.global.error)
    },
    closeData () {
      this.$apollo.mutate({
        mutation: CLOSE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        }
      })
      .then(() => {
        this.$emit('method', 'info')
        this.$toasted.global.success("จบรายการสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
</style>
