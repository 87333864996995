import gql from 'graphql-tag'


const listResponse = `
  id type name isFixPrice
  qty stdPrice totalPrice priceLimit
  outputDocPercent outputDocPrice outputDocInRange
  outputPredictPercent outputPredictPrice outputPredictInRange
  unitId unit {id name}
  inventoryId inventory {
    id type code name
    unitId unit {id name}
  }
`

export const LIST_INVENTORY_COST_ORDER_OUTLET = (templateType) => gql`query LIST_INVENTORY_COST_ORDER_OUTLET ($inventoryCostType: String!, $inventoryCostId: Int!) {
  orders: listInventory${templateType}OrderOutlet (inventoryCostType: $inventoryCostType, inventoryCostId: $inventoryCostId) {${listResponse}}
}`

export const CREATE_INVENTORY_COST_ORDER_OUTLET = (templateType) => gql`mutation CREATE_INVENTORY_COST_ORDER_OUTLET ($inventoryCostType: String!, $inventoryCostId: Int!, $input: Inventory${templateType}OrderOutletInput!) {
  createOrder: createInventory${templateType}OrderOutlet (inventoryCostType: $inventoryCostType, inventoryCostId: $inventoryCostId, input: $input) {${listResponse}}
}`

export const UPDATE_INVENTORY_COST_ORDER_OUTLET = (templateType) => gql`mutation UPDATE_INVENTORY_COST_ORDER_OUTLET ($inventoryCostType: String!, $inventoryCostOrderId: Int!, $input: Inventory${templateType}OrderOutletInput!) {
  updateOrder: updateInventory${templateType}OrderOutlet (inventoryCostType: $inventoryCostType, inventoryCostOrderId: $inventoryCostOrderId, input: $input) {${listResponse}}
}`

export const DESTROY_INVENTORY_COST_ORDER_OUTLET = (templateType) => gql`mutation DESTROY_INVENTORY_COST_ORDER_OUTLET ($inventoryCostType: String!, $inventoryCostOrderId: Int!) {
  destroyOrder: destroyInventory${templateType}OrderOutlet (inventoryCostType: $inventoryCostType, inventoryCostOrderId: $inventoryCostOrderId) {id}
}`
