<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          <span class="mr-2">รายการ</span>
          <router-link
            class="text-decoration-none text-secondary mr-2"
            v-if="$auth.hasRole(`contact:${this.contactType}:config`)"
            :to="toContactConfig">
            <small><fa icon="cog"></fa></small>
          </router-link>
          <UserManual
            :contactType="contactType"
            :templateType="templateType">
          </UserManual>
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <Category
            :contactType="contactType"
            :templateType="templateType"
            :options="categories"
            v-model="categoryId">
          </Category>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <Location
      v-if="categoryId === -3"
      :contactType="contactType"
      :templateType="templateType"
      :group="group">
    </Location>

    <Validation
      v-else-if="categoryId === -2"
      :contactType="contactType"
      :templateType="templateType"
      :group="group">
    </Validation>

    <ListCategory
      v-else
      :categoryId="categoryId"
      :contactType="contactType"
      :templateType="templateType"
      :group="group">
    </ListCategory>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import Category from '@/views/contact_core/components/Category.vue'
import Location from '@/views/contact_core/components/Location.vue'
import UserManual from '@/views/contact_core/components/UserManual.vue'
import Validation from '@/views/contact_core/components/Validation'
import ListCategory from './ListCategory'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-รายการ`
    }
  },
  props: {
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      contactConfigView : `Contact${this.$form.capitalize(this.contactType)}ConfigList`,
      rKey: `Contact${this.$form.capitalize(this.contactType)}List`,
      rFields: ['categoryId'],
      categoryId: 0,
      categories: [
        {text: 'แผนที่', value: -3},
        {text: 'ตรวจสอบ', value: -2},
      ]
    }
  },
  computed: {
    toContactConfig () {
      return {name: this.contactConfigView}
    },
  },
  watch: {
    categoryId: 'setRetaining'
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    Category,
    ListCategory,
    UserManual,
    Validation,
    Location,
  }
}
</script>

<style lang="css" scoped>
</style>
