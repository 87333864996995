import { capitalize } from '@/libs/form'

export default ({auditType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `audit/${auditType}`,
      name: `Audit${capitalize(auditType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        auditType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
