<template>
  <div class="">
    <sgv-table
      ref="docList"
      :rKey="rKey"
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code">
            <router-link
              :class="{'disabled': !item.isLink, 'text-muted': !item.isLink}"
              class="text-decoration-none"
              :to="toDetail(item)">
              {{item.code}}
            </router-link>
          </td>
          <td v-if="hidden.createdAt">
            {{ item.createdAt | date }}<br>
            <small class="text-primary">{{item.createdUser.name}}</small>
          </td>
          <td v-if="hidden.approvedAt">
            <template v-if="item.approvedAt">
              <span class="text-warning">{{item.approvedAt | date}}</span><br>
              <small class="text-primary">{{item.approvedUser.name}}</small>
            </template>
          </td>
          <td v-if="hidden.closedAt">
            <template v-if="item.closedAt">
              <span class="text-success">{{item.closedAt | date}}</span><br>
              <small class="text-primary">{{item.closedUser.name}}</small>
            </template>
          </td>
          <td v-if="hidden.name">
            {{ item.name }}
            <small v-if="item.remark">
              <div class="text-primary">{{ item.remark }}</div>
            </small>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_DOC } from '../graph/doc'

export default {
  mixins: [retainMixin],
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'วันที่บันทึก', value: 'createdAt', sort: true, filter: false},
        {text: 'วันที่อนุมัติ', value: 'approvedAt', sort: true, filter: false},
        {text: 'วันที่เสร็จ', value: 'closedAt', sort: true, filter: false},
        {text: 'รายละเอียด', value: 'name', sort: false, filter: true}
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'createdAt', 'approvedAt', 'closedAt', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: [],
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}DocList`,
      rFields: ['filter', 'options']
    }
  },
  apollo: {
    docs: {
      query () {
        return LIST_DOC(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          inventoryType: this.inventoryType,
          inventoryId: this.inventoryId,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
      result (res) {
        this.items = res.data.docs.map(v => {
          return {
            ...v,
            isLink: this.$auth.hasRole(`doc:${v.type}:read`)
          }
        })
      }
    }
  },
  methods: {
    getFilter (v) {
      return {...v, params: v.params || {}}
    },
    toDetail (item) {
      return {
        name: `Doc${this.$form.capitalize(item.type)}Detail`,
        params: {docId: item.id}
      }
    },
  }
}
</script>
