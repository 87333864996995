import gql from 'graphql-tag'


export const LIST_NOTIFICATION = (templateType) => gql`query LIST_NOTIFICATION ($inventoryType: String!){
  notifications: listInventory${templateType}Notification (inventoryType: $inventoryType) {
    id type code name
    unitId unit {id name}
    upperLimit lowerLimit purchaseLimit
    balance {current future}
    lowMessage {qty message}
    highMessage {qty message}
  }
}`
