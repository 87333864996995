<template>
  <div class="">
    <template v-if="!headerless">
      <div class="row mb-3">
        <div class="col-12 col-sm-6">
          <slot name="action"></slot>
        </div>
        <div class="col-12 col-sm-6 text-right">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="ค้นหา..." @input="emitSearch($event.target.value)" :value="options.search">
            <div class="input-group-append">
              <span class="input-group-text pointer" @click="reset">
                <fa icon="sync-alt" class="text-primary"></fa>
              </span>
              <span
                class="input-group-text pointer"
                @click="emitToolbar(toolbar)"
                v-for="toolbar in toolbarsByRole"
                :key="toolbar.value">

                <fa v-if="toolbar.icon" :icon="toolbar.icon" :class="toolbar.class"></fa>
                <span v-if="toolbar.text" :class="toolbar.class">{{toolbar.text}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3 mt-4" v-if="options.toolbar === 'filter'">
        <div class="col-12">
          <div class="form-row">
            <div class="form-group col-12">
              <div class="form-check form-check-inline" v-for="header in headers" :key="header.value">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="uid + 'header' + header.value"
                  :checked="getMatchHeader(header.value)"
                  @click="emitHeader(header.value)">

                <label class="form-check-label" :for="uid + 'header' + header.value">{{header.text}}</label>
              </div>
            </div>
            <sgv-input-select
              label="แถว / หน้า"
              class="col-sm-3 col-6"
              :options="rowsPerPage"
              :value="filter.limit"
              @input="emitLimit"
              select="value">
              <template slot-scope="option">
                {{option.text}}
              </template>
            </sgv-input-select>
            <slot name="filter"></slot>
          </div>
        </div>
      </div>

      <slot name="option"></slot>
    </template>

    <div class="row">
      <div class="col-12 table-responsive">
        <table class="table" :class="tableClass">
          <thead>
            <tr>
              <th v-if="selectAll">
                <input type="checkbox" @click="$emit('selectAll', true)">
              </th>
              <th
                v-for="header in headersFilter"
                :key="header.value"
                class="header"
                :class="[...getMatchSort(header.value), ...getHeaderClass(header)]"
                @click="emitSort(header)">

                <fa
                  v-if="header.filter"
                  icon="filter"
                  :class="getMatchColumn(header.value)"
                  @click.stop="emitColumn(header)">
                </fa>&nbsp;
                <span>{{header.text}}</span>

              </th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="items.length > 0" key="data">
              <slot name="first-row" v-bind="{hidden: isHidden()}"></slot>
              <slot v-for="(item,idx) in items" v-bind="{item, hidden: isHidden(), idx}"></slot>
            </tbody>
            <tbody v-else key="nodata">
              <tr>
                <td :colspan="activeHeader" class="">
                  {{message}}
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SgvTable2',
  props: {
    rKey: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    headerless: {
      type: Boolean,
      required: false
    },
    headers: {
      type: Array,
      required: true
    },
    filter: {
      type: Object,
      required: false,
      default () {
        return {
          limit: 10,
          offset: 0,
          order: null,
          params: null
        }
      }
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {
          headers: [],
          filter: null,
          sort: null,
          search: null,
          toolbar: null,
        }
      }
    },
    selectAll: {
      type: Boolean,
      required: false
    },
    toolbars: {
      type: Array,
      required: false,
      default () {
        return [
          {value: 'filter', icon: 'cog', class: 'text-warning'}
        ]
      }
    },
    rowsPerPage: {
      type: Array,
      required: false,
      default () {
        return [
          {text: '10', value: 10},
          {text: '25', value: 25},
          {text: '50', value: 50},
        ]
      }
    },
    tableClass: {
      type: Array,
      required: false,
      default () {
        return [
          'table-hover'
        ]
      }
    }
  },
  data () {
    return {
      uid: this.$form.uid(),
      message: ''
    }
  },
  computed: {
    activeHeader () {
      return this.headersFilter.length
    },
    headersFilter () {
      return this.headers.filter(v => this.options.headers.includes(v.value))
    },
    toolbarsByRole () {
      return this.toolbars.filter(v => this.$auth.hasRole(v.role))
    }
  },
  methods: {
    getHeaderClass(header) {
      if (header && header.class && Array.isArray(header.class)) {
        return header.class
      } else {
        return []
      }
    },
    isHidden () {
      let result = this.headers.reduce((obj, v) => {
        obj[v.value] = this.options.headers.includes(v.value)
        return obj
      }, {})
      return result
    },
    getMatchSort (value) {
      if (!this.filter.order) return []
      let found = this.filter.order.search(value)

      const arr = []
      if (found === 0) {
        arr.push('headerSortDown')
      } else if (found === 1) {
        arr.push('headerSortUp')
      }

      return arr
    },
    getMatchColumn (value) {
      if (this.options.column === value) return ['text-warning']
      return ['text-secondary']
    },
    getMatchHeader (value) {
      return this.options.headers.find(v => v === value)
    },
    emitHeader (value) {
      const index = this.options.headers.findIndex(v => v === value)
      const headers = [...this.options.headers]
      if (index !== -1) {
        headers.splice(index, 1)
      } else {
        headers.push(value)
      }

      if (this.options.column && !headers.includes(this.options.column)) {
        const { params } = this.serialSearch(this.options.column, this.options.search)
        const filter = {...this.filter, params}
        this.$emit('update:options', {...this.options, headers, column: null})
        this.$emit('update:filter', filter)
      } else {
        this.$emit('update:options', {...this.options, headers})
        this.emitFilter()
      }
    },
    emitLimit (value) {
      const filter = {...this.filter, limit: value, offset: 0}
      this.$emit('update:filter', filter)
    },
    emitColumn (header) {
      const column = this.options.column === header.value ? null : header.value
      const { params } = this.serialSearch(column, this.options.search)
      const filter = {...this.filter, params}
      this.$emit('update:options', {...this.options, column})
      this.$emit('update:filter', filter)
    },
    emitSort (header) {
      if (!header.sort) return
      let order
      if (!this.filter.order) {
        order = header.value
      } else {
        let found = this.filter.order.search(header.value)
        if (found === -1) {
          order = header.value
        } else {
          if (found === 0) {
            order = '-' + header.value
          } else {
            order = header.value
          }
        }
      }
      const filter = {...this.filter, order}
      this.$emit('update:filter', filter)
    },
    serialSearch (column, search) {
      search = search ? search.trimStart() : ''
      let params = this.filter.params ? {...this.filter.params} : {}
      this.headers.forEach(header => {
        if (header.filter && params[header.value]) delete params[header.value]
      })
      delete params.search
      if (search) {
        if (column) params[column] = search
        else params.search = search
      } else {
        if (Object.keys(params).length === 0) params = null
      }
      return {search, params}
    },
    emitSearch (value) {
      const {search, params} = this.serialSearch(this.options.column, value)
      const filter = {...this.filter, params, offset: 0}
      this.$emit('update:options', {...this.options, search})
      this.$emit('update:filter', filter)
    },
    emitToolbar (toolbar) {
      if (toolbar.func) {
        toolbar.func()
      } else {
        let result = null
        if (this.options.toolbar !== toolbar.value) result = toolbar.value
        this.$emit('update:options', {...this.options, toolbar: result})
        this.emitFilter()
      }
    },
    reset () {
      if (this.rKey) {
        const userName = localStorage.getItem('userName')
        let localData = localStorage.getItem(userName)
        let retaining = {}
        if (localData) {
          retaining = JSON.parse(localData)
        }
        delete retaining[this.rKey]
        localStorage.setItem(userName, JSON.stringify(retaining))

        this.$router.push({
          name: this.$route.name,
          query: {...this.$route.query, t: this.$form.uid()}
        })
      } else {
        this.emitFilter()
      }
    },
    emitFilter: _.debounce(function () {
      this.$emit('filter', this.filter)
    }, 200)
  },
  watch: {
    filter: {
      handler: 'emitFilter',
      immediate: true,
      deep: true
    },
    rowsPerPage (value) {
      const found = value.find(v => v.value === value)
      if (!found) this.emitLimit(value[0].value)
    }
  },
  created () {
    setTimeout(() => {
      if (this.items.length === 0) this.message = 'ไม่พบข้อมูล'
    }, 1000)
  }
}
</script>

<style lang="css">

</style>
