<template>
  <div>
    <ListCategorySummary
      v-if="docs.length > 0"
      :items="docs"
      :selected="selectedAccountIds"
      @account="selectedAccountIds = $event"
      @selectAll="onSelectAll">
    </ListCategorySummary>

    <sgv-table
      :rKey="rKey"
      :items="contacts"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot="action">
        <button
          v-if="selectedContactIds.length > 0"
          type="button" 
          class="btn btn-warning"
          @click="hideAll">
          ซ่อนเอกสาร
        </button>

        <button
          v-else
          type="button" 
          class="btn btn-warning"
          @click="showAll">
          แสดงเอกสาร
        </button>
      </template>

      <template slot-scope="{item, hidden, idx}">
        <tr 
          class="pointer"
          :key="`contact-${item.id}`"
          @click="toggle(item.id)">
          <td :colspan="headers.length">
            <span>{{ idx+1 }}. {{ item.code }} ({{ item.name }})</span>
            <span class="text-warning mx-2">จำนวน {{ item.docs.length }} รายการ</span>
            <span class="text-info">มูลค่า {{ item.remaining | comma }} บาท</span>
          </td>
        </tr>
        <tr 
          v-show="selectedContactIds.includes(item.id)"
          v-for="doc in item.docs" 
          :key="doc.id">
          <td v-if="hidden.code">
            <router-link
              class="text-decoration-none no-wrap"
              :to="toDoc(doc)">
              <span :class="getStatus(doc)">
                {{doc.code}}
              </span>
            </router-link>
            <div v-if="doc.invoiceRef">
              <small class="text-warning">
                <fa icon="truck"></fa> {{doc.invoiceRef}}
              </small>
            </div>

            <div v-if="doc.relevance && doc.relevance.payments.length > 0">
              <div v-if="doc.receiptRef">
                <small class="text-warning">
                  <fa icon="truck"></fa> {{doc.receiptRef}}
                </small>
              </div>
              <div v-for="rel in doc.relevance.payments" :key="rel.id">
                <router-link
                  :to="toDoc(rel)"
                  class="text-decoration-none">
                  <small style="white-space: nowrap;" class="text-info">
                    <fa icon="dollar-sign"></fa>
                    <span class="ml-1">{{rel.code}}</span>
                  </small>
                </router-link>
              </div>
            </div>
          </td>
          <td v-if="hidden.orders" class="no-wrap">
            <span :class="[doc.remaining > 0 ? 'text-success' : 'text-danger']">
              {{doc.remaining | comma}}
            </span>
            /
            <span :class="[doc.amount > 0 ? 'text-success' : 'text-danger']">
              {{doc.amount | comma}} บาท
            </span>

            <small v-if="doc.account">
              <div class="text-info">
                {{doc.account.code}} ({{doc.account.name}})
              </div>
            </small>

            <small v-if="doc.orders.length > 0">
              <div v-for="order in doc.orders" :key="order.id">
                <div v-for="(child,chIdx) in order.children" :key="child.id">
                  <div
                    class="text-info"
                    v-for="ledger in child.ledgers"
                    :key="ledger.id">
                    {{chIdx+1}}. 
                    <router-link
                      :to="toDoc(child.doc)"
                      class="text-decoration-none text-info">
                      {{child.doc.code}}
                    </router-link>
                    ->
                    <span :class="[ledger.amount > 0 ? 'text-success' : 'text-danger']">
                      {{ledger.amount | comma}} บาท
                    </span>
                  </div>
                </div>
              </div>
            </small>
          </td>

          <td v-if="hidden.createdAt">
            {{ doc.createdAt | date }}<br>
            <small class="text-primary">{{doc.createdUser.name}}</small>
          </td>
          <td v-if="hidden.approvedAt">
            <span v-if="doc.approvedAt">
              <span class="text-warning">{{doc.approvedAt | date}}</span><br>
              <small class="text-primary">{{doc.approvedUser.name}}</small>
            </span>
          </td>
          <td v-if="hidden.closedAt">
            <span v-if="doc.closedAt">
              <span class="text-success">{{doc.closedAt | date}}</span><br>
              <small class="text-primary">{{doc.closedUser.name}}</small>
            </span>
          </td>
          <td v-if="hidden.name" class="no-wrap">
            {{doc.name}}
            <small>
              <div class="">
                {{doc.contact.code}} ({{doc.contact.name}})
                <span
                  class="px-1"
                  v-if="doc.contact.branch && doc.contact.branch !== 'สำนักงานใหญ่'">
                  สาขา: {{doc.contact.branch}}
                </span>
              </div>
              <div
                class="text-primary pre-line"
                v-if="doc.remark">
                {{doc.remark}}
              </div>
              <div class="text-info" v-if="doc.categories.length > 0">
                <fa icon="object-group"></fa>
                <span class="ml-1">{{mapCategoryText(doc.categories)}}</span>
              </div>
            </small>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import ListCategorySummary from './ListCategorySummary.vue'
import retainMixin from '@/mixins/retain-mixin'
import { LIST_DOC } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    accruedDate: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      detailView: `Doc${this.$form.capitalize(this.docType)}Detail`,
      rKey: `Doc${this.$form.capitalize(this.docType)}ListCategoryByContact`,
      filter: {
        limit: 10,
        offset: 0,
        order: 'closedAt',
        params: null
      },
      options: {
        headers: [
          'code', 'orders',
          'closedAt', 'name',
        ],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['filter', 'options'],
      docs: [],
      selectedAccountIds: [],
      selectedContactIds: []
    }
  },
  computed: {
    headers () {
      return [
        {text: 'รหัส', value: 'code', filter: true},
        {text: `ยอดรวม`, value: 'orders'},
        {text: 'วันที่สร้าง', value: 'createdAt'},
        {text: 'วันทีอนุมัติ', value: 'approvedAt'},
        {text: 'วันที่เสร็จ', value: 'closedAt', sort: true},
        {text: 'รายละเอียด', value: 'name', filter: true},
      ]
    },
    rowsPerPage () {
      return [
        { text: 'ทั้งหมด', value: null },
      ]
    },
    docsFilter () {
      return this.docs.filter(d => this.selectedAccountIds.includes(d.accountId))
    },
    contacts () {
      const obj = this.docsFilter.reduce((t,v) => {
        if (!t[v.contactId]) {
          t[v.contactId] = {
            id: v.contact.id,
            code: v.contact.code,
            name: v.contact.name,
            remaining: +v.remaining,
            docs: [v]
          }
        } else {
          t[v.contactId].remaining += +v.remaining
          t[v.contactId].docs.push(v)
        }
        return t
      }, {})

      return Object.keys(obj).map(key => obj[key]).sort((a,b) => a.name - b.name)
    }
  },
  methods: {
    fetchData () {
      if (!this.accruedDate) return

      this.docs = []

      this.setRetaining()

      this.$apollo.query({
        query: LIST_DOC(this.templateType),
        variables: {
          docType: this.docType,
          q: this.getFilter(this.filter),
          accruedDate: new Date(this.accruedDate + 'T00:00:00')
        },
        fetchPolicy: 'network-only',
      }).then(res => {
        this.docs = res.data.docs
        this.selectedAccountIds = [...new Set(res.data.docs.map(l => l.accountId))]
      })
    },
    getFilter (v) {
      const filter = {
        ...v,
        params: {
          ...v.params
        }
      }

      return filter
    },
    getStatus (item) {
      if (item.approvedAt && !item.closedAt) return ['text-warning']
      else if (item.closedAt) return ['text-success']
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
    mapCategoryText (categories) {
      return categories.map(v => v.name).join(', ')
    },
    toggle (contactId) {
      const index = this.selectedContactIds.findIndex(c => c === contactId)
      if (index === -1) {
        this.selectedContactIds.push(contactId)
      } else {
        this.selectedContactIds.splice(index, 1)
      }
    },
    showAll () {
      this.selectedContactIds = this.contacts.map(c => c.id)
    },
    hideAll () {
      this.selectedContactIds = []
    },
    onSelectAll () {
      const selectedAccountIds = [...new Set(this.selectedAccountIds)]
      const allAccountIds = [...new Set(this.docs.map(l => l.accountId))]

      if (selectedAccountIds.length === allAccountIds.length) {
        this.selectedAccountIds = []
      } else {
        this.selectedAccountIds = allAccountIds
      }
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ListCategorySummary
  }
}
</script>

<style lang="css" scoped>
</style>
