<template>
  <span class="">
    <span
      class="pointer mr-1"
      @click="toggle">
      <slot></slot>
    </span>

    <b-modal
      v-model="showModal"
      :title="title"
      hide-footer
      body-class="p-0">

      <div class="row px-3">
        <div class="col-12 pt-3">
          <div class="form-row">
            <sgv-input-textarea
              class="col-12"
              label="USR"
              disabled
              :value="modalDescription">
            </sgv-input-textarea>

            <sgv-input-select
              class="col-12"
              label="หมวดหมู่"
              :options="tags"
              v-model="formData.tag"
              select="value">
              <template slot-scope="option">
                {{option.text}}
              </template>
            </sgv-input-select>

            <sgv-input-textarea
              class="col-12"
              label="HR"
              v-model="formData.remark">
            </sgv-input-textarea>

            <div class="col-12">
              <sgv-input-check
                inline
                label="เข้ากะกลางคืน"
                v-model="formData.options.isNight">
              </sgv-input-check>

              <sgv-input-check
                inline
                label="ผสมเคมี"
                v-model="formData.options.isMixer">
              </sgv-input-check>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-warning my-3"
            @click="updateData">
            อัพเดท
          </button>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { UPDATE_ATTENDANCE_REMARK } from './graph'

export default {
  props: {
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    tag: {
      type: String,
      required: false
    },
    tags: {
      type: Array,
      required: false
    },
    remark: {
      type: String,
      required: false
    },
    attendedDate: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    options: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      showModal: false,
      modalDescription: '',
      formData: {
        attendedDate: '',
        contactId: null,
        tag: '',
        remark: '',
        options: {
          isMixer: false,
          isNight: false
        }
      }
    }
  },
  validations: {
    formData: {
      attendedDate: { required },
      contactId: { required },
    }
  },
  methods: {
    toggle () {
      if (this.disabled) return
      this.showModal = true
    },
    serializeInput (v) {
      return {
        ...v,
        options: {
          isMixer: v.options?.isMixer ? 1 : 0,
          isNight: v.options?.isNight ? 1 : 0,
        }
      }
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: UPDATE_ATTENDANCE_REMARK(this.templateType),
        variables: {
          contactType: this.contactType,
          input
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success("อัพเดทสำเร็จ")
        this.showModal = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  watch: {
    showModal (v) {
      if (v) {
        this.$nextTick(() => {
          this.formData.attendedDate = this.attendedDate
          this.formData.contactId = this.contactId
          this.formData.tag = this.tag || ''
          this.formData.remark = this.remark
          this.modalDescription = this.description
          this.formData.options.isMixer = this.options?.isMixer > 0 ? true : false
          this.formData.options.isNight = this.options?.isNight > 0 ? true : false
        })
      } else {
        this.modalDescription = ''
        this.formData.options.isMixer = false
        this.formData.options.isNight = false
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
