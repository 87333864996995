"use strict"

import Vue from 'vue'
import axios from "axios"
import router from '@/router'

// Name of the localStorage item

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.baseURL || process.env.apiUrl || process.env.VUE_APP_API_URL
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (err) {
    if (err.response.status === 401) {
      const config = {
        ...err.response.config,
        __retryCount: err.response.config.__retryCount || 0
      }

      if (config.__retryCount >= 3) {
        router.push({ name: 'Logout' }).catch(() => { })
        return Promise.reject(err)
      }

      config.__retryCount += 1

      const backoff = new Promise(resolve => {
        setTimeout(() => { resolve() }, 10)
      })

      return backoff.then(async () => {
        return refreshToken()
          .then(() => _axios(config))
          .catch(() => {
            router.push({ name: 'Logout' }).catch(() => { })
            return Promise.reject(err)
          })
      })
    }

    return Promise.reject(err)
  }
)

export const usernameLogin = async ({ username, password }) => {
  const url = process.env.VUE_APP_API_URL + '/auth/user/login'

  return axios.post(url, { username, password })
    .then(res => {
      const { accessToken, refreshToken, expiresIn, user } = res.data
      localStorage.setItem(process.env.VUE_APP_AUTH_ACCESS_TOKEN, accessToken)
      localStorage.setItem(process.env.VUE_APP_AUTH_REFRESH_TOKEN, refreshToken)
      localStorage.setItem(process.env.VUE_APP_AUTH_EXPIRES_IN, expiresIn)
      localStorage.setItem(process.env.VUE_APP_USER_NAME, user.name)
      localStorage.setItem(process.env.VUE_APP_USER_ROLES, user.roles.join(','))
      return res.data
    })
}

export const verifyGoogle = async ({ token }) => {
  const url = `${process.env.VUE_APP_API_URL}/auth/google/verify`

  return axios.post(url, { token })
    .then(res => {
      const { accessToken, refreshToken, expiresIn, user } = res.data
      localStorage.setItem(process.env.VUE_APP_AUTH_ACCESS_TOKEN, accessToken)
      localStorage.setItem(process.env.VUE_APP_AUTH_REFRESH_TOKEN, refreshToken)
      localStorage.setItem(process.env.VUE_APP_AUTH_EXPIRES_IN, expiresIn)
      localStorage.setItem(process.env.VUE_APP_USER_NAME, user.name)
      localStorage.setItem(process.env.VUE_APP_USER_ROLES, user.roles.join(','))
      return res.data
    })
}

export const logout = () => {
  const url = `${process.env.VUE_APP_API_URL}/auth/token/logout`
  const tokens = [
    process.env.VUE_APP_AUTH_ACCESS_TOKEN,
    process.env.VUE_APP_AUTH_REFRESH_TOKEN
  ]

  const tasks = tokens.map(v => {
    const token = localStorage.getItem(v)
    localStorage.removeItem(v)
    return axios.post(url, { token })
  })

  localStorage.removeItem(process.env.VUE_APP_AUTH_EXPIRES_IN)
  localStorage.removeItem(process.env.VUE_APP_USER_NAME)
  localStorage.removeItem(process.env.VUE_APP_USER_ROLES)

  return Promise.all(tasks)
}

export const register = ({ verifyCode, token }) => {
  const url = `${process.env.VUE_APP_API_URL}/auth/google/register`

  return axios.post(url, { verifyCode, token })
}

export const confirmUser = async ({ password }) => {
  const url = `${process.env.VUE_APP_API_URL}/auth/token/confirm`

  return axios.post(url, { password })
    .then(res => {
      const { accessToken } = res.data
      localStorage.setItem(process.env.VUE_APP_AUTH_ACCESS_TOKEN, accessToken)
      return res.data
    })
}

export const refreshToken = async () => {
  const token = localStorage.getItem(process.env.VUE_APP_AUTH_REFRESH_TOKEN)

  if (!token) return Promise.reject(new Error('REFRESH_NOT_FOUND'))

  const url = `${process.env.VUE_APP_API_URL}/auth/token/refresh`

  return axios.post(url, { token })
    .then(res => {
      const { accessToken } = res.data
      localStorage.setItem(process.env.VUE_APP_AUTH_ACCESS_TOKEN, accessToken)
      return Promise.resolve(res.data)
    })
    .catch(err => {
      if (err.response.status === 401) return Promise.reject(err)
      return Promise.resolve(null)
    })
}

const myPlugin = {
  install(Vue) {
    Vue.axios = _axios
    window.axios = _axios
    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return _axios
        }
      },
      $axios: {
        get() {
          return _axios
        }
      },
    })
  }
}

Vue.use(myPlugin)

export default myPlugin
