<template>
  <nav class="sidebar-nav">
    <div class="sidebar-header">
      <button class="nav-toggler nav-toggler-md sidebar-toggler" type="button" @click="toggleMenu">
        <span class="sr-only">Toggle nav</span>
      </button>
      <div class="sidebar-brand img-responsive pl-3">
        <small class="text-success">
          {{userName}}
        </small>
      </div>
    </div>

    <div class="">
      <div class="collapse nav-toggleable-md" :class="{show: menu}">
        <div class="nav-height">
          <ul class="nav nav-pills nav-stacked flex-column">
            <li class="pt-3">
              <router-link
                :to="{name: 'Logout'}"
                class="nav-link py-0"
                @click.native="menu = false">
                ออกจากระบบ
              </router-link>
            </li>
            <li v-for="(item, i) in optionsByRole" :key="i" :class="{'nav-header': item.header, 'nav-item': !item.header}">
              <template v-if="item.header">{{item.text}}</template>

              <router-link
                :to="item.to"
                class="nav-link"
                v-else-if="item.group"
                :class="{active: getMatch(item)}"
                @click.prevent="toPage(item)">
                {{item.text}}
              </router-link>

              <router-link
                :to="item.to"
                class="nav-link"
                v-else
                active-class="active"
                @click.native="menu = false"
                :exact="item.exact">
                {{item.text}}
              </router-link>
            </li>
          </ul>
        </div>

        <hr class="d-md-none mt-3">
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'SideNav',
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    userName () {
      return localStorage.getItem("userName")
    },
    optionsByRole () {
      return this.options.filter(v => this.$auth.hasRole(v.role))
    }
  },
  methods: {
    toggleMenu () {
      this.menu = !this.menu
    },
    toPage(item) {
      this.menu = false
      const data = this.$store.getters['path/getRoute']({group: item.group})
      if (data === this.$route.path) this.$router.push(item.to)
      else this.$router.push(data || item.to)
    },
    getMatch(item) {
      if (item.exact) {
        return this.$route.path === item.to.path
      } else {
        const itemArr = item.to.path.split('/')
        const routeArr = this.$route.path.split('/')
        return itemArr.every((v, i) => {
          return v === routeArr[i]
        })
      }
    }
  },
  watch: {
    '$route.fullPath': {
      handler () {
        this.menu = false
      }
    }
  }
}
</script>

<style lang="css">
.nav-height {
  direction: rtl;
  overflow-y: auto;
  max-height: 80vh;
  padding-left: 5px;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
}
.nav-height ul {
  direction:ltr;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #252830;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #434857;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
