<template>
  <span class="">
    <span
      class="pointer mr-1"
      @click="toggle">
      <slot></slot>
    </span>

    <b-modal
      v-model="showModal"
      :title="title"
      hide-footer
      body-class="p-0">

      <div class="row px-3">
        <div class="col-12 pt-3">
          <div class="form-row">
            <sgv-input-datetime
              class="col-8"
              label="แก้ไขเวลาออก"
              isNull
              v-model="formData.outAt">
            </sgv-input-datetime>
          </div>

          <button
            type="button"
            class="btn btn-warning mb-3"
            @click="updateData">
            อัพเดท
          </button>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { UPDATE_ATTENDANCE_OUT_AT } from './graph'

export default {
  props: {
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    outAt: {
      type: String,
      required: false
    },
    attendedDate: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      showModal: false,
      formData: {
        attendedDate: '',
        contactId: null,
        outAt: '',
      }
    }
  },
  validations: {
    formData: {
      attendedDate: { required },
      contactId: { required },
    }
  },
  methods: {
    toggle () {
      if (this.disabled) return
      this.showModal = true
    },
    serializeInput (v) {
      return {
        attendedDate: v.attendedDate,
        contactId: v.contactId,
        outAt: new Date(v.outAt),
      }
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: UPDATE_ATTENDANCE_OUT_AT(this.templateType),
        variables: {
          contactType: this.contactType,
          input
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success("อัพเดทสำเร็จ")
        this.showModal = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  watch: {
    showModal (v) {
      if (v) {
        this.formData.attendedDate = this.attendedDate
        this.formData.contactId = this.contactId
        this.formData.outAt = this.$date.format(this.outAt).datetime
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
