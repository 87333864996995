<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="pre-line">
          {{item.name}}
        </div>
        <small>
          <div v-if="item.options">
            วัน: <span class="text-warning">{{item.options.expiredDay}}</span>
          </div>
          <div v-if="item.options">
            เอกสาร: <span class="text-info">{{item.options.docs.join(',')}}</span>
          </div>
        </small>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-text
            label="ชื่อ"
            class="col-12"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-text>

          <sgv-input-number
            label="วัน"
            class="col-4"
            v-model="formData.options.expiredDay"
            :validations="[
              {text: 'required!', value: $v.formData.options.expiredDay.$dirty && !$v.formData.options.expiredDay.required}
            ]">
          </sgv-input-number>

          <sgv-input-text
            label="เอกสาร"
            placeholder="iv,giv..."
            class="col-8"
            v-model="formData.options.docs"
            :validations="[
              {text: 'required!', value: $v.formData.options.docs.$dirty && !$v.formData.options.docs.required}
            ]">
          </sgv-input-text>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        name: '',
        options: {
          expiredDay: 0,
          docs: ''
        }
      }
    }
  },
  validations: {
    formData: {
      name: {required},
      options: {
        expiredDay: {required},
        docs: {required}
      },
    }
  },
  methods: {
    setFormData (v) {
      this.formData.name = v.name
      this.formData.options.expiredDay = v.options?.expiredDay || 0
      this.formData.options.docs = v.options?.docs.join(',') || ''
    },
    serializeInput (formData) {
      return {
        type: this.type,
        name: formData.name,
        options: {
          expiredDay: formData.options.expiredDay,
          docs: formData.options.docs.split(',').map(v => v.trim()).filter(v => v)
        }
      }
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.$v.formData.$reset()
          this.toggle = false
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.setFormData(this.item)
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      } else {
        this.formData.name = ''
        this.formData.options.expiredDay = 0
        this.formData.options.docs = ''
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
