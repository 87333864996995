import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  data () {
    return {
      currentPath: null,
      rFields: ['currentPath'],
      isRetainingPathLoaded: false
    }
  },
  methods: {
    refreshPage () {
      if (!this.isRetainingLoaded) return

      this.isRetainingPathLoaded = false
      const isMain = this.rKey === this.$route.name
      if (isMain) {
        if (this.$route.fullPath !== this.currentPath) {
          const redirectPath = this.currentPath
          this.currentPath = this.$route.fullPath
          this.setRetaining()
          if (redirectPath) {
            this.$router.push(redirectPath)
            .then(() => {
              this.isRetainingPathLoaded = true
            })
          }
        }
      } else {
        this.currentPath = this.$route.fullPath
        this.setRetaining()
        this.isRetainingPathLoaded = true
      }
    }
  },
  watch: {
    '$route.fullPath': {
      handler: 'refreshPage',
      immediate: true
    },
    isRetainingLoaded (v) {
      if (v) this.refreshPage()
    }
  },
}
