import { capitalize } from '@/libs/form'
import { TEMPLATE_TYPE } from '../config'


export default ({ docType, title, subTitle, group }) => {
  const templateType = TEMPLATE_TYPE
  return [
    {
      path: `doc/${docType}/docConfig`,
      name: `Doc${capitalize(docType)}DocConfig`,
      component: () => import('../DocConfig.vue'),
      props: () => ({
        templateType,
        docType,
        title,
        subTitle,
        group
      }),
      children: [
        {
          path: 'contactId',
          name: `Doc${capitalize(docType)}DocConfigContactId`,
          component: () => import('../DocConfigContactId.vue'),
          props: () => ({
            templateType,
            docType,
          }),
        },
        {
          path: 'userManual',
          name: `Doc${capitalize(docType)}DocConfigUserManual`,
          component: () => import('../DocConfigUserManual.vue'),
          props: () => ({
            templateType,
            docType,
          }),
        },
      ]
    },
    {
      path: `doc/${docType}`,
      name: `Doc${capitalize(docType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}/:docId`,
      name: `Doc${capitalize(docType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        docId: Number(route.params.docId),
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
  ]
}
