export const format = (value, seconds = 0, days = 0, months = 0, years = 0) => {
  if (value == null) return {date: '', time: ''}
  let date = new Date(value)

  date.setFullYear(date.getFullYear() + years)
  date.setMonth(date.getMonth() + months)
  date.setDate(date.getDate() + days)
  date.setSeconds(date.getSeconds() + seconds)

  let dd = date.getDate()
  let mm = date.getMonth() + 1
  let yyyy = date.getFullYear()
  let hh = date.getHours()
  let mi = date.getMinutes()

  if (dd < 10) { dd = '0' + dd }
  if (mm < 10) { mm = '0' + mm }
  if (hh < 10) { hh = '0' + hh }
  if (mi < 10) { mi = '0' + mi }

  let startOfMonth = new Date(yyyy + '-' + mm + '-01')
  startOfMonth.setMonth(startOfMonth.getMonth() + 1)
  startOfMonth.setDate(startOfMonth.getDate() - 1)
  let startOfMonthDay = startOfMonth.getDate()
  let startOfMonthMonth = startOfMonth.getMonth() + 1
  let startOfMonthYear = startOfMonth.getFullYear()

  if (startOfMonthDay < 10) { startOfMonthDay = '0' + startOfMonthDay }
  if (startOfMonthMonth < 10) { startOfMonthMonth = '0' + startOfMonthMonth }

  var endOfMonth = startOfMonthYear + '-' + startOfMonthMonth + '-' + startOfMonthDay

  return {
    raw: date,
    date: yyyy + '-' + mm + '-' + dd,
    time: hh + ':' + mi,
    month: yyyy + '-' + mm,
    dd: dd,
    mm: mm,
    yyyy: yyyy,
    display: dd + '/' + mm + '/' + yyyy,
    displayThai: dd + '/' + mm + '/' + (yyyy + 543),
    displayDatetime: `${dd}/${mm}/${yyyy} (${hh}:${mi})`,
    startOfMonth: yyyy + '-' + mm + '-01',
    endOfMonth: endOfMonth,
    datetime: yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + mi,
    utc: new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  }
}

export const current = () => {
  let date = new Date()
  var dd = date.getDate()
  var mm = date.getMonth() + 1
  var yyyy = date.getFullYear()
  var hh = date.getHours()
  var mi = date.getMinutes()

  if (dd < 10) { dd = '0' + dd }
  if (mm < 10) { mm = '0' + mm }
  if (hh < 10) { hh = '0' + hh }
  if (mi < 10) { mi = '0' + mi }

  return {
    raw: date,
    date: yyyy + '-' + mm + '-' + dd,
    time: hh + ':' + mi,
    month: yyyy + '-' + mm,
    dd: dd,
    mm: mm,
    yyyy: yyyy,
    display: dd + '/' + mm + '/' + yyyy,
    displayThai: dd + '/' + mm + '/' + (yyyy + 543),
    displayDatetime: `${dd}/${mm}/${yyyy} (${hh}:${mi})`,
    datetime: yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + mi
  }
}

export const serial = (startDate, endDate) => {
  var s = new Date(startDate)
  var e = new Date(endDate)
  var a = []

  while (s <= e) {
    a.push(format(s).date)
    s = new Date(s.setDate(
      s.getDate() + 1
    ))
  }
  return a
}

export const timeSeries = (startTime, endTime, interval) => {
  var s = new Date(startTime)
  var e = new Date(endTime)
  var a = []

  while (s <= e) {
    a.push(new Date(s))
    s = new Date(s.setSeconds(
      s.getSeconds() + interval
    ))
  }

  return a
}

export const dayDiff = (startDate, endDate) => {
  let s = new Date(startDate)
  let e = new Date(endDate)
  let timeDiff = Math.abs(e.getTime() - s.getTime())
  let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
  return diffDays
}

export const hourDiff = (startDate, endDate) => {
  let s = new Date(startDate)
  let e = new Date(endDate)
  let timeDiff = Math.abs(e.getTime() - s.getTime())
  let delta = timeDiff / (1000 * 3600)
  return delta
}
