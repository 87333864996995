<template>
  <sgv-csv
    :items="csvData"
    :labels="csvLabels"
    :filename="csvFilename">
    <button
      :disabled="csvData.length === 0"
      type="button"
      class="btn btn-success">
      CSV รายงานภาษีขาย
    </button>
  </sgv-csv>
</template>

<script>
import {
  LIST_ORDER_SALES
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    docCode: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      csvData: [],
      csvLabels: {
        id: { title: 'ลำดับ' },
        closedAt: { title: 'วันเดือนปี' },
        docCode: { title: 'ใบกำกับภาษี' },
        contactCode: { title: 'รหัส' },
        contactName: { title: 'ชื้อผู้ซื้อสินค้า/บริการ' },
        contactTaxCode: { title: 'เลขประจำตัวผู้เสียภาษี' },
        contactBranch: { title: 'สาขา' },
        totalPrice: { title: 'มูลค่าสินค้า/บริการ' },
        vatPrice: { title: 'ภาษีมูลค่าเพิ่ม' },
        remark: { title: 'หมายเหตุ' },
      },
      csvFilename: `${this.docCode}-ภาษีขาย`
    }
  },
  methods: {
    serializeData (orders) {
      return orders.map((v,idx) => {
        return {
          id: idx+1,
          closedAt: this.$date.format(v.closedAt).displayThai,
          docCode: v.code,
          contactCode: v.contact.code,
          contactName: v.contact.name,
          contactTaxCode: v.contact.taxCode,
          contactBranch: v.contact.branch && v.contact.branch !== 'สำนักงานใหญ่' ? v.contact.branch : 'X',
          totalPrice: this.$form.addComma(v.totalPrice),
          vatPrice: this.$form.addComma(v.vatPrice * -1),
          remark: v.remark
        }
      })
    },
    fetchData () {
      this.$apollo.query({
        query: LIST_ORDER_SALES(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.csvData = this.serializeData(res.data.orders)
      })
      .catch(this.$toasted.global.error)
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
</style>
