import gql from 'graphql-tag'
import { capitalize } from '@/libs/form'


const docConfigResponse = `
  id type docType name cron
  actionPlanId actionPlan {id name}
  riskAssessmentId riskAssessment {id name}
  targetGraphId targetGraph {id name}
  docCategoryId docCategory {id name}
`

export const LIST_DOC_CONFIG_REPORT = (templateType, reportType) => gql`query LIST_DOC_CONFIG_REPORT ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfig${capitalize(reportType)} (docType: $docType, q: $q) {${docConfigResponse}}
}`

export const CREATE_DOC_CONFIG_REPORT = (templateType, reportType) => gql`mutation CREATE_DOC_CONFIG_REPORT ($docType: String!, $docConfigId: Int, $input: DocConfigReportInput!) {
  item: createDoc${templateType}DocConfig${capitalize(reportType)} (docType: $docType, docConfigId: $docConfigId, input: $input) {${docConfigResponse}}
}`

export const DESTROY_DOC_CONFIG_REPORT = (templateType, reportType) => gql`mutation DESTROY_DOC_CONFIG_REPORT ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}DocConfig${capitalize(reportType)} (docType: $docType, docConfigId: $docConfigId) {id}
}`
