import gql from 'graphql-tag'


export const LIST_REPORT_PRINTER = (templateType) => gql`query LIST_REPORT_PRINTER ($inventoryType: String!) {
  printers: listInventory${templateType}Printer (inventoryType: $inventoryType) {
    id type code name
  }
}`

export const LIST_REPORT_ITEM = (templateType) => gql`query LIST_REPORT_ITEM ($inventoryType: String!, $filter: InventoryReportItemFilterInput!, $options: InventoryReportItemOptionsInput) {
  items: listReportInventory${templateType}Item (inventoryType: $inventoryType, filter: $filter, options: $options) {
    id type code name barcode address
    unitId unit {id name}
    categories {id name}
  }
}`


export const LIST_REPORT_STOCK_AUDITED_BY_INTERVAL = (templateType) => gql`query LIST_REPORT_STOCK_AUDITED_BY_INTERVAL ($inventoryType: String!, $startDate: Date!, $endDate: Date!) {
  rows: listInventory${templateType}StockAuditedByInterval (inventoryType: $inventoryType, startDate: $startDate, endDate: $endDate) {
    id type code name
    auditedAt auditedBy auditedUser {id name}
    docStockCount {
      id code name isStockDiff remark
    }
  }
}`

export const LIST_REPORT_STOCK_BALANCE_BY_INTERVAL = (templateType) => gql`query LIST_REPORT_STOCK_BALANCE_BY_INTERVAL ($inventoryType: String!, $startDate: Date!, $endDate: Date!, $search: String) {
  balances: listInventory${templateType}StockBalanceByInterval (inventoryType: $inventoryType, startDate: $startDate, endDate: $endDate, search: $search) {
    inventoryId inventoryType inventoryCode
    inventoryName inventoryUnit
    balanceIn balanceOut balanceCurrent
  }
}`

export const LIST_REPORT_STOCK_BALANCE_BY_INVENTORY = (templateType) => gql`query LIST_REPORT_STOCK_BALANCE_BY_INVENTORY ($inventoryType: String!, $startDate: Date!, $endDate: Date!, $inventoryId: Int!) {
  balances: listInventory${templateType}StockBalanceByInventory (inventoryType: $inventoryType, startDate: $startDate, endDate: $endDate, inventoryId: $inventoryId) {
    id docId docType docCode qty
    inventoryId inventoryType inventoryCode inventoryName inventoryUnit
    balanceIn balanceOut balanceCurrent
    approvedAt approvedBy approvedUser
  }
}`

export const LIST_REPORT_STOCK_IN_BY_INTERVAL = (templateType) => gql`query LIST_REPORT_STOCK_IN_BY_INTERVAL ($inventoryType: String!, $startDate: Date!, $endDate: Date!) {
  rows: listInventory${templateType}StockInByInterval (inventoryType: $inventoryType, startDate: $startDate, endDate: $endDate) {
    id code name total unit
  }
}`

export const LIST_REPORT_STOCK_OUT_BY_INTERVAL = (templateType) => gql`query LIST_REPORT_STOCK_OUT_BY_INTERVAL ($inventoryType: String!, $startDate: Date!, $endDate: Date!) {
  rows: listInventory${templateType}StockOutByInterval (inventoryType: $inventoryType, startDate: $startDate, endDate: $endDate) {
    id code name total unit
  }
}`
