import { capitalize } from '@/libs/form'


export default ({countryType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `country/${countryType}`,
      name: `Country${capitalize(countryType)}List`,
      component: () => import('../List'),
      props: () => ({
        templateType,
        countryType,
        title,
        subTitle,
        group,
      })
    },
    {
      path: `country/${countryType}/country/:countryId`,
      name: `Country${capitalize(countryType)}Detail`,
      component: () => import('../DetailCountry'),
      props: (route) => ({
        templateType,
        countryType,
        title,
        subTitle,
        group,
        countryId: Number(route.params.countryId)
      })
    },
    {
      path: `country/${countryType}/province/:provinceId`,
      name: `Country${capitalize(countryType)}ProvinceDetail`,
      component: () => import('../DetailProvince'),
      props: (route) => ({
        templateType,
        countryType,
        title,
        subTitle,
        group,
        provinceId: Number(route.params.provinceId)
      })
    },
    {
      path: `country/${countryType}/district/:districtId`,
      name: `Country${capitalize(countryType)}DistrictDetail`,
      component: () => import('../DetailDistrict'),
      props: (route) => ({
        templateType,
        countryType,
        title,
        subTitle,
        group,
        districtId: Number(route.params.districtId)
      })
    },
    {
      path: `country/${countryType}/subdistrict/:subdistrictId`,
      name: `Country${capitalize(countryType)}SubdistrictDetail`,
      component: () => import('../DetailSubdistrict'),
      props: (route) => ({
        templateType,
        countryType,
        title,
        subTitle,
        group,
        subdistrictId: Number(route.params.subdistrictId)
      })
    },
  ]
}
