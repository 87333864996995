<template>
  <div>
    <transition name="modal" v-if="show">
      <div class="modal" style="display: block">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div class="modal-body text-center">
              <img :src="image" alt="รูป" class="img-fluid">
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-block" @click="show = false">ปิด</button>
            </div>

          </div>
        </div>
      </div>
    </transition>

    <div v-if="image" class="">
      <img
        :src="image"
        :alt="alt"
        class="img-fluid rounded"
        :style="getWidth"
        @click="showModal"
        :class="{'pointer': modal}">
    </div>


    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SgvImage',
  props: {
    alt: {
      type: String,
      requried: false,
      default: 'รูปภาพ...'
    },
    modal: {
      type: Boolean,
      requried: false,
      default: true
    },
    width: {
      type: String,
      required: false,
      default: '20rem'
    },
    value: {
      type: [String, Blob],
      required: false
    },
    api: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      image: null,
      show: false
    }
  },
  computed: {
    getWidth () {
      return {'width': this.width}
    }
  },
  methods: {
    showModal () {
      if (this.modal) {
        this.show = true
      }
    },
    loadImage (value) {
      if (!value || value == {}) return ''
      if (typeof value === 'string') return value
      if (value instanceof Blob) return window.URL.createObjectURL(value)
    }
  },
  watch: {
    value (value) {
      this.image = this.loadImage(value)
    }
  }
}
</script>

<style lang="css">
  .modal {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
    overflow: auto;
  }
</style>
