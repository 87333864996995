import { capitalize } from '@/libs/form'
import { TEMPLATE_TYPE } from '../config'

export default ({ accountTrialBalanceType, title, subTitle, group }) => {
  const templateType = TEMPLATE_TYPE
  return [
    {
      path: `accountTrialBalance/${accountTrialBalanceType}/`,
      name: `AccountTrialBalance${capitalize(accountTrialBalanceType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        accountTrialBalanceType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `accountTrialBalance/${accountTrialBalanceType}/detail`,
      name: `AccountTrialBalance${capitalize(accountTrialBalanceType)}Detail`,
      component: () => import('../Detail.vue'),
      props: () => ({
        templateType,
        accountTrialBalanceType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
