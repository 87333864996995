import { capitalize } from '@/libs/form'
import List from '../List.vue'
import Detail from '../Detail.vue'
import Config from '@/views/contact_core/components/ContactConfig.vue'


export default ({contactType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `contact/${contactType}/config`,
      name: `Contact${capitalize(contactType)}ConfigList`,
      component: Config,
      props: () => ({
        templateType,
        contactType,
        title, subTitle, group,
      })
    },
    {
      path: `contact/${contactType}/category/:categoryId`,
      name: `Contact${capitalize(contactType)}CategoryDetail`,
      component: () => import(`@/views/contact_core/components/CategoryDetail.vue`),
      props: (route) => ({
        categoryId: Number(route.params.categoryId),
        templateType,
        contactType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `contact/${contactType}`,
      name: `Contact${capitalize(contactType)}List`,
      component: List,
      props: () => ({
        templateType,
        contactType,
        title, subTitle, group,
      })
    },
    {
      path: `contact/${contactType}/:contactId`,
      name: `Contact${capitalize(contactType)}Detail`,
      component: Detail,
      props: (route) => ({
        templateType,
        contactType,
        title, subTitle, group,
        contactId: Number(route.params.contactId)
      })
    },
  ]
}
