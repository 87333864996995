import Vue from 'vue'
import Toasted from 'vue-toasted'

const MESSAGES = {
  NOT_FOUND: {en: 'Not Found', th: 'ไม่พบข้อมูล'},
  NOT_VALID: {en: 'Validation Error', th: 'ข้อมูลไม่ถูกต้อง'},
  PRINTING: {en: 'Printing...', th: 'ส่งข้อมูลไปยังเครื่องพิมพ์...'},

  DEFAULT_UNIT_NOT_FOUND: {en: 'Default Unit not found', th: 'ไม่พบหน่วยมาตรฐาน'},

  DOC_CONFIG_NOT_FOUND: {en: 'Document configuration not found', th: 'ไม่พบการตั้งค่าเอกสาร'},
  DOC_CONFIG_NOT_PROVIDE_TRANSFER_QTY: {en: 'Please provide transfer qty', th: 'โปรดระบุจำนวนสินค้าเพิ่มเติม'},
  DOC_CONFIG_SALES_PRICE_NOT_VALID: {en: 'Sales price not correct format', th: 'กำหนดราคาขายไม่ถูกต้อง'},
  DOC_CONFIG_SALES_PRICE_NOT_FOUND: {en: 'Sales price not found', th: 'ไม่พบราคาขายสินค้า'},
  DOC_CONFIG_DEFAULT_SALES_PRICE_NOT_FOUND: {en: 'Default Sales price not found', th: 'ไม่พบราคาขายสินค้าหลัก'},

  DOC_NOT_FOUND: {en: 'Document not found', th: 'ไม่พบเอกสาร'},
  DOC_HAS_BEEN_CLOSED: {en: 'Document has been closed', th: 'เอกสารจบไปแล้ว'},
  DOC_HAS_BEEN_APPROVED: {en: 'Document has been approved', th: 'เอกสารอนุมัติไปแล้ว'},
  DOC_HAS_NOT_BEEN_APPROVED: {en: 'Document has not been approved', th: 'เอกสารยังไม่ได้อนุมัติ'},
  DOC_HAS_NOT_BEEN_CLOSED: {en: 'Document has not been closed', th: 'เอกสารยังไม่ได้จบ'},

  CONTACT_NOT_FOUND: {en: 'Contact not found', th: 'ไม่พบผู้ติดต่อ'},
  CONTACT_ADDRESS_NOT_FOUND: {en: 'Contact address not found', th: 'ไม่พบที่อยู่ผู้ติดต่อ'},

  ORDER_NOT_FOUND: {en: 'Order not found', th: 'ไม่พบรายการ'},
  ORDER_MISMATCH: {en: 'Order mismatch', th: 'สินค้าไม่ตรงกับรายการขาย'},
  ORDER_QTY_MISMATCH: {en: 'Order quantity mismatch', th: 'จำนวนสินค้ามากกว่าในใบสั่งขาย'},
  ORDER_DISCOUNT_ERROR: {en: 'Order discount error', th: 'รายการส่วนลดไม่ถูกต้อง'},
  ORDERS_TYPE_ERROR: {en: 'Order type error', th: 'ข้อมูลรายการสินค้าไม่ถูกต้อง'},
  ORDER_HAS_NOT_BEEN_DESTROY: {en: 'Order has not been deleted', th: 'โปรดลบรายการก่อนลบเอกสาร'},
  TRANSFER_QTY_EQUAL_0: {en: 'Please provide waste quantity', th: 'โปรดระบุจำนวนของเสีย'},
  ORDER_ID_MUST_PROVIDE: {en: 'Please provide orderId', th: 'โปรดระบุเลขที่รายการสินค้า'},
  VAT_PRICE_ORDER_NOT_FOUND: {en: 'Vat order not found', th: 'ไม่พบรายการภาษีขาย'},
  NET_PRICE_ORDER_NOT_FOUND: {en: 'Net price not found', th: 'ไม่พบรายการยอดรวม'},
  ORDER_HAS_BEEN_USED: {en: 'Order has been used by other', th: 'มีการใช้รายการร่วมกับเอกสารอื่น'},
  ORDER_VAT_PRICE_MUST_NOT_EXIST: {en: 'Please remove vat price before adding item', th: 'โปรดลบรายการ vat ก่อน'},
  ORDER_NET_PRICE_MUST_NOT_EXIST: {en: 'Please remove net price before adding item', th: 'โปรดลบยอดรวมรายการก่อน'},
  ORDER_CHILDREN_MUST_NOT_EXIST: {en: 'Please remove children first', th: 'โปรดลบรายการลูกก่อน'},

  INVOICE_NOT_FOUND: {en: 'Invoice not found', th: 'ไม่พบใบจัดส่งสินค้า'},
  INVOICE_ORDER_NOT_FOUND: {en: 'No order to deliver', th: 'ไม่พบรายการสินค้าที่จะจัดส่ง'},
  INVOICE_ORDER_FOUND: {en: 'Please cancel invoice order first', th: 'โปรดลบรายการจัดส่งก่อน'},
  INVOICE_VEHICLE_NOT_FOUND: {en: 'Please select vehicle', th: 'โปรดเลือกรถขนส่ง'},
  INVOICE_DELIVERED_AT_NOT_FOUND: {en: 'Please specify delivery time', th: 'โปรดระบุวันเวลาจัดส่ง'},

  RECEIPT_NOT_FOUND: {en: 'Receipt not found', th: 'ไม่พบใบเสร็จชำระเงิน'},
  RECEIPT_RECEIVED_AT_NOT_FOUND: {en: 'Please specify receipt time', th: 'โปรดระบุวันเวลาชำระเงิน'},
  RECEIPT_DOC_CONFIG_NOT_FOUND: {en: 'Please specify receipt method', th: 'โปรดระบุช่องทางชำระเงิน'},

  STOCK_NOT_FOUND: {en: 'Stock not found', th: 'ไม่พบรายการสินค้า'},
  STOCK_FOUND: {en: 'Stock found', th: 'พบรายการสินค้า'},
  STOCK_HAS_BEEN_USED: {en: 'Stock has been used', th: 'มีการใช้สินค้าไปแล้ว'},
  STOCK_HAS_BEEN_APPROVED: {en: 'Stock has been approved', th: 'ตรวจรับสินค้าไปแล้ว'},
  STOCK_HAS_NOT_BEEN_APPROVED: {en: 'Stock has not been approved', th: 'สินค้ายังไม่ได้ตรวจรับ'},
  STOCK_OVER_AMOUNT: {en: 'Out of stock please check the amount', th: 'จำนวนสินค้าไม่เพียงพอ'},
  STOCK_REMAINING_ZERO: {en: 'Stock remain 0', th: 'จำนวนสินค้าคงเหลือ 0'},
  STOCK_QTY_OVER_ORDER: {en: 'Stock is greater than order', th: 'จำนวนสินค้ามากกว่ารายการ'},
  STOCK_LEDGER_AMOUNT_NOT_FOUND: {en: 'Stock is not valuable', th: 'ไม่พบมูลค่าทางบัญชีของสินค้า'},

  LEDGER_NOT_FOUND: {en: 'Ledger not found', th: 'ไม่พบรายการบัญชี'},
  LEDGER_FOUND: {en: 'Ledger found', th: 'พบรายการบัญชี'},
  LEDGER_SUM_NOT_ZERO: {en: 'Ledger sum not equal to 0', th: 'ผลรวมบัญชีไม่เท่ากับ 0'},
  LEDGER_HAS_BEEN_APPROVED: {en: 'Ledger has been approved', th: 'ตรวจรับบัญชีไปแล้ว'},
  LEDGER_HAS_NOT_BEEN_APPROVED: {en: 'Ledger has not been approved', th: 'บัญชียังไม่ได้ตรวจรับ'},
  LEDGER_APPROVED_DATE_NOT_FOUND: {en: 'Please provide approve date', th: 'โปรดระบุวันตรวจรับ'},

  PAYMENT_ERROR: {en: 'Payment Error', th: 'รายการชำระไม่สมบูรณ์'},
  PAYMENT_OVER_LIMIT: {en: 'Payment over limit', th: 'จำนวนเงินเกินกว่ายอดจ่าย'},
  DISCOUNT_NOT_CORRECT_FORMAT: {en: 'Discount format error', th: 'กำหนดส่วนลดไม่ถูกต้อง'},
  CHEQUE_ID_NOT_FOUND: {en: 'Please provide chequeId', th: 'โปรดระบุเลขที่เช็ค'},

  NO_AUTHORIZATION: {en: 'Permission Denied', th: 'ไม่ได้รับสิทธิ์'},

  ACCOUNT_RECEIVABLE_NOT_FOUND: {en: 'Account receivable not found', th: 'ไม่พบบัญชีลูกหนี้การค้า'},
  SELL_ACCOUNT_RECEIVABLE_NOT_FOUND: {en: 'Sell account receivable not found', th: 'ไม่พบบัญชีขาย'},
  COST_OF_SALES_NOT_FOUND: {en: 'Cost of sales not found', th: 'ไม่พบบัญชีต้นทุนขาย'},

  SO_HAS_BEEN_CLOSED: {en: 'Some Salesorder has been closed', th: 'รายการขายบางรายการได้จบไปแล้ว'},
  DELIVER_ORDER_NOT_FOUND: {en: 'Deliver Order not found', th: 'ไม่มีสินค้าที่ต้องจัดส่ง'},
  DOC_CONFIG_IS_USED_IN_ORDER: {en: 'DocConfig is used in some order', th: 'มีการใช้การตั้งค่านี้อยู่ในรายการขาย'},

  PROCEDURE_ALREADY_EXIST: {en: 'Item already exist', th: 'มีการตั้งค่าไปแล้ว'},

  MEASUREMENT_TEMPLATE_NOT_FOUND: {en: 'Measurement template is not found', th: 'ไม่พบฟอร์มตรวจวัดหรือซ่อมบำรุง'},

  SURVEY_ALREADY_CREATED: {en: 'Survey form has been already created', th: 'มีการสร้างฟอร์มสำรวจไปแล้ว'},

  CONTROL_PLAN_NOT_VALID: {en: 'Control Plan not valid.', th: 'แผนควบคุมไม่ถูกต้อง ติดต่อ QC'},
  QC_NOT_FOUND: {en: 'Qc not found!', th: 'ไม่พบการตรวจสอบด้วย QC'},
}

Vue.use(Toasted, {
    iconPack : 'fontawesome',
    position: 'bottom-right',
    duration: 3000
})

Vue.toasted.register(
  'success',
  (payload) => {
    if(!payload) return 'success'
    return payload
  },
  {
    type : 'success',
    icon : 'check',
  }
)

Vue.toasted.register(
  'warning',
  (payload) => {
    if(!payload) return 'warning'
    return payload
  },
  {
    type : 'info',
    icon : 'exclamation',
  }
)

Vue.toasted.register(
  'error',
  (payload) => {

    if (payload && process.env.NODE_ENV === 'development') {
      console.log(JSON.stringify(payload,null,2))
    }

    if(!payload) {

      return 'เกิดข้อผิดพลาด!'

    } else if (Object.prototype.hasOwnProperty.call(payload, 'graphQLErrors') && payload.graphQLErrors.length > 0) {

      return payload.graphQLErrors.map(v => {
        return MESSAGES[v.message] ? MESSAGES[v.message].th : v.message
      }).join(',')

    } else if (Object.prototype.hasOwnProperty.call(payload, 'networkError') && payload.networkError) {

      if (typeof payload.networkError.result === 'object') {
        return JSON.stringify(payload.networkError.result)
      } else {
        return payload.networkError.result
      }

    } else if (payload?.response?.data?.lang) {

      return payload.response.data.lang.th

    } else if (payload?.response?.data?.message) {

      const msg = payload?.response?.data?.message
      return MESSAGES[msg]?.th || msg

    } else if (Object.prototype.hasOwnProperty.call(payload, 'message')) {

      return payload.message

    } else {

      return payload

    }
  },
  {
    type : 'error',
    icon : 'exclamation',
    duration: 5000
  }
)
