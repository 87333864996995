import gql from 'graphql-tag'


export const LIST_INVENTORY = (templateType) => gql`query LIST_INVENTORY ($inventoryStockType: String!, $q: FilterInput) {
  inventories: list${templateType} (inventoryStockType: $inventoryStockType, q: $q) {
    id type code name
    unitId unit {id name}
    outQty balanceQty
    upperLimit lowerLimit purchaseLimit
    deltaLimit turnoverRatio
  }
}`