<template>
  <div class="">
    <TabDivider :options="options" link class="mb-3"></TabDivider>
    <transition name="fade" mode="out-in">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import retainPathMixin from '@/mixins/retain-path-mixin'

export default {
  mixins: [retainPathMixin],
  props: {
    group: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      rKey: this.group,
    }
  }
}
</script>

<style lang="css" scoped>
</style>
