import { capitalize } from '@/libs/form'


export default ({countryAreaType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `countryArea/${countryAreaType}`,
      name: `CountryArea${capitalize(countryAreaType)}List`,
      component: () => import('../List'),
      props: () => ({
        templateType,
        countryAreaType,
        title,
        subTitle,
        group,
      })
    },
    {
      path: `countryArea/${countryAreaType}/:countryAreaId`,
      name: `CountryArea${capitalize(countryAreaType)}Detail`,
      component: () => import('../Detail'),
      props: (route) => ({
        templateType,
        countryAreaType,
        title,
        subTitle,
        group,
        countryAreaId: Number(route.params.countryAreaId)
      })
    },
  ]
}
