<template>
  <span class="">
    <span
      v-if="!inventoryCostOrderId"
      class="px-1 pointer"
      @click="toggle = true">
      <fa icon="plus" class="text-success"></fa>
    </span>

    <span
      v-if="inventoryCostOrderId"
      class="px-1 pointer"
      @click="toggle = true">
      <fa icon="pencil-alt" class="text-warning"></fa>
    </span>

    <b-modal v-model="toggle" title="Output">

      <div class="form-row">
        <sgv-input-radio
          class="col-12"
          :options="tabs"
          select="value"
          v-model="selectedTab"
          inline>
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-radio>
      </div>

      <div class="form-row" v-if="selectedTab === 'custom'">
        <sgv-input-text
          class="col-8"
          label="ชื่อ"
          v-model="formData.name"
          :validations="[
            {text: 'required!', value: $v.formData.name.$dirty && $v.formData.name.$error}
          ]">
        </sgv-input-text>

        <DropdownUnit
          class="col-4"
          select="id"
          v-model="formData.unitId"
          label="หน่วย"
          :inventoryCostType="inventoryCostType"
          :templateType="templateType"
          :validations="[
            {text: 'required!', value: $v.formData.unitId.$dirty && $v.formData.unitId.$error}
          ]">
        </DropdownUnit>
      </div>

      <div class="form-row" v-else-if="selectedTab === 'inventory'">
        <DropdownInventory
          class="col-12"
          select="id"
          v-model="formData.inventoryId"
          label="สินค้า"
          :inventoryCostType="inventoryCostType"
          :templateType="templateType"
          :validations="[
            {text: 'required!', value: $v.formData.inventoryId.$dirty && $v.formData.inventoryId.$error}
          ]">
        </DropdownInventory>
      </div>

      <div class="form-row">
        <sgv-input-number
          class="col-6"
          label="จำนวน"
          :precision="2"
          v-model="formData.qty"
          :validations="[
            {text: 'required!', value: $v.formData.qty.$dirty && $v.formData.qty.$error}
          ]">
        </sgv-input-number>

        <sgv-input-number
          class="col-6"
          label="ราคา"
          :precision="2"
          v-model="formData.stdPrice"
          :validations="[
            {text: 'required!', value: $v.formData.stdPrice.$dirty && $v.formData.stdPrice.$error}
          ]">
        </sgv-input-number>

        <sgv-input-text
          class="col-6"
          label="แจ้งเตือนราคา (%)"
          placeholder="ex. 90% 110%"
          v-model="formData.priceLimit">
        </sgv-input-text>
      </div>

      <div class="form-row">
        <div class="col-12">
          <sgv-input-check
            label="คงที่"
            inline
            v-model="formData.isFixPrice">
          </sgv-input-check>
        </div>
      </div>

      <div slot="modal-footer" class="col-12 px-0">
        <button
          v-if="!inventoryCostOrderId"
          type="button"
          @click="addOrder"
          class="btn btn-success">
          เพิ่ม
        </button>

        <button
          v-if="inventoryCostOrderId"
          type="button"
          class="btn btn-warning"
          @click="updateOrder">
          อัพเดท
        </button>

        <button
          v-if="inventoryCostOrderId"
          type="button"
          class="btn btn-danger float-right"
          @dblclick="deleteOrder">
          ลบ
        </button>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DropdownUnit from './DropdownUnit.vue'
import DropdownInventory from './DropdownInventory.vue'
import {
  CREATE_INVENTORY_COST_ORDER_OUTLET,
  UPDATE_INVENTORY_COST_ORDER_OUTLET,
  DESTROY_INVENTORY_COST_ORDER_OUTLET,
} from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    inventoryCostType: {
      type: String,
      required: true
    },
    inventoryCostId: {
      type: Number,
      required: true
    },
    inventoryCostOrderId: {
      type: Number,
      required: false
    },
    item: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        name: '',
        unitId: null,
        qty: 0,
        stdPrice: 0,
        priceLimit: '',
        inventoryId: null,
        isFixPrice: false,
      },
      tabs: [
        {text: 'กำหนดเอง', value: 'custom'},
        {text: 'คลังสินค้า', value: 'inventory'},
      ],
      selectedTab: 'custom'
    }
  },
  validations () {
    if (this.selectedTab === 'custom') {
      return {
        formData: {
          name: { required },
          unitId: { required },
          qty: { required },
          stdPrice: { required },
        }
      }
    } else if (this.selectedTab === 'inventory') {
      return {
        formData: {
          inventoryId: { required },
          qty: { required },
          stdPrice: { required },
        }
      }
    }
  },
  methods: {
    serializeInput (value) {
      if (this.selectedTab === 'custom') {
        return {
          name: value.name,
          unitId: value.unitId,
          qty: value.qty,
          stdPrice: value.stdPrice,
          inventoryId: null,
          priceLimit: value.priceLimit,
          isFixPrice: value.isFixPrice,
        }
      } else if (this.selectedTab === 'inventory') {
        return {
          name: '',
          unitId: null,
          qty: value.qty,
          stdPrice: value.stdPrice,
          inventoryId: value.inventoryId,
          priceLimit: value.priceLimit,
          isFixPrice: value.isFixPrice,
        }
      } else {
        this.$toasted.global.error('ข้อมูลไม่ถูกต้อง')
        return null
      }
    },
    addOrder () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_INVENTORY_COST_ORDER_OUTLET(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          inventoryCostId: this.inventoryCostId,
          input: this.serializeInput(this.formData)
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$emit('change', null)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    updateOrder () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_INVENTORY_COST_ORDER_OUTLET(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          inventoryCostOrderId: this.inventoryCostOrderId,
          input: this.serializeInput(this.formData)
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$emit('change', null)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    deleteOrder () {
      this.$apollo.mutate({
        mutation: DESTROY_INVENTORY_COST_ORDER_OUTLET(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          inventoryCostOrderId: this.inventoryCostOrderId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$emit('change', null)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.$v.$reset()
      this.formData.name = ''
      this.formData.unitId = null
      this.formData.qty = 0
      this.formData.stdPrice = 0
      this.formData.priceLimit = ''
      this.formData.inventoryId = null
      this.formData.isFixPrice = false
    }
  },
  watch: {
    toggle (value) {
      if (value && this.item) {
        if (this.item.inventoryId) this.selectedTab = 'inventory'
        else this.selectedTab = 'custom'

        Object.keys(this.formData).forEach(key => {
          this.formData[key] = this.item[key] || this.formData[key]
        })
      } else {
        this.setDefault()
      }
    }
  },
  components: {
    DropdownUnit,
    DropdownInventory
  }
}
</script>

<style lang="css" scoped>
</style>
