<template>
  <span class="">
    <button
      class="btn btn-primary btn-sm"
      @click="modalShow = !modalShow">
      <fa icon="print"></fa>
      <span class="ml-1">{{title}}</span>
    </button>

    <b-modal
      v-model="modalShow"
      hide-footer
      :title="title">
      <div class="form-row">
        <sgv-input-select
          class="col-12 col-sm-6"
          label="เครื่องพิมพ์"
          placeholder="เลือกเครื่องพิมพ์..."
          :options="devices"
          select="id"
          v-model="deviceId">
          <template slot-scope="option">
            {{option.name}}
          </template>
        </sgv-input-select>

        <div class="form-group col-12 col-sm-6">
          <label>
            จำนวน:
          </label>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              placeholder="จำนวน"
              v-model.number="qty"
              @keyup.enter="printAddress">
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" @click="printAddress">
                <fa icon="print"></fa>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </span>

</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_BRANCH_DEVICE,
  PRINT_BRANCH
} from '../graph/address'

export default {
  mixins: [retainMixin],
  props: {
    title: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Contact${this.$form.capitalize(this.contactType)}AddressLabelBranch`,
      rFields: ['deviceId'],
      deviceId: null,
      qty: 1,
      devices: [],
      modalShow: false
    }
  },
  apollo: {
    devices: {
      query () {
        return LIST_BRANCH_DEVICE(this.templateType)
      },
      variables () {
        return {contactType: this.contactType}
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    printAddress () {
      if (this.qty > 0) {
        if (!this.contactId) {
          this.$toasted.global.error("ไม่พบข้อมูลพิมพ์ป้าย")
          return
        } else if (!this.deviceId) {
          this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
          return
        } else {
          this.$apollo.mutate({
            mutation: PRINT_BRANCH(this.templateType),
            variables: {
              contactType: this.contactType,
              deviceId: this.deviceId,
              contactId: this.contactId,
              qty: this.qty
            }
          })
          .then(() => {
            this.$toasted.global.success('ส่งข้อมูลไปยังเครื่องพิมพ์...')
          })
          .catch(this.$toasted.global.error)
        }
      } else {
        this.$toasted.global.error("ระบุจำนวนไม่ถูกต้อง")
      }
    }
  },
  watch: {
    deviceId () {
      this.setRetaining()
    }
  }
}
</script>

<style lang="css">
</style>
