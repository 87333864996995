import { capitalize } from '@/libs/form'

export default ({ logType, title, subTitle, group }) => {
  const templateType = 'ChronowoodthLog'
  return [
    {
      path: `chronowoodth/log/${logType}/`,
      name: `ChronowoodthLog${capitalize(logType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        logType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
