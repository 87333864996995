<template>
  <div class="col-10 col-md-6 col-lg-5 mt-4">
    <h2 class="text-center my-4">Confirm Password</h2>

    <sgv-input-password
      label="รหัสผ่าน"
      placeholder="รหัสผ่าน"
      v-model="formData.password"
      @keyup.enter="confirm"
      :validations="[
        {text: 'โปรดระบุรหัสผ่าน', value: $v.formData.password.$dirty && $v.formData.password.$error},
      ]">
    </sgv-input-password>

    <button
      type="button"
      class="btn btn-block btn-outline-primary"
      @click="confirm">
      ยืนยัน
    </button>

    <button
      type="button"
      class="btn btn-block btn-outline-danger"
      @click="$router.go(-1)">
      กลับ
    </button>

  </div>
</template>

<script>
import { confirmUser } from '@/plugins/axios'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      formData: {
        password: ''
      },
      appName: process.env.VUE_APP_NAME
    }
  },
  validations: {
    formData: {
      password: {required}
    }
  },
  methods: {
    confirm () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const formData = {password: this.password}
      
      confirmUser(formData)
      .then(() => {
        this.$router.go(-1)
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>

</style>
