import { capitalize } from '@/libs/form'

export default ({ priceType, unitType, title, subTitle, group }) => {
  const templateType = 'ChronowoodthPrice'
  return [
    {
      path: `chronowoodth/price/${priceType}/`,
      name: `ChronowoodthPrice${capitalize(priceType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        priceType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `chronowoodth/price/${priceType}/:priceId`,
      name: `ChronowoodthPrice${capitalize(priceType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        templateType,
        priceType,
        unitType,
        title, subTitle, group,
        priceId: Number(route.params.priceId)
      })
    },
  ]
}
