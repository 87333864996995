import { capitalize } from '@/libs/form'

export default ({kioskViewType, title, subTitle, group}) => {
  const templateType = 'DocStatus'
  return [
    {
      path: `kioskView/${kioskViewType}`,
      name: `KioskView${capitalize(kioskViewType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        kioskViewType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `kioskView/${kioskViewType}/:kioskViewId`,
      name: `KioskView${capitalize(kioskViewType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        kioskViewId: Number(route.params.kioskViewId),
        templateType,
        kioskViewType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
