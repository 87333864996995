import gql from 'graphql-tag'


const detailResponse = `
  id type code name isActive
  children {
    id type name order parentId
    children {
      id type name order parentId
      formType isShowRemark
      actualPoint minPoint maxPoint
      choices {text value}
    }
  }
`

export const LIST_KPI_TEMPLATE = (templateType) => gql`query LIST_KPI_TEMPLATE ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}KpiTemplate (docType: $docType, q: $q) {
    id type code name isActive
  }
}`

export const DETAIL_KPI_TEMPLATE = (templateType) => gql`query DETAIL_KPI_TEMPLATE ($docType: String!, $id: Int!) {
  item: detailDoc${templateType}KpiTemplate (docType: $docType, id: $id) {${detailResponse}}
}`

export const CREATE_KPI_TEMPLATE = (templateType) => gql`mutation CREATE_KPI_TEMPLATE ($docType: String!, $input: KpiTemplateInput!) {
  item: createDoc${templateType}KpiTemplate (docType: $docType, input: $input) {${detailResponse}}
}`

export const UPDATE_KPI_TEMPLATE = (templateType) => gql`mutation UPDATE_KPI_TEMPLATE ($docType: String!, $id: Int!, $input: KpiTemplateInput!) {
  item: updateDoc${templateType}KpiTemplate (docType: $docType, id: $id, input: $input) {${detailResponse}}
}`

export const DESTROY_KPI_TEMPLATE = (templateType) => gql`mutation DESTROY_KPI_TEMPLATE ($docType: String!, $id: Int!) {
  item: destroyDoc${templateType}KpiTemplate (docType: $docType, id: $id) {id}
}`
