import { capitalize } from '@/libs/form'

import ProfileAnnouncementRouter from './profile_template_announcement/router'
import ProfileUserRouter from './profile_template_user/router'

import PaperActionPlanRouter from './paper_template_action_plan/router'
import PaperAppDistributionListRouter from './paper_template_app_distribution_list/router'
import PaperCheckListRouter from './paper_template_check_list/router'
import PaperCparRouter from './paper_template_cpar/router'
import PaperCparLogRouter from './paper_template_cpar_log/router'
import PaperDistributionListRouter from './paper_template_distribution_list/router'
import PaperDocumentActionRequestRouter from './paper_template_document_action_request/router'
import PaperDocumentControlRouter from './paper_template_document_control/router'
import PaperDrawingDistributionListRouter from './paper_template_drawing_distribution_list/router'
import PaperInternalAuditReportRouter from './paper_template_internal_audit_report/router'
import PaperManagementReviewReportRouter from './paper_template_management_review_report/router'
import PaperMarkdownRouter from './paper_template_markdown/router'
import PaperOpportunityRouter from './paper_template_opportunity/router'
import PaperQualityManualRouter from './paper_template_quality_manual/router'
import PaperQualityProcedureRouter from './paper_template_quality_procedure/router'
import PaperRiskAssessmentRouter from './paper_template_risk_assessment/router'
import PaperSupportingDocumentRouter from './paper_template_supporting_document/router'
import PaperTargetGraphRouter from './paper_template_target_graph/router'
import PaperWorkInstructionRouter from './paper_template_work_instruction/router'

import AccountTrialBalanceRouter from './account_template_trial_balance/router'

import AccountGeneralRouter from './account_template_account_general/router'
import ManageGeneralRouter from './account_template_manage_general/router'
import UnitGeneralRouter from './account_template_unit_general/router'

import BankStatementGeneralRouter from './bank_statement_template_general/router'

import CountryGeneralRouter from './contact_template_country_general/router'
import CountryAreaGeneralRouter from './contact_template_country_area_general/router'
import ContactEmployeeRouter from './contact_template_employee/router'
import ContactJobDescriptionGeneralRouter from './contact_template_jobdescription/router'
import ContactGeneralRouter from './contact_template_general/router'

import InventoryGeneralRouter from './inventory_template_general/router'
import InventoryGeneralDeadStockRouter from './inventory_template_general_dead_stock/router'
import InventoryGeneralNotificationRouter from './inventory_template_general_notification/router'
import InventoryGeneralReportRouter from './inventory_template_general_report/router'
import InventoryGeneralStockInfoRouter from './inventory_template_general_stock_info/router'
import InventoryCostRouter from './inventory_template_cost/router'
import InventoryStockRouter from './inventory_template_stock/router'

import StockAssetGeneralRouter from './stock_template_asset_general/router'

import UserGeneralRouter from './admin_template_user_general/router'
import UserEmployeeRouter from './admin_template_user_employee/router'
import UserDeviceRouter from './admin_template_user_device/router'
import UserKioskDeviceRouter from './admin_template_user_kiosk_device/router'

import RoleGeneralRouter from './admin_template_role_general/router'
import GroupGeneralRouter from './admin_template_group_general/router'
import AuditGeneralRouter from './admin_template_audit_general/router'
import AssetGeneralRouter from './admin_template_asset_general/router'

import LabelGeneralRouter from './label_template_general/router'

import DeviceBarcodeScannerRouter from './device_template_barcode_scanner/router'
import DeviceBaySmeRouter from './device_template_bay_sme/router'
import DeviceBblSmeRouter from './device_template_bbl_sme/router'
import DeviceEpsonCloudRouter from './device_template_epson_cloud/router'
import DeviceExtruderRouter from './device_template_extruder/router'
import DeviceOpcuaRouter from './device_template_opcua/router'
import DeviceModbusRouter from './device_template_modbus/router'
import DeviceFacilityRouter from './device_template_facility/router'
import DeviceGmailRouter from './device_template_gmail/router'
import DeviceSendgridRouter from './device_template_sendgrid/router'
import DeviceGodexRouter from './device_template_godex/router'
import DeviceKbankSmeRouter from './device_template_kbank_sme/router'
import DeviceLineAppRouter from './device_template_line_app/router'
import DeviceMixerRouter from './device_template_mixer/router'
import DeviceScbSmeRouter from './device_template_scb_sme/router'
import DeviceVajaRouter from './device_template_vaja/router'
import DeviceWindowPrinterRouter from './device_template_window_printer/router'
import DeviceTrelloRouter from './device_template_trello/router'

import KioskDeviceGeneralRouter from './kiosk_device_template_general/router'
import KioskViewEmployeeAttendanceRouter from './kiosk_view_template_employee_attendance/router'
import KioskViewEmployeeScannerRouter from './kiosk_view_template_employee_scanner/router'
import KioskViewEmployeeValidationRouter from './kiosk_view_template_employee_validation/router'
import KioskViewTrelloRouter from './kiosk_view_template_trello/router'
import KioskViewIpcRouter from './kiosk_view_template_ipc/router'
import KioskViewDocStatusRouter from './kiosk_view_template_doc_status/router'
import KioskViewPaperStatusRouter from './kiosk_view_template_paper_status/router'

import ProcedureControlRouter from './procedure_template_control/router'
import ProcedureGeneralRouter from './procedure_template_general/router'
import ProcedureInventoryRouter from './procedure_template_inventory/router'
import ProcedurePlanningRouter from './procedure_template_planning/router'
import ProcedureQualityRouter from './procedure_template_quality/router'

import DocContactComplaintRouter from './doc_template_contact_complaint/router'
import DocContactEvaluationRouter from './doc_template_contact_evaluation/router'
import DocContactSatisfactionRouter from './doc_template_contact_satisfaction/router'
import DocContactSurveyRouter from './doc_template_contact_survey/router'
import DocContactRegistrationRouter from './doc_template_contact_registration/router'

import DocDesignNoteRouter from './doc_template_design_note/router'

import DocAttendanceInvoiceRouter from './doc_template_hr_attendance_invoice/router'
import DocAttendanceNoteRouter from './doc_template_hr_attendance_note/router'
import DocAttendancePaymentRouter from './doc_template_hr_attendance_payment/router'
import DocAttendancePayrollRouter from './doc_template_hr_attendance_payroll/router'
import DocAttendanceWithholdingRouter from './doc_template_hr_attendance_withholding/router'

import DocEmployeeApplicationRouter from './doc_template_hr_employee_application/router'
import DocEmployeeEvaluationRouter from './doc_template_hr_employee_evaluation/router'
import DocEmployeeRequestRouter from './doc_template_hr_employee_request/router'
import DocEmployeeSurveyRouter from './doc_template_hr_employee_survey/router'
import DocEmployeeTrainingRouter from './doc_template_hr_employee_training/router'
import DocEmployeeTrainingRequestRouter from './doc_template_hr_employee_training_request/router'
import DocEmployeeWelfareRouter from './doc_template_hr_employee_welfare/router'

import DocInventoryMaintenanceRouter from './doc_template_inventory_maintenance/router'
import DocInventoryRepairRouter from './doc_template_inventory_repair/router'
import DocInventoryRequisitionRouter from './doc_template_inventory_requisition/router'
import DocInventoryStockcountRouter from './doc_template_inventory_stockcount/router'
import DocInventoryTransferRouter from './doc_template_inventory_transfer/router'
import DocInventoryLoggingRouter from './doc_template_inventory_logging/router'

import DocStockAssetDeductionRouter from './doc_template_stock_asset_deduction/router'

import DocAccountSummaryRouter from './doc_template_account_summary/router'
import DocAccountAccruedCreditorRouter from './doc_template_account_accrued_creditor/router'
import DocAccountAccruedDebitorRouter from './doc_template_account_accrued_debitor/router'
import DocAccountVatRouter from './doc_template_account_vat/router'
import DocAccountWithholdingCompanyRouter from './doc_template_account_withholding_company/router'
import DocAccountWithholdingPersonRouter from './doc_template_account_withholding_person/router'
import DocAccountPersonalTaxRouter from './doc_template_account_personal_tax/router'
import DocAccountSocialSecurityRouter from './doc_template_account_social_security/router'
import DocAccountStudentLoanRouter from './doc_template_account_student_loan/router'
import DocAccountGeneralRouter from './doc_template_account_general/router'
import DocAccountLedgerBalanceRouter from './doc_template_account_ledger_balance/router'

import DocProductionorderRouter from './doc_template_production_productionorder/router'

import DocPurchaseWithholdingRouter from './doc_template_purchase_withholding/router'
import DocPurchaseCashRouter from './doc_template_purchase_cash/router'
import DocPurchaseExpenseRouter from './doc_template_purchase_expense/router'
import DocPurchaseExpenseRequestRouter from './doc_template_purchase_expense_request/router'
import DocPurchaseExpenseBillingRouter from './doc_template_purchase_expense_billing/router'
import DocPurchaseExpenseCreditNoteRouter from './doc_template_purchase_expense_credit_note/router'
import DocPurchaseExpenseInvoiceRouter from './doc_template_purchase_expense_invoice/router'
import DocPurchaseExpensePaymentRouter from './doc_template_purchase_expense_payment/router'
import DocPurchaseExpenseDepositRouter from './doc_template_purchase_expense_deposit/router'


import DocSaleBadDebtRouter from './doc_template_sale_bad_debt/router'
import DocSaleBillingRouter from './doc_template_sale_billing/router'
import DocSaleContractorRouter from './doc_template_sale_contractor/router'
import DocSaleCreditNoteRouter from './doc_template_sale_credit_note/router'
import DocSaleDebitNoteRouter from './doc_template_sale_debit_note/router'
import DocSaleEquipmentRouter from './doc_template_sale_equipment/router'
import DocSaleInvoiceRouter from './doc_template_sale_invoice/router'
import DocSaleTemporaryInvoiceRouter from './doc_template_sale_temporary_invoice/router'
import DocSalePaymentRouter from './doc_template_sale_payment/router'
import DocSalePriceListRouter from './doc_template_sale_price_list/router'
import DocSaleQuotationRouter from './doc_template_sale_quotation/router'
import DocSaleReceiptRouter from './doc_template_sale_receipt/router'
import DocSaleReceiptDepositRouter from './doc_template_sale_receipt_deposit/router'
import DocSaleSalesorderRouter from './doc_template_sale_salesorder/router'
import DocSaleRequisitionRouter from './doc_template_sale_requisition/router'
import DocSaleThaipostRouter from './doc_template_sale_thaipost/router'

import DocSpcSiteSurveyRouter from './doc_template_spc_site_survey/router'
import DocSpcInstallationRouter from './doc_template_spc_installation/router'
import DocSpcNoticeRouter from './doc_template_spc_notice/router'
import DocSpcDeliveryNoteRouter from './doc_template_spc_delivery_note/router'

import CouponLuckyDrawRouter from './coupon_template_lucky_draw/router'

import ChronowoodthAssetRouter from './chronowoodth_template_asset/router'
import ChronowoodthProductRouter from './chronowoodth_template_product/router'
import ChronowoodthProjectRouter from './chronowoodth_template_project/router'
import ChronowoodthLogRouter from './chronowoodth_template_log/router'
import ChronowoodthPromotionRouter from './chronowoodth_template_promotion/router'
import ChronowoodthUnitRouter from './chronowoodth_template_unit/router'
import ChronowoodthPriceRouter from './chronowoodth_template_price/router'


export default {
  ProfileAnnouncement: ({ profileType, title, subTitle, group }) => ({
    defaultView: `Profile${capitalize(profileType)}List`,
    requiedRoles: ['*'],
    routes: ProfileAnnouncementRouter({ profileType, title, subTitle, group })
  }),
  ProfileUser: ({ profileType, title, subTitle, group }) => ({
    defaultView: `Profile${capitalize(profileType)}List`,
    requiedRoles: ['*'],
    routes: ProfileUserRouter({ profileType, title, subTitle, group })
  }),

  PaperActionPlan: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperActionPlanRouter({ paperType, title, subTitle, group })
  }),
  PaperAppDistributionList: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperAppDistributionListRouter({ paperType, title, subTitle, group })
  }),
  PaperCheckList: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperCheckListRouter({ paperType, title, subTitle, group })
  }),
  PaperCpar: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperCparRouter({ paperType, title, subTitle, group })
  }),
  PaperCparLog: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperCparLogRouter({ paperType, title, subTitle, group })
  }),
  PaperDistributionList: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperDistributionListRouter({ paperType, title, subTitle, group })
  }),
  PaperDocumentActionRequest: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperDocumentActionRequestRouter({ paperType, title, subTitle, group })
  }),
  PaperDocumentControl: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperDocumentControlRouter({ paperType, title, subTitle, group })
  }),
  PaperDrawingDistributionList: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperDrawingDistributionListRouter({ paperType, title, subTitle, group })
  }),
  PaperInternalAuditReport: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperInternalAuditReportRouter({ paperType, title, subTitle, group })
  }),
  PaperManagementReviewReport: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperManagementReviewReportRouter({ paperType, title, subTitle, group })
  }),
  PaperMarkdown: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperMarkdownRouter({ paperType, title, subTitle, group })
  }),
  PaperOpportunity: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperOpportunityRouter({ paperType, title, subTitle, group })
  }),
  PaperQualityManual: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperQualityManualRouter({ paperType, title, subTitle, group })
  }),
  PaperQualityProcedure: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperQualityProcedureRouter({ paperType, title, subTitle, group })
  }),
  PaperRiskAssessment: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperRiskAssessmentRouter({ paperType, title, subTitle, group })
  }),
  PaperSupportingDocument: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperSupportingDocumentRouter({ paperType, title, subTitle, group })
  }),
  PaperTargetGraph: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperTargetGraphRouter({ paperType, title, subTitle, group })
  }),
  PaperWorkInstruction: ({ paperType, title, subTitle, group }) => ({
    defaultView: `Paper${capitalize(paperType)}List`,
    requiedRoles: [`paper:${paperType}:read`],
    routes: PaperWorkInstructionRouter({ paperType, title, subTitle, group })
  }),

  AccountTrialBalance: ({ accountTrialBalanceType, title, subTitle, group }) => ({
    defaultView: `AccountTrialBalance${capitalize(accountTrialBalanceType)}List`,
    requiedRoles: [`accountTrialBalance:${accountTrialBalanceType}:read`],
    routes: AccountTrialBalanceRouter({ accountTrialBalanceType, title, subTitle, group })
  }),

  AccountGeneral: ({ accountType, title, subTitle, group }) => ({
    defaultView: `Account${capitalize(accountType)}List`,
    requiedRoles: [`account:${accountType}:read`],
    routes: AccountGeneralRouter({ accountType, title, subTitle, group })
  }),
  ManageGeneral: ({ manageType, title, subTitle, group }) => ({
    defaultView: `Manage${capitalize(manageType)}List`,
    requiedRoles: [`manage:${manageType}:read`],
    routes: ManageGeneralRouter({ manageType, title, subTitle, group })
  }),
  UnitGeneral: ({ unitType, title, subTitle, group }) => ({
    defaultView: `Unit${capitalize(unitType)}List`,
    requiedRoles: [`unit:${unitType}:read`],
    routes: UnitGeneralRouter({ unitType, title, subTitle, group })
  }),

  BankStatementGeneral: ({ bankStatementType, title, subTitle, group }) => ({
    defaultView: `BankStatement${capitalize(bankStatementType)}List`,
    requiedRoles: [`bankStatement:${bankStatementType}:read`],
    routes: BankStatementGeneralRouter({ bankStatementType, title, subTitle, group })
  }),

  CountryGeneral: ({ countryType, title, subTitle, group }) => ({
    defaultView: `Country${capitalize(countryType)}List`,
    requiedRoles: [`country:${countryType}:read`],
    routes: CountryGeneralRouter({ countryType, title, subTitle, group })
  }),
  CountryAreaGeneral: ({ countryAreaType, title, subTitle, group }) => ({
    defaultView: `CountryArea${capitalize(countryAreaType)}List`,
    requiedRoles: [`countryArea:${countryAreaType}:read`],
    routes: CountryAreaGeneralRouter({ countryAreaType, title, subTitle, group })
  }),
  ContactEmployee: ({ contactType, title, subTitle, group }) => ({
    defaultView: `Contact${capitalize(contactType)}List`,
    requiedRoles: [`contact:${contactType}:read`],
    routes: ContactEmployeeRouter({ contactType, title, subTitle, group })
  }),
  ContactJobDescriptionGeneral: ({ jobDescriptionType, title, subTitle, group }) => ({
    defaultView: `ContactJobDescription${capitalize(jobDescriptionType)}List`,
    requiedRoles: [`jobDescription:${jobDescriptionType}:read`],
    routes: ContactJobDescriptionGeneralRouter({ jobDescriptionType, title, subTitle, group })
  }),
  ContactGeneral: ({ contactType, title, subTitle, group }) => ({
    defaultView: `Contact${capitalize(contactType)}List`,
    requiedRoles: [`contact:${contactType}:read`],
    routes: ContactGeneralRouter({ contactType, title, subTitle, group })
  }),

  InventoryGeneral: ({ inventoryType, title, subTitle, group, stockcountTemplateType, stockcountDocType }) => ({
    defaultView: `Inventory${capitalize(inventoryType)}List`,
    requiedRoles: [`inventory:${inventoryType}:read`],
    routes: InventoryGeneralRouter({ inventoryType, title, subTitle, group, stockcountTemplateType, stockcountDocType })
  }),
  InventoryGeneralDeadStock: ({ inventoryType, title, subTitle, group }) => ({
    defaultView: `Inventory${capitalize(inventoryType)}DeadStock`,
    requiedRoles: [`inventory:${inventoryType}:read`],
    routes: InventoryGeneralDeadStockRouter({ inventoryType, title, subTitle, group })
  }),
  InventoryGeneralNotification: ({ inventoryType, title, subTitle, group }) => ({
    defaultView: `Inventory${capitalize(inventoryType)}Notification`,
    requiedRoles: [`inventory:${inventoryType}:read`],
    routes: InventoryGeneralNotificationRouter({ inventoryType, title, subTitle, group })
  }),
  InventoryGeneralReport: ({ inventoryType, title, subTitle, group }) => ({
    defaultView: `Inventory${capitalize(inventoryType)}Report`,
    requiedRoles: [`inventory:${inventoryType}:read`],
    routes: InventoryGeneralReportRouter({ inventoryType, title, subTitle, group })
  }),
  InventoryGeneralStockInfo: ({ inventoryType, title, subTitle, group }) => ({
    defaultView: `Inventory${capitalize(inventoryType)}StockInfo`,
    requiedRoles: [`inventory:${inventoryType}:read`],
    routes: InventoryGeneralStockInfoRouter({ inventoryType, title, subTitle, group })
  }),
  InventoryCost: ({ inventoryCostType, title, subTitle, group }) => ({
    defaultView: `InventoryCost${capitalize(inventoryCostType)}List`,
    requiedRoles: [`inventoryCost:${inventoryCostType}:read`],
    routes: InventoryCostRouter({ inventoryCostType, title, subTitle, group })
  }),
  InventoryStock: ({ inventoryStockType, title, subTitle, group }) => ({
    defaultView: `InventoryStock${capitalize(inventoryStockType)}List`,
    requiedRoles: [`inventoryStock:${inventoryStockType}:read`],
    routes: InventoryStockRouter({ inventoryStockType, title, subTitle, group })
  }),

  StockAssetGeneral: ({ stockAssetType, title, subTitle, group }) => ({
    defaultView: `StockAsset${capitalize(stockAssetType)}List`,
    requiedRoles: [`stockAsset:${stockAssetType}:read`],
    routes: StockAssetGeneralRouter({ stockAssetType, title, subTitle, group })
  }),

  UserGeneral: ({ userType, title, subTitle, group }) => ({
    defaultView: `User${capitalize(userType)}List`,
    requiedRoles: [`user:${userType}:read`],
    routes: UserGeneralRouter({ userType, title, subTitle, group })
  }),
  UserEmployee: ({ userType, title, subTitle, group }) => ({
    defaultView: `User${capitalize(userType)}List`,
    requiedRoles: [`user:${userType}:read`],
    routes: UserEmployeeRouter({ userType, title, subTitle, group })
  }),
  UserDevice: ({ userType, title, subTitle, group }) => ({
    defaultView: `User${capitalize(userType)}List`,
    requiedRoles: [`user:${userType}:read`],
    routes: UserDeviceRouter({ userType, title, subTitle, group })
  }),
  UserKioskDevice: ({ userType, title, subTitle, group }) => ({
    defaultView: `User${capitalize(userType)}List`,
    requiedRoles: [`user:${userType}:read`],
    routes: UserKioskDeviceRouter({ userType, title, subTitle, group })
  }),

  RoleGeneral: ({ roleType, title, subTitle, group }) => ({
    defaultView: `Role${capitalize(roleType)}List`,
    requiedRoles: [`role:${roleType}:read`],
    routes: RoleGeneralRouter({ roleType, title, subTitle, group })
  }),
  GroupGeneral: ({ groupType, title, subTitle, group }) => ({
    defaultView: `Group${capitalize(groupType)}List`,
    requiedRoles: [`group:${groupType}:read`],
    routes: GroupGeneralRouter({ groupType, title, subTitle, group })
  }),
  AuditGeneral: ({ auditType, title, subTitle, group }) => ({
    defaultView: `Audit${capitalize(auditType)}List`,
    requiedRoles: [`audit:${auditType}:read`],
    routes: AuditGeneralRouter({ auditType, title, subTitle, group })
  }),
  AssetGeneral: ({ assetType, title, subTitle, group }) => ({
    defaultView: `Asset${capitalize(assetType)}List`,
    requiedRoles: [`asset:${assetType}:read`],
    routes: AssetGeneralRouter({ assetType, title, subTitle, group })
  }),

  LabelGeneral: ({ labelType, title, subTitle, group }) => ({
    defaultView: `Label${capitalize(labelType)}List`,
    requiedRoles: [`label:${labelType}:read`],
    routes: LabelGeneralRouter({ labelType, title, subTitle, group })
  }),

  DeviceBarcodeScanner: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceBarcodeScannerRouter({ deviceType, title, subTitle, group })
  }),
  DeviceBaySme: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceBaySmeRouter({ deviceType, title, subTitle, group })
  }),
  DeviceBblSme: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceBblSmeRouter({ deviceType, title, subTitle, group })
  }),
  DeviceEpsonCloud: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceEpsonCloudRouter({ deviceType, title, subTitle, group })
  }),
  DeviceExtruder: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceExtruderRouter({ deviceType, title, subTitle, group })
  }),
  DeviceOpcua: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceOpcuaRouter({ deviceType, title, subTitle, group })
  }),
  DeviceModbus: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceModbusRouter({ deviceType, title, subTitle, group })
  }),
  DeviceFacility: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceFacilityRouter({ deviceType, title, subTitle, group })
  }),
  DeviceGmail: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceGmailRouter({ deviceType, title, subTitle, group })
  }),
  DeviceSendgrid: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceSendgridRouter({ deviceType, title, subTitle, group })
  }),
  DeviceGodex: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceGodexRouter({ deviceType, title, subTitle, group })
  }),
  DeviceKbankSme: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceKbankSmeRouter({ deviceType, title, subTitle, group })
  }),
  DeviceLineApp: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceLineAppRouter({ deviceType, title, subTitle, group })
  }),
  DeviceMixer: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceMixerRouter({ deviceType, title, subTitle, group })
  }),
  DeviceScbSme: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceScbSmeRouter({ deviceType, title, subTitle, group })
  }),
  DeviceVaja: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceVajaRouter({ deviceType, title, subTitle, group })
  }),
  DeviceWindowPrinter: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceWindowPrinterRouter({ deviceType, title, subTitle, group })
  }),
  DeviceTrello: ({ deviceType, title, subTitle, group }) => ({
    defaultView: `Device${capitalize(deviceType)}List`,
    requiedRoles: [`device:${deviceType}:read`],
    routes: DeviceTrelloRouter({ deviceType, title, subTitle, group })
  }),

  KioskDeviceGeneral: ({ kioskDeviceType, title, subTitle, group }) => ({
    defaultView: `KioskDevice${capitalize(kioskDeviceType)}List`,
    requiedRoles: [`kioskDevice:${kioskDeviceType}:read`],
    routes: KioskDeviceGeneralRouter({ kioskDeviceType, title, subTitle, group })
  }),
  KioskViewEmployeeAttendance: ({ kioskViewType, title, subTitle, group }) => ({
    defaultView: `KioskView${capitalize(kioskViewType)}List`,
    requiedRoles: [`kioskView:${kioskViewType}:read`],
    routes: KioskViewEmployeeAttendanceRouter({ kioskViewType, title, subTitle, group })
  }),
  KioskViewEmployeeScanner: ({ kioskViewType, title, subTitle, group }) => ({
    defaultView: `KioskView${capitalize(kioskViewType)}List`,
    requiedRoles: [`kioskView:${kioskViewType}:read`],
    routes: KioskViewEmployeeScannerRouter({ kioskViewType, title, subTitle, group })
  }),
  KioskViewEmployeeValidation: ({ kioskViewType, title, subTitle, group }) => ({
    defaultView: `KioskView${capitalize(kioskViewType)}List`,
    requiedRoles: [`kioskView:${kioskViewType}:read`],
    routes: KioskViewEmployeeValidationRouter({ kioskViewType, title, subTitle, group })
  }),
  KioskViewTrello: ({ kioskViewType, title, subTitle, group }) => ({
    defaultView: `KioskView${capitalize(kioskViewType)}List`,
    requiedRoles: [`kioskView:${kioskViewType}:read`],
    routes: KioskViewTrelloRouter({ kioskViewType, title, subTitle, group })
  }),
  KioskViewIpc: ({ kioskViewType, title, subTitle, group }) => ({
    defaultView: `KioskView${capitalize(kioskViewType)}List`,
    requiedRoles: [`kioskView:${kioskViewType}:read`],
    routes: KioskViewIpcRouter({ kioskViewType, title, subTitle, group })
  }),
  KioskViewDocStatus: ({ kioskViewType, title, subTitle, group }) => ({
    defaultView: `KioskView${capitalize(kioskViewType)}List`,
    requiedRoles: [`kioskView:${kioskViewType}:read`],
    routes: KioskViewDocStatusRouter({ kioskViewType, title, subTitle, group })
  }),
  KioskViewPaperStatus: ({ kioskViewType, title, subTitle, group }) => ({
    defaultView: `KioskView${capitalize(kioskViewType)}List`,
    requiedRoles: [`kioskView:${kioskViewType}:read`],
    routes: KioskViewPaperStatusRouter({ kioskViewType, title, subTitle, group })
  }),

  ProcedureControl: ({ procedureType, title, subTitle, group }) => ({
    defaultView: `ProcedureControl${capitalize(procedureType)}List`,
    requiedRoles: [`procedure:${procedureType}:control:read`],
    routes: ProcedureControlRouter({ procedureType, title, subTitle, group })
  }),
  ProcedureGeneral: ({ procedureType, title, subTitle, group }) => ({
    defaultView: `Procedure${capitalize(procedureType)}List`,
    requiedRoles: [`procedure:${procedureType}:read`],
    routes: ProcedureGeneralRouter({ procedureType, title, subTitle, group })
  }),
  ProcedureInventory: ({ procedureType, title, subTitle, group }) => ({
    defaultView: `ProcedureInventory${capitalize(procedureType)}List`,
    requiedRoles: [`procedure:${procedureType}:inventory:read`],
    routes: ProcedureInventoryRouter({ procedureType, title, subTitle, group })
  }),
  ProcedurePlanning: ({ procedureType, title, subTitle, group }) => ({
    defaultView: `Procedure${capitalize(procedureType)}Planning`,
    requiedRoles: [`procedure:${procedureType}:planning:read`],
    routes: ProcedurePlanningRouter({ procedureType, title, subTitle, group })
  }),
  ProcedureQuality: ({ procedureType, title, subTitle, group }) => ({
    defaultView: `ProcedureQuality${capitalize(procedureType)}List`,
    requiedRoles: [`procedure:${procedureType}:quality:read`],
    routes: ProcedureQualityRouter({ procedureType, title, subTitle, group })
  }),

  DocContactComplaint: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocContactComplaintRouter({ docType, title, subTitle, group })
  }),
  DocContactEvaluation: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocContactEvaluationRouter({ docType, title, subTitle, group })
  }),
  DocContactSatisfaction: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocContactSatisfactionRouter({ docType, title, subTitle, group })
  }),
  DocContactSurvey: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocContactSurveyRouter({ docType, title, subTitle, group })
  }),
  DocContactRegistration: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocContactRegistrationRouter({ docType, title, subTitle, group })
  }),

  DocDesignNote: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocDesignNoteRouter({ docType, title, subTitle, group })
  }),

  DocAttendanceInvoice: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAttendanceInvoiceRouter({ docType, title, subTitle, group })
  }),
  DocAttendanceNote: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAttendanceNoteRouter({ docType, title, subTitle, group })
  }),
  DocAttendancePayment: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAttendancePaymentRouter({ docType, title, subTitle, group })
  }),
  DocAttendancePayroll: ({ docType, title, subTitle, group, contactTypes }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAttendancePayrollRouter({ docType, title, subTitle, group, contactTypes })
  }),
  DocAttendanceWithholding: ({ docType, title, subTitle, group, contactTypes }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAttendanceWithholdingRouter({ docType, title, subTitle, group, contactTypes })
  }),

  DocEmployeeApplication: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocEmployeeApplicationRouter({ docType, title, subTitle, group })
  }),
  DocEmployeeEvaluation: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocEmployeeEvaluationRouter({ docType, title, subTitle, group })
  }),
  DocEmployeeRequest: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocEmployeeRequestRouter({ docType, title, subTitle, group })
  }),
  DocEmployeeSurvey: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocEmployeeSurveyRouter({ docType, title, subTitle, group })
  }),
  DocEmployeeTraining: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocEmployeeTrainingRouter({ docType, title, subTitle, group })
  }),
  DocEmployeeTrainingRequest: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocEmployeeTrainingRequestRouter({ docType, title, subTitle, group })
  }),
  DocEmployeeWelfare: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocEmployeeWelfareRouter({ docType, title, subTitle, group })
  }),

  DocInventoryMaintenance: ({ docType, title, subTitle, group, inventoryType }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocInventoryMaintenanceRouter({ docType, title, subTitle, group, inventoryType })
  }),
  DocInventoryRepair: ({ docType, title, subTitle, group, inventoryType }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocInventoryRepairRouter({ docType, title, subTitle, group, inventoryType })
  }),
  DocInventoryRequisition: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocInventoryRequisitionRouter({ docType, title, subTitle, group })
  }),
  DocInventoryStockcount: ({ docType, title, subTitle, group, inventoryType }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocInventoryStockcountRouter({ docType, title, subTitle, group, inventoryType })
  }),
  DocInventoryTransfer: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocInventoryTransferRouter({ docType, title, subTitle, group })
  }),
  DocInventoryLogging: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocInventoryLoggingRouter({ docType, title, subTitle, group })
  }),

  DocStockAssetDeduction: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocStockAssetDeductionRouter({ docType, title, subTitle, group })
  }),

  DocAccountSummary: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountSummaryRouter({ docType, title, subTitle, group })
  }),
  DocAccountAccruedCreditor: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountAccruedCreditorRouter({ docType, title, subTitle, group })
  }),
  DocAccountAccruedDebitor: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountAccruedDebitorRouter({ docType, title, subTitle, group })
  }),
  DocAccountVat: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountVatRouter({ docType, title, subTitle, group })
  }),
  DocAccountWithholdingCompany: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountWithholdingCompanyRouter({ docType, title, subTitle, group })
  }),
  DocAccountWithholdingPerson: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountWithholdingPersonRouter({ docType, title, subTitle, group })
  }),
  DocAccountPersonalTax: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountPersonalTaxRouter({ docType, title, subTitle, group })
  }),
  DocAccountSocialSecurity: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountSocialSecurityRouter({ docType, title, subTitle, group })
  }),
  DocAccountStudentLoan: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountStudentLoanRouter({ docType, title, subTitle, group })
  }),
  DocAccountGeneral: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountGeneralRouter({ docType, title, subTitle, group })
  }),
  DocAccountLedgerBalance: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocAccountLedgerBalanceRouter({ docType, title, subTitle, group })
  }),

  DocProductionorder: ({ docType, title, subTitle, group, isEmployee }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocProductionorderRouter({ docType, title, subTitle, group, isEmployee })
  }),

  DocPurchaseWithholding: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocPurchaseWithholdingRouter({ docType, title, subTitle, group })
  }),
  DocPurchaseCash: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocPurchaseCashRouter({ docType, title, subTitle, group })
  }),
  DocPurchaseExpense: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocPurchaseExpenseRouter({ docType, title, subTitle, group })
  }),
  DocPurchaseExpenseRequest: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocPurchaseExpenseRequestRouter({ docType, title, subTitle, group })
  }),
  DocPurchaseExpenseBilling: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocPurchaseExpenseBillingRouter({ docType, title, subTitle, group })
  }),
  DocPurchaseExpenseCreditNote: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocPurchaseExpenseCreditNoteRouter({ docType, title, subTitle, group })
  }),
  DocPurchaseExpenseInvoice: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocPurchaseExpenseInvoiceRouter({ docType, title, subTitle, group })
  }),
  DocPurchaseExpensePayment: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocPurchaseExpensePaymentRouter({ docType, title, subTitle, group })
  }),
  DocPurchaseExpenseDeposit: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocPurchaseExpenseDepositRouter({ docType, title, subTitle, group })
  }),

  DocSaleBadDebt: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleBadDebtRouter({ docType, title, subTitle, group })
  }),
  DocSaleBilling: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleBillingRouter({ docType, title, subTitle, group })
  }),
  DocSaleContractor: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleContractorRouter({ docType, title, subTitle, group })
  }),
  DocSaleCreditNote: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleCreditNoteRouter({ docType, title, subTitle, group })
  }),
  DocSaleDebitNote: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleDebitNoteRouter({ docType, title, subTitle, group })
  }),
  DocSaleEquipment: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleEquipmentRouter({ docType, title, subTitle, group })
  }),
  DocSaleInvoice: ({ docType, title, subTitle, group, salesOrderType, billType, receiptType }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`, `doc:${docType}:planning:read`],
    routes: DocSaleInvoiceRouter({ docType, title, subTitle, group, salesOrderType, billType, receiptType })
  }),
  DocSaleTemporaryInvoice: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`, `doc:${docType}:read`],
    routes: DocSaleTemporaryInvoiceRouter({ docType, title, subTitle, group })
  }),
  DocSalePayment: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSalePaymentRouter({ docType, title, subTitle, group })
  }),
  DocSalePriceList: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSalePriceListRouter({ docType, title, subTitle, group })
  }),
  DocSaleQuotation: ({ docType, title, subTitle, group, isRepair }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleQuotationRouter({ docType, title, subTitle, group, isRepair })
  }),
  DocSaleReceipt: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleReceiptRouter({ docType, title, subTitle, group })
  }),
  DocSaleReceiptDeposit: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleReceiptDepositRouter({ docType, title, subTitle, group })
  }),
  DocSaleSalesorder: ({ docType, title, subTitle, group, invoiceType, salesPriceType, receiptType, billingType, isRepair }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleSalesorderRouter({ docType, title, subTitle, group, invoiceType, salesPriceType, receiptType, billingType, isRepair })
  }),
  DocSaleRequisition: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleRequisitionRouter({ docType, title, subTitle, group })
  }),
  DocSaleThaipost: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSaleThaipostRouter({ docType, title, subTitle, group })
  }),

  DocSpcSiteSurvey: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSpcSiteSurveyRouter({ docType, title, subTitle, group })
  }),
  DocSpcInstallation: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSpcInstallationRouter({ docType, title, subTitle, group })
  }),
  DocSpcNotice: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSpcNoticeRouter({ docType, title, subTitle, group })
  }),
  DocSpcDeliveryNote: ({ docType, title, subTitle, group }) => ({
    defaultView: `Doc${capitalize(docType)}List`,
    requiedRoles: [`doc:${docType}:read`],
    routes: DocSpcDeliveryNoteRouter({ docType, title, subTitle, group })
  }),

  CouponLuckyDraw: ({ couponType, title, subTitle, group }) => ({
    defaultView: `Coupon${capitalize(couponType)}List`,
    requiedRoles: [`coupon:${couponType}:read`],
    routes: CouponLuckyDrawRouter({ couponType, title, subTitle, group })
  }),

  ChronowoodthAsset: ({ assetType, title, subTitle, group }) => ({
    defaultView: `ChronowoodthAsset${capitalize(assetType)}List`,
    requiedRoles: [`chronowoodthAsset:${assetType}:read`],
    routes: ChronowoodthAssetRouter({ assetType, title, subTitle, group })
  }),
  ChronowoodthProduct: ({ productType, assetType, priceType, title, subTitle, group }) => ({
    defaultView: `ChronowoodthProduct${capitalize(productType)}List`,
    requiedRoles: [`chronowoodthProduct:${productType}:read`],
    routes: ChronowoodthProductRouter({ productType, assetType, priceType, title, subTitle, group })
  }),
  ChronowoodthProject: ({ projectType, assetType, productType, title, subTitle, group }) => ({
    defaultView: `ChronowoodthProject${capitalize(projectType)}List`,
    requiedRoles: [`chronowoodthProject:${projectType}:read`],
    routes: ChronowoodthProjectRouter({ projectType, assetType, productType, title, subTitle, group })
  }),
  ChronowoodthLog: ({ logType, title, subTitle, group }) => ({
    defaultView: `ChronowoodthLog${capitalize(logType)}List`,
    requiedRoles: [`chronowoodthLog:${logType}:read`],
    routes: ChronowoodthLogRouter({ logType, title, subTitle, group })
  }),
  ChronowoodthPromotion: ({ promotionType, assetType, title, subTitle, group }) => ({
    defaultView: `ChronowoodthPromotion${capitalize(promotionType)}List`,
    requiedRoles: [`chronowoodthPromotion:${promotionType}:read`],
    routes: ChronowoodthPromotionRouter({ promotionType, assetType, title, subTitle, group })
  }),
  ChronowoodthUnit: ({ unitType, title, subTitle, group }) => ({
    defaultView: `ChronowoodthUnit${capitalize(unitType)}List`,
    requiedRoles: [`chronowoodthUnit:${unitType}:read`],
    routes: ChronowoodthUnitRouter({ unitType, title, subTitle, group })
  }),
  ChronowoodthPrice: ({ priceType, unitType, title, subTitle, group }) => ({
    defaultView: `ChronowoodthPrice${capitalize(priceType)}List`,
    requiedRoles: [`chronowoodthPrice:${priceType}:read`],
    routes: ChronowoodthPriceRouter({ priceType, unitType, title, subTitle, group })
  }),
}
