const state = {
  current: {}
}

const getters = {
  getRoute: state => ({group}) => {
    return state.current[group]
  }
}

const actions = {
  
}

const mutations = {
  setCurrent: (state, {to, group}) => {
    const current = state.current[group]
    if (to.path !== current) {
      state.current[group] = to.path
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
