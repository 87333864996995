import { capitalize } from '@/libs/form'

export default ({accountType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `account/${accountType}/category/:categoryId`,
      name: `Account${capitalize(accountType)}CategoryDetail`,
      component: () => import(`../CategoryDetail.vue`),
      props: (route) => ({
        categoryId: Number(route.params.categoryId),
        templateType,
        accountType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `account/${accountType}/`,
      name: `Account${capitalize(accountType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        accountType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `account/${accountType}/:accountId`,
      name: `Account${capitalize(accountType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        accountId: Number(route.params.accountId),
        templateType,
        accountType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
