import gql from 'graphql-tag'


const docConfigResponse = `
  id type docType cron name
  paperId paper {id code name}
  actionPlanId actionPlan {id name}
  riskAssessmentId riskAssessment {id name}
  targetGraphId targetGraph {id name}
  docCategoryId docCategory {id name}
  measurementTemplateId measurementTemplate {
    id code name
  }
  inventoryId inventory {
    id type code name unitId
    unit {id name}
  }
  children {
    id type docType name parentId
  }
`

export const LIST_DOC_CONFIG_MENU = (templateType) => gql`query LIST_DOC_CONFIG_MENU ($docType: String!) {
  menus: listDoc${templateType}DocConfigMenu (docType: $docType)
}`

export const LIST_DOC_CONFIG = (templateType) => gql`query LIST_DOC_CONFIG ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfig (docType: $docType, q: $q) {${docConfigResponse}}
}`

export const CREATE_DOC_CONFIG = (templateType) => gql`mutation CREATE_DOC_CONFIG ($docType: String!, $docConfigId: Int, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfig (docType: $docType, docConfigId: $docConfigId, input: $input) {${docConfigResponse}}
}`

export const CREATE_DOC_CONFIG_CHILDREN = (templateType) => gql`mutation CREATE_DOC_CONFIG_CHILDREN ($docType: String!, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfig (docType: $docType, input: $input) {
    id type docType name isDefault parentId
  }
}`

export const DESTROY_DOC_CONFIG = (templateType) => gql`mutation DESTROY_DOC_CONFIG ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}DocConfig (docType: $docType, docConfigId: $docConfigId) {id}
}`
