<template>
  <div style="position:relative" class="form-group">
    <label v-if="label" class="mr-1">
      {{ label }}
    </label>

    <slot name="label"></slot>

    <div class="input-group">
      <input
        :id="uid"
        class="form-control radius"
        :class="{'is-invalid': validate.value, 'rounded-right': disabled}"
        type="text"
        :value="display"
        @keydown.enter='enter'
        @keydown.down='down'
        @keydown.up='up'
        @focus="initSearch"
        @input="getSearch"
        :disabled="disabled"
        v-bind="$attrs">
      <div class="input-group-append" @click="resetForm" v-if="!disabled">
        <span
          class="input-group-text pointer text-danger"
          :class="{'rounded-right': !disabled}">
          <fa icon="times"></fa>
        </span>
      </div>
      <div class="invalid-feedback">
        {{ validate.text }}
      </div>
    </div>
    <ul
      class="dropdown-menu scroll-300"
      style="min-width: 100%;max-width: 90vw;"
      :class="{'open':openSuggestion}">
      <li v-for="(option, index) in options"
        :key="index"
        :class="{'active': isActive(index)}"
        @click="optionClick(index)"
        class="dropdown-item pointer">
        <slot v-bind="option">{{option.id}}</slot>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SgvInputAutocomplete',
  props: {
    reset: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    search: {
      type: String,
      required: false
    },
    display: {
      type: String,
      required: false
    },
    value: {
      type: [String, Number, Object],
      required: false
    },
    label: {
      type: String,
      required: false,
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    focus: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      uid: this.$form.uid(),
      open: false,
      current: 0
    }
  },
  computed: {
    openSuggestion() {
      return this.options.length !== 0 && this.open === true
    },
    validate () {
      return this.validations.find(v => v.value) || {value: false, text: ''}
    }
  },
  methods: {
    initSearch () {
      if (!this.display) {
        this.open = true
        this.emitSearch('')
      }
    },
    getSearch(e) {
      this.emitDisplay(e.target.value)
      this.emitSearch(e.target.value)
    },
    enter() {
      if (this.options.length > 0) {
        let option = this.options[this.current]
        this.emitInput(option)
        this.open = false
      }
    },
    up() {
      if (this.current > 0) {
        this.current--
      }
    },
    down() {
      if (this.current < this.options.length - 1) {
        this.current++
      }
    },
    isActive(index) {
      return index === this.current
    },
    optionClick(index) {
      this.current = index
      let option = this.options[this.current]
      this.emitInput(option)
      this.open = false
    },
    resetForm() {
      this.current = 0
      this.open = false
      this.emitDisplay(null)
      this.emitInput(null)
      this.emitSearch(null)
    },
    emitSearch(value) {
      this.$emit('update:search', value)
    },
    emitDisplay(value) {
      this.$emit('update:display', value)
    },
    emitInput(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    setFocus () {
      let el = document.getElementById(this.uid)
      el.focus()
      this.resetForm()
      this.open = true
    }
  },
  watch: {
    reset() {
      this.resetForm()
    },
    search (value) {
      if (value === '') this.open = true
    },
    options () {
      this.current = 0
    },
    focus (value) {
      if (value) this.setFocus()
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.focus) this.setFocus()
    })
  }
}
</script>

<style lang="css" scoped>
.open {
  display: block;
}

.scroll-300 {
  max-height: 300px;
  overflow: auto;
}

.dropdown-menu .dropdown-item{white-space:normal;}
</style>
