<template>
  <div class="">
    <sgv-input-month v-model="selectedMonth"></sgv-input-month>

    <DetailAttendanceListSummary
      :items="datelist">
    </DetailAttendanceListSummary>

    <sgv-table
      :items="datelist"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage"
      @query="findAll">

      <sgv-csv
        slot="action"
        :items="csvData"
        :labels="csvLabels"
        :filename="csvFilename">
        <button type="button" class="btn btn-link text-warning">พิมพ์</button>
      </sgv-csv>

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.date">
            <DetailAttendanceListModalRemark
              :templateType="templateType"
              :contactType="contactType"
              :title="$date.format(item.date).displayThai"
              :description="item.description"
              :tag="item.tag"
              :remark="item.remark"
              :attendedDate="item.date"
              :contactId="contactId"
              :tags="tags"
              :options="item.options"
              @updated="findAll">
              {{item.date | date}}
              <small>
                <span
                  v-for="weekend in item.weekends"
                  :key="weekend.date.toString() + weekend.tag"
                  class="text-info">
                  <br>
                  {{weekend.name}}
                </span>
                <span
                  v-for="holiday in item.holidays"
                  :key="holiday.date.toString() + holiday.tag"
                  class="text-success">
                  <br>
                  <fa icon="umbrella-beach"></fa>
                  {{holiday.name}}
                </span>
                <span
                  v-if="item.options && item.options.isNight"
                  class="mr-1 text-primary">
                  <br>
                  <fa icon="moon"></fa>
                  <span class="pl-1">เข้ากะ</span>
                </span>
                <span v-if="item.options && item.options.isMixer"
                  class="mr-1 text-warning">
                  <br>
                  <fa icon="industry"></fa>
                  <span class="pl-1">ผสมเคมี</span>
                </span>
              </small>
            </DetailAttendanceListModalRemark>
          </td>
          <td v-if="hidden.late">
            {{item.late}}
          </td>
          <td v-if="hidden.ot">
            {{item.ot}}
          </td>
          <td v-if="hidden.inAt">
            <DetailAttendanceListModalInAt
              :templateType="templateType"
              :contactType="contactType"
              :title="$date.format(item.date).displayThai"
              :inAt="item.inAt"
              :late="item.late"
              :attendedDate="item.date"
              :contactId="contactId"
              @updated="findAll">
              {{item.inAt | datetime}}
            </DetailAttendanceListModalInAt>

            <small v-if="item.inUpdatedUser">
              <div class="text-primary">{{item.inUpdatedUser.name}}</div>
            </small>
          </td>
          <td v-if="hidden.outAt">
            <DetailAttendanceListModalOutAt
              :templateType="templateType"
              :contactType="contactType"
              :title="$date.format(item.date).displayThai"
              :outAt="item.outAt"
              :attendedDate="item.date"
              :contactId="contactId"
              @updated="findAll">
              {{item.outAt | datetime}}
            </DetailAttendanceListModalOutAt>

            <small v-if="item.outUpdatedUser">
              <div class="text-primary">{{item.outUpdatedUser.name}}</div>
            </small>
          </td>
          <td v-if="hidden.otInAt">
            {{ item.otInAt | datetime }}
          </td>
          <td v-if="hidden.otOutAt">
            {{ item.otOutAt | datetime }}
          </td>
        </tr>

        <tr v-if="item.description || item.remark">
          <td
            class="pt-0"
            :colspan="options.headers.length"
            style="border-top: none !important;">
            <div class="row">
              <small>
                <div class="col-12 pre-line" v-if="item.description">
                  <span class="text-info">USR:</span>
                  {{item.description}}
                </div>
              </small>

              <small>
                <div class="col-12 pre-line" v-if="item.remark">
                  <span class="text-success">HR:</span>
                  <span :class="getTextColor(item.tag)">
                    {{getTagText(item.tag)}}
                    <span>{{item.remark}}</span>
                  </span>
                </div>
              </small>
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailAttendanceListSummary from './DetailAttendanceListSummary'
import DetailAttendanceListModalRemark from './DetailAttendanceListModalRemark'
import DetailAttendanceListModalInAt from './DetailAttendanceListModalInAt'
import DetailAttendanceListModalOutAt from './DetailAttendanceListModalOutAt'
import {
  LIST_ATTENDANCE,
  LIST_ATTENDANCE_ACTION_PLAN
} from './graph'


export default {
  mixins: [retainMixin],
  props: {
    contactId: {
      type: Number,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      headers: [
        {text: 'วันที่', value: 'date', sort: true, filter: false},
        {text: 'สาย (นาที)', value: 'late', sort: false, filter: false},
        {text: 'OT (นาที)', value: 'ot', sort: false, filter: false},
        {text: 'เข้า', value: 'inAt', sort: false, filter: false},
        {text: 'ออก', value: 'outAt', sort: false, filter: false},
        {text: 'เข้า OT', value: 'otInAt', sort: false, filter: false},
        {text: 'ออก OT', value: 'otOutAt', sort: false, filter: false},
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['date', 'late', 'ot', 'inAt', 'outAt', 'otInAt', 'otOutAt'],
        column: null,
        search: null,
        toolbar: null,
      },
      rowsPerPage: [
        {text: 'ทั้งหมด', value: null}
      ],
      selectedInDate: '',
      selectedOutDate: '',
      inAt: '',
      outAt: '',
      remark: '',
      late: 0,
      csvLabels: {
        date: { title: 'วันที่' },
        late: { title: 'สาย (นาที)' },
        ot: { title: 'OT (นาที)' },
        inAt: { title: 'เข้า' },
        outAt: { title: 'ออก' },
        otInAt: { title: 'เข้า OT' },
        otOutAt: { title: 'ออก OT' },
        description: { title: 'รายละเอียด' },
      },
      selectedMonth: null,
      rKey: `Contact${this.$form.capitalize(this.contactType)}DetailAttendanceList`,
      rFields: ['selectedMonth', 'options'],
      attendances: [],
      actionPlans: [],
      tags: [
        {text: 'ไม่ระบุ', value: ''},
        {text: 'ลากิจ', value: 'leave'},
        {text: 'ลาป่วย', value: 'sick'},
        {text: 'ลาป่วย-มีใบรับรองแพทย์', value: 'sickCert'},
        {text: 'ลาพักร้อน', value: 'annualLeave'},
        {text: 'ขาดงาน', value: 'absent'},
        {text: 'ลาคลอด', value: 'maternity'},
        {text: 'อื่นๆ', value: 'other'},
      ]
    }
  },
  computed: {
    datelist () {
      if (!this.selectedMonth) return []

      const d = new Date(this.selectedMonth + '-01')
      const arr = this.$date.serial(this.$date.format(d).startOfMonth, this.$date.format(d).endOfMonth)
      const weekends = this.actionPlans.filter(v => v.tag === 'weekend')
      const holidays = this.actionPlans.filter(v => v.tag === 'holiday')

      return arr
      .map(v => {
        if (this.attendances.length > 0) {
          let found = this.attendances.find(x => x.attendedDate === v) || {}
          found.date = v
          return found
        } else {
          return {date: v}
        }
      })
      .map(v => {
        return {
          ...v,
          weekends: weekends.filter(w => {
            return +new Date(w.date) === +this.$date.format(v.date).utc
          }),
          holidays: holidays.filter(w => {
            return +new Date(w.date) === +this.$date.format(v.date).utc
          }),
        }
      })
    },
    csvData () {
      return this.datelist.map(v => {
        return {
          date: v.date,
          late: v.late || 0,
          ot: v.ot || 0,
          inAt: `${this.$date.format(v.inAt).time}`,
          outAt: `${this.$date.format(v.outAt).time}`,
          otInAt: `${this.$date.format(v.otInAt).time}`,
          otOutAt: `${this.$date.format(v.otOutAt).time}`,
          description: v.description || ''
        }
      })
    },
    csvFilename () {
      if (this.attendances.length === 0) return ''
      let name = this.attendances[0].contact.name
      name = name.replace(/\s/g, "-")
      return this.selectedMonth + '-' + name
    }
  },
  apollo: {
    attendances: {
      query () {
        return LIST_ATTENDANCE(this.templateType)
      },
      variables () {
        this.setRetaining()
        return {
          contactType: this.contactType,
          contactId: this.contactId,
          month: this.selectedMonth + '-01'
        }
      },
      skip () {
        return !this.selectedMonth
      },
      fetchPolicy: 'no-cache',
      debounce: 150,
      error (err) {
        this.$toasted.global.error(err)
      }
    },
    actionPlans: {
      query () {
        return LIST_ATTENDANCE_ACTION_PLAN(this.templateType)
      },
      variables () {
        this.setRetaining()
        return {
          contactType: this.contactType,
          contactId: this.contactId,
          month: this.selectedMonth + '-01',
          tags: ['weekend', 'holiday']
        }
      },
      skip () {
        return !this.selectedMonth
      },
      fetchPolicy: 'no-cache',
      debounce: 150,
      error (err) {
        this.$toasted.global.error(err)
      }
    }
  },
  methods: {
    getTextColor (tag) {
      if (['leave', 'sick', 'sickCert', 'annualLeave', 'maternity'].includes(tag)) return ['text-warning']
      else if (['absent', 'other'].includes(tag)) return ['text-danger']
    },
    getTagText (tag) {
      const found = this.tags.find(t => t.value === tag)
      return found.value ? `(${found.text})` : ''
    },
    findAll () {
      this.$apollo.queries.attendances.refetch()
    }
  },
  components: {
    DetailAttendanceListModalRemark,
    DetailAttendanceListModalInAt,
    DetailAttendanceListModalOutAt,
    DetailAttendanceListSummary
  }
}
</script>
