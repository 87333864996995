<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-4"
        label="รหัส"
        v-model="formData.code"
        :validations="[
          {text: 'required!', value: v.formData.code.$dirty && v.formData.code.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-4"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>

      <DropdownUnit
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-4"
        select="id"
        v-model="formData.unitId"
        label="หน่วย"
        :inventoryType="inventoryType"
        :templateType="templateType"
        :validations="[
          {text: 'required!', value: v.formData.unitId.$dirty && v.formData.unitId.$error}
        ]">
      </DropdownUnit>
    </div>

    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-4"
        label="ที่เก็บ"
        v-model="formData.address">
      </sgv-input-text>

      <sgv-input-number
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-2"
        label="จำนวน/กล่อง"
        v-model="formData.packing">
      </sgv-input-number>

      <sgv-input-number
        :disabled="$auth.disabled(method)"
        class="col-4 col-sm-2"
        label="จำนวนน้อยสุด"
        minus
        v-model="formData.lowerLimit">
      </sgv-input-number>

      <sgv-input-number
        :disabled="$auth.disabled(method)"
        class="col-4 col-sm-2"
        label="จำนวนสูงสุด"
        minus
        v-model="formData.upperLimit">
      </sgv-input-number>

      <sgv-input-number
        :disabled="$auth.disabled(method)"
        class="col-4 col-sm-2"
        label="จำนวนสั่งซื้อ"
        minus
        v-model="formData.purchaseLimit">
      </sgv-input-number>
    </div>

    <button
      type="button"
      class="btn btn-outline-secondary btn-block mb-3 mt-2"
      :class="{'active': toggleDetail}"
      @click="toggleDetail = !toggleDetail">
      รายละเอียดเพิ่มเติม
    </button>

    <div v-if="toggleDetail">
      <div class="form-row">
        <sgv-input-barcode
          :disabled="$auth.disabled(method)"
          class="col-6"
          label="บาร์โค๊ด"
          v-model="formData.barcode">
        </sgv-input-barcode>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6"
          label="รหัสสินค้าซัพพลายเออร์"
          v-model="formData.supplierSku">
        </sgv-input-text>
      </div>

      <div class="form-row">
        <sgv-input-textarea
          :disabled="$auth.disabled(method)"
          class="col-12 col-md-6"
          :rows="1"
          label="รายละเอียด"
          v-model="formData.description">
        </sgv-input-textarea>

        <sgv-input-textarea
          :disabled="$auth.disabled(method)"
          class="col-12 col-md-6"
          :rows="1"
          label="พิมพ์ติดกล่อง"
          v-model="formData.infoTag">
        </sgv-input-textarea>

        <sgv-input-textarea
          :disabled="$auth.disabled(method)"
          class="col-12 col-md-6"
          :rows="1"
          label="พิมพ์ป้ายสินค้า"
          v-model="formData.productTag">
        </sgv-input-textarea>

        <sgv-input-textarea
          :disabled="$auth.disabled(method)"
          class="col-12 col-md-6"
          :rows="1"
          label="พิมพ์ป้ายข้อควรระวัง"
          v-model="formData.cautionTag">
        </sgv-input-textarea>
      </div>

      <CategoryCheck
        :inventoryType="inventoryType"
        :templateType="templateType"
        :disabled="$auth.disabled(method)"
        :method="method"
        v-model="formData.categories">
      </CategoryCheck>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ใช้งาน"
          inline
          v-model="formData.isActive">
        </sgv-input-check>

        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ไม่พิมพ์โลโก้บริษัท"
          inline
          v-model="formData.isNoLogo">
        </sgv-input-check>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import CategoryCheck from '@/views/inventory_core/components/CategoryCheck.vue'
import DropdownUnit from '@/views/inventory_core/components/DropdownUnit.vue'

export default {
  mixins: [retainMixin],
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      toggleDetail: false,
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}Form`,
      rFields: ['toggleDetail']
    }
  },
  watch: {
    toggleDetail () {
      this.setRetaining()
    }
  },
  components: {
    CategoryCheck,
    DropdownUnit,
  }
}
</script>

<style lang="css">
</style>
