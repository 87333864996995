<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="kpiFilter.startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.kpiFilter.startDate.$dirty && $v.kpiFilter.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="kpiFilter.endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.kpiFilter.endDate.$dirty && $v.kpiFilter.endDate.$error}
        ]">
      </sgv-input-date>

      <DetailFormContactInput
        label="ผู้ถูกประเมิน"
        placeholder="เลือกผู้ติดต่อ..."
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        v-model="kpiFilter.contactId">
        <template slot-scope="option">
          {{option.contact.code}} ({{option.contact.name}})
        </template>
      </DetailFormContactInput>
    </div>

    <button class="btn btn-warning" @click="fetchData">
      ค้นหา
    </button>

    <div class="form-row mt-3">
      <sgv-input-month
        class="col-6"
        v-model="reportFilter.month"
        label="เดือน"
        isNull
        :validations="[
          {text: 'required!', value: $v.reportFilter.month.$dirty && $v.reportFilter.month.$error}
        ]">
      </sgv-input-month>
    </div>

    <button class="btn btn-info" @click="syncReport">
      Sync
    </button>

    <div>
      <ListReportKpiSummary :contacts="items"></ListReportKpiSummary>
    </div>

    <div class="table-responsive mt-3" v-if="items">
      <table class="table">
        <thead>
          <tr>
            <th class="text-center">รายละเอียด</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              <div class="mt-1 mb-2">
                <h6 class="text-primary">
                  {{item.code}} ({{item.name}})
                </h6>
              </div>
              <ListReportKpiProgress
                class="my-1"
                v-for="kpi in item.kpis"
                :key="kpi.name"
                :percent="kpi.percent"
                :name="kpi.name"
                :remarks="kpi.remarks">
              </ListReportKpiProgress>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import ListReportKpiProgress from './ListReportKpiProgress'
import DetailFormContactInput from './DetailFormContactInput'
import ListReportKpiSummary from './ListReportKpiSummary'
import {
  LIST_REPORT_KPI,
  SYNC_REPORT_KPI
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}ListReportKpi`,
      kpiFilter: {
        startDate: '',
        endDate: '',
        contactId: null,
      },
      reportFilter: {
        month: '',
      },
      rFields: ['kpiFilter', 'reportFilter'],
      items: []
    }
  },
  validations: {
    kpiFilter: {
      startDate: {required},
      endDate: {required},
    },
    reportFilter: {
      month: {required}
    }
  },
  methods: {
    fetchData () {
      this.$v.kpiFilter.$touch()
      if (this.$v.kpiFilter.$invalid) return

      this.setRetaining()
      this.items = []
      this.$apollo.query({
        query: LIST_REPORT_KPI(this.templateType),
        variables: {
          docType: this.docType,
          startDate: this.$date.format(this.kpiFilter.startDate).utc,
          endDate: this.$date.format(this.kpiFilter.endDate, 0, 1).utc,
          contactId: this.kpiFilter.contactId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    syncReport () {
      this.$v.reportFilter.$touch()
      if (this.$v.reportFilter.$invalid) return

      this.setRetaining()

      this.$apollo.mutate({
        mutation: SYNC_REPORT_KPI(this.templateType),
        variables: {
          docType: this.docType,
          month: this.reportFilter.month
        },
        fetchPolicy: 'no-cache'
      })
      .then(() => {
        this.$toasted.global.success('อัพเดทสำเร็จ')
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    }
  },
  components: {
    ListReportKpiProgress,
    DetailFormContactInput,
    ListReportKpiSummary
  }
}
</script>

<style lang="css" scoped>
</style>
