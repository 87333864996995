<template>
  <button
    @click="download"
    class="btn btn-warning"
    :disabled="isDisabled">
    <fa icon="download"></fa>
    ไฟล์นำส่ง
  </button>
</template>

<script>
import round from 'lodash/round'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  computed: {
    isDisabled () {
      return this.items.some(v => !v.contact.addresses[0]?.houseId)
    },
    filename () {
      return this.formData.code
    },
    rows () {
      const arr = this.items.flatMap((v,idx) => {
        const name = this.mapName(v.contact.name)
        const addr = v.contact.addresses[0] || {}
        const branch = (v.contact.branch === 'สำนักงานใหญ่') || !v.contact.branch || v.contact.branch == '0' ? '0' : v.contact.branch
        return {
          'ลำดับที่' : idx,
          'เลขที่ประจำตัวผู้เสียภาษี' : v.contact.taxCode,
          'สาขา': branch,
          'คำนำหน้าชื่อ' : name.prefix,
          'ชื่อ' : name.companyname,
          'บ้านเลขที่' : addr.houseId || '',
          'อาคาร' : addr.building || '',
          'ห้องเลขที่' : addr.roomId || '',
          'ชั้นที่' : addr.floorId || '',
          'หมู่บ้าน' : addr.village || '',
          'หมู่ที่' : addr.villageId || '',
          'ซอย' : addr.laneId || '',
          'ถนน' : addr.road || '',
          'แขวง/ตำบล' : addr.subdistrict || '',
          'เขต/อำเภอ' : addr.district || '',
          'จังหวัด' : addr.province || '',
          'รหัสไปรษณีย์' : addr.postcode || '',

          'วันเดือนปีที่จ่าย-1' : '',
          'ประเภทเงินได้-1' : '',
          'อัตราภาษี-1' : '',
          'จำนวนเงิน-1' : '',
          'จำนวนภาษีที่หัก-1' : '',
          'เงื่อนไข-1' : '',

          'วันเดือนปีที่จ่าย-2' : '',
          'ประเภทเงินได้-2' : '',
          'อัตราภาษี-2' : '',
          'จำนวนเงิน-2' : '',
          'จำนวนภาษีที่หัก-2' : '',
          'เงื่อนไข-2' : '',

          'วันเดือนปีที่จ่าย-3' : '',
          'ประเภทเงินได้-3' : '',
          'อัตราภาษี-3' : '',
          'จำนวนเงิน-3' : '',
          'จำนวนภาษีที่หัก-3' : '',
          'เงื่อนไข-3' : '',

          ...v.orders.reduce((t, order, i) => {

            t['วันเดือนปีที่จ่าย-'+(i+1).toString()] = this.$date.format(v.closedAt).displayThai
            t['ประเภทเงินได้-'+(i+1).toString()] = order.withholdingName
            t['อัตราภาษี-'+(i+1).toString()] = order.withholdingRate
            t['จำนวนเงิน-'+(i+1).toString()] = round(order.withholdingPrice, 2)
            t['จำนวนภาษีที่หัก-'+(i+1).toString()] = round(Math.abs(order.totalPrice), 2)
            t['เงื่อนไข-'+(i+1).toString()] = order.withholdingType

            return t
          }, {})
        }
      })

      return arr
    }
  },
  methods: {
    mapName (contactName) {
      let prefix, companyname

      const matchs = contactName.match(/^(ห้างหุ้นส่วนจำกัด|ห้างหุ้นส่วนสามัญ|หจก.|บริษัท)(.+)$/)
      if (matchs?.length > 0) {
        prefix = matchs[1]
        companyname = matchs[2].trim()
      } else {
        prefix = ''
        companyname = contactName
      }

      return {prefix, companyname}
    },
    download () {
      const csv = this.$papa.unparse(this.rows)
      const blob = new Blob(["\uFEFF", csv], {type: "text/csv;charset=utf-8;"}, this.filename)

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", this.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<style lang="css" scoped>
</style>
