<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <div class="row">
          <div class="col-12 col-sm-6 mb-2">
            <span
              :class="{'text-warning': item.isDefault}">
              {{item.name}}
              <span v-if="item.isDefault"> - ค่าเริ่มต้น</span>
            </span>
            <span
              v-if="canEdit"
              class="px-2 pointer"
              @click="toggle = !toggle">
              <fa icon="pencil-alt" class="text-warning"></fa>
            </span>
          </div>

          <div class="col-12 col-sm-6 mb-2 text-right">
            <AddressCardAddress
              v-if="item.addressTag"
              title="ป้ายที่อยู่"
              class="mr-2"
              :contactType="contactType"
              :templateType="templateType"
              :addressId="item.id">
            </AddressCardAddress>

            <AddressCardBranch
              title="ป้ายสาขา"
              v-if="printBranch"
              :contactType="contactType"
              :templateType="templateType"
              :contactId="contactId">
            </AddressCardBranch>
          </div>
        </div>

        <dl class="row mb-0 mt-2">
          <template v-for="(fData, idx) in formDataMapp">
            <dt class="col-3 col-sm-2 col-md-2 font-weight-normal text-right" :key="idx + '0'">{{fData.text}}:</dt>
            <dd class="col-9 col-sm-10 col-md-10 pre-line" :key="idx + '1'">

              <AddressCardChildModal
                v-if="fData.comp === 'AddressCardChildModal'"
                :title="fData.value"
                :templateType="templateType"
                :contactType="contactType"
                addressType="main"
                :childId="fData.childId">
                {{fData.value}}
              </AddressCardChildModal>

              <AddressCardMap
                v-else-if="fData.comp === 'AddressCardMap'"
                :url="fData.value">
              </AddressCardMap>

              <span v-else>{{fData.value}}</span>
            </dd>
          </template>
        </dl>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-text
            class="col-6"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && $v.formData.name.$error}
            ]">
          </sgv-input-text>

          <sgv-input-text
            class="col-6"
            label="ผู้ติดต่อ"
            v-model="formData.attendance">
          </sgv-input-text>
        </div>

        <div class="form-row">
          <sgv-input-text
            class="col-12 col-sm-6"
            label="E-mail"
            v-model="formData.email">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-sm-6"
            label="Line App"
            v-model="formData.lineApp">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="โทรศัพท์"
            v-model="formData.telephone">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="มือถือ"
            v-model="formData.mobile">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="แฟกซ์"
            v-model="formData.fax">
          </sgv-input-text>
        </div>

        <div class="form-row">
          <sgv-input-text
            class="col-12 col-lg-6"
            label="แผนที่(URL)"
            placeholder="https://goo.gl/maps/LMtY8Zig8eCSXKdT7"
            v-model="formData.mapUrl">
          </sgv-input-text>

          <sgv-input-text
            class="col-12 col-lg-6"
            label="แผนที่(Lat,Lng)"
            placeholder="13.64645278376233, 100.68063804029426"
            v-model="formData.mapLocation">
          </sgv-input-text>
        </div>

        <div class="form-row">
          <DropdownCountryProvince
            label="จังหวัด"
            :contactType="contactType"
            :templateType="templateType"
            class="col-6 col-lg-4"
            select="id"
            v-model="formData.countryProvinceId">
          </DropdownCountryProvince>

          <DropdownCountryDistrict
            v-if="formData.countryProvinceId"
            :countryProvinceId="formData.countryProvinceId"
            label="อำเภอ"
            :contactType="contactType"
            :templateType="templateType"
            class="col-6 col-lg-4"
            select="id"
            v-model="formData.countryDistrictId">
          </DropdownCountryDistrict>

          <DropdownCountrySubdistrict
            v-if="formData.countryProvinceId && formData.countryDistrictId"
            :countryDistrictId="formData.countryDistrictId"
            label="ตำบล"
            :contactType="contactType"
            :templateType="templateType"
            class="col-6 col-lg-4"
            select="id"
            v-model="formData.countrySubdistrictId">
          </DropdownCountrySubdistrict>
        </div>

        <div class="form-row">
          <DropdownLogistic
            class="col-12 col-lg-6"
            select="id"
            v-model="formData.logisticId"
            :types="['logistic']"
            :contactType="contactType"
            :templateType="templateType">
          </DropdownLogistic>

          <DropdownVehicle
            class="col-12 col-lg-6"
            select="id"
            v-model="formData.vehicleId"
            :types="['vehicle']"
            :contactType="contactType"
            :templateType="templateType">
          </DropdownVehicle>
        </div>

        <div class="form-row">
          <sgv-input-textarea
            class="col-12 col-lg-6"
            label="ที่อยู่"
            v-model="formData.address">
          </sgv-input-textarea>

          <sgv-input-textarea
            class="col-12 col-lg-6"
            label="พิมพ์ที่อยู่"
            v-model="formData.addressTag">
          </sgv-input-textarea>
        </div>

        <div class="form-row">
          <sgv-input-text
            class="col-6 col-lg-4"
            label="เลขที่"
            v-model="formData.houseId">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="อาคาร"
            v-model="formData.building">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="ห้องเลขที่"
            v-model="formData.roomId">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="ชั้นที่"
            v-model="formData.floorId">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="หมู่บ้าน"
            v-model="formData.village">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="หมู่ที่"
            v-model="formData.villageId">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="ซอย"
            v-model="formData.laneId">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="ถนน"
            v-model="formData.road">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="ตำบล/แขวง"
            v-model="formData.subdistrict">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="อำเภอ/เขต"
            v-model="formData.district">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="จังหวัด"
            v-model="formData.province">
          </sgv-input-text>

          <sgv-input-text
            class="col-6 col-lg-4"
            label="รหัสไปรษณีย์"
            v-model="formData.postcode">
          </sgv-input-text>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <sgv-input-check
              label="ค่าเริ่มต้น"
              inline
              v-model="formData.isDefault">
            </sgv-input-check>
          </div>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="createData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DropdownLogistic from './DropdownLogistic.vue'
import DropdownVehicle from './DropdownVehicle.vue'
import AddressCardAddress from './AddressCardAddress'
import AddressCardBranch from './AddressCardBranch'
import AddressCardChildModal from './AddressCardChildModal'
import AddressCardMap from './AddressCardMap'
import DropdownCountryProvince from './DropdownCountryProvince'
import DropdownCountryDistrict from './DropdownCountryDistrict'
import DropdownCountrySubdistrict from './DropdownCountrySubdistrict'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    printBranch: {
      type: Boolean,
      required: false
    },
    isForm: {
      type: Boolean,
      required: false
    },
    canEdit: {
      type: Boolean,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        name: '',
        attendance: '',

        telephone: '',
        mobile: '',
        fax: '',
        email: '',
        lineApp: '',

        mapUrl: '',
        mapLocation: '',
        countryProvinceId: null,
        countryDistrictId: null,
        countrySubdistrictId: null,

        logisticId: null,
        logistic: null,
        vehicleId: null,
        vehicle: null,

        address: '',
        addressTag: '',

        houseId: '',
        building: '',
        roomId: '',
        floorId: '',
        village: '',
        villageId: '',
        laneId: '',
        road: '',
        subdistrict: '',
        district: '',
        province: '',
        postcode: '',

        isDefault: false
      },
    }
  },
  computed: {
    formDataMapp () {
      const attributes = [
        {text: 'ผู้ติดต่อ', key: 'attendance'},
        {text: 'Email', key: 'email'},
        {text: 'โทรศัพท์', key: 'telephone'},
        {text: 'มือถือ', key: 'mobile'},
        {text: 'แฟกซ์', key: 'fax'},
        {text: 'Line App', key: 'lineApp'},
        {text: 'ขนส่ง', key: 'logistic', comp: 'AddressCardChildModal'},
        {text: 'ยานพาหนะ', key: 'vehicle'},
        {text: 'ที่อยู่', key: 'address'},
        {text: 'พิมพ์', key: 'addressTag'},
        {text: 'แผนที่', key: 'mapUrl', comp: 'AddressCardMap'},
      ]

      return attributes
      .filter(attr => this.item[attr.key])
      .map(attr => {
        let value = this.item[attr.key]

        if (attr.key === 'logistic') {
          value = `${this.item[attr.key].code} (${this.item[attr.key].name})`
        }

        if (attr.key === 'vehicle') {
          value = `${this.item[attr.key].code} (${this.item[attr.key].name})`
        }

        return {
          text: attr.text,
          value,
          comp: attr.comp,
          childId: this.item[attr.key]?.id
        }
      })
    }
  },
  validations: {
    formData: {
      name: { required },
    }
  },
  methods: {
    serializeInput (v) {
      const input = {
        ...v,
        type: this.type
      }
      delete input.logistic
      delete input.vehicle

      if (!input.countryProvinceId) {
        input.countryDistrictId = null
        input.countrySubdistrictId = null
      } else if (!input.countryDistrictId) {
        input.countrySubdistrictId = null
      }

      return input
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('create', {
        input: this.serializeInput(this.formData),
        cb: () => {
          this.$v.formData.$reset()
          this.toggle = false
        }
      })
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.$v.formData.$reset()
          this.toggle = false
        }
      })
    },
    destroyData (id) {
      this.$emit('destroy', id)
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          Object.keys(this.formData).forEach(key => {
            this.formData[key] = this.item[key]
          })
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      } else {
        this.formData.name = ''
        this.formData.attendance = ''
        this.formData.address = ''
        this.formData.addressTag = ''
        this.formData.telephone = ''
        this.formData.mobile = ''
        this.formData.fax = ''
        this.formData.email = ''
        this.formData.mapUrl = ''
        this.formData.mapLocation = ''
        this.formData.logisticId = null
        this.formData.logistic = null
        this.formData.vehicleId = null
        this.formData.vehicle = null
        this.formData.lineApp = ''
        this.formData.countryProvinceId = null
        this.formData.countryDistrictId = null
        this.formData.countrySubdistrictId = null

        this.formData.houseId = ''
        this.formData.building = ''
        this.formData.roomId = ''
        this.formData.floorId = ''
        this.formData.village = ''
        this.formData.villageId = ''
        this.formData.laneId = ''
        this.formData.road = ''
        this.formData.subdistrict = ''
        this.formData.district = ''
        this.formData.province = ''
        this.formData.postcode = ''

        this.formData.isDefault = false
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  },
  components: {
    DropdownLogistic,
    DropdownVehicle,
    AddressCardAddress,
    AddressCardBranch,
    AddressCardChildModal,
    DropdownCountryProvince,
    DropdownCountryDistrict,
    DropdownCountrySubdistrict,
    AddressCardMap,
  }
}
</script>

<style lang="css">
</style>
