<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-12">
        <sgv-form :method.sync="method" :options="options">
          <DetailForm
            :inventoryCostType="inventoryCostType"
            :templateType="templateType"
            :formData="formData"
            :method="method"
            :v="$v">
          </DetailForm>
        </sgv-form>
      </div>
    </div>

    <HrDivider :options="tabs" v-model="selectedTab" class="mt-3"></HrDivider>

    <keep-alive>
      <component
        :is="selectedTab"
        v-if="inventoryCostId > 0"
        :inventoryCostType="inventoryCostType"
        :templateType="templateType"
        :inventoryCostId="inventoryCostId"
        :method="method"
        :formData="formData">
      </component>
    </keep-alive>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm.vue'
import DetailOrder from './DetailOrder.vue'
import {
  DETAIL_INVENTORY_COST,
  CREATE_INVENTORY_COST,
  UPDATE_INVENTORY_COST,
  DESTROY_INVENTORY_COST,
  CALCULATE_INVENTORY_COST
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-${this.formData.code || 'รายการ'}`
    }
  },
  props: {
    inventoryCostType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryCostId: {
      type: Number,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      listView: `InventoryCost${this.$form.capitalize(this.inventoryCostType)}List`,
      rKey: `InventoryCost${this.$form.capitalize(this.inventoryCostType)}Detail`,
      rFields: ['selectedTab'],
      formData: {
        code: '',
        name: ''
      },
      selectedTab: ''
    }
  },
  validations: {
    formData: {
      code: { required },
      name: { required },
    }
  },
  computed: {
    tabs () {
      return [
        {text: 'รายการ', value: 'DetailOrder'},
      ]
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.inventoryCostId === 0 || !this.$auth.hasRole(`inventory:${this.inventoryCostType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.inventoryCostId === 0 || !this.$auth.hasRole(`inventory:${this.inventoryCostType}:add`)
        },
        {
          text: 'คำนวณต้นทุน',
          variant: 'info',
          method: 'calculate',
          func: this.calculateData,
          disabled: this.inventoryCostId === 0 || !this.$auth.hasRole(`inventory:${this.inventoryCostType}:read`),
          header: true,
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_INVENTORY_COST(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          inventoryCostId: this.inventoryCostId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.item)})
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'categories') {
          this.formData[key] = v[key].map(x => x.id)
          return
        }
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const obj = {...v}
      return obj
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_INVENTORY_COST(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, inventoryCostId: res.data.createItem.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_INVENTORY_COST(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          inventoryCostId: this.inventoryCostId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateItem)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_INVENTORY_COST(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          inventoryCostId: this.inventoryCostId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    calculateData () {
      this.$apollo.mutate({
        mutation: CALCULATE_INVENTORY_COST(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          inventoryCostId: this.inventoryCostId
        }
      })
      .then(() => {
        this.$toasted.global.success("อัพเดทสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  watch: {
    toggleDetail () {
      this.setRetaining()
    },
    selectedTab () {
      this.setRetaining()
    }
  },
  created () {
    if (this.inventoryCostId === 0) {
      this.method = 'add'
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
    DetailOrder
  }
}
</script>

<style lang="css" scoped>
</style>
