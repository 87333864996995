import gql from 'graphql-tag'


const orderResponse = `
  id type name qty price totalPrice
  unitId unit {id name}
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  parentId parent {
    id docId doc {
      id code name
    }
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const CREATE_ORDER_EXPENSE = (templateType) => gql`mutation CREATE_ORDER_EXPENSE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderExpense (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_NET_PRICE = (templateType) => gql`mutation CREATE_ORDER_NET_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderNetPrice (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`

export const LIST_ORDER_CONFIG = (templateType) => gql`query LIST_ORDER_CONFIG ($docType: String!) {
  configs: listDoc${templateType}DocConfig (docType: $docType) {
    id type name isDefault options
  }
}`

export const LIST_ORDER_PAYABLE_TYPE = (templateType) => gql`query LIST_ORDER_PAYABLE_TYPE ($docType: String!) {
  payableTypes: listDoc${templateType}OrderPayableType (docType: $docType) {
    id text docType orderType
  }
}`

export const LIST_ORDER_PAYABLE = (templateType) => gql`query LIST_ORDER_PAYABLE ($docType: String!, $orderTypes: [String], $q: FilterInput) {
  docs: listDoc${templateType}OrderPayable (docType: $docType, orderTypes: $orderTypes, q: $q) {
    id type code name remark contactId billedAt
    orders {
      id type name total paid pending remaining
      account {
        id code name
      }
    }
  }
}`

export const CREATE_ORDER_PAYABLE = (templateType) => gql`mutation CREATE_ORDER_PAYABLE ($docType: String!, $docId: Int!, $input: [Doc${templateType}OrderPayableInput]!) {
  orders: createDoc${templateType}OrderPayable (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`
