export default {
  data () {
    return {
      rKey: null,
      isRetainingLoaded: false,
      rFields: []
    }
  },
  methods: {
    setRetaining () {
      if (this.rKey && this.isRetainingLoaded) {
        let value = this.rFields.reduce((obj, v) => {
          if (!this[v] && this[v] !== false) return obj
          obj[v] = this[v]
          return obj
        }, {})
        let userName = localStorage.getItem(process.env.VUE_APP_USER_NAME)
        let localData = localStorage.getItem(userName)
        let retaining = {}
        if (localData) { retaining = JSON.parse(localData) }
        retaining[this.rKey] = value
        localStorage.setItem(userName, JSON.stringify(retaining))
      }
    },
    getRetaining () {
      if (this.rKey) {
        try {
          let userName = localStorage.getItem(process.env.VUE_APP_USER_NAME)
          let localData = localStorage.getItem(userName)
          let retaining = {}
          if (localData) { retaining = JSON.parse(localData) }
          let value = Object.prototype.hasOwnProperty.call(retaining, this.rKey) ? retaining[this.rKey] : null

          if (value) {
            this.rFields.forEach(v => {
              if(Object.prototype.hasOwnProperty.call(value, v)) {
                if (!this[v]) {
                  this[v] = value[v]
                } else if (typeof this[v] === 'object' && !Array.isArray(this[v])) {
                  this[v] = {...this[v], ...value[v]}
                } else {
                  this[v] = value[v]
                }
              }
            })
          }
        } catch (e) {
          this.removeRetaining()
        } finally {
          this.isRetainingLoaded = true
        }
      }
    },
    removeRetaining () {
      let userName = localStorage.getItem(process.env.VUE_APP_USER_NAME)
      let localData = localStorage.getItem(userName)
      let retaining = {}
      if (localData) {
        retaining = JSON.parse(localData)
      }
      delete retaining[this.rKey]
      localStorage.setItem(userName, JSON.stringify(retaining))
    },
    init() {}
  },
  created () {
    this.init()
    this.getRetaining()
  }
}
