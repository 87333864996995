<template>
  <div class="">
    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :toolbars="toolbars"
      :options.sync="options">

      <template slot="option" v-if="options.toolbar === 'other'">
        <div class="mb-3">
          <DetailOrderExpenseInput
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :contactId="formData.contactId"
            :configs="configs"
            :orders="orders">
          </DetailOrderExpenseInput>

          <DetailOrderNetPriceInput
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :contactId="formData.contactId"
            :configs="configs"
            :orders="orders">
          </DetailOrderNetPriceInput>
        </div>
      </template>

      <template slot="option" v-if="payableTypeIndex !== -1">
        <div class="mb-3">
          <DetailOrderPayableAvailable
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :payableType="options.toolbar">
          </DetailOrderPayableAvailable>
        </div>
      </template>

      <template slot-scope="row">
        <template v-if="row.item.type === 'netPrice'">
          <tr>
            <td v-if="row.hidden.name">ยอดรวม</td>
            <td v-if="row.hidden.qty"></td>
            <td v-if="row.hidden.totalPrice"></td>
          </tr>
        </template>

        <DetailOrderSubItem
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :row="row"
          v-model="selectedRows"
          :canDestroy="!formData.approvedAt">
        </DetailOrderSubItem>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import { round } from 'lodash'
import DetailOrderPayableAvailable from './DetailOrderPayableAvailable.vue'
import DetailOrderExpenseInput from './DetailOrderExpenseInput.vue'
import DetailOrderNetPriceInput from './DetailOrderNetPriceInput.vue'
import DetailOrderSubItem from './DetailOrderSubItem.vue'
import {
  LIST_ORDER,
  LIST_ORDER_CONFIG,
  LIST_ORDER_PAYABLE_TYPE,
  WATCH_ORDER_CREATED,
  WATCH_ORDER_UPDATED,
  WATCH_ORDER_DESTROYED,
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name', 'qty', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
      configs: [],
      selectedRows: [],
      payableTypes: []
    }
  },
  computed: {
    payableTypeIndex () {
      return this.payableTypes.findIndex(x => x.orderType === this.options.toolbar)
    },
    headers () {
      return [
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty', class: ['text-right']},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice', class: ['text-right']}
      ]
    },
    toolbars () {
      if (this.formData.approvedAt) return []

      const arr = [
        {value: 'other', icon: 'plus', class: 'text-success'}
      ]

      this.payableTypes.forEach(v => {
        arr.push({value: v.orderType, text: v.text, class: 'text-warning'})
      })

      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    items () {
      return [
        ...this.orders.filter(v => v.type === 'item'),
        ...this.orders.filter(v => v.type === 'expense'),
        ...this.orders.filter(v => v.type === 'netPrice'),
      ]
    },
    ledgersSum () {
      const total = this.orders
      .flatMap(v => v.ledgers)
      .reduce((t,v) => t += +v.amount, 0)

      return round(total, 2)
    },
  },
  apollo: {
    payableTypes: {
      query () {
        return LIST_ORDER_PAYABLE_TYPE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType
        }
      }
    },
    configs: {
      query () {
        return LIST_ORDER_CONFIG(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          q: {
            params: {
              contactId: this.formData.contactId
            }
          }
        }
      },
      fetchPolicy: 'network-only'
    },
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'network-only',
      subscribeToMore: [
        {
          document () {
            return WATCH_ORDER_CREATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const newOrder = subscriptionData.data.orderCreated
            const orders = [...previous.orders, newOrder]
            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_UPDATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const updatedOrder = subscriptionData.data.orderUpdated
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === updatedOrder.id)
            if (idx !== -1) {
              orders[idx] = updatedOrder
            }
            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const destroyedOrder = subscriptionData.data.orderDestroyed
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === destroyedOrder.id)
            if (idx !== -1) {
              orders.splice(idx, 1)
            }
            return {...previous, orders}
          }
        }
      ]
    }
  },
  watch: {
    'formData.approvedAt': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.options.toolbar = ''
        }
      }
    }
  },
  components: {
    DetailOrderSubItem,
    DetailOrderExpenseInput,
    DetailOrderNetPriceInput,
    DetailOrderPayableAvailable,
  }
}
</script>

<style lang="css">
</style>
