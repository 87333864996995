<template>
  <div class="">
    <div v-if="method ==='edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <ul>
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addDoc(item.id)">
          </fa>&nbsp;
          {{item.code}} <span v-show="item.name">({{item.name}})</span>
        </li>
      </ul>
    </div>

    <ul class="list-group">
      <li
        class="list-group-item"
        v-for="(contact, idx) in contacts"
        :key="contact.id">

        <div class="mb-1">
          <router-link
            :to="toContact(contact)"
            class="text-decoration-none pointer">
            {{idx+1}}. {{contact.code}} ({{contact.name}})
          </router-link>

          <fa
            icon="arrow-down"
            class="text-primary pointer mx-2 float-right"
            @click="moveChild(contact.id, 1)">
          </fa>
          <fa
            icon="arrow-up"
            class="text-primary pointer mx-2 float-right"
            @click="moveChild(contact.id, -1)">
          </fa>
        </div>

        <div v-if="contact.docs.length > 0">
          <span
            v-for="doc in contact.docs"
            :key="doc.id"
            class="mr-2">
            <router-link
              :to="toDoc(doc)"
              class="text-decoration-none text-info pointer">
              {{doc.code}}
            </router-link>
            <span
              v-if="method ==='edit'"
              class="text-danger pointer px-1"
              @click="deleteDoc(doc.id)">
              <fa icon="trash"></fa>
            </span>
          </span>
        </div>

        <div class="mt-1 row" v-if="contact.addresses.length > 0">
          <div class="col-12 col-sm-8">
            {{contact.addresses[0].attendance}}
            <div class="pre-line">{{contact.addresses[0].addressTag}}</div>
          </div>
          <div class="col-12 col-sm-4">
            <AddressCardAddress
              class="float-right"
              title="หน้าซอง"
              :addressId="contact.addresses[0].id"
              :docType="docType"
              :templateType="templateType">
            </AddressCardAddress>
          </div>
        </div>

      </li>
    </ul>
  </div>
</template>

<script>
import {
  LIST_CHILDREN,
  CREATE_CHILD,
  DESTROY_CHILD,
  MOVE_CHILD
} from './graph'
import debounce from 'lodash/debounce'
import AddressCardAddress from '@/views/doc_core/components/AddressCardAddress.vue'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  computed: {
    contacts () {
      const obj = this.formData.junctionChildren.reduce((t,doc,order) => {
        const d = {
          id: doc.id,
          type: doc.type,
          code: doc.code,
          name: doc.name
        }
        if (!t[doc.contactId]) {
          t[doc.contactId] = {
            order,
            docs: [d],
            ...doc.contact,
          }
        } else {
          t[doc.contactId].docs.push(d)
        }
        return t
      }, {})

      const arr = Object.keys(obj)
      .map(key => ({
        order: obj[key].order,
        id: obj[key].id,
        type: obj[key].type,
        code: obj[key].code,
        name: obj[key].name,
        addresses: obj[key].addresses,
        docs: obj[key].docs
      }))
      .sort((a,b) => a.order - b.order)

      return arr
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_CHILDREN(this.templateType),
        variables: {
          docType: this.docType,
          q: {
            limit: 10,
            ...filter
          }
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.items.filter(v => {
          return !this.formData.junctionChildren
          .map(child => child.id)
          .includes(v.id)
        })
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    addDoc (childId) {
      this.$apollo.mutate({
        mutation: CREATE_CHILD(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          childId
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.getList()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    deleteDoc (childId) {
      this.$apollo.mutate({
        mutation: DESTROY_CHILD(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          childId
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.getList()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    moveChild (contactId, step) {
      this.$apollo.mutate({
        mutation: MOVE_CHILD(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          contactId,
          step
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    getAddress(addresses) {
      const addr = addresses.find(v => v.type === 'main' && v.isDefault)

      return addr
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
    toContact (contact) {
      return {
        name: `Contact${this.$form.capitalize(contact.type)}Detail`,
        params: {contactId: contact.id},
        query: {redirect: this.$route.name}
      }
    },
  },
  watch: {
    search (value) {
      if (value) {
        this.getList({
          limit: 20,
          params: {
            search: value
          },
          order: 'code'
        })
      } else {
        this.items = []
      }
    }
  },
  components: {
    AddressCardAddress
  }
}
</script>

<style lang="css" scoped>
</style>
