var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6 col-md-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รหัส"},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('sgv-input-text',{staticClass:"col-6 col-md-8",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"หมายเหตุ","rows":1,"autoGrow":""},model:{value:(_vm.formData.remark),callback:function ($$v) {_vm.$set(_vm.formData, "remark", $$v)},expression:"formData.remark"}})],1),(_vm.method === 'add')?_c('div',{staticClass:"form-row"},[_c('DetailFormInputJobApplication',{staticClass:"col-12",attrs:{"label":"ใบร้องขอบุคคล","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.v.formData.parentId.$dirty && _vm.v.formData.parentId.$error}
      ]},model:{value:(_vm.formData.parentId),callback:function ($$v) {_vm.$set(_vm.formData, "parentId", $$v)},expression:"formData.parentId"}}),_c('DetailFormInputJobDescription',{staticClass:"col-12 col-md-6",attrs:{"label":"ตำแหน่ง","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.v.formData.jobDescriptionId.$dirty && _vm.v.formData.jobDescriptionId.$error}
      ]},model:{value:(_vm.formData.jobDescriptionId),callback:function ($$v) {_vm.$set(_vm.formData, "jobDescriptionId", $$v)},expression:"formData.jobDescriptionId"}}),(_vm.formData.jobDescriptionId)?_c('DetailFormInputAuditor',{staticClass:"col-12 col-md-6",attrs:{"jobDescriptionId":_vm.formData.jobDescriptionId,"placeholder":"...","label":"ผู้สัมภาษณ์งาน","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.v.formData.auditorId.$dirty && _vm.v.formData.auditorId.$error}
      ]},model:{value:(_vm.formData.auditorId),callback:function ($$v) {_vm.$set(_vm.formData, "auditorId", $$v)},expression:"formData.auditorId"}}):_vm._e()],1):_vm._e(),(_vm.method !== 'add')?_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":"","label":"ใบร้องขอบุคคล","select":"id","value":((_vm.formData.parent.code) + " (" + (_vm.formData.parent.name) + ")")}}),_c('sgv-input-text',{staticClass:"col-12 col-md-6",attrs:{"disabled":"","label":"ตำแหน่ง","select":"id","value":((_vm.formData.jobDescription.code) + " (" + (_vm.formData.jobDescription.position) + ")")}}),_c('sgv-input-text',{staticClass:"col-12 col-md-6",attrs:{"disabled":"","label":"ผู้สัมภาษณ์งาน","value":((_vm.formData.auditor.code) + " (" + (_vm.formData.auditor.name) + ")")}})],1):_vm._e(),(!_vm.formData.closedAt)?_c('div',{staticClass:"form-row"},[_c('DetailFormInputEmployee',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"พนักงาน","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.v.formData.contactId.$dirty && _vm.v.formData.contactId.$error}
      ]},model:{value:(_vm.formData.contactId),callback:function ($$v) {_vm.$set(_vm.formData, "contactId", $$v)},expression:"formData.contactId"}})],1):_vm._e(),(_vm.formData.closedAt)?_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":"","label":"พนักงาน","value":((_vm.formData.contact.code) + " (" + (_vm.formData.contact.name) + ")")}})],1):_vm._e(),_c('div',{staticClass:"form-row"},[(_vm.formData.kpiTemplate)?_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":"","label":"แบบฟอร์ม","value":((_vm.formData.kpiTemplate.code) + " (" + (_vm.formData.kpiTemplate.name) + ")")}}):_vm._e()],1),_c('CategoryCheck',{attrs:{"docId":_vm.docId,"docType":_vm.docType,"templateType":_vm.templateType,"disabled":_vm.$auth.disabled(_vm.method),"method":_vm.method,"isClosed":!!_vm.formData.closedAt || !!_vm.formData.approvedAt},on:{"updated":_vm.emitUpdated},model:{value:(_vm.formData.categories),callback:function ($$v) {_vm.$set(_vm.formData, "categories", $$v)},expression:"formData.categories"}}),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-4",attrs:{"disabled":"","label":"สร้าง","value":_vm.getState('created')}}),_c('sgv-input-text',{staticClass:"col-4",attrs:{"disabled":"","label":"อนุมัติ","value":_vm.getState('approved')}}),_c('sgv-input-text',{staticClass:"col-4",attrs:{"disabled":"","label":"จบ","value":_vm.getState('closed')}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ไม่ผ่าน","inline":""},model:{value:(_vm.formData.isVoid),callback:function ($$v) {_vm.$set(_vm.formData, "isVoid", $$v)},expression:"formData.isVoid"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }