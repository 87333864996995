<template>
  <div class="pl-2">
    <small>

      <span v-if="!item.isVoid" class="text-success">ผ่าน</span>
      <span v-else class="text-danger">ไม่ผ่าน</span>

      <span
        class="pl-2"
        :class="{
          'text-warning': item.approvedBy && !item.closedBy,
          'text-success': item.closedBy
        }">
        {{item.code}}
      </span>

      <span v-if="item.remark" class="text-primary">{{item.remark}}</span>

    </small>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      maxStar: 3
    }
  }
}
</script>

<style lang="css" scoped>
</style>
