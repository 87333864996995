import { capitalize } from '@/libs/form'

export default ({unitType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `unit/${unitType}`,
      name: `Unit${capitalize(unitType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        unitType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `unit/${unitType}/:unitId`,
      name: `Unit${capitalize(unitType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        unitId: Number(route.params.unitId),
        templateType,
        unitType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
