<template>
  <div class="row">
    <div class="col-12 col-sm-6">
      <ul>
        <li>
          ปกติ:
          <div class="">
            <span class="ml-2">{{summary.days}} วัน</span>
            <span class="ml-2">สาย {{getTotalLate(summary.normals)}} นาที</span>
            <span class="ml-2">OT {{getTotalOT(summary.normals) | comma}} ชม.</span>
          </div>
        </li>
        <li class="text-success">
          ทำงานวันหยุด:
          <div class="">
            <span class="ml-2">{{summary.extras.length}} วัน</span>
            <span class="ml-2">สาย {{getTotalLate(summary.extras)}} นาที</span>
            <span class="ml-2">OT {{getTotalOT(summary.extras) | comma}} ชม.</span>
          </div>
        </li>
        <li class="text-info">
          วันหยุด:
          <span class="ml-2">{{summary.weekends.length}} วัน</span>
        </li>
        <li class="text-success">
          วันหยุดนักขัตฤกษ์:
          <span class="ml-2">{{summary.holidays.length}} วัน</span>
        </li>
      </ul>
    </div>

    <div class="col-12 col-sm-6">
      <ul>
        <li class="text-warning">
          ลากิจ:
          <span class="ml-2">{{summary.leaves.length}} วัน</span>
        </li>
        <li class="text-warning">
          ลาป่วย:
          <span class="ml-2">{{summary.sicks.length}} วัน</span>
        </li>
        <li class="text-warning">
          ลาป่วย-มีใบรับรองแพทย์:
          <span class="ml-2">{{summary.sickCerts.length}} วัน</span>
        </li>
        <li class="text-warning">
          ลาพักร้อน:
          <span class="ml-2">{{summary.annualLeaves.length}} วัน</span>
        </li>
        <li class="text-danger">
          ขาดงาน:
          <span class="ml-2">{{summary.absents.length}} วัน</span>
        </li>
        <li class="text-primary">
          ลาคลอด:
          <span class="ml-2">{{summary.maternities.length}} วัน</span>
        </li>
        <li class="text-danger">
          อื่นๆ:
          <span class="ml-2">{{summary.others.length}} วัน</span>
        </li>
        <li class="">
          <fa icon="moon"></fa>
          <span class="pl-1">เข้ากะ:</span>
          <span class="ml-2">{{summary.isNight}} วัน</span>
        </li>
        <li class="">
          <fa icon="industry"></fa>
          <span class="pl-1">ผสมเคมี:</span>
          <span class="ml-2">{{summary.isMixer}} วัน</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import round from 'lodash/round'

export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    summary () {
      const result = this.items.reduce((t,v) => {
        if (v.tag === 'leave') {
          t.leaves.push(v)
        } else if (v.tag === 'sick') {
          t.sicks.push(v)
        } else if (v.tag === 'sickCert') {
          t.sickCerts.push(v)
        } else if (v.tag === 'maternity') {
          t.maternities.push(v)
        } else if (v.tag === 'annualLeave') {
          t.annualLeaves.push(v)
        } else if (v.tag === 'absent') {
          t.absents.push(v)
        } else if (v.tag === 'other') {
          t.others.push(v)
        } else if ((v.weekends.length > 0 || v.holidays.length > 0) && v.inAt && v.outAt) {
          t.extras.push(v)
        } else if (v.weekends.length) {
          t.weekends.push(v)
        } else if (v.inAt && v.outAt) {
          t.normals.push(v)

          const delta = this.$date.hourDiff(v.inAt, v.outAt)
          if (delta + (+v.late/60) > 7.9) {
            t.days = round(t.days + 1, 2)
          } else {
            t.days = round(t.days + 0.5, 2)
          }
        }

        if (v.holidays.length && v.weekends.length == 0) {
          t.holidays.push(v)
        }

        if (v.options?.isNight) t.isNight += 1
        if (v.options?.isMixer) t.isMixer += 1

        return t
      }, {
        days: 0,
        normals: [],
        extras: [],

        leaves: [],
        sicks: [],
        sickCerts: [],
        maternities: [],
        annualLeaves: [],
        absents: [],
        others: [],

        weekends: [],
        holidays: [],

        isNight: 0,
        isMixer: 0,
      })

      return result
    },
  },
  methods: {
    getTotalLate (items) {
      return items.reduce((t,v) => t + +v.late, 0)
    },
    getTotalOT (items) {
      return items.reduce((t,v) => t + +v.ot, 0) / 60
    }
  }
}
</script>

<style lang="css" scoped>
</style>
