import gql from 'graphql-tag'


const listResponse = `
  id type code name remark
  contactId contact {id type code name branch}
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  isVoid kpiTemplate {id code name}
  categories {id name}
`

export const LIST_DOC = (templateType) => gql`query LIST_DOC ($docType: String!, $q: FilterInput) {
  docs: listDoc${templateType} (docType: $docType, q: $q) {${listResponse}}
}`

export const WATCH_DOCS_CREATED = (templateType) => gql`subscription WATCH_DOCS_CREATED ($docType: String!) {
  docCreated: watchDoc${templateType}Created (docType: $docType) {${listResponse}}
}`

export const WATCH_DOCS_UPDATED = (templateType) => gql`subscription WATCH_DOCS_UPDATED ($docType: String!) {
  docUpdated: watchDoc${templateType}Updated (docType: $docType) {${listResponse}}
}`

export const WATCH_DOCS_DESTROYED = (templateType) => gql`subscription WATCH_DOCS_DESTROYED ($docType: String!) {
  docDestroyed: watchDoc${templateType}Destroyed (docType: $docType) {id}
}`

const detailResponse = `
  id type code name remark
  contactId contact {id type code name branch}
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  isVoid
  categories {id name}
  kpiTemplate {
    id code name type
    children {
      id code name
      children {
        id code name formType
        actualPoint minPoint maxPoint
        choices {
          text value
        }
      }
    }
  }
  kpis {
    id type code name remark
    actualPoint minPoint maxPoint
  }
`

export const DETAIL_DOC = (templateType) => gql`query DOC ($docType: String!, $docId: Int!) {
  doc: detailDoc${templateType} (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_DOC_UPDATED = (templateType) => gql`subscription WATCH_DOC_UPDATED ($docType: String!, $docId: Int) {
  docUpdated: watchDoc${templateType}Updated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const CREATE_DOC = (templateType) => gql`mutation CREATE_DOC ($docType: String!, $input: Doc${templateType}CreateInput!) {
  doc: createDoc${templateType} (docType: $docType, input: $input) {id}
}`

export const UPDATE_DOC = (templateType) => gql`mutation UPDATE_DOC ($docType: String!, $docId: Int!, $input: Doc${templateType}UpdateInput!) {
  doc: updateDoc${templateType} (docType: $docType, docId: $docId, input: $input) {id}
}`

export const DESTROY_DOC = (templateType) => gql`mutation DESTROY_DOC ($docType: String!, $docId: Int!) {
  doc: destroyDoc${templateType} (docType: $docType, docId: $docId) {id}
}`

export const VOID_DOC = (templateType) => gql`mutation VOID_DOC ($docType: String!, $docId: Int!) {
  doc: voidDoc${templateType} (docType: $docType, docId: $docId) {id}
}`

export const APPROVE_DOC = (templateType) => gql`mutation APPROVE_DOC ($docType: String!, $docId: Int!) {
  doc: approveDoc${templateType} (docType: $docType, docId: $docId) {id}
}`

export const CANCEL_DOC = (templateType) => gql`mutation CANCEL_DOC ($docType: String!, $docId: Int!) {
  doc: cancelDoc${templateType} (docType: $docType, docId: $docId) {id}
}`

export const CLOSE_DOC = (templateType) => gql`mutation CLOSE_DOC ($docType: String!, $docId: Int!) {
  doc: closeDoc${templateType} (docType: $docType, docId: $docId) {id}
}`

export const ROLLBACK_DOC = (templateType) => gql`mutation ROLLBACK_DOC ($docType: String!, $docId: Int!) {
  doc: rollbackDoc${templateType} (docType: $docType, docId: $docId) {id}
}`

export const LIST_CONTACT = (templateType) => gql`query LIST_CONTACT ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}Contact (docType: $docType, q: $q) {
    id type code name branch
  }
}`

export const LIST_DROPDOWN_KPI_TEMPLATE = (templateType) => gql`query LIST_DROPDOWN_KPI_TEMPLATE ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DropdownKpiTemplate (docType: $docType, q: $q) {
    id type code name
  }
}`
