import { capitalize } from '@/libs/form'

export default ({manageType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `manage/${manageType}/category/:categoryId`,
      name: `Manage${capitalize(manageType)}CategoryDetail`,
      component: () => import(`../CategoryDetail.vue`),
      props: (route) => ({
        categoryId: Number(route.params.categoryId),
        templateType,
        manageType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `manage/${manageType}/`,
      name: `Manage${capitalize(manageType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        manageType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `manage/${manageType}/:manageId`,
      name: `Manage${capitalize(manageType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        manageId: Number(route.params.manageId),
        templateType,
        manageType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
