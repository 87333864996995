<template>
  <div class="form-row">
    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="เอกสารอ้างอิง"
      v-model="formData.kpi.documentRef">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="ชื่อลูกค้า/ผู้ควบคุมงาน"
      v-model="formData.kpi.customerName">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="เบอร์ติดต่อ"
      v-model="formData.kpi.customerTel">
    </sgv-input-text>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="สถานที่ติดตั้ง"
      :rows="2"
      v-model="formData.kpi.customerAddr">
    </sgv-input-textarea>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="บทนำ"
      :rows="3"
      v-model="formData.kpi.introduction">
    </sgv-input-textarea>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="ประเด็นที่พบ"
      :rows="3"
      v-model="formData.kpi.issues">
    </sgv-input-textarea>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="การดำเนินการที่ต้องการ"
      :rows="3"
      v-model="formData.kpi.requiredActions">
    </sgv-input-textarea>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="การนัดหมายติดตั้งใหม่"
      :rows="3"
      v-model="formData.kpi.reScheduling">
    </sgv-input-textarea>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="ข้อมูลการติดต่อ"
      :rows="3"
      v-model="formData.kpi.contactInformation">
    </sgv-input-textarea>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="สรุป"
      :rows="3"
      v-model="formData.kpi.conclusion">
    </sgv-input-textarea>
  </div>
</template>

<script>
export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>

</style>