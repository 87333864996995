<template>
  <sgv-input-select
    :options="items"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs"
    :value="value"
    select="id">
    <template slot-scope="option">
      {{option.code}} ({{option.name}})
    </template>
  </sgv-input-select>
</template>

<script>
import { LIST_DROPDOWN_MEASUREMENT } from './graph'

export default {
  name: 'DetailFormMeasurementTemplateInput',
  props: {
    inventoryId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
  },
  data () {
    return {
      items: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_DROPDOWN_MEASUREMENT(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          inventoryId: this.inventoryId
        }
      },
      fetchPolicy: 'no-cache',
      result (res) {
        const items = res.data.items
        if (items.length === 1) {
          this.emitInput(items[0].id)
        } else {
          this.emitInput(null)
        }
      }
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
