import { capitalize } from '@/libs/form'
import List from '../List.vue'
import Detail from '../Detail.vue'
import DocConfig from '../DocConfig.vue'


export default ({docType, title, subTitle, inventoryType, group}) => {
  const templateType = 'InventoryRepair'
  return [
    {
      path: `doc/${docType}/config`,
      name: `Doc${capitalize(docType)}ConfigList`,
      component: DocConfig,
      props: () => ({
        templateType,
        docType, inventoryType,
        title, subTitle, group,
      })
    },
    {
      path: `doc/${docType}/category/:categoryId`,
      name: `Doc${capitalize(docType)}CategoryDetail`,
      component: () => import(`@/views/doc_core/components/CategoryDetail.vue`),
      props: (route) => ({
        categoryId: Number(route.params.categoryId),
        templateType,
        docType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `doc/${docType}`,
      name: `Doc${capitalize(docType)}List`,
      component: List,
      props: () => ({
        templateType,
        docType, inventoryType,
        title, subTitle, group,
      })
    },
    {
      path: `doc/${docType}/:docId`,
      name: `Doc${capitalize(docType)}Detail`,
      component: Detail,
      props: (route) => ({
        templateType,
        docType, inventoryType,
        title, subTitle, group,
        docId: Number(route.params.docId)
      })
    },
  ]
}
