<template>
  <div v-if="items.length > 0">
    <div
      class="mb-3"
      v-for="(section, i) in items"
      :key="section.code">

      <h5 class="text-warning">{{i+1}}. {{section.name}}</h5>
      <ul class="list-unstyled">
        <li
          class="px-3 pb-1"
          v-for="(item, index) in section.children"
          :key="item.code">

          <div class="form-row" v-if="item.formType === 'choice'">
            <sgv-input-radio
              class="col-12 mb-2"
              :noClick="$auth.disabled(method)"
              :options="item.choices"
              select="value"
              inline
              v-model="item.actualValue">
              <div slot="label">
                <span class="mr-1 text-secondary">{{i+1}}.{{index+1}}</span>
                {{item.name}}
              </div>
              <template slot-scope="option">
                {{option.text}}
              </template>
            </sgv-input-radio>

            <sgv-input-text
              v-show="item.remark || method === 'edit'"
              :disabled="$auth.disabled(method)"
              placeholder="หมายเหตุ (ถ้ามี)"
              v-model="item.remark"
              class="col-12">
            </sgv-input-text>
          </div>

          <div class="form-row" v-if="item.formType === 'between'">
            <sgv-input-number
              class="col-12"
              minus
              :precision="2"
              :disabled="$auth.disabled(method)"
              v-model="item.actualValue">
              <div slot="label" class="pb-2">
                <span class="mr-1 text-secondary">{{i+1}}.{{index+1}}.</span>
                {{item.minValue | comma(-1)}}
                <fa icon="less-than-equal" class="mx-1"></fa>
                {{item.name}}
                <fa icon="less-than-equal" class="ml-1"></fa>
                {{item.maxValue | comma(-1)}}
              </div>
            </sgv-input-number>

            <sgv-input-text
              v-show="item.remark || method === 'edit'"
              :disabled="$auth.disabled(method)"
              placeholder="หมายเหตุ (ถ้ามี)"
              v-model="item.remark"
              class="col-12">
            </sgv-input-text>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: []
    }
  },
  methods: {
    setFormData () {
      if (!this.formData.measurementTemplate) return []

      this.items = this.formData.measurementTemplate.children.map(section => {
        const obj = {...section}
        obj.children = obj.children.map(v => {
          const found = this.formData.measurements.find(x => x.code === v.code)
          const z = {...v}
          delete z.id
          if (found) {
            z.id = found.id
            z.actualValue = found.actualValue
            z.remark = found.remark
          }
          return z
        })
        return obj
      })
    }
  },
  watch: {
    'formData.measurements': {
      handler: 'setFormData',
      immediate: true
    },
    items: {
      handler (v) {
        this.$emit('measurements', v)
      },
      immediate: true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
