<template>
  <div class="row">
    <div class="col-12">
      <span class="float-left">
        <span class="mr-1">{{itemIndex}}</span>
        {{item.name}}
        <small>
          <div>
            {{item.minValue | comma(-1)}}
            <fa icon="less-than-equal" class="mx-1"></fa>
            {{item.actualValue | comma(-1)}}
            <fa icon="less-than-equal" class="ml-1"></fa>
            {{item.maxValue | comma(-1)}}
          </div>
          <div v-if="item.device" class="text-info">
            <fa icon="hdd"></fa>
            {{item.device.code}} ({{item.device.name}}) ({{item.deviceTopic}})
          </div>
        </small>
      </span>

      <DetailMeasurementFormBetweenModal
        class="float-right"
        :item="item"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :canSave="canSave">
      </DetailMeasurementFormBetweenModal>
    </div>

    <div class="col-12">
      <canvas ref="chart"></canvas>
    </div>
  </div>
</template>

<script>
import { isNumberInRange } from '@/libs/form'
import { CustomChart, CHART_COLORS } from '@/plugins/chart'
import DetailMeasurementFormBetweenModal from './DetailMeasurementFormBetweenModal'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    itemIndex: {
      type: String,
      required: true
    },
    canSave: {
      type: Boolean
    },
    xLabels: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      chartHeight: '180px',
      chartCanvas: null
    }
  },
  computed: {
    datasets () {
      const datasets = []
      const mean = (this.item.maxValue - this.item.minValue) / 2

      this.xLabels.forEach((l,idx,arr) => {
        if (!arr[idx+1]) return

        const lower = arr[idx].datetime
        const upper = arr[idx+1].datetime

        const items = this.item.children
        .filter(child => {
          const createdAt = new Date(child.createdAt)
          return createdAt >= lower && createdAt < upper
        })
        .map(child => {
          return {
            id: child.id,
            actualValue: child.actualValue,
            value: child.actualValue,
            delta: Math.abs(child.actualValue - mean)
          }
        })
        .sort((a,b) => {
          if (a.delta === b.delta) {
            return a.actualValue - b.actualValue
          }
          return b.delta - a.delta
        })

        const data = items[0]

        if (!data) return

        datasets.push({
          x: l.label,
          y: data.value,
          id: data.id
        })
      })

      return datasets
    },
  },
  methods: {
    chartConfig ({labels, scales, datasets}) {
      return {
        type: 'line',
        data: {
          labels,
          datasets
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                footer: (tooltipItems) => {
                  const dataIndex = tooltipItems[0].dataIndex
                  const childId = tooltipItems[0].dataset.data[dataIndex].id
                  const child = this.item.children.find(c => c.id === childId)

                  if (!child) return

                  return [
                    this.$date.format(child.createdAt).displayDatetime,
                    child.createdUser.name
                  ].join('\n')
                }
              }
            }
          },
          scales: {
            y: scales,
            x: {
              ticks: {
                callback: function(tickValue) {
                  const label = this.getLabelForValue(tickValue)
                  const arr = label.split(' ')
                  if (arr[1] === '(00:00)') return arr[0]
                  return arr[1].replace('(', '').replace(')', '')
                }
              }
            }
          }
        },
      }
    },
    chartDataset () {
      const labels = this.xLabels.map(l => l.label)

      const scales = {
        type: 'linear',
        position: 'left',
        stack: 'y',
        offset: true,
        stackWeight: 1,
        grid: {
          borderColor: CHART_COLORS.LINE_PRIMARY
        }
      }

      const datasets = [
        {
          label: this.item.name,
          data: this.datasets,
          borderColor: CHART_COLORS.LINE_PRIMARY,
          backgroundColor: CHART_COLORS.LINE_PRIMARY,
          pointBackgroundColor: (context) => {
            let index = context.dataIndex;
            let value = context.dataset.data[index];

            if (!value) return

            if (isNumberInRange(value.y, this.item.minValue, this.item.maxValue)) {
              return CHART_COLORS.POINT_SUCCESS
            }

            return CHART_COLORS.POINT_DANGER
          },
          yAxisID: 'y',
          tension: 0.25
        },
        {
          data: labels.map(() => this.item.minValue),
          pointRadius: 0,
          borderColor: CHART_COLORS.LINE_DANGER,
          backgroundColor: CHART_COLORS.LINE_DANGER,
          yAxisID: 'y',
          borderDash: [5, 5]
        },
        {
          data: labels.map(() => this.item.maxValue),
          pointRadius: 0,
          borderColor: CHART_COLORS.LINE_DANGER,
          backgroundColor: CHART_COLORS.LINE_DANGER,
          yAxisID: 'y',
          borderDash: [5, 5]
        }
      ]

      return {labels, scales, datasets}
    },
    createChart() {
      if (this.chartCanvas) {
        this.chartCanvas.destroy()
      }

      const ctx = this.$refs.chart
      ctx.style.maxHeight = this.chartHeight

      const chartData = this.chartDataset()
      const chartConfig = this.chartConfig(chartData)

      this.chartCanvas = new CustomChart(ctx, chartConfig)
    },
  },
  watch: {
    item: {
      handler () {
        this.createChart()
      },
      deep: true
    },
    labels () {
      this.createChart()
    }
  },
  mounted () {
    this.createChart()
  },
  components: {
    DetailMeasurementFormBetweenModal
  }
}
</script>

<style lang="css" scoped>
</style>
