import gql from 'graphql-tag'


const orderResponse = `
  id type code remark closedAt
  contact {
    id code name taxCode branch
    addresses {
      id
      houseId building roomId floorId
      village villageId laneId road subdistrict
      district province postcode
    }
  }
  orders {
    id totalPrice
    withholdingType withholdingTopic
    withholdingName withholdingRemark
    withholdingPrice withholdingRate
    externalDoc {id type code}
  }
`

export const LIST_ORDER_WITHHOLDING = (templateType) => gql`query LIST_ORDER_WITHHOLDING ($docType: String!, $docId: Int!, $q: FilterInput) {
  docs: listDoc${templateType}OrderWithholding (docType: $docType, docId: $docId, q: $q) {${orderResponse}}
}`

export const LIST_ORDER_WITHHOLDING_PAYABLE = (templateType) => gql`query LIST_ORDER_WITHHOLDING_PAYABLE ($docType: String!, $search: String, $startDate: DateTime, $endDate: DateTime) {
  docs: listDoc${templateType}OrderWithholdingPayable (docType: $docType, search: $search, startDate: $startDate, endDate: $endDate) {
    id type code name remark contactId
    orders {
      id type name total paid pending remaining
      account {
        id code name
      }
    }
  }
}`


export const CREATE_ORDER_WITHHOLDING = (templateType) => gql`mutation CREATE_ORDER_WITHHOLDING ($docType: String!, $docId: Int!, $input: [Doc${templateType}OrderPayableInput]!) {
  orders: createDoc${templateType}OrderWithholding (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const DESTROY_ORDER_WITHHOLDING = (templateType) => gql`mutation DESTROY_ORDER_WITHHOLDING ($docType: String!, $docId: Int!, $parentIds: [Int!]!) {
  destroyOrders: destroyDoc${templateType}OrderWithholding (docType: $docType, docId: $docId, parentIds: $parentIds) {id}
}`
