<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{section.name}}</h5>
      <div class="card-text">
        <div
          class="mb-3"
          v-for="(item,childIndex) in section.children"
          :key="item.code">
          <div v-if="xLabels.length > 0">
            <component
              :is="getFormComponent(item.formType)"
              :docId="docId"
              :docType="docType"
              :templateType="templateType"
              :item="item"
              :itemIndex="`${sectionIndex+1}.${childIndex+1}`"
              :canSave="!formData.closedAt && !!formData.approvedAt && !item.deviceId"
              :xLabels="xLabels">
            </component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailMeasurementCardChartFormChoice from './DetailMeasurementCardChartFormChoice'
import DetailMeasurementCardChartFormBetween from './DetailMeasurementCardChartFormBetween'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    section: {
      type: Object,
      required: true
    },
    sectionIndex: {
      type: Number,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    xLabels: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      formComps: [
        {formType: 'choice', comp: 'DetailMeasurementCardChartFormChoice'},
        {formType: 'between', comp: 'DetailMeasurementCardChartFormBetween'},
      ],
    }
  },
  methods: {
    getFormComponent (formType) {
      return this.formComps.find(v => v.formType === formType)?.comp || ''
    }
  },
  components: {
    DetailMeasurementCardChartFormChoice,
    DetailMeasurementCardChartFormBetween
  }
}
</script>

<style lang="css" scoped>
</style>
