import gql from 'graphql-tag'


const contactConfigResponse = `
  id type contactType name regexExp isDefault cron
  paperId paper {id type code name}
  deviceId device {id type code name}
  children {
    id type contactType name isDefault parentId
  }
`

export const LIST_CONTACT_CONFIG_MENU = templateType => gql`query LIST_CONTACT_CONFIG_MENU ($contactType: String!) {
  menus: listContact${templateType}ContactConfigMenu (contactType: $contactType)
}`

export const LIST_CONTACT_CONFIG = templateType => gql`query LIST_CONTACT_CONFIG ($contactType: String!, $q: FilterInput) {
  items: listContact${templateType}ContactConfig (contactType: $contactType, q: $q) {${contactConfigResponse}}
}`

export const CREATE_CONTACT_CONFIG = templateType => gql`mutation CREATE_CONTACT_CONFIG ($contactType: String!, $contactConfigId: Int, $input: ContactConfigInput!) {
  item: createContact${templateType}ContactConfig (contactType: $contactType,contactConfigId: $contactConfigId, input: $input) {${contactConfigResponse}}
}`

export const CREATE_CONTACT_CONFIG_CHILDREN = templateType => gql`mutation CREATE_CONTACT_CONFIG_CHILDREN ($contactType: String!, $input: ContactConfigInput!) {
  item: createContact${templateType}ContactConfig (contactType: $contactType, input: $input) {
    id type contactType name isDefault parentId
  }
}`

export const DESTROY_CONTACT_CONFIG = templateType => gql`mutation DESTROY_CONTACT_CONFIG ($contactType: String!, $contactConfigId: Int!) {
  item: destroyContact${templateType}ContactConfig (contactType: $contactType, contactConfigId: $contactConfigId) {id}
}`

export const DP_DEVICES = templateType => gql`query DP_DEVICES ($contactType: String!, $q: FilterInput) {
  items: listContact${templateType}ContactConfigDropdownDevice (contactType: $contactType, q: $q) {
    id type code name
  }
}`

export const DP_PAPERS = templateType => gql`query DP_PAPERS ($contactType: String!, $q: FilterInput) {
  items: listContact${templateType}ContactConfigDropdownPaper (contactType: $contactType, q: $q) {
    id type code name
  }
}`
