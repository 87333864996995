import gql from 'graphql-tag'


const listResponse = `
  id type code name isActive
  address barcode
  balance {current future}
  unit {id name}
  junctionParents {
    id type code name
    balance {current future}
    unit {id name}
  }
  junctionChildren {
    id type code name
    balance {current future}
    unit {id name}
  }
`

export const LIST_INVENTORY = (templateType) => gql`query LIST_INVENTORY ($inventoryType: String!, $q: FilterInput) {
  inventories: listInventory${templateType}Balance (inventoryType: $inventoryType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type code name unitId
  description barcode isNoLogo
  packing upperLimit lowerLimit purchaseLimit
  address infoTag productTag cautionTag
  supplierSku isActive
  categories {id name}
`

export const DETAIL_INVENTORY = (templateType) => gql`query DETAIL_INVENTORY ($inventoryType: String!, $inventoryId: Int!) {
  inventory: detailInventory${templateType} (inventoryType: $inventoryType, inventoryId: $inventoryId) {${detailResponse}}
}`

export const CREATE_INVENTORY = (templateType) => gql`mutation CREATE_INVENTORY ($inventoryType: String!, $input: Inventory${templateType}Input!) {
  createInventory: createInventory${templateType} (inventoryType: $inventoryType, input: $input) {${detailResponse}}
}`

export const UPDATE_INVENTORY = (templateType) => gql`mutation UPDATE_INVENTORY ($inventoryType: String!, $inventoryId: Int!, $input: Inventory${templateType}Input!) {
  updateInventory: updateInventory${templateType} (inventoryType: $inventoryType, inventoryId: $inventoryId, input: $input) {${detailResponse}}
}`

export const DESTROY_INVENTORY = (templateType) => gql`mutation DESTROY_INVENTORY ($inventoryType: String!, $inventoryId: Int!) {
  destroyInventory: destroyInventory${templateType} (inventoryType: $inventoryType, inventoryId: $inventoryId) {id}
}`
