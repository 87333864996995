<template>
  <sgv-input-select
    :options="items"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs"
    :value="value"
    select="id">
    <template slot-scope="option">
      {{option.code}} ({{option.name}})
    </template>
  </sgv-input-select>
</template>

<script>
import { LIST_AUDITOR } from './graph'

export default {
  name: 'DetailFormInputAuditor',
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    jobDescriptionId: {
      type: Number,
      required: true
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
  },
  data () {
    return {
      items: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_AUDITOR(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          jobDescriptionId: this.jobDescriptionId
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  },
  watch: {
    jobDescriptionId () {
      this.$emit('input', null)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
