<template>
  <button
    @click="download"
    class="btn btn-warning">
    <fa icon="download"></fa>
    ไฟล์นำส่ง
  </button>
</template>

<script>
import round from 'lodash/round'
import {
  LIST_ORDER_PURCHASE,
  LIST_ORDER_SALES
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  computed: {
    filename () {
      return this.formData.code
    }
  },
  methods: {
    fetchPurchase () {
      return this.$apollo.query({
        query: LIST_ORDER_PURCHASE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        return res.data.orders.reduce((t,v) => {
          t.vatPrice += v.vatPrice
          t.totalPrice += v.totalPrice
          return t
        }, {vatPrice: 0, totalPrice: 0})
      })
      .catch(this.$toasted.global.error)
    },
    fetchSales () {
      return this.$apollo.query({
        query: LIST_ORDER_SALES(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        return res.data.orders.reduce((t,v) => {
          t.vatPrice += v.vatPrice
          t.totalPrice += v.totalPrice
          return t
        }, {vatPrice: 0, totalPrice: 0})
      })
      .catch(this.$toasted.global.error)
    },
    serializeData (purchase, sales) {
      const purchaseVatPrice = round(Math.abs(purchase.vatPrice), 2)
      const saleVatPrice = round(Math.abs(sales.vatPrice), 2)
      const netPrice = round(Math.abs(saleVatPrice - purchaseVatPrice), 2)
      const arr = [{
        'ลำดับที่': 1,
        'สาขาที่': 0,
        'เลขที่': '46/9 หมู่ที่ 21',
        'รหัสไปรษณีย์': '10540',
        'ยอดขายที่ต้องเสีย': round(Math.abs(sales.totalPrice), 2),
        'ภาษีขาย': saleVatPrice,
        'ยอดซื้อ': round(Math.abs(purchase.totalPrice), 2),
        'ภาษีซื้อ': purchaseVatPrice,
        'ภาษีทีต้องชำระ/ชำระเกิน': netPrice
      }]

      return arr
    },
    async download () {
      const purchase = await this.fetchPurchase()
      const sales = await this.fetchSales()
      const data = this.serializeData(purchase, sales)
      const csv = this.$papa.unparse(data)
      const blob = new Blob(["\uFEFF", csv], {type: "text/csv;charset=utf-8;"}, this.filename)

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", this.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<style lang="css" scoped>
</style>
