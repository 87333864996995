<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">แจ้งเตือน</h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :rKey="rKey"
      :items="notifications"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot-scope="{item, hidden}">
        <tr class="">
          <td
            v-if="hidden.code"
            @click="toDetail(item.id)"
            class="pointer">
            {{ item.code }}
          </td>
          <td v-if="hidden.name">
            {{ item.name }}
          </td>
          <td v-if="hidden.balance">
            <span v-if="item.lowerLimit >= 0">
              <span class="text-warning mr-1">{{item.lowerLimit}}</span>
              <fa icon="chevron-left" class="text-secondary"></fa>
            </span>

            {{Number(item.balance.current)}}

            <span v-if="item.balance.current !== item.balance.future">
              <fa icon="long-arrow-alt-right"></fa>
              <span
                :class="{
                  'text-success': item.balance.future > item.balance.current,
                  'text-danger': item.balance.future < item.balance.current
                }">
                {{ Number(item.balance.future) }}
              </span>
            </span>

            <span v-if="item.upperLimit >= 0">
              <fa icon="chevron-left" class="text-secondary"></fa>
              <span class="text-danger ml-1">{{item.upperLimit}}</span>
            </span>

            {{item.unit.name}}

            <small v-if="item.lowMessage" class="text-warning">
              <br>
              {{item.lowMessage.message}}
            </small>
            <small v-if="item.highMessage" class="text-danger">
              <br>
              {{item.highMessage.message}}
            </small>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_NOTIFICATION } from './graph/notification'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-แจ้งเตือน`
    }
  },
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      detailView: `Inventory${this.$form.capitalize(this.inventoryType)}Detail`,
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}Notification`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
        {text: 'รายละเอียด', value: 'balance'},
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name', 'balance'],
        column: null,
        search: null,
        toolbar: null,
      },
      notifications: [],
      rowsPerPage: [
        { text: 'ทั้งหมด', value: null },
      ]
    }
  },
  apollo: {
    notifications: {
      query () {
        return LIST_NOTIFICATION(this.templateType)
      },
      variables () {
        return {inventoryType: this.inventoryType}
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    toDetail (id) {
      this.$router.push({
        name: this.detailView,
        params: {inventoryId: id},
        query: {redirect: this.rKey}
      })
    }
  },
  created () {
    this.$store.commit(
      'path/setCurrent',
      {to: this.$route, group: this.group}
    )
  }
}
</script>

<style lang="css" scoped>
</style>
