<template>
  <sgv-csv
    :items="csvData"
    :labels="csvLabels"
    :filename="csvFilename">
    <button
      :disabled="csvData.length === 0"
      type="button"
      class="btn btn-success form-group">
      EXCEL
    </button>
  </sgv-csv>
</template>

<script>
export default {
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required:false
    },
    endDate: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      csvLabels: {
        id: { title: 'ลำดับ' },
        code: { title: 'รหัส' },
        name: { title: 'ชื่อ' },
        unit: { title: 'หน่วย' },
        total: { title: 'ออก' },
      },
      csvFilename: `${this.inventoryType}-stockOut-${this.startDate}-to-${this.endDate}`
    }
  },
  computed: {
    csvData () {
      return this.items.map((v,idx) => {
        return {
          id: idx+1,
          code: v.code,
          name: v.name,
          unit: v.unit,
          total: v.total,
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
