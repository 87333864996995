<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">รายการ</h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-12">
        <sgv-form :method.sync="method" :options="options">
          <Profile
            type="profile"
            v-if="contactId > 0"
            class="row"
            :contactType="contactType"
            :templateType="templateType"
            :contactId="contactId"
            :method="method">
          </Profile>

          <DetailForm
            :contactType="contactType"
            :templateType="templateType"
            :formData="formData"
            :method="method"
            :v="$v">
          </DetailForm>
        </sgv-form>
      </div>
    </div>

    <HrDivider :options="tabs" v-model="selectedTab" class="mt-3"></HrDivider>

    <keep-alive>
      <component
        :is="selectedTab"
        v-if="contactId > 0"
        :contactId="contactId"
        :contactType="contactType"
        :templateType="templateType"
        :method="method"
        :formData="formData"
        :attachments="attachments"
        printBranch
        @updated="dataDetail">
      </component>
    </keep-alive>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm.vue'
import Attachment from '@/views/contact_core/components/Attachment.vue'
import Profile from '@/views/contact_core/components/Profile.vue'
import DocList from '@/views/contact_core/components/DocList.vue'
import Address from '@/views/contact_core/components/Address.vue'
import {
  DETAIL_CONTACT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DESTROY_CONTACT,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-${this.formData.code || 'รายการ'}`
    }
  },
  props: {
    contactId: {
      type: Number,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      method: 'info',
      listView: `Contact${this.$form.capitalize(this.contactType)}List`,
      rKey: `Contact${this.$form.capitalize(this.contactType)}Detail`,
      rFields: ['selectedTab'],
      formData: {
        code: '',
        name: '',
        branch: 'สำนักงานใหญ่',
        taxCode: '',
        isActive: true,
        categories: [],
        addresses: [],
        headOfficeId: null,
        isUnknown: false
      },
      tabs: [
        {text: 'ติดต่อ', value: 'Address'},
        {text: 'เอกสาร', value: 'DocList'},
        {text: 'ไฟล์', value: 'Attachment'},
      ],
      selectedTab: 'DocList',
      addressTypes: ['main', 'logistic', 'purchases'],
      attachments: [
        {text: 'ทั่วไป', value: 'etc'},
        {text: 'โปรไฟล์', value: 'profile'}
      ]
    }
  },
  validations: {
    formData: {
      code: { required },
      name: { required },
    }
  },
  computed: {
    options () {
      return [
        {text: 'เพิ่ม', variant: 'success', method: 'add', func: this.createData, disabled: true},
        {text: 'แก้ไข', variant: 'warning', method: 'edit', func: this.updateData, disabled: !this.$auth.hasRole(`contact:${this.contactType}:add`)},
        {text: 'ลบ', variant: 'danger', method: 'delete', func: this.destroyData, disabled: !this.$auth.hasRole(`contact:${this.contactType}:add`)},
        {text: 'กลับ', variant: 'primary', func: this.closeForm, header: true, align: 'right'},
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_CONTACT(this.templateType),
        variables: {
          contactType: this.contactType,
          contactId: this.contactId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.contact)})
      .catch(err => {this.$toasted.global.error(err)})
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'categories') {
          this.formData[key] = v[key].map(x => x.id)
          return
        }
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const obj = {...v}
      delete obj.addresses
      return obj
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_CONTACT(this.templateType),
        variables: {
          contactType: this.contactType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, contactId: res.data.createContact.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_CONTACT(this.templateType),
        variables: {
          contactType: this.contactType,
          contactId: this.contactId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateContact)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_CONTACT(this.templateType),
        variables: {
          contactType: this.contactType,
          contactId: this.contactId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  watch: {
    toggleDetail () {
      this.setRetaining()
    },
    selectedTab () {
      this.setRetaining()
    }
  },
  created () {
    if (this.contactId === 0) {
      this.method = 'add'
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    Attachment,
    Address,
    DetailForm,
    DocList,
    Profile,
  }
}
</script>

<style lang="css" scoped>
</style>
