<template>
  <div class="">
    <div class="form-row">
      <DocConfigFormContact
        :docType="docType"
        :templateType="templateType"
        v-if="menu.contactTypes"
        placeholder="เลือกพนักงาน..."
        class="col-12"
        select="id"
        :types="menu.contactTypes"
        v-model="contactId"
        @change="contact = $event">
      </DocConfigFormContact>
    </div>

    <div class="form-row">
      <div class="col-auto">
        <button
          v-if="selected"
          type="button"
          class="btn btn-success mb-3 mr-2"
          @click="addForm">
          เพิ่ม
        </button>
        <button
          type="button"
          class="btn btn-primary mb-3"
          @click="toList">
          กลับ
        </button>
      </div>

      <div class="col"></div>

      <sgv-input-text
        class="col-sm-auto col-12"
        v-model="search"
        placeholder="ค้นหา...">
      </sgv-input-text>
    </div>

    <component
      key="form"
      class="mb-3"
      v-if="formComp"
      :is="formComp"
      :isForm="isForm"
      :docType="docType"
      :templateType="templateType"
      :type="selected"
      :item="formData"
      :menu="menu"
      :contact="contact"
      @cancel="cancelForm"
      @update="updateData">
    </component>

    <div
      v-for="item in items"
      :key="item.id"
      class="mb-3">
      <template v-for="(comp, name) in comps">
        <component
          v-if="item.type === name"
          :key="name"
          :is="comp"
          :docType="docType"
          :templateType="templateType"
          :type="selected"
          :item="item"
          :menu="menu"
          @update="updateData"
          @child="createChildren"
          @destroy="destroyData">
        </component>
      </template>
    </div>
  </div>
</template>

<script>
import {
  LIST_DOC_CONFIG,
  CREATE_DOC_CONFIG,
  DESTROY_DOC_CONFIG,
  CREATE_DOC_CONFIG_CHILDREN
} from './graph'
import DocConfigFormContact from '@/views/doc_core/components/DocConfigFormContact'
import CardContact from './DocConfigCardContact'
import CardDiscountPrice from './DocConfigCardDiscount'
import CardExpense from './DocConfigCardExpense'
import CardNetPrice from './DocConfigCardNetPrice'
import CardUserManual from '@/views/doc_core/components/DocConfigCardUserManual'
import CardReport from '@/views/doc_core/components/DocConfigCardReport'
import CardPrintPdf from './DocConfigCardPrintPdf'
import CardDoc from './DocConfigCardDoc'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    selected: {
      type: String,
      required: false
    },
    menu: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      search: '',
      formComp: '',
      formData: {},
      isForm: false,
      comps: {
        contact: 'CardContact',
        expense: 'CardExpense',
        discountPrice: 'CardDiscountPrice',
        payment: 'CardNetPrice',
        netPrice: 'CardNetPrice',
        changeError: 'CardNetPrice',
        studentLoanDoc: 'CardDoc',
        reportGroup: 'CardReport',
        printCoverPdf: 'CardPrintPdf',
        printWithholdingPdf: 'CardPrintPdf',
        userManual: 'CardUserManual',
      },
      items: [],
      contactId: null,
      contact: null
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_DOC_CONFIG(this.templateType)
      },
      fetchPolicy: 'cache-and-network',
      variables () {
        return {
          docType: this.docType,
          q: {
            params: {
              type: this.selected,
              search: this.search,
              contactId: this.contactId
            }
          }
        }
      },
      skip () {
        return !this.selected
      },
      debounce: 250
    }
  },
  methods: {
    toList () {
      this.$router.push({
        name: `Doc${this.$form.capitalize(this.docType)}List`
      })
    },
    addForm () {
      if (this.isForm) return

      this.formComp = this.comps[this.selected]
      this.formData = {}
      this.isForm = true
    },
    cancelForm () {
      this.formComp = null
      this.formData = {}
      this.isForm = false
    },
    cacheQuery () {
      return {
        query: LIST_DOC_CONFIG(this.templateType),
        variables: {
          docType: this.docType,
          q: {
            params: {
              type: this.selected,
              search: this.search,
              contactId: this.contactId
            }
          }
        }
      }
    },
    updateData ({id, input, cb}) {
      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: id,
          input
        },
        update: (store, {data: {item}}) => {
          const data = store.readQuery(this.cacheQuery())

          if (id) {
            const idx = data.items.findIndex(v => v.id === item.id)
            if (idx !== -1) {
              data.items[idx] = item
            }
          } else {
            data.items.unshift(item)
          }
          store.writeQuery({...this.cacheQuery(), data})
        }
      })
      .then(() => {
        this.$toasted.global.success('เพิ่มสำเร็จ')
        this.cancelForm()
        cb()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    createChildren ({input, cb}) {
      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_CHILDREN(this.templateType),
        variables: {
          docType: this.docType,
          input
        },
        update: (store, {data: {item}}) => {
          const data = store.readQuery(this.cacheQuery())
          const idx = data.items.findIndex(v => v.id === item.parentId)
          if (idx !== -1) {
            data.items[idx].children.push(item)
          }
          store.writeQuery({...this.cacheQuery(), data})
        }
      })
      .then(() => {
        this.$toasted.global.success('เพิ่มสำเร็จ')
        cb()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData ({id, parentId}) {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: id
        },
        update: (store, {data: {item}}) => {
          const data = store.readQuery(this.cacheQuery())
          if (parentId) {
            const idx = data.items.findIndex(v => v.id === parentId)
            const chx = data.items[idx].children.findIndex(v => v.id === item.id)
            if (chx !== -1) {
              data.items[idx].children.splice(chx, 1)
            }
          } else {
            const idx = data.items.findIndex(v => v.id === item.id)
            if (idx !== -1) {
              data.items.splice(idx, 1)
            }
          }
          store.writeQuery({...this.cacheQuery(), data})
        }
      })
      .then(() => {
        this.$toasted.global.success('ลบสำเร็จ')
        this.cancelForm()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  watch: {
    selected () {
      this.contactId = null
      this.contact = null
    }
  },
  components: {
    CardContact,
    CardNetPrice,
    CardUserManual,
    DocConfigFormContact,
    CardReport,
    CardPrintPdf,
    CardDoc,
    CardDiscountPrice,
    CardExpense
  }
}
</script>

<style lang="css">

</style>
