<template>
  <div class="row">
    <div class="col-12">
      <div class="form-inline">
        <sgv-input-select
          placeholder="เลือกเครื่องพิมพ์..."
          :options="printers"
          select="id"
          class="mr-2"
          v-model="deviceId">
          <template slot-scope="option">
            {{option.name}}
          </template>
        </sgv-input-select>

        <button
          class="btn btn-warning form-group mr-2"
          @click="print">
          <fa icon="print"></fa>
          <span class="ml-1">พิมพ์</span>
        </button>

        <button
          class="btn btn-info form-group"
          @click="download">
          <fa icon="file-pdf"></fa>
          <span class="ml-1">PDF</span>
        </button>

        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_REPORT_PRINTER } from '../graph/report'


export default {
  mixins: [retainMixin],
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      deviceId: null,
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}ReportPrinter`,
      rFields: ['deviceId'],
      printers: []
    }
  },
  apollo: {
    printers: {
      query () {
        return LIST_REPORT_PRINTER(this.templateType)
      },
      variables () {
        return {inventoryType: this.inventoryType}
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    print () {
      this.$emit('print', this.deviceId)
    },
    download () {
      this.$emit('download', null)
    },
    search () {
      this.$emit('search', null)
    }
  },
  watch: {
    deviceId: 'setRetaining'
  },
}
</script>

<style lang="css" scoped>
</style>
