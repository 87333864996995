<template>
  <div class="form-row" v-if="categories.length > 0">
    <div class="col-12">
      <sgv-input-check-array
        label="หมวดหมู่"
        :disabled="disabled"
        :options="items"
        inline
        :value="value"
        select="id"
        @input="emitInput">
        <template slot-scope="option">
          <span :class="{'text-success': value.find(v => v.id === option.id)}">
            {{option.name}}
          </span>
        </template>
      </sgv-input-check-array>
    </div>
  </div>
</template>

<script>
import { LIST_CATEGORY } from '../graph/category'

export default {
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      categories: []
    }
  },
  apollo: {
    categories: {
      query () {
        return LIST_CATEGORY(this.templateType)
      },
      variables () {
        return {inventoryType: this.inventoryType}
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    items () {
      if (['edit', 'add'].includes(this.method)) {
        return this.categories
      } else {
        return this.categories.filter(v => this.value.includes(v.id))
      }
    }
  },
  methods: {
    emitInput (v) {
      this.$emit('input', v)
    }
  }
}
</script>

<style lang="css">
</style>
