import gql from 'graphql-tag'

export const LIST_IMPORT_VALIDATION = (templateType) => gql`query LIST_IMPORT_VALIDATION ($inventoryType: String!, $input: [Inventory${templateType}ImportInput]!) {
  result: listInventory${templateType}ImportValidation (inventoryType: $inventoryType, input: $input) {
    units {
      code name unit
    }
    categories {
      code name
    }
    adds {
      code name unitId unit {id name}
      description barcode
      packing upperLimit lowerLimit purchaseLimit
      address supplierSku
      categories {id code name}
    }
    updates {
      id code name unitId unit {id name}
      description barcode
      packing upperLimit lowerLimit purchaseLimit
      address supplierSku
      categories {id code name}
    }
  }
}`
