export default {
  routers: [
    {
      title: 'คลังสินค้า',
      header: true,
      sections: [
        {
          title: 'สินค้า',
          group: 'InventoryProduct',
          sections: [
            {
              title: 'ค้นหา',
              template: 'InventoryGeneralStockInfo',
              options: {inventoryType: 'product'}
            },
            {
              title: 'สินค้า',
              template: 'InventoryGeneral',
              options: {
                inventoryType: 'product',
                stockcountTemplateType: 'InventoryStockcount',
                stockcountDocType: 'stp',
              }
            },
            {
              title: 'ต้นทุนสินค้า',
              template: 'InventoryCost',
              options: {
                inventoryCostType: 'product'
              }
            },
            {
              title: 'แจ้งเตือน',
              template: 'InventoryGeneralNotification',
              options: {inventoryType: 'product'}
            },
            {
              title: 'ค้างสต๊อก',
              template: 'InventoryGeneralDeadStock',
              options: {inventoryType: 'product'}
            },
            {
              title: 'ใบแจ้งสินค้า (NCR)',
              template: 'DocInventoryRepair',
              options: {
                docType: 'rpp',
                inventoryType: 'product'
              }
            },
            {
              title: 'นับสต๊อก',
              template: 'DocInventoryStockcount',
              options: {
                docType: 'stp',
                inventoryType: 'product'
              }
            },
            {
              title: 'รายงาน',
              template: 'InventoryGeneralReport',
              options: {inventoryType: 'product'}
            },
            {
              title: 'ใบเบิกสินค้าตัวอย่าง',
              template: 'DocSaleRequisition',
              options: {docType: 'sm'}
            },
            {
              title: 'ใบสินค้าชำรุด',
              template: 'DocInventoryRequisition',
              options: {docType: 'zz'}
            },
            {
              title: 'ใบแก้ไขสินค้า',
              template: 'DocInventoryTransfer',
              options: {docType: 'xs'}
            },
          ],
        },
        {
          title: 'วัตถุดิบ',
          group: 'InventoryMaterial',
          sections: [
            {
              title: 'ค้นหา',
              template: 'InventoryGeneralStockInfo',
              options: {inventoryType: 'material'}
            },
            {
              title: 'วัตถุดิบ',
              template: 'InventoryGeneral',
              options: {
                inventoryType: 'material',
                stockcountTemplateType: 'InventoryStockcount',
                stockcountDocType: 'stm',
              }
            },
            {
              title: 'แจ้งเตือน',
              template: 'InventoryGeneralNotification',
              options: {inventoryType: 'material'}
            },
            {
              title: 'ใบแจ้งวัตถุดิบ (NCR)',
              template: 'DocInventoryRepair',
              options: {
                docType: 'rpr',
                inventoryType: 'material'
              }
            },
            {
              title: 'นับสต๊อก',
              template: 'DocInventoryStockcount',
              options: {
                docType: 'stm',
                inventoryType: 'material'
              }
            },
            {
              title: 'รายงาน',
              template: 'InventoryGeneralReport',
              options: {inventoryType: 'material'}
            },
            {
              title: 'ใบเบิกอุปกรณ์ผลิต',
              template: 'DocInventoryRequisition',
              options: {docType: 'eu'}
            },
            {
              title: 'ใบแก้ไขวัตถุดิบ',
              template: 'DocInventoryTransfer',
              options: {docType: 'rxs'}
            },
          ],
        },
        {
          title: 'สำนักงาน',
          group: 'InventoryStore',
          sections: [
            {
              title: 'ค้นหา',
              template: 'InventoryGeneralStockInfo',
              options: {inventoryType: 'store'}
            },
            {
              title: 'สำนักงาน',
              template: 'InventoryGeneral',
              options: {
                inventoryType: 'store',
                stockcountTemplateType: 'InventoryStockcount',
                stockcountDocType: 'sts',
              }
            },
            {
              title: 'แจ้งเตือน',
              template: 'InventoryGeneralNotification',
              options: {inventoryType: 'store'}
            },
            {
              title: 'ใบตรวจสภาพ',
              template: 'DocInventoryMaintenance',
              options: {
                docType: 'mns',
                inventoryType: 'store'
              }
            },
            {
              title: 'แจ้งซ่อม',
              template: 'DocInventoryRepair',
              options: {
                docType: 'rps',
                inventoryType: 'store'
              }
            },
            {
              title: 'นับสต๊อก',
              template: 'DocInventoryStockcount',
              options: {
                docType: 'sts',
                inventoryType: 'store'
              }
            },
            {
              title: 'รายงาน',
              template: 'InventoryGeneralReport',
              options: {inventoryType: 'store'}
            },
            {
              title: 'ใบเบิกของสำนักงาน',
              template: 'DocInventoryRequisition',
              options: {docType: 'ou'}
            },
            {
              title: 'ค่าใช้จ่ายสำนักงาน',
              template: 'DocPurchaseCash',
              options: {docType: 'pso'}
            },
            {
              title: 'ใบแก้ไขสำนักงาน',
              template: 'DocInventoryTransfer',
              options: {docType: 'sxs'}
            },
            {
              title: 'ทรัพย์สิน',
              template: 'StockAssetGeneral',
              options: {stockAssetType: 'store'}
            },
            {
              title: 'ใบบันทึกค่าเสื่อม',
              template: 'DocStockAssetDeduction',
              options: {docType: 'sast'}
            },
          ],
        },
        {
          title: 'โรงงาน',
          group: 'InventoryFactory',
          sections: [
            {
              title: 'ค้นหา',
              template: 'InventoryGeneralStockInfo',
              options: {inventoryType: 'factory'}
            },
            {
              title: 'โรงงาน',
              template: 'InventoryGeneral',
              options: {
                inventoryType: 'factory',
                stockcountTemplateType: 'InventoryStockcount',
                stockcountDocType: 'stf',
              }
            },
            {
              title: 'แจ้งเตือน',
              template: 'InventoryGeneralNotification',
              options: {inventoryType: 'factory'}
            },
            {
              title: 'ใบตรวจสภาพ',
              template: 'DocInventoryMaintenance',
              options: {
                docType: 'mnf',
                inventoryType: 'factory'
              }
            },
            {
              title: 'แจ้งซ่อม',
              template: 'DocInventoryRepair',
              options: {
                docType: 'rpf',
                inventoryType: 'factory'
              }
            },
            {
              title: 'นับสต๊อก',
              template: 'DocInventoryStockcount',
              options: {
                docType: 'stf',
                inventoryType: 'factory'
              }
            },
            {
              title: 'รายงาน',
              template: 'InventoryGeneralReport',
              options: {inventoryType: 'factory'}
            },
            {
              title: 'ใบเบิกของโรงงาน',
              template: 'DocInventoryRequisition',
              options: {docType: 'mt'}
            },
            {
              title: 'ค่าใช้จ่ายโรงงาน',
              template: 'DocPurchaseCash',
              options: {docType: 'pfo'}
            },
            {
              title: 'ใบแก้ไขโรงงาน',
              template: 'DocInventoryTransfer',
              options: {docType: 'fxs'}
            },
            {
              title: 'ทรัพย์สิน',
              template: 'StockAssetGeneral',
              options: {stockAssetType: 'factory'}
            },
            {
              title: 'ใบบันทึกค่าเสื่อม',
              template: 'DocStockAssetDeduction',
              options: {docType: 'fast'}
            },
          ],
        },
        {
          title: 'แม่พิมพ์',
          group: 'InventoryMold',
          sections: [
            {
              title: 'ค้นหา',
              template: 'InventoryGeneralStockInfo',
              options: {inventoryType: 'mould'}
            },
            {
              title: 'แม่พิมพ์',
              template: 'InventoryGeneral',
              options: {
                inventoryType: 'mould',
                stockcountTemplateType: 'InventoryStockcount',
                stockcountDocType: 'std',
              }
            },
            {
              title: 'ต้นทุนแม่พิมพ์',
              template: 'InventoryCost',
              options: {
                inventoryCostType: 'mould'
              }
            },
            {
              title: 'ร้องขอการออกแบบ',
              template: 'DocDesignNote',
              options: {docType: 'dsq'}
            },
            {
              title: 'แผนการออกแบบ',
              template: 'DocDesignNote',
              options: {docType: 'dsp'}
            },
            {
              title: 'การทดสอบแม่พิมพ์',
              template: 'DocDesignNote',
              options: {docType: 'dsm'}
            },
            {
              title: 'ใบตรวจสภาพ',
              template: 'DocInventoryMaintenance',
              options: {
                docType: 'mnd',
                inventoryType: 'mould'
              }
            },
            {
              title: 'แจ้งซ่อม',
              template: 'DocInventoryRepair',
              options: {
                docType: 'rpd',
                inventoryType: 'mould'
              }
            },
            {
              title: 'นับสต๊อก',
              template: 'DocInventoryStockcount',
              options: {
                docType: 'std',
                inventoryType: 'mould'
              }
            },
            {
              title: 'ใบเบิกอุปกรณ์แม่พิมพ์',
              template: 'DocInventoryRequisition',
              options: {docType: 'mdt'}
            },
            {
              title: 'ใบแก้ไขแม่พิมพ์',
              template: 'DocInventoryTransfer',
              options: {docType: 'dxs'}
            },
            {
              title: 'ทรัพย์สิน',
              template: 'StockAssetGeneral',
              options: {stockAssetType: 'mould'}
            },
            {
              title: 'ใบบันทึกค่าเสื่อม',
              template: 'DocStockAssetDeduction',
              options: {docType: 'dast'}
            },
          ],
        },
        {
          title: 'เครื่องจักร',
          group: 'InventoryMachine',
          sections: [
            {
              title: 'ค้นหา',
              template: 'InventoryGeneralStockInfo',
              options: {inventoryType: 'machine'}
            },
            {
              title: 'เครื่องจักร',
              template: 'InventoryGeneral',
              options: {
                inventoryType: 'machine',
                stockcountTemplateType: 'InventoryStockcount',
                stockcountDocType: 'ste',
              }
            },
            {
              title: 'แจ้งเตือน',
              template: 'InventoryGeneralNotification',
              options: {inventoryType: 'machine'}
            },
            {
              title: 'ใบตรวจสภาพ',
              template: 'DocInventoryMaintenance',
              options: {
                docType: 'mnm',
                inventoryType: 'machine'
              }
            },
            {
              title: 'ใบบันทึกสำรวจอาคาร',
              template: 'DocInventoryMaintenance',
              options: {
                docType: 'mnb',
                inventoryType: 'machine'
              }
            },
            {
              title: 'แจ้งซ่อม',
              template: 'DocInventoryRepair',
              options: {
                docType: 'rpm',
                inventoryType: 'machine'
              }
            },
            {
              title: 'นับสต๊อก',
              template: 'DocInventoryStockcount',
              options: {
                docType: 'ste',
                inventoryType: 'machine'
              }
            },
            {
              title: 'รายงาน',
              template: 'InventoryGeneralReport',
              options: {inventoryType: 'machine'}
            },
            {
              title: 'ใบเบิกอุปกรณ์เครื่องจักร',
              template: 'DocInventoryRequisition',
              options: {docType: 'mct'}
            },
            {
              title: 'ใบแก้ไขเครื่องจักร',
              template: 'DocInventoryTransfer',
              options: {docType: 'mxs'}
            },
            {
              title: 'ทรัพย์สิน',
              template: 'StockAssetGeneral',
              options: {stockAssetType: 'machine'}
            },
            {
              title: 'ใบบันทึกค่าเสื่อม',
              template: 'DocStockAssetDeduction',
              options: {docType: 'mast'}
            },
          ],
        },
        {
          title: 'เครื่องตรวจวัด',
          group: 'InventoryInstrument',
          sections: [
            {
              title: 'ค้นหา',
              template: 'InventoryGeneralStockInfo',
              options: {inventoryType: 'instrument'}
            },
            {
              title: 'เครื่องตรวจวัด',
              template: 'InventoryGeneral',
              options: {
                inventoryType: 'instrument',
                stockcountTemplateType: 'InventoryStockcount',
                stockcountDocType: 'sti',
              }
            },
            {
              title: 'แจ้งเตือน',
              template: 'InventoryGeneralNotification',
              options: {inventoryType: 'instrument'}
            },
            {
              title: 'ใบทวนสอบ',
              template: 'DocInventoryMaintenance',
              options: {
                docType: 'mni',
                inventoryType: 'instrument'
              }
            },
            {
              title: 'แจ้งซ่อม',
              template: 'DocInventoryRepair',
              options: {
                docType: 'rpi',
                inventoryType: 'instrument'
              }
            },
            {
              title: 'นับสต๊อก',
              template: 'DocInventoryStockcount',
              options: {
                docType: 'sti',
                inventoryType: 'instrument'
              }
            },
            {
              title: 'รายงาน',
              template: 'InventoryGeneralReport',
              options: {inventoryType: 'instrument'}
            },
            {
              title: 'ใบแก้ไขเครื่องตรวจวัด',
              template: 'DocInventoryTransfer',
              options: {docType: 'ixs'}
            },
            {
              title: 'ทรัพย์สิน',
              template: 'StockAssetGeneral',
              options: {stockAssetType: 'instrument'}
            },
            {
              title: 'ใบบันทึกค่าเสื่อม',
              template: 'DocStockAssetDeduction',
              options: {docType: 'iast'}
            },
          ],
        },
        {
          title: 'ยานพาหนะ',
          group: 'InventoryVehicle',
          sections: [
            {
              title: 'ค้นหา',
              template: 'InventoryGeneralStockInfo',
              options: {inventoryType: 'vehicle'}
            },
            {
              title: 'ยานพาหนะ',
              template: 'InventoryGeneral',
              options: {
                inventoryType: 'vehicle',
                stockcountTemplateType: 'InventoryStockcount',
                stockcountDocType: 'stv',
              }
            },
            {
              title: 'แจ้งเตือน',
              template: 'InventoryGeneralNotification',
              options: {inventoryType: 'vehicle'}
            },
            {
              title: 'ใบตรวจสภาพ',
              template: 'DocInventoryMaintenance',
              options: {
                docType: 'mnv',
                inventoryType: 'vehicle'
              }
            },
            {
              title: 'แจ้งซ่อม',
              template: 'DocInventoryRepair',
              options: {
                docType: 'rpv',
                inventoryType: 'vehicle'
              }
            },
            {
              title: 'นับสต๊อก',
              template: 'DocInventoryStockcount',
              options: {
                docType: 'stv',
                inventoryType: 'vehicle'
              }
            },
            {
              title: 'รายงาน',
              template: 'InventoryGeneralReport',
              options: {inventoryType: 'vehicle'}
            },
            {
              title: 'ทรัพย์สิน',
              template: 'StockAssetGeneral',
              options: {stockAssetType: 'vehicle'}
            },
            {
              title: 'ใบบันทึกค่าเสื่อม',
              template: 'DocStockAssetDeduction',
              options: {docType: 'vast'}
            },
            {
              title: 'ใบเบิกอุปกรณ์ซ่อมบำรุงรถยนต์',
              template: 'DocInventoryRequisition',
              options: {docType: 'mtv'}
            },
            {
              title: 'ค่าซ่อมบำรุงรถยนต์',
              template: 'DocPurchaseCash',
              options: {docType: 'pmto'}
            },
            {
              title: 'ค่าขนส่งภายใน',
              template: 'DocPurchaseCash',
              options: {docType: 'pmgo'}
            },
          ],
        },
        {
          title: 'ค่าใช้จ่าย',
          group: 'InventoryExpense',
          sections: [
            {
              title: 'ค้นหา',
              template: 'InventoryGeneralStockInfo',
              options: {inventoryType: 'expense'}
            },
            {
              title: 'ค่าใช้จ่าย',
              template: 'InventoryGeneral',
              options: {
                inventoryType: 'expense',
                stockcountTemplateType: 'InventoryStockcount',
                stockcountDocType: 'stz',
              }
            },
            {
              title: 'แจ้งเตือน',
              template: 'InventoryGeneralNotification',
              options: {inventoryType: 'expense'}
            },
            {
              title: 'นับสต๊อก',
              template: 'DocInventoryStockcount',
              options: {
                docType: 'stz',
                inventoryType: 'expense'
              }
            },
            {
              title: 'รายงาน',
              template: 'InventoryGeneralReport',
              options: {inventoryType: 'expense'}
            },
            {
              title: 'ค่าสาธารณูปโภค',
              template: 'DocPurchaseCash',
              options: {docType: 'pwr'}
            },
            {
              title: 'ค่าโทรศัพท์',
              template: 'DocPurchaseCash',
              options: {docType: 'tel'}
            },
            {
              title: 'ค่าธรรมเนียม/ค่าดำเนินการ',
              template: 'DocPurchaseCash',
              options: {docType: 'fee'}
            },
            {
              title: 'ค่าเช่าอื่นๆ',
              template: 'DocPurchaseCash',
              options: {docType: 'rnt'}
            },
            {
              title: 'ค่าสวัสดิการพนักงาน',
              template: 'DocPurchaseCash',
              options: {docType: 'wel'}
            },
            {
              title: 'ค่าใช้จ่ายขาย',
              template: 'DocPurchaseCash',
              options: {docType: 'psao'}
            },
            {
              title: 'ค่าใช้จ่ายผู้บริหาร',
              template: 'DocPurchaseCash',
              options: {docType: 'pbao'}
            },
            {
              title: 'ค่าขนส่งนอก',
              template: 'DocPurchaseCash',
              options: {docType: 'plgo'}
            },
            {
              title: 'ค่าส่งเสริมการขาย',
              template: 'DocPurchaseCash',
              options: {docType: 'pmko'}
            },
          ],
        },
        {
          title: 'Software',
          group: 'InventorySoftware',
          sections: [
            {
              title: 'ค้นหา',
              template: 'InventoryGeneralStockInfo',
              options: {inventoryType: 'software'}
            },
            {
              title: 'Software',
              template: 'InventoryGeneral',
              options: {
                inventoryType: 'software',
                stockcountTemplateType: 'InventoryStockcount',
                stockcountDocType: 'stw',
              }
            },
            {
              title: 'แจ้งเตือน',
              template: 'InventoryGeneralNotification',
              options: {inventoryType: 'software'}
            },
            {
              title: 'ใบแจ้งปัญหา',
              template: 'DocInventoryRepair',
              options: {
                docType: 'rpw',
                inventoryType: 'software'
              }
            },
            {
              title: 'นับสต๊อก',
              template: 'DocInventoryStockcount',
              options: {
                docType: 'stw',
                inventoryType: 'software'
              }
            },
            {
              title: 'รายงาน',
              template: 'InventoryGeneralReport',
              options: {inventoryType: 'software'}
            },
          ],
        }
      ]
    },
  ]
}
