import gql from 'graphql-tag'


export const LIST_VALIDATION = (templateType) => gql`query LIST_VALIDATION ($contactType: String!) {
  contacts: listContact${templateType}Validation (contactType: $contactType) {
    id type code name message
  }
}`

export const LIST_VALIDATION_LOG = (templateType) => gql`mutation LIST_VALIDATION_LOG ($contactType: String!) {
  logs: updateContact${templateType}ValidationLog (contactType: $contactType) {
    id contactType type level
    name dueAt
    contactId contact {
      id type code name
    }
  }
}`
