<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-4"
        v-model="searchFilter.startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.searchFilter.startDate.$dirty && $v.searchFilter.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-4"
        v-model="searchFilter.endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.searchFilter.endDate.$dirty && $v.searchFilter.endDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-text
        class="col-6 col-sm-4"
        v-model="searchFilter.search"
        label="ตัวกรอง">
      </sgv-input-text>
    </div>

    รายงาน
    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          label="สรุป"
          inline
          v-model="optionsFilter.isSummary">
        </sgv-input-check>

        <sgv-input-check
          label="รายเอกสาร"
          inline
          v-model="optionsFilter.isDoc">
        </sgv-input-check>
      </div>
    </div>

    <ListReportPrinter
      class="mb-3"
      :templateType="templateType"
      :docType="docType"
      @search="fetchData"
      @print="printData"
      @download="downloadData">
      ค้นหา
    </ListReportPrinter>

    <div v-if="isFetching">
      กำลังค้นหา...
    </div>

    <div v-else>
      <ListReportLedgerSummary
        class="mt-3"
        v-if="report.summary.length > 0"
        :items="report.summary">
      </ListReportLedgerSummary>

      <ListReportLedgerDoc
        class="mt-3"
        v-if="report.doc.length > 0"
        :items="report.doc">
      </ListReportLedgerDoc>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import { LIST_REPORT_LEDGER } from '../graph/report'
import ListReportPrinter from './ListReportPrinter'
import ListReportLedgerSummary from './ListReportLedgerSummary'
import ListReportLedgerDoc from './ListReportLedgerDoc'


export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      report: {
        summary: [],
        doc: []
      },
      searchFilter: {
        startDate: '',
        endDate: '',
        search: '',
      },
      optionsFilter: {
        isDoc: false,
        isSummary: false,
      },
      isFetching: false,
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportLedger`,
      rFields: ['searchFilter', 'optionsFilter'],
      url: `/doc/${this.docType}/print/report/ledger`
    }
  },
  validations: {
    searchFilter: {
      startDate: {required},
      endDate: {required}
    }
  },
  methods: {
    serializeInput () {
      const filter = {...this.searchFilter}

      const options = {}

      if (this.optionsFilter.isSummary) {
        options.isSummary = true
      }

      if (this.optionsFilter.isDoc) {
        options.isDoc = true
      }

      return {filter, options}
    },
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()

      this.report.summary = []
      this.report.doc = []

      this.isFetching = true

      this.$apollo.query({
        query: LIST_REPORT_LEDGER(this.templateType),
        variables: {
          ...this.serializeInput(),
          docType: this.docType
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.report.summary = res.data.report.summary
        this.report.doc = res.data.report.doc
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isFetching = false
      })
    },
    downloadData () {
      const input = this.serializeInput()

      this.$axios.get(this.url, {
        params: {
          ...input.filter,
          ...input.options
        },
        responseType: 'blob'
      })
      .then(res => {
        let filename = `${this.docType}_report_ledger.pdf`
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      }).catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    printData (deviceId) {
      if (!deviceId) {
        this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
        return
      }

      const input = this.serializeInput()

      this.$axios.get(this.url, {
        params: {
          ...input.filter,
          ...input.options,
          deviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ส่งไปยังเครื่องพิมพ์")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถพิมพ์หรือบันทึกไฟล์ได้")
      })
    }
  },
  components: {
    ListReportPrinter,
    ListReportLedgerSummary,
    ListReportLedgerDoc
  }
}
</script>

<style lang="css" scoped>
</style>
