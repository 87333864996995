import gql from 'graphql-tag'


export const LIST_LABEL_INVENTORY_BARCODE_DEVICE = (templateType) => gql`query LIST_LABEL_INVENTORY_BARCODE_DEVICE ($inventoryType: String!) {
  devices: listInventory${templateType}LabelInventoryBarcodeDevice (inventoryType: $inventoryType) {
    id code name
  }
}`

export const PRINT_LABEL_INVENTORY_BARCODE = (templateType) => gql`mutation PRINT_LABEL_INVENTORY_BARCODE ($inventoryType: String!, $deviceId: Int!, $inventoryId: Int! $qty: Int!) {
  print: printInventory${templateType}LabelInventoryBarcode (inventoryType: $inventoryType, deviceId: $deviceId, inventoryId: $inventoryId, qty: $qty)
}`


export const LIST_LABEL_INVENTORY_BARCODE_DETAIL_DEVICE = (templateType) => gql`query LIST_LABEL_INVENTORY_BARCODE_DETAIL_DEVICE ($inventoryType: String!) {
  devices: listInventory${templateType}LabelInventoryBarcodeDetailDevice (inventoryType: $inventoryType) {
    id code name
  }
}`

export const PRINT_LABEL_INVENTORY_BARCODE_DETAIL = (templateType) => gql`mutation PRINT_LABEL_INVENTORY_BARCODE_DETAIL ($inventoryType: String!, $deviceId: Int!, $inventoryId: Int! $qty: Int!) {
  print: printInventory${templateType}LabelInventoryBarcodeDetail (inventoryType: $inventoryType, deviceId: $deviceId, inventoryId: $inventoryId, qty: $qty)
}`


export const LIST_LABEL_INVENTORY_BARCODE_CAUTION_DEVICE = (templateType) => gql`query LIST_LABEL_INVENTORY_BARCODE_CAUTION_DEVICE ($inventoryType: String!) {
  devices: listInventory${templateType}LabelInventoryBarcodeCautionDevice (inventoryType: $inventoryType) {
    id code name
  }
}`

export const PRINT_LABEL_INVENTORY_BARCODE_CAUTION = (templateType) => gql`mutation PRINT_LABEL_INVENTORY_BARCODE_CAUTION ($inventoryType: String!, $deviceId: Int!, $inventoryId: Int! $qty: Int!) {
  print: printInventory${templateType}LabelInventoryBarcodeCaution (inventoryType: $inventoryType, deviceId: $deviceId, inventoryId: $inventoryId, qty: $qty)
}`


export const LIST_LABEL_INVENTORY_DETAIL_DEVICE = (templateType) => gql`query LIST_LABEL_INVENTORY_DETAIL_DEVICE ($inventoryType: String!) {
  devices: listInventory${templateType}LabelInventoryDetailDevice (inventoryType: $inventoryType) {
    id code name
  }
}`

export const PRINT_LABEL_INVENTORY_DETAIL = (templateType) => gql`mutation PRINT_LABEL_INVENTORY_DETAIL ($inventoryType: String!, $deviceId: Int!, $inventoryId: Int! $qty: Int!) {
  print: printInventory${templateType}LabelInventoryDetail (inventoryType: $inventoryType, deviceId: $deviceId, inventoryId: $inventoryId, qty: $qty)
}`


export const LIST_LABEL_CONTACT_COMPANY_DEVICE = (templateType) => gql`query LIST_LABEL_CONTACT_COMPANY_DEVICE ($inventoryType: String!) {
  devices: listInventory${templateType}LabelContactCompanyDevice (inventoryType: $inventoryType) {
    id code name
  }
}`

export const PRINT_LABEL_CONTACT_COMPANY = (templateType) => gql`mutation PRINT_LABEL_CONTACT_COMPANY ($inventoryType: String!, $deviceId: Int!, $qty: Int!) {
  print: printInventory${templateType}LabelContactCompany (inventoryType: $inventoryType, deviceId: $deviceId, qty: $qty)
}`


export const LIST_LABEL_INVENTORY_CAUTION_DEVICE = (templateType) => gql`query LIST_LABEL_INVENTORY_CAUTION_DEVICE ($inventoryType: String!) {
  devices: listInventory${templateType}LabelInventoryCautionDevice (inventoryType: $inventoryType) {
    id code name
  }
}`

export const PRINT_LABEL_INVENTORY_CAUTION = (templateType) => gql`mutation PRINT_LABEL_INVENTORY_CAUTION ($inventoryType: String!, $deviceId: Int!, $qty: Int!) {
  print: printInventory${templateType}LabelInventoryCaution (inventoryType: $inventoryType, deviceId: $deviceId, qty: $qty)
}`
