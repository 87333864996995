import gql from 'graphql-tag'


const orderResponse = `
  id type name qty price totalPrice
  unitId unit {id name}
  inventoryId inventory {
    id type code name unitId
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  parentId parent {
    id docId doc {
      id code name
    }
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}Order (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`

export const LIST_ORDER_CONFIG = (templateType) => gql`query LIST_ORDER_CONFIG ($docType: String!) {
  configs: listDoc${templateType}DocConfig (docType: $docType) {
    id type name isDefault options
    inventoryId inventory {
      id type code name unitId
      unit {id name}
    }
  }
}`

export const LIST_ORDER_PAYABLE = (templateType) => gql`query ORDER_PAYABLES ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}Payable (docType: $docType, q: $q) {
    id type code name remark
    orders {
      id name total paid pending remaining
    }
  }
}`

export const CREATE_ORDER_PAYABLE = (templateType) => gql`mutation CREATE_ORDER_PAYABLE ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  createOrder: createDoc${templateType}OrderPayable (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {${orderResponse}}
}`
