<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-8"
        label="หมายเหตุ"
        :rows="1"
        autoGrow
        v-model="formData.remark">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="รายละเอียด"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row" v-if="method === 'add'">
      <DetailFormContactInput
        label="ผู้อบรม"
        placeholder="เลือกผู้อบรม..."
        :disabled="$auth.disabled(method)"
        class="col-12"
        select="id"
        v-model="formData.contactId"
        :docType="docType"
        :templateType="templateType"
        :validations="[
          {text: 'required!', value: v.formData.contactId.$dirty && v.formData.contactId.$error}
        ]">
        <template slot-scope="option">
          {{option.contact.code}} ({{option.contact.name}})
        </template>
      </DetailFormContactInput>
    </div>

    <div class="form-row" v-if="method !== 'add'">
      <sgv-input-text
        disabled
        class="col-12"
        label="ผู้อบรม"
        :value="`${formData.contact.code} (${formData.contact.name})`">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="จุดประสงค์"
        :rows="3"
        autoGrow
        v-model="formData.content"
        :validations="[
          {text: 'required!', value: v.formData.content.$dirty && v.formData.content.$error}
        ]">
      </sgv-input-textarea>

      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="สรุปผล"
        :rows="3"
        autoGrow
        v-model="formData.summary">
      </sgv-input-textarea>
    </div>

    <CategoryCheck
      :docId="docId"
      :docType="docType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      :method="method"
      :isClosed="!!formData.closedAt || !!formData.approvedAt"
      v-model="formData.categories"
      @updated="emitUpdated">
    </CategoryCheck>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-4"
        label="สร้าง"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="อนุมัติ"
        :value="getState('approved')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="จบ"
        :value="getState('closed')">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ไม่อนุมัติ"
          inline
          v-model="formData.isVoid">
        </sgv-input-check>
      </div>
    </div>
  </div>
</template>

<script>
import DetailFormContactInput from './DetailFormContactInput'
import CategoryCheck from '@/views/doc_core/components/CategoryCheck'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {

    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  components: {
    DetailFormContactInput,
    CategoryCheck
  }
}
</script>

<style lang="css">
</style>
