<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
          <router-link
            class="text-decoration-none text-secondary"
            v-if="$auth.hasRole(`doc:${docType}:config`)"
            :to="toDocConfig()">
            <small><fa icon="cog"></fa></small>
          </router-link>

          <UserManual
            class="ml-2"
            :templateType="templateType"
            :docType="docType">
          </UserManual>
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class="dashhead-toolbar-item">

        </div>
      </div>
    </div>

    <hr class="my-3">

    <ListCategory
      :docType="docType"
      :templateType="templateType"
      :group="group"
      :title="title">
    </ListCategory>
  </div>
</template>

<script>
import ListCategory from './ListCategory'
import retainMixin from '@/mixins/retain-mixin'
import UserManual from '@/views/doc_core/components/UserManual'


export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      docConfigView : `Doc${this.$form.capitalize(this.docType)}ConfigList`,
      rKey: `Doc${this.$form.capitalize(this.docType)}List`,
      rFields: []
    }
  },
  methods: {
    toDocConfig () {
      return {name: this.docConfigView}
    },
  },
  components: {
    ListCategory,
    UserManual,
  }
}
</script>

<style lang="css" scoped>
</style>
