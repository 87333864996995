export default {
  routers: [
    {
      title: 'ห้องสุมด',
      header: true,
      sections: [
        {
          title: 'QMR',
          group: 'PaperQmr',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'qmr-markdown' }
            },
            {
              title: 'คู่มือคุณภาพ (QM)',
              template: 'PaperQualityManual',
              options: { paperType: 'qmr-qualityManual' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'qmr-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'qmr-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'qmr-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'qmr-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'qmr-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'qmr-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'qmr-cpar' }
            },
            {
              title: 'CPAR LOG',
              template: 'PaperCparLog',
              options: { paperType: 'qmr-cparLog' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'qmr-internalAuditReport' }
            },
            {
              title: 'บันทึกรายงานการประชุม',
              template: 'PaperManagementReviewReport',
              options: { paperType: 'qmr-managementReviewReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'qmr-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'qmr-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'qmr-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'qmr-distributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'qmr-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'qmr-documentControl' }
            },

          ],
        },

        {
          title: 'บัญชี (AC)',
          group: 'PaperAc',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'ac-markdown' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'ac-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'ac-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'ac-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'ac-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'ac-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'ac-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'ac-cpar' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'ac-internalAuditReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'ac-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'ac-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'ac-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'ac-distributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'ac-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'ac-documentControl' }
            },

          ],
        },

        {
          title: 'บุคคล (HR)',
          group: 'PaperHr',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'hr-markdown' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'hr-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'hr-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'hr-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'hr-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'hr-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'hr-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'hr-cpar' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'hr-internalAuditReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'hr-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'hr-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'hr-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'hr-distributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'hr-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'hr-documentControl' }
            },

          ],
        },

        {
          title: 'จัดซื้อ (PU)',
          group: 'PaperPu',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'pu-markdown' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'pu-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'pu-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'pu-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'pu-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'pu-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'pu-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'pu-cpar' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'pu-internalAuditReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'pu-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'pu-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'pu-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'pu-distributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'pu-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'pu-documentControl' }
            },

          ],
        },

        {
          title: 'ขาย (SA)',
          group: 'PaperSa',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'sa-markdown' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'sa-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'sa-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'sa-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'sa-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'sa-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'sa-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'sa-cpar' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'sa-internalAuditReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'sa-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'sa-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'sa-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'sa-distributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'sa-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'sa-documentControl' }
            },

          ],
        },

        {
          title: 'คลังสินค้า (ST)',
          group: 'PaperSt',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'st-markdown' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'st-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'st-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'st-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'st-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'st-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'st-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'st-cpar' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'st-internalAuditReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'st-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'st-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'st-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'st-distributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'st-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'st-documentControl' }
            },

          ],
        },

        {
          title: 'จัดส่งสินค้า (LG)',
          group: 'PaperLg',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'lg-markdown' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'lg-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'lg-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'lg-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'lg-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'lg-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'lg-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'lg-cpar' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'lg-internalAuditReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'lg-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'lg-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'lg-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'lg-distributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'lg-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'lg-documentControl' }
            },

          ],
        },

        {
          title: 'ผลิต (PD)',
          group: 'PaperPd',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'pd-markdown' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'pd-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'pd-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'pd-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'pd-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'pd-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'pd-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'pd-cpar' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'pd-internalAuditReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'pd-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'pd-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'pd-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'pd-distributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'pd-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'pd-documentControl' }
            },

          ],
        },

        {
          title: 'ควบคุมผลิต (QC)',
          group: 'PaperQc',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'qc-markdown' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'qc-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'qc-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'qc-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'qc-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'qc-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'qc-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'qc-cpar' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'qc-internalAuditReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'qc-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'qc-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'qc-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'qc-distributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'qc-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'qc-documentControl' }
            },

          ],
        },

        {
          title: 'ออกแบบ (DS)',
          group: 'PaperDs',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'ds-markdown' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'ds-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'ds-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'ds-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'ds-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'ds-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'ds-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'ds-cpar' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'ds-internalAuditReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'ds-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'ds-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'ds-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'ds-distributionList' }
            },
            {
              title: 'นำจ่ายแบบ Drawing',
              template: 'PaperDrawingDistributionList',
              options: { paperType: 'ds-drawingDistributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'ds-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'ds-documentControl' }
            },

          ],
        },

        {
          title: 'ซ่อมบำรุง (MT)',
          group: 'PaperMt',
          sections: [
            {
              title: 'ทั่วไป',
              template: 'PaperMarkdown',
              options: { paperType: 'mt-markdown' }
            },
            {
              title: 'ระเบียบปฏิบัติ (QP)',
              template: 'PaperQualityProcedure',
              options: { paperType: 'mt-qualityProcedure' }
            },
            {
              title: 'เอกสารสนับสนุน (SD)',
              template: 'PaperSupportingDocument',
              options: { paperType: 'mt-supportingDocument' }
            },
            {
              title: 'คู่มือการทำงาน (WI)',
              template: 'PaperWorkInstruction',
              options: { paperType: 'mt-workInstruction' }
            },
            {
              title: 'ประเมินความเสี่ยง',
              template: 'PaperRiskAssessment',
              options: { paperType: 'mt-riskAssessment' }
            },
            {
              title: 'ประเมินโอกาส',
              template: 'PaperOpportunity',
              options: { paperType: 'mt-opportunity' }
            },
            {
              title: 'Check List',
              template: 'PaperCheckList',
              options: { paperType: 'mt-checkList' }
            },
            {
              title: 'CPAR',
              template: 'PaperCpar',
              options: { paperType: 'mt-cpar' }
            },
            {
              title: 'รายงานตรวจติดตามภายใน',
              template: 'PaperInternalAuditReport',
              options: { paperType: 'mt-internalAuditReport' }
            },
            {
              title: 'แผนกิจกรรม',
              template: 'PaperActionPlan',
              options: { paperType: 'mt-actionPlan' }
            },
            {
              title: 'สรุปผล / KPI',
              template: 'PaperTargetGraph',
              options: { paperType: 'mt-targetGraph' }
            },
            {
              title: 'DAR',
              template: 'PaperDocumentActionRequest',
              options: { paperType: 'mt-documentActionRequest' }
            },
            {
              title: 'ใบนำจ่ายเอกสาร',
              template: 'PaperDistributionList',
              options: { paperType: 'mt-distributionList' }
            },
            {
              title: 'ใบบันทึกการ Load เอกสาร',
              template: 'PaperAppDistributionList',
              options: { paperType: 'mt-appDistributionList' }
            },
            {
              title: 'ควบคุมและทำลายเอกสาร',
              template: 'PaperDocumentControl',
              options: { paperType: 'mt-documentControl' }
            },

          ],
        }
      ]
    }
  ]
}