<template>
  <sgv-table
    :rKey="rKey"
    :items="orders"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden, idx}">
      <tr class="">
        <td v-if="hidden.name">
          <DetailOrderGroupInletModal
            :templateType="templateType"
            :inventoryCostType="inventoryCostType"
            :inventoryCostId="inventoryCostId"
            :inventoryCostOrderId="item.id"
            :item="item"
            @change="calculateData">
          </DetailOrderGroupInletModal>
          {{idx+1}}.
          <span v-if="item.inventory">
            {{item.inventory.code}}
            <small>
              <div class="text-primary">{{item.inventory.name}}</div>
            </small>
          </span>
          <span v-else>{{item.name}}</span>

          <span v-if="item.parent">
            <small>
              <div class="text-warning">
                {{item.parent.inventoryCost.code}} ({{item.parent.inventoryCost.name}})
              </div>
            </small>
          </span>

        </td>
        <td v-if="hidden.qty">
          {{item.qty | comma}}
          <span v-if="item.inventory">{{item.inventory.unit.name}}</span>
          <span v-else>{{item.unit.name}}</span>
        </td>
        <td v-if="hidden.stdPrice">
          {{item.stdPrice | comma}}
        </td>
        <td v-if="hidden.totalPrice">
          {{item.totalPrice | comma}}
        </td>
        <td v-if="hidden.inputPredictPercent">
          <span v-if="item.inputPredictPercent">
            <span class="text-info">{{item.inputPredictPercent}}</span>
            ->
          </span>
          <span class="text-info">{{item.inputPredictPrice | comma}}</span>
        </td>
        <td v-if="hidden.inputDocPercent">
          <span v-if="item.inputDocPercent">
            <span class="text-info">{{item.inputDocPercent}}</span>
            ->
          </span>
          <span class="text-info">{{item.inputDocPrice | comma}}</span>
          <div v-if="item.doc">
            <router-link
              :to="toDoc(item.doc)"
              class="text-decoration-none text-warning">
              <small style="white-space: nowrap;">
                {{item.doc.code}}
              </small>
            </router-link>
          </div>
        </td>
      </tr>
    </template>

    <button
      slot="action"
      type="button"
      class="btn btn-link text-decoration-none"
      v-if="$auth.hasRole(`inventoryCost:${inventoryCostType}:add`)">
      Input
      <DetailOrderGroupInletModal
        :templateType="templateType"
        :inventoryCostType="inventoryCostType"
        :inventoryCostId="inventoryCostId"
        @change="calculateData">
      </DetailOrderGroupInletModal>
    </button>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailOrderGroupInletModal from './DetailOrderGroupInletModal'
import {
  LIST_INVENTORY_COST_ORDER_INLET,
  WATCH_INVENTORY_COST_CALCULATED,
  CALCULATE_INVENTORY_COST
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    inventoryCostType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryCostId: {
      type: Number,
      required: true
    },
  },
  data () {
    return {
      rKey: `InventoryCost${this.$form.capitalize(this.inventoryCostType)}DetailOrderGroupInlet`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'ชื่อ', value: 'name'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา', value: 'stdPrice'},
        {text: 'มูลค่า', value: 'totalPrice'},
        {text: 'simulate', value: 'inputPredictPercent'},
        {text: 'เอกสาร', value: 'inputDocPercent'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name', 'qty', 'stdPrice', 'totalPrice', 'inputPredictPercent', 'inputDocPercent'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_INVENTORY_COST_ORDER_INLET(this.templateType)
      },
      variables () {
        return {
          inventoryCostType: this.inventoryCostType,
          inventoryCostId: this.inventoryCostId
        }
      },
      fetchPolicy: 'no-cache'
    },
    $subscribe: {
      inventoryCostCalculated: {
        query () {
          return WATCH_INVENTORY_COST_CALCULATED(this.templateType)
        },
        variables () {
          return {
            inventoryCostType: this.inventoryCostType,
            inventoryCostId: this.inventoryCostId
          }
        },
        result () {
          this.refetch()
        },
      },
    },
  },
  methods: {
    refetch () {
      this.$apollo.queries.orders.refetch()
    },
    calculateData () {
      this.$apollo.mutate({
        mutation: CALCULATE_INVENTORY_COST(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          inventoryCostId: this.inventoryCostId
        }
      })
      .then(() => {
        this.refetch()
      })
      .catch(this.$toasted.global.error)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
  },
  watch: {
    'options.headers': 'setRetaining'
  },
  components: {
    DetailOrderGroupInletModal
  }
}
</script>

<style lang="css" scoped>
</style>
