<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6 col-md-4"
        v-model="startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6 col-md-4"
        v-model="endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-text
        class="col-md-4"
        v-model="search"
        label="ตัวกรอง">
      </sgv-input-text>
    </div>

    <button class="btn btn-warning mb-3" @click="fetchData">
      ค้นหา
    </button>

    <ReportStockBalanceCsv
      class="mb-3 ml-2"
      v-if="items.length > 0"
      :inventoryType="inventoryType"
      :startDate="startDate"
      :endDate="endDate"
      :items="items">
    </ReportStockBalanceCsv>

    <div class="table-responsive" v-if="items">
      <table class="table">
        <thead>
          <tr>
            <th>สินค้า</th>
            <th>
              เข้า
              <div class="text-success">
                {{summary.balanceIn | comma}}
              </div>
            </th>
            <th>
              ออก
              <div class="text-danger">
                {{summary.balanceOut | comma}}
              </div>
            </th>
            <th>
              คงเหลือ
              <div class="">
                {{summary.balanceCurrent | comma}}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i">
            <td class="pointer">
              <ReportStockBalanceByIntervalModal
                :inventoryId="item.inventoryId"
                :inventoryType="inventoryType"
                :templateType="templateType"
                :title="`${item.inventoryCode} (${item.inventoryName})`"
                :startDate="startDate"
                :endDate="endDate">
                {{item.inventoryCode}}<br>
                <small class="text-primary">{{item.inventoryName}}</small>
              </ReportStockBalanceByIntervalModal>
            </td>
            <td class="text-success">
              {{item.balanceIn}} {{item.inventoryUnit}}
            </td>
            <td class="text-danger">
              {{item.balanceOut}} {{item.inventoryUnit}}
            </td>
            <td>
              {{item.balanceCurrent}} {{item.inventoryUnit}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import { LIST_REPORT_STOCK_BALANCE_BY_INTERVAL } from '../graph/report'
import ReportStockBalanceCsv from './ReportStockBalanceCsv.vue'
import ReportStockBalanceByIntervalModal from './ReportStockBalanceByIntervalModal.vue'


export default {
  components: {
    ReportStockBalanceCsv,
    ReportStockBalanceByIntervalModal
  },
  mixins: [retainMixin],
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      startDate: '',
      endDate: '',
      search: '',
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}ReportStockBalanceByInterval`,
      rFields: ['startDate', 'endDate']
    }
  },
  validations: {
    startDate: {required},
    endDate: {required}
  },
  computed: {
    summary () {
      return this.items.reduce((t,v) => {
        t.balanceIn += v.balanceIn
        t.balanceOut += v.balanceOut
        t.balanceCurrent += v.balanceCurrent
        return t
      }, {balanceIn: 0, balanceOut: 0, balanceCurrent: 0})
    }
  },
  methods: {
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()
      this.items = []
      this.$apollo.query({
        query: LIST_REPORT_STOCK_BALANCE_BY_INTERVAL(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          startDate: this.startDate,
          endDate: this.endDate,
          search: this.search
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.balances
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
