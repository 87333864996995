<template>
  <div>
    <sgv-table
      :items="plannings"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">

      <sgv-input-month
        slot="action"
        v-model="selectedMonth">
      </sgv-input-month>

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.date">
            {{item.date | date}}
            <div v-if="item.crons.length > 0" class="mt-2">
              <button
                type="button"
                class="btn btn-sm btn-success px-3"
                @dblclick="createDocByCron(item.date)">
                <fa icon="plus"></fa>
              </button>
            </div>
          </td>
          <td v-if="hidden.crons">
            <ul class="list-unstyled mb-0">
              <li v-for="(cron,i) in item.crons" :key="i">
                <div style="white-space: nowrap">
                  {{cron.name}}
                </div>
                <div style="white-space: nowrap">
                  <small class="text-info">{{cron.measurementTemplateName}}</small>
                </div>
              </li>
            </ul>
          </td>
          <td v-if="hidden.docs">
            <ul class="list-unstyled mb-0">
              <li v-for="doc in item.docs" :key="doc.id">
                <div style="white-space: nowrap">
                  <router-link
                    class="text-decoration-none"
                    :to="toDoc(doc)">
                    {{doc.code}}
                  </router-link>
                  ({{doc.name}})
                </div>
                <div v-if="doc.measurementName" style="white-space: nowrap">
                 <small class="text-info">{{doc.measurementName}}</small>
                </div>
                <div v-if="doc.remark">
                  <small class="text-primary">{{doc.remark}}</small>
                </div>
              </li>
            </ul>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_PLANNING, CREATE_DOC_BY_CRON } from './graph'


export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      title: 'ตารางเวลา',
      subTitle: 'การตรวจสภาพ / แจ้งซ่อม',
      headers: [
        {text: 'วันที่', value: 'date', sort: false, filter: false},
        {text: 'รายการ', value: 'crons', sort: false, filter: false},
        {text: 'เอกสาร', value: 'docs', sort: false, filter: false}
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['date', 'crons', 'docs'],
        column: null,
        search: null,
        toolbar: null,
      },
      rKey: `Doc${this.$form.capitalize(this.docType)}ListByPlanning`,
      rFields: ['options', 'selectedMonth'],
      selectedMonth: '',
      items: [],
      docs: []
    }
  },
  computed: {
    today () {
      return this.$date.current().date
    },
    startOfMonth () {
      if (!this.selectedMonth) return null

      const d = new Date(this.selectedMonth + '-01')
      return this.$date.format(d).startOfMonth
    },
    endOfMonth () {
      if (!this.selectedMonth) return null

      const d = new Date(this.selectedMonth + '-01')
      return this.$date.format(d).endOfMonth
    },
  },
  apollo: {
    plannings: {
      query () {
        return LIST_PLANNING(this.templateType)
      },
      variables () {
        this.setRetaining()
        return {
          docType: this.docType,
          startDate: this.startOfMonth,
          endDate: this.endOfMonth
        }
      },
      skip () {
        return !this.selectedMonth
      },
      fetchPolicy: 'no-cache',
      debounce: 150
    }
  },
  methods: {
    toDoc (item) {
      return {
        name: `Doc${this.$form.capitalize(item.type)}Detail`,
        params: {docId: item.id},
        query: {redirect: this.$route.name}
      }
    },
    getStatus (item) {
      if (item.approvedAt && !item.closedAt) return ['text-warning']
      else if (item.closedAt) return ['text-success']
    },
    createDocByCron (date) {
      this.$apollo.mutate({
        mutation: CREATE_DOC_BY_CRON(this.templateType),
        variables: {
          docType: this.docType,
          startDate: date,
          endDate: date
        }
      })
      .then(() => {
        this.$apollo.queries.plannings.refetch()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>
