<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="">
          {{item.name}}
          <span v-if="item.isDefault" class="text-success">
            --- ค่าเริ่มต้น
          </span>
        </div>
        <div class="text-info" v-if="item.options && item.options.title">
          <small>ชื่อเอกสาร: {{item.options.title}}</small>
        </div>
        <div class="text-primary" v-if="item.options && item.options.contact">
          <small>ชื่อบริษัท: {{item.options.contact}}</small>
        </div>
        <div class="pre-line" v-if="item.options && item.options.address">
          <small>ที่อยู่บริษัท: {{item.options.address}}</small>
        </div>
        <div class="pre-line" v-if="item.options && item.options.taxCode">
          <small>เลขที่ประจำตัวผู้เสียภาษี: {{item.options.taxCode}}</small>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-text
            class="col-12"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-text>

          <sgv-input-text
            class="col-12"
            label="ชื่อเอกสาร"
            v-model="formData.options.title"
            :validations="[
              {text: 'required!', value: $v.formData.options.title.$dirty && !$v.formData.options.title.required}
            ]">
          </sgv-input-text>

          <sgv-input-text
            class="col-12"
            label="ชื่อบริษัท"
            v-model="formData.options.contact"
            :validations="[
              {text: 'required!', value: $v.formData.options.contact.$dirty && !$v.formData.options.contact.required}
            ]">
          </sgv-input-text>

          <sgv-input-textarea
            class="col-12"
            label="ที่อยู่บริษัท"
            v-model="formData.options.address"
            :validations="[
              {text: 'required!', value: $v.formData.options.address.$dirty && !$v.formData.options.address.required}
            ]">
          </sgv-input-textarea>

          <sgv-input-text
            class="col-12"
            label="เลขที่ประจำตัวผู้เสียภาษี"
            v-model="formData.options.taxCode"
            :validations="[
              {text: 'required!', value: $v.formData.options.taxCode.$dirty && !$v.formData.options.taxCode.required}
            ]">
          </sgv-input-text>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        name: '',
        options: {
          title: '',
          contact: '',
          address: '',
          taxCode: '',
        }
      }
    }
  },
  validations: {
    formData: {
      name: {required},
      options: {
        title: {required},
        contact: {required},
        address: {required},
        taxCode: {required},
      }
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.$v.formData.$reset()
          this.toggle = false
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.name = this.item.name
          this.formData.options.title = this.item.options?.title || ''
          this.formData.options.contact = this.item.options?.contact || ''
          this.formData.options.address = this.item.options?.address || ''
          this.formData.options.taxCode = this.item.options?.taxCode || ''
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      } else {
        this.formData.name = ''
        this.formData.options.title = ''
        this.formData.options.contact = ''
        this.formData.options.address = ''
        this.formData.options.taxCode = ''
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
