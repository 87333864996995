<template>
  <tr
    @dblclick.stop="emitInput(item)"
    class="pointer">
    <td v-if="hidden.name">
      <span v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.approvedAt, 'text-warning': !ledger.approvedAt}">
          {{ledger.account.code}}: {{ledger.account.name}}
        </small>
        <br>
      </span>
      <span
        style="white-space: pre-line;">
        <fa
          v-if="selectedRow === item.id && canDestroy"
          icon="trash"
          class="text-danger pointer"
          @click="destroyOrder(item.id)">
        </fa>
        <span v-if="item.parentId && item.parent">
          {{row.idx+1}}. {{item.name}}
          <div class="text-primary">
            <small>
              {{item.parent.doc.code}} ---
              {{item.parent.doc.name}}
            </small>
          </div>
        </span>
        <span v-else>
          {{item.name}}
        </span>
      </span>
    </td>
    <td v-if="hidden.qty" class="text-right">
      <span v-for="ledger in item.ledgers" :key="ledger.id"><br></span>
      {{item.qty | comma(-1)}}
      {{item.unit.name}}
    </td>
    <td v-if="hidden.totalPrice" class="text-right">
      <span v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}">
          {{Math.abs(ledger.amount) | comma}}
        </small>
        <br>
      </span>
      {{item.totalPrice | comma}}
    </td>
  </tr>
</template>

<script>
import {
  DESTROY_ORDER
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    canDestroy: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    item () {
      return this.row.item
    },
    hidden () {
      return this.row.hidden
    },
    selectedRow () {
      return this.value.find(v => v === this.item.id)
    }
  },
  methods: {
    emitInput (item) {
      const found = this.value.indexOf(item.id)
      const arr = [...this.value]
      if (found === -1) {
        arr.push(item.id)
      } else {
        arr.splice(found, 1)
      }
      this.$emit('input', arr)
    },
    destroyOrder (id) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
      .then(() => {
        this.emitOrderUpdated()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitOrderUpdated () {
      this.$emit('updated', null)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
