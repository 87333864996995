<template>
  <div>
    <div class="row">
      <div class="col-12">
        <sgv-input-radio
          label="ประเภท"
          inline
          :options="addressTypes"
          select="value"
          v-model="addressType">
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-radio>
      </div>
      <div
        class="col-12"
        v-for="(addrType) in addressTypes"
        :key="addrType.value">
        <div v-if="addressType === addrType.value">
          <AddressCard
            v-for="addr in addressesByType(addrType.value)"
            :key="addr.id"
            :type="addrType.value"
            :docType="docType"
            :contactId="addr.contactId"
            :templateType="templateType"
            :printBranch="printBranch"
            :item="addr"
            class="mb-3">
          </AddressCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import AddressCard from './AddressCard'
import {
  LIST_ADDRESS_TYPE,
  LIST_ADDRESS
} from '../graph/address'

export default {
  mixins: [retainMixin],
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    printBranch: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}Address`,
      rFields: ['addressType'],
      addressTypes: [],
      addressType: 'main',
      addresses: []
    }
  },
  apollo: {
    addressTypes: {
      query () {
        return LIST_ADDRESS_TYPE(this.templateType)
      },
      variables () {
        return {docType: this.docType}
      },
      fetchPolicy: 'no-cache'
    },
    addresses: {
      query () {
        return LIST_ADDRESS(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    addressesByType (type) {
      return this.addresses.filter(v => v.type === type)
    }
  },
  watch: {
    addressType () {
      this.setRetaining()
    }
  },
  components: {
    AddressCard
  }
}
</script>

<style lang="css">
</style>
