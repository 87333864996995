import gql from 'graphql-tag'


export const LIST_ATTACHMENT = (templateType) => gql`query LIST_ATTACHMENT ($contactType: String!, $type: String!, $contactId: Int!) {
  items: listContact${templateType}Attachment (contactType: $contactType, type: $type, contactId: $contactId) {
    id filename thumbnail
  }
}`

export const DETAIL_ATTACHMENT = (templateType) => gql`query DETAIL_ATTACHMENT ($contactType: String!, $type: String!, $attachmentId: Int!, $isDownload: Boolean) {
  url: detailContact${templateType}Attachment (contactType: $contactType, type: $type, attachmentId: $attachmentId, isDownload: $isDownload)
}`

export const CREATE_ATTACHMENT = (templateType) => gql`mutation CREATE_ATTACHMENT ($contactType: String!, $type: String!, $contactId: Int!, $input: ContactAttachmentInput!) {
  createItem: createContact${templateType}Attachment (contactType: $contactType, type: $type, contactId: $contactId, input: $input)
}`

export const DESTROY_ATTACHMENT = (templateType) => gql`mutation DESTROY_ATTACHMENT ($contactType: String!, $type: String!, $attachmentId: Int!) {
  deleteItem: destroyContact${templateType}Attachment (contactType: $contactType, type: $type, attachmentId: $attachmentId)
}`
