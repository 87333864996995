<template>
  <div class="form-group">
    <label v-if="label" :for="uid">
      {{ label }}
    </label>

    <slot name="label"></slot>

    <select
      :id="uid"
      class="custom-select custom-height"
      :class="{'is-invalid': validate.value}"
      v-model="selected"
      v-bind="$attrs"
      v-on="listeners">
      <option :value="null" selected v-if="placeholder">{{ placeholder }}</option>
      <option v-for="(option, i) in optionsByRole" :value="optionByKey(option)" :key="i">
        <slot v-bind="option">{{ option }}</slot>
      </option>
    </select>

    <div class="invalid-feedback">
      {{ validate.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SgvInputSelect',
  props: {
    options: {
      type: Array,
      required: true
    },
    select: {
      type: String,
      required: false
    },
    value: {
      type: [Array, Object, String, Number],
      required: false
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    focus: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      uid: this.$form.uid(),
      selected: null
    }
  },
  computed: {
    validate () {
      return this.validations.find(v => v.value) || {value: false, text: ''}
    },
    listeners () {
      return {
        ...this.$listeners,
        input: () => {},
        change: () => {}
      }
    },
    optionsByRole () {
      return this.options.filter(v => this.$auth.hasRole(v.role))
    }
  },
  methods: {
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    },
    setFocus () {
      let el = document.getElementById(this.uid)
      el.focus()
    }
  },
  watch: {
    selected(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    value: {
      handler (value) {
        this.selected = value
      },
      immediate: true
    },
    focus (value) {
      if (value) this.setFocus()
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.focus) this.setFocus()
    })
  }
}
</script>

<style lang="css" scope>
.custom-height {
  max-height: 35.5px;
}
</style>
