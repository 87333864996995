<template>
  <div @click="showModal">
    <slot></slot>

    <b-modal
      v-model="toggle"
      :title="title"
      ok-only
      ok-title="ปิด">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Stock Id</th>
              <th>จำนวน</th>
              <th>เข้าสะสม</th>
              <th>ออกสะสม</th>
              <th>คงเหลือ</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in items">
              <tr :key="i + '-1'">
                <td>
                  {{item.id}}
                </td>
                <td>
                  <span :class="{'text-success': item.qty > 0, 'text-danger': item.qty < 0}">
                    {{item.qty}}
                  </span>
                  {{item.inventoryUnit}}
                </td>
                <td class="text-success">
                  {{item.balanceIn}} {{item.inventoryUnit}}
                </td>
                <td class="text-danger">
                  {{item.balanceOut}} {{item.inventoryUnit}}
                </td>
                <td>
                  {{item.balanceCurrent}} {{item.inventoryUnit}}
                </td>
              </tr>
              <tr :key="i + '-2'">
                <td colspan="5">
                  <div>
                    <small>
                      เอกสาร:
                      <router-link
                        class="text-decoration-none"
                        :to="toDoc(item)">
                        <span class="text-primary">
                          {{item.docCode}}
                        </span>
                      </router-link>
                    </small>
                  </div>
                  <div v-if="item.approvedAt">
                    <small >
                      อนุมัติ:
                      {{item.approvedAt | datetime}} {{item.approvedUser}}
                    </small>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { LIST_REPORT_STOCK_BALANCE_BY_INVENTORY } from '../graph/report'


export default {
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      toggle: false
    }
  },
  methods: {
    fetchData () {
      this.items = []
      this.$apollo.query({
        query: LIST_REPORT_STOCK_BALANCE_BY_INVENTORY(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          startDate: this.startDate,
          endDate: this.endDate,
          inventoryId: this.inventoryId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.balances
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    toDoc (item) {
      return {
        name: `Doc${this.$form.capitalize(item.docType)}Detail`,
        params: {docId: item.docId}
      }
    },
    showModal () {
      this.toggle = true
    }
  },
  watch: {
    toggle (v) {
      if (v) {
        this.fetchData()
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
