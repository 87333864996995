import gql from 'graphql-tag'

export const LIST_ATTENDANCE = (templateType) => gql`query LIST_ATTENDANCE ($contactType: String!, $contactId: Int!, $month: String!) {
  attendances: listContact${templateType}Attendance (contactType: $contactType, contactId: $contactId, month: $month) {
    id attendedDate late ot
    inAt outAt otInAt otOutAt
    description remark tag
    options {isNight isMixer}
    contactId contact {id code name}
    inUpdatedBy inUpdatedUser {id name}
    outUpdatedBy outUpdatedUser {id name}
  }
}`

export const LIST_ATTENDANCE_ACTION_PLAN = (templateType) => gql`query LIST_ATTENDANCE_ACTION_PLAN ($contactType: String!, $contactId: Int!, $tags: [String!]!, $month: String!) {
  actionPlans: listContact${templateType}AttendanceActionPlan (contactType: $contactType, contactId: $contactId, tags: $tags, month: $month) {
    tag name date
  }
}`

export const UPDATE_ATTENDANCE_IN_AT = (templateType) => gql`mutation UPDATE_ATTENDANCE_IN_AT ($contactType: String!, $input: Contact${templateType}AttendanceInAtInput!) {
  update: updateContact${templateType}AttendanceInAt (contactType: $contactType, input: $input) {id}
}`

export const UPDATE_ATTENDANCE_OUT_AT = (templateType) => gql`mutation UPDATE_ATTENDANCE_OUT_AT ($contactType: String!, $input: Contact${templateType}AttendanceOutAtInput!) {
  update: updateContact${templateType}AttendanceOutAt (contactType: $contactType, input: $input) {id}
}`

export const UPDATE_ATTENDANCE_REMARK = (templateType) => gql`mutation UPDATE_ATTENDANCE_REMARK ($contactType: String!, $input: Contact${templateType}AttendanceRemarkInput!) {
  update: updateContact${templateType}AttendanceRemark (contactType: $contactType, input: $input) {id}
}`

export const LIST_ATTENDANCE_LEAVE = (templateType) => gql`query LIST_ATTENDANCE_LEAVE ($contactType: String!, $contactId: Int!, $yearAt: String!) {
  attendances: listContact${templateType}AttendanceLeave (contactType: $contactType, contactId: $contactId, yearAt: $yearAt) {
    id contactId attendedDate
    late tag description remark
  }
}`

export const DETAIL_CONTACT_WELFARE = (templateType) => gql`query DETAIL_CONTACT_WELFARE ($contactType: String!, $contactId: Int!, $yearAt: String!) {
  welfare: detailContact${templateType}Welfare (contactType: $contactType, contactId: $contactId, yearAt: $yearAt) {
    id registrationAt fillingWorkAt yearOfExperience
    welfare {
      leaveLimit sickLimit sickCertLimit
      maternityLimit absentLimit otherLimit
      broughtForwardAnnualLeaveLimit annualLeaveLimit
    }
  }
}`