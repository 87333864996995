<template>
  <span class="">
    <slot></slot>
    <span
      class="text-warning pointer px-2"
      @click="toggle = !toggle">
      <fa icon="info"></fa>
    </span>

    <b-modal v-model="toggle" :title="title">
      <div
        v-for="addr in addresses"
        :key="addr.id"
        class="mb-3">
        <h5
          :class="{'text-primary': addr.isDefault}">
          {{addr.name}}
          <span v-if="addr.isDefault"> - ค่าเริ่มต้น</span>
        </h5>
        <dl class="row mb-0 mt-2">
          <template v-for="(fData, idx) in formDataMapp(addr)">
            <dt
              class="col-3 col-sm-2 col-md-2 font-weight-normal text-right"
              :key="idx + '0'">
              {{fData.text}}:
            </dt>
            <dd
              class="col-9 col-sm-10 col-md-10 pre-line"
              :key="idx + '1'">
              {{fData.value}}
            </dd>
          </template>
        </dl>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { LIST_ADDRESS_CHILD } from '../graph/address'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    childId: {
      type: Number,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    addressType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      addresses: []
    }
  },
  methods: {
    formDataMapp (item) {
      const attributes = [
        {text: 'ผู้ติดต่อ', key: 'attendance'},
        {text: 'Email', key: 'email'},
        {text: 'โทรศัพท์', key: 'telephone'},
        {text: 'มือถือ', key: 'mobile'},
        {text: 'แฟกซ์', key: 'fax'},
        {text: 'Line App', key: 'lineApp'},
        {text: 'ขนส่ง', key: 'logistic'},
        {text: 'ยานพาหนะ', key: 'vehicle'},
        {text: 'ที่อยู่', key: 'address'},
        {text: 'พิมพ์', key: 'addressTag'}
      ]

      return attributes
      .filter(attr => item[attr.key])
      .map(attr => {
        let value = item[attr.key]

        if (attr.key === 'logistic') {
          value = `${item[attr.key].code} (${item[attr.key].name})`
        }

        if (attr.key === 'vehicle') {
          value = `${item[attr.key].code} (${item[attr.key].name})`
        }

        return {
          text: attr.text,
          value
        }
      })
    },
    dataDetail () {
      this.$apollo.query({
        query: LIST_ADDRESS_CHILD(this.templateType),
        variables: {
          contactType: this.contactType,
          addressType: this.addressType,
          childId: this.childId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.addresses = res.data.addresses
      })
    }
  },
  watch: {
    toggle (value) {
      if (value) this.dataDetail()
    }
  }
}
</script>

<style lang="css" scoped>
</style>
