export default {
  routers: [
    {
      title: 'website',
      header: true,
      sections: [
        {
          title: 'Chronowoodth',
          group: 'Chronowoodth',
          sections: [
            {
              title: 'โปรโมชั่น',
              template: 'ChronowoodthPromotion',
              options: {
                promotionType: 'promotion',
                assetType: 'general',
              }
            },
            {
              title: 'สินค้า',
              template: 'ChronowoodthProduct',
              options: {
                productType: 'product',
                assetType: 'general',
                priceType: 'product'
              }
            },
            {
              title: 'บริการ',
              template: 'ChronowoodthProduct',
              options: {
                productType: 'service',
                assetType: 'general',
                priceType: 'product'
              }
            },
            {
              title: 'โปรเจค',
              template: 'ChronowoodthProject',
              options: {
                projectType: 'product',
                assetType: 'general',
                productType: 'product'
              }
            },
            {
              title: 'ราคา',
              template: 'ChronowoodthPrice',
              options: {
                priceType: 'product',
                unitType: 'product'
              }
            },
            {
              title: 'หน่วย',
              template: 'ChronowoodthUnit',
              options: {
                unitType: 'product',
              }
            },
            {
              title: 'Log',
              template: 'ChronowoodthLog',
              options: {
                logType: 'chronowoodth'
              }
            },
            {
              title: 'Asset',
              template: 'ChronowoodthAsset',
              options: {
                assetType: 'general'
              }
            },
          ],
        }
      ]
    },
  ]
}
