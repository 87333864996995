import { capitalize } from '@/libs/form'


export default ({docType, title, subTitle, group}) => {
  const templateType = 'SalePriceList'
  return [
    {
      path: `doc/${docType}/config`,
      name: `Doc${capitalize(docType)}ConfigList`,
      component: () => import('../DocConfig.vue'),
      props: () => ({
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}`,
      name: `Doc${capitalize(docType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}/contact/:docConfigId`,
      name: `Doc${capitalize(docType)}DetailContact`,
      component: () => import('../DetailContact.vue'),
      props: (route) => ({
        docConfigId: Number(route.params.docConfigId),
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}/group/:docConfigId`,
      name: `Doc${capitalize(docType)}DetailGroup`,
      component: () => import('../DetailGroup.vue'),
      props: (route) => ({
        docConfigId: Number(route.params.docConfigId),
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}/inventory/:type/:docConfigId`,
      name: `Doc${capitalize(docType)}DetailInventory`,
      component: () => import('../DetailInventory.vue'),
      props: (route) => ({
        docConfigId: Number(route.params.docConfigId),
        type: route.params.type,
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}/account/:type/:docConfigId`,
      name: `Doc${capitalize(docType)}DetailAccount`,
      component: () => import('../DetailAccount.vue'),
      props: (route) => ({
        docConfigId: Number(route.params.docConfigId),
        type: route.params.type,
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}/expense/:docConfigId`,
      name: `Doc${capitalize(docType)}DetailExpense`,
      component: () => import('../DetailExpense.vue'),
      props: (route) => ({
        docConfigId: Number(route.params.docConfigId),
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}/discountPrice/:docConfigId`,
      name: `Doc${capitalize(docType)}DetailDiscountPrice`,
      component: () => import('../DetailDiscountPrice.vue'),
      props: (route) => ({
        docConfigId: Number(route.params.docConfigId),
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
  ]
}
