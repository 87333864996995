import gql from 'graphql-tag'


export const DETAIL_STOCK_INFO = (templateType) => gql`query DETAIL_STOCK_INFO ($inventoryType: String!, $stockId: Int!) {
  stockInfo: detailInventory${templateType}StockInfo (inventoryType: $inventoryType, stockId: $stockId) {
    id qty isPrinted orderId remaining
    inventoryId inventory {
      id type code name address
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
    docId doc {id type code name}
    parentId parent {
      id docId doc {id type code name}
    }
    children {
      id docId doc {id type code name}
    }
  }
}`

export const LIST_LABEL_STOCK_DEVICE = (templateType) => gql`query LIST_LABEL_STOCK_DEVICE ($inventoryType: String!) {
  devices: listInventory${templateType}LabelInventoryStockDevice (inventoryType: $inventoryType) {
    id type code name
  }
}`

export const PRINT_LABEL_STOCK = (templateType) => gql`mutation PRINT_LABEL_STOCK ($inventoryType: String!, $deviceId: Int!, $stockId: Int!, $qty: Int!) {
  print: printInventory${templateType}LabelInventoryStock (inventoryType: $inventoryType, deviceId: $deviceId, stockId: $stockId, qty: $qty)
}`
