import gql from 'graphql-tag'

export const LIST_CHILDREN = (templateType) => gql`query LIST_CHILDREN ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}Children (docType: $docType, q: $q) {
    id type code name
  }
}`

export const CREATE_CHILD = (templateType) => gql`mutation CREATE_CHILD ($docType: String!, $docId: Int!, $childId: Int!) {
  createDoc${templateType}Child (docType: $docType, docId: $docId, childId: $childId) {
    parentId childId
  }
}`

export const DESTROY_CHILD = (templateType) => gql`mutation DESTROY_CHILD ($docType: String!, $docId: Int!, $childId: Int!) {
  destroyDoc${templateType}Child (docType: $docType, docId: $docId, childId: $childId) {
    parentId childId
  }
}`

export const MOVE_CHILD = (templateType) => gql`mutation MOVE_CHILD ($docType: String!, $docId: Int!, $contactId: Int!, $step: Int!) {
  moveDoc${templateType}Child (docType: $docType, docId: $docId, contactId: $contactId, step: $step) {
    parentId childId
  }
}`
