<template>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-md-4"
          label="รหัส"
          v-model="formData.code"
          :validations="[
            {text: 'required!', value: v.formData.code.$dirty && v.formData.code.$error}
          ]">
        </sgv-input-text>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-md-4"
          label="เลขที่บัตรประจำตัว"
          v-model="formData.taxCode">
        </sgv-input-text>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-md-4"
          label="รหัสบัตรพนักงาน"
          v-model="formData.tag">
        </sgv-input-text>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-12"
          label="ชื่อ"
          v-model="formData.name"
          :validations="[
            {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
          ]">
        </sgv-input-text>
      </div>

      <div class="form-row">
        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-md-2"
          label="คำนำหน้า"
          v-model="formData.prefix">
        </sgv-input-text>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-md-4"
          label="ชื่อ"
          v-model="formData.firstname">
        </sgv-input-text>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-md-2"
          label="ชื่อกลาง"
          v-model="formData.middlename">
        </sgv-input-text>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-md-4"
          label="นามสกุล"
          v-model="formData.surname">
        </sgv-input-text>
      </div>

      <div class="form-row">
        <sgv-input-check-array
          :disabled="$auth.disabled(method)"
          class="col-12"
          label="เวลาทำงาน"
          :options="attnTypes"
          v-model="formData.attendanceTypes"
          select="id"
          inline>
          <template slot-scope="option">
            <span :class="{'text-success': formData.attendanceTypes.includes(option.id)}">
              {{option.name}}
            </span>
          </template>
        </sgv-input-check-array>
      </div>

      <CategoryCheck
        :contactType="contactType"
        :templateType="templateType"
        :disabled="$auth.disabled(method)"
        :method="method"
        v-model="formData.categories">
      </CategoryCheck>

      <div class="form-row">
        <div class="form-group col-12">
          <sgv-input-check
            :disabled="$auth.disabled(method)"
            label="ใช้งาน"
            inline
            v-model="formData.isActive">
          </sgv-input-check>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryCheck from '@/views/contact_core/components/CategoryCheck.vue'

export default {
  props: {
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      attnTypes: [
        {id: 'in0700', name: 'เข้า 07:00'},
        {id: 'in0800', name: 'เข้า 08:00'},
        {id: 'in1900', name: 'เข้า 19:00'},
        {id: 'out0400', name: 'ออก 04:00'},
        {id: 'out1600', name: 'ออก 16:00'},
        {id: 'out1700', name: 'ออก 17:00'},
        {id: 'out1740', name: 'ออก 17:40'},
        {id: 'out1800', name: 'ออก 18:00'},
        {id: 'out1900', name: 'ออก 19:00'},
        {id: 'out2300', name: 'ออก 23:00'}
      ],
    }
  },
  components: {
    CategoryCheck
  }
}
</script>

<style lang="css">
</style>
