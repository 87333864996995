<template>
  <div class="form-group">
    <label v-if="label" :for="uid">
      {{ label }}
    </label>

    <slot name="label"></slot>

    <input
      :id="uid"
      type="file"
      ref="upload"
      class="form-control-file"
      :class="{'is-invalid': validate.value}"
      v-bind="$attrs"
      v-on="listeners">

    <div class="invalid-feedback">
      {{ validate.text }}
    </div>
  </div>
</template>

<script>
import Compressor from 'compressorjs'

export default {
  name: 'SgvInputFile',
  props: {
    accept: {
      type: String,
      required: false,
      default: 'application/*, audio/*, video/*, image/*'
    },
    label: {
      type: String,
      required: false,
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    focus: {
      type: Boolean,
      required: false
    },
    url: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      uid: this.$form.uid(),
      files: []
    }
  },
  computed: {
    validate () {
      return this.validations.find(v => v.value) || {value: false, text: ''}
    },
    listeners () {
      return {
        ...this.$listeners,
        change: event => {
          this.$emit('change', event)
          this.$emit('file', this.onFileChange(event))
        }
      }
    }
  },
  methods: {
    setFocus () {
      let el = document.getElementById(this.uid)
      el.focus()
    },
    onFileChange (e) {
      var fileInput = e.target.files
      this.files = []
      if (this.accept !== '*') {
        if(!this.checkType(fileInput)) return
      }
      this.compressFile(fileInput)
    },
    compressFile (files) {
      for (let key in files) {
        if (key !== 'length' && key !== 'item') {
          let file = files[key]
          let type = file.type.split("/")[0]
          let vm = this

          if (type == 'image') {
            new Compressor(file, {
              quality: 0.4,
              convertSize: 200000,
              success(res) {
                vm.files.push(res)
              }
            })
          } else {
            vm.files.push(file)
          }
        }
      }
    },
    checkType (files) {
      let arr = []
      for (let key in files) {
        if (key !== 'length' && key !== 'item') {
          let inputAccept = this.accept.split(',')
          let typeAccept = files[key].type.split('/')

          let match = inputAccept.some(v => {
            let app = v.split('/')
            if(app[0].trim() !== typeAccept[0].trim()) return false
            if(app[1].trim() == '*') return true
            if(app[1].trim() !== typeAccept[1].trim()) return false
            return true
          })
          arr.push(match)
        }
      }
      return arr.every(v => v)
    },
    upload () {
      if (!this.url) return
      if (this.files.length === 0) {
        return new Promise(resolve => resolve([]))
      }
      let formData = new FormData()

      for( var i = 0; i < this.files.length; i++ ){
        formData.append('upload', this.files[i], this.files[i].name);
      }
      return this.$axios.post(this.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        return res.data.files
      }).catch(() => {
        return []
      })
    },
    reset () {
      this.$refs.upload.value = ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.focus) this.setFocus()
    })
  },
  watch: {
    files: {
      handler (value) {
        this.$emit('files', value)
      },
      immediate: true
    },
    focus (value) {
      if (value) this.setFocus()
    }
  }
}
</script>

<style lang="css">
</style>
