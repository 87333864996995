<template>
  <div>
    <div class="col-6 col-md-4 mb-3" v-for="item in images" :key="item.id">
      <img v-if="item.src" :src="item.src" style="max-height:250px;" class="img-fluid rounded">
    </div>
  </div>
</template>

<script>
import {
  LIST_ATTACHMENT,
  DETAIL_ATTACHMENT,
} from '../graph/attachment'

export default {
  props: {
    contactType: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      category: 'profile',
      items: [],
      images: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_ATTACHMENT(this.templateType)
      },
      variables () {
        return {
          contactType: this.contactType,
          type: this.type,
          contactId: this.contactId
        }
      },
      fetchPolicy: 'no-cache',
      result (res) {
        res.data.items.forEach(async v => {
          this.fetchCover(v)
          this.images.push({
            id: v.id,
            filename: v.filename,
            src: ''
          })
        })
      }
    }
  },
  methods: {
    fetchCover (item) {
      let extension = item.filename.split('.').pop()
      if (['jpg', 'jpeg', 'png', 'svg'].includes(extension.toLowerCase())) {
        return this.$apollo.query({
          query: DETAIL_ATTACHMENT(this.templateType),
          variables: {
            contactType: this.contactType,
            type: this.type,
            attachmentId: item.id
          },
          fetchPolicy: 'network-only'
        })
        .then(res => {
          const index = this.images.findIndex(v => v.id === item.id)
          if (index !== -1) {
            this.images[index].src = res.data.url
          }
        })
        .catch(() => '')
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
