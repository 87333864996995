<template>
  <div class="">
    <transition name="modal" v-if="pdf && show">
      <div class="modal" style="display: block">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">

            <div class="modal-header">
              <div class="col-12">
                <div class="row justify-content-center">
                  <div class="form-row col-12">
                    <div class="form-group col-2 text-left">
                      <button type="button" class="btn btn-primary" @click="toPrevPage">
                        <fa icon="step-backward"></fa>
                      </button>
                    </div>

                    <div class="form-group col-3">
                      <label class="sr-only">currentPage</label>
                      <input type="number" class="form-control text-right" v-model.number="currentPage">
                    </div>
                    <div class="form-group col-3">
                      <label class="sr-only">pageCount</label>
                      <input type="text" readonly class="form-control-plaintext" v-model="getPageCount">
                    </div>
                    <div class="form-group col-2">

                    </div>

                    <div class="form-group col-2 text-right">
                      <button type="button" class="btn btn-primary" @click="toNextPage">
                        <fa icon="step-forward"></fa>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-body">
              <pdf
                :src="pdf"
                :page="getCurrentPage"
                @num-pages="pageCount = $event">
              </pdf>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-block pointer" @click="show = false">กลับ</button>
            </div>

          </div>
        </div>
      </div>
    </transition>

    <div class="row justify-content-center" v-if="pdf">
      <div class="text-center col-12">
        <div 
          @click="showModal" 
          :class="{'pointer': modal}" 
          :style="{width: width}">
          <pdf
            :style="{width: width}"
            :src="pdf"
            @num-pages="pageCount = $event">
          </pdf>
        </div>
        {{pageCount}} หน้า
      </div>
    </div>

    <slot></slot>

  </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'VPdf',
  props: {
    file: {
      type: [Blob, String],
      requried: true
    },
    filename: {
      type: String,
      required: false
    },
    alt: {
      type: String,
      requried: false,
      default: 'รูปภาพ...'
    },
    modal: {
      type: Boolean,
      requried: false,
      default: true
    },
    width: {
      type: String,
      required: false,
      default: '100%'
    }
  },
  data() {
    return {
      pdf: null,
      show: false,
      currentPage: 1,
			pageCount: 0,
      loaded: false,
      modal_loaded: false
    }
  },
  computed: {
    getPageCount() {
      return ' / ' + this.pageCount + ' หน้า'
    },
    getCurrentPage() {
      if(!this.currentPage) return 0
      return this.currentPage
    }
  },
  methods: {
    toNextPage() {
      this.currentPage += 1
      if(this.currentPage > this.pageCount) return this.currentPage = this.pageCount
    },
    toPrevPage() {
      this.currentPage -= 1
      if(this.currentPage < 1) return this.currentPage = 1
    },
    showModal() {
      if (this.modal) {
        this.modal_loaded = false
        this.currentPage = 1
        this.show = true
      }
    },
    loadPdf() {
      if (this.file) {
        var vm = this
        let reader = new FileReader()
        reader.onload = (e) => {
          vm.pdf = e.target.result
        }
        reader.readAsDataURL(this.file)
      }
    },
    downloadPdf() {
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(this.file, {
        type: 'data:attachment/pdf'
      });
      a.download = this.filename || 'download';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  },
  watch: {
    file() {
      this.loadPdf()
    }
  },
  created() {
    this.loadPdf()
  },
  components: {
    pdf
  }
}
</script>

<style lang="css" scoped>
  .modal{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
    overflow: auto;
  }
  .modal-footer {
    z-index: 9000;
  }
  .modal-body {
    z-index: 3000;
  }
</style>
