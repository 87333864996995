<template>
  <div class="row">
    <div class="col-12 mb-3" v-show="map && markers.length > 0">
      <div ref="gmap" class="gmap"></div>
    </div>

    <div class="col-12">
      <div class="form-row">
        <DropdownCountryProvince
          label="จังหวัด"
          :contactType="contactType"
          :templateType="templateType"
          class="col-6 col-sm-6 col-md-4"
          select="id"
          v-model="countryFilter.countryProvinceId">
        </DropdownCountryProvince>

        <DropdownCountryDistrict
          v-if="countryFilter.countryProvinceId"
          :countryProvinceId="countryFilter.countryProvinceId"
          label="อำเภอ"
          :contactType="contactType"
          :templateType="templateType"
          class="col-6 col-sm-6 col-md-4"
          select="id"
          v-model="countryFilter.countryDistrictId">
        </DropdownCountryDistrict>

        <DropdownCountrySubdistrict
          v-if="countryFilter.countryProvinceId && countryFilter.countryDistrictId"
          :countryDistrictId="countryFilter.countryDistrictId"
          label="ตำบล"
          :contactType="contactType"
          :templateType="templateType"
          class="col-6 col-sm-6 col-md-4"
          select="id"
          v-model="countryFilter.countrySubdistrictId">
        </DropdownCountrySubdistrict>
      </div>

      <div class="form-row">
        <DropdownCountryArea
          label="พื้นที่"
          :contactType="contactType"
          :templateType="templateType"
          class="col-12 col-sm-6"
          select="id"
          v-model="countryFilter.countryAreaId">
        </DropdownCountryArea>

        <DropdownCountryAreaContact
          label="พื้นที่-ผู้ติดต่อ"
          :contactType="contactType"
          :templateType="templateType"
          class="col-12 col-sm-6"
          select="id"
          v-model="countryFilter.contactId">
        </DropdownCountryAreaContact>
      </div>

      <div class="form-row">
        <sgv-input-text
          class="col-12"
          label="ค้นหา"
          v-model="formData.search">
        </sgv-input-text>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <sgv-input-check
            label="ค่าเริ่มต้น"
            inline
            v-model="countryFilter.isDefault">
          </sgv-input-check>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-warning"
        @click="getLocation">
        ค้นหา
      </button>
    </div>

    <div class="col-12 col-md-6 mt-3">
      ค้นพบ <span class="text-success">{{markers.length}}</span> รายการ:
      <ul>
        <li
          class="mt-2"
          v-for="addr in markers"
          :key="addr.id">
          <span class="text-danger">{{addr.label}}.</span> [ <span class="text-warning">{{addr.name}}</span> ]
          {{addr.contact.code}} ({{addr.contact.branch}})

          <a
            :href="addr.url"
            target="_blank"
            class="text-decoration-none pointer">
            <fa icon="map-marked-alt" class="text-info"></fa>
          </a>

          <div class="">
            <router-link
              class="text-decoration-none"
              :to="toDetail(addr.contact)">
              {{addr.contact.name}}
            </router-link>
          </div>
        </li>
      </ul>
    </div>

    <div class="col-12 col-md-6 mt-3">
      ไม่พบตำแหน่ง <span class="text-danger">{{unLocations.length}}</span> รายการ:
      <ul>
        <li
          class="mt-2"
          v-for="addr in unLocations"
          :key="addr.id">
          [ <span class="text-warning">{{addr.name}}</span> ]
          {{addr.contact.code}} ({{addr.contact.branch}})
          <div class="">
            <router-link
              class="text-decoration-none"
              :to="toDetail(addr.contact)">
              {{addr.contact.name}}
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"
import retainMixin from '@/mixins/retain-mixin'
import DropdownCountryProvince from './DropdownCountryProvince'
import DropdownCountryDistrict from './DropdownCountryDistrict'
import DropdownCountrySubdistrict from './DropdownCountrySubdistrict'
import DropdownCountryArea from './DropdownCountryArea'
import DropdownCountryAreaContact from './DropdownCountryAreaContact'
import {
  DETAIL_GOOGLE_MAP,
  LIST_LOCATION
} from '../graph/location'

export default {
  mixins: [retainMixin],
  props: {
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Contact${this.$form.capitalize(this.contactType)}ListMap`,
      rFields: ['countryFilter', 'formData'],
      countryFilter: {
        countryProvinceId: null,
        countryDistrictId: null,
        countrySubdistrictId: null,
        countryAreaId: null,
        contactId: null,
        isDefault: false
      },
      formData: {
        search: ''
      },
      locations: [],
      map: null,
      token: null,
    }
  },
  computed: {
    markers () {
      return this.locations
      .filter(loc => loc.mapLocation)
      .map((loc,idx) => {
        const pos = loc.mapLocation.replace(',', '').replace('  ', ' ').split(' ')
        return {
          id: loc.id,
          name: loc.name,
          label: `D${idx+1}`,
          url: loc.mapUrl,
          contact: loc.contact,
          address: loc.address,
          position: {
            lat: +pos[0]?.trim() || 0,
            lng: +pos[1]?.trim() || 0,
          }
        }
      })
    },
    unLocations () {
      return this.locations.filter(loc => !loc.mapLocation)
    }
  },
  methods: {
    getLocation () {
      this.setRetaining()

      this.$apollo.query({
        query: LIST_LOCATION(this.templateType),
        variables: {
          contactType: this.contactType,
          country: this.countryFilter,
          q: {
            params: {
              search: this.formData.search
            }
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.locations = res.data.locations
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    toDetail (contact) {
      return {
        name: `Contact${this.$form.capitalize(contact.type)}Detail`,
        params: {contactId: contact.id}
      }
    },
    getToken () {
      this.$apollo.query({
        query: DETAIL_GOOGLE_MAP(this.templateType),
        variables: {
          contactType: this.contactType
        }
      })
      .then(res => {
        this.token = res.data.token
      })
    },
    setMarker (google, map, {position, label, url, contact, address}) {
      const marker = new google.maps.Marker({
        position,
        map,
        label: {
          text: label,
          color: "#FFF"
        },
        url
      })

      const infowindow = new google.maps.InfoWindow({
        content: `
          <div class="text-dark">
            <div>${contact.code} (${contact.branch || '-'})</div>
            <div>${contact.name}</div>
            <div>${address || '-'}</div>
            <div>โทร.${contact.telephone || '-'} มือถือ.${contact.mobile || '-'}</div>
          </div>
        `
      })

      google.maps.event.addListener(
        marker,
        'click',
        function() {
          infowindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          })
        }
      )

      infowindow.addListener('closeclick', function() {
        this.close()
      })

      google.maps.event.addListener(
        marker,
        'dblclick',
        function() {
          window.open(this.url)
        }
      )
    },
    generateMap (apiKey) {
      const loader = new Loader({
        apiKey,
        version: "weekly",
      })

      loader.load().then((google) => {
        const elem = this.$refs.gmap
        this.map = new google.maps.Map(elem, {
          mapTypeControl: false,
          streetViewControl: false,
          center: this.markers[0].position,
          zoom: 15,
        })

        const bounds = new google.maps.LatLngBounds()

        for (let i = 0; i < this.markers.length; i++) {
          const marker = this.markers[i]
          this.setMarker(google, this.map, marker)
          bounds.extend(marker.position)
        }

        if (this.markers.length === 1) return

        this.$nextTick(() => {
          this.map.fitBounds(bounds)
        })
      })
    }
  },
  watch: {
    markers (arr) {
      if (arr.length > 0 && this.token) {
        this.generateMap(this.token)
      }
    },
    'countryFilter.countryProvinceId': {
      handler () {
        this.countryFilter.countryDistrictId = null
        this.countryFilter.countrySubdistrictId = null
      }
    },
    'countryFilter.countryDistrictId': {
      handler () {
        this.countryFilter.countrySubdistrictId = null
      }
    },
    'countryFilter.contactId': {
      handler (v) {
        if (v) {
          this.countryFilter.countryAreaId = null
        }
      }
    },
    'countryFilter.countryAreaId': {
      handler (v) {
        if (v) {
          this.countryFilter.contactId = null
        }
      }
    },
  },
  created () {
    this.getToken()
  },
  components: {
    DropdownCountryProvince,
    DropdownCountryDistrict,
    DropdownCountrySubdistrict,
    DropdownCountryArea,
    DropdownCountryAreaContact,
  }
}
</script>

<style lang="css" scoped>
.gmap {
  height: 50vh;
}
</style>
