<template>
  <div class="">
    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :toolbars="toolbars"
      :options.sync="options">

      <DetailOrderWithholdingCsv
        slot="action"
        :items="items"
        :formData="formData">
      </DetailOrderWithholdingCsv>

      <template slot="option" v-if="options.toolbar === 'other'">
        <div class="mb-3">
          <DetailOrderWithholdingPayable
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :parentIds="parentIds">
          </DetailOrderWithholdingPayable>
        </div>
      </template>

      <template slot-scope="{item, hidden, idx}">
        <tr>
          <td v-if="hidden.id">
            <span
              v-if="!formData.approvedAt"
              class="text-danger pointer px-1"
              @click="destroyOrder(item.id)">
              <fa icon="trash"></fa>
            </span>
            <span>{{idx+1}}.</span>
          </td>
          <td v-if="hidden.closedAt">
            {{item.closedAt | date}}
            <small>
              <div>
                <router-link
                  class="text-decoration-none text-success"
                  :to="toDoc(item)">
                  {{item.code}}
                </router-link>
              </div>
              <div
                v-for="doc in mapExternalDocs(item.orders)"
                :key="doc.id">
                <router-link
                  class="text-decoration-none text-info"
                  :to="toDoc(doc)">
                  {{doc.code}}
                </router-link>
              </div>
            </small>
          </td>
          <td v-if="hidden.contact">
            {{item.contact.code}}
            <span
              v-if="isContactAddressMissing(item.contact)"
              class="text-danger px-1 blink">
              <fa icon="map-marker-alt"></fa>
            </span>
            <small>
              <div class="text-primary">
                {{item.contact.name}}
                <div v-if="item.contact.branch && item.contact.branch !== 'สำนักงานใหญ่'">
                  สาขา: {{item.contact.branch}}
                </div>
              </div>
            </small>
          </td>
          <td v-if="hidden.orders">
            <div v-for="order in item.orders" :key="order.id">
              {{order.withholdingTopic}}.
              {{order.withholdingName}}
              <small>
                <div class="text-info">
                  {{withholdingTypeText(order.withholdingType)}}
                  <span v-if="order.withholdingRemark">
                    ({{order.withholdingRemark}})
                  </span>
                </div>
              </small>
            </div>
          </td>
          <td v-if="hidden.totalPrices">
            <div v-for="order in item.totalPrices" :key="order.id">
              <span>
                {{Math.abs(order.totalPrice) | comma}}
              </span>
              <small>
                <div class="text-info">
                  {{order.withholdingPrice}} ({{order.withholdingRate}}%)
                </div>
              </small>
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import { round } from 'lodash'
import DetailOrderWithholdingPayable from './DetailOrderWithholdingPayable.vue'
import DetailOrderWithholdingCsv from './DetailOrderWithholdingCsv.vue'
import {
  LIST_ORDER_WITHHOLDING,
  DESTROY_ORDER_WITHHOLDING,
  WATCH_ORDER_CREATED,
  WATCH_ORDER_DESTROYED,
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'closedAt', 'contact', 'orders', 'totalPrices'],
        column: null,
        search: null,
        toolbar: null,
      },
      docs: [],
      withholdingTypes: [
        {text: 'หักภาษี ณ ที่จ่าย', value: 1},
        {text: 'ออกภาษีให้ครั้งเดียว', value: 2},
        {text: 'ออกให้ตลอดไป', value: 3},
        {text: 'อื่นๆ', value: 4},
      ],
    }
  },
  computed: {
    headers () {
      return [
        {text: 'ลำดับ', value: 'id'},
        {text: 'วันที่', value: 'closedAt'},
        {text: 'ผู้ติดต่อ', value: 'contact'},
        {text: `รายละเอียด`, value: 'orders'},
        {text: `ภาษีที่หัก (${this.ledgersSum})`, value: 'totalPrices'}
      ]
    },
    toolbars () {
      const arr = []

      if (!this.formData.approvedAt) {
        arr.push({value: 'other', icon: 'plus', class: 'text-success'})
      }

      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    ledgersSum () {
      const total = this.docs
      .flatMap(v => v.orders)
      .map(v => Math.abs(+v.totalPrice))
      .reduce((t,v) => t += v, 0)

      return round(total, 2)
    },
    parentIds () {
      return this.docs.map(o => o.id)
    },
    items () {
      return this.docs.map(doc => {
        return {
          id: doc.id,
          type: doc.type,
          closedAt: doc.closedAt,
          code: doc.code,
          contact: doc.contact,
          orders: doc.orders,
          totalPrices: doc.orders
        }
      })
    }
  },
  apollo: {
    docs: {
      query () {
        return LIST_ORDER_WITHHOLDING(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 250,
      fetchPolicy: 'network-only'
    },
    $subscribe: {
      orderCreated: {
        query () {
          return WATCH_ORDER_CREATED(this.templateType)
        },
        variables() {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.$apollo.queries.docs.refetch()
        },
      },
      orderDestroyed: {
        query () {
          return WATCH_ORDER_DESTROYED(this.templateType)
        },
        variables() {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.$apollo.queries.docs.refetch()
        },
      },
    }
  },
  methods: {
    mapExternalDocs (orders) {
      const arr = orders
      .filter(o => o.externalDoc)
      .map(o => o.externalDoc)
      return [...new Set(arr)]
    },
    withholdingTypeText (value) {
      return this.withholdingTypes.find(v => v.value === value)?.text || ''
    },
    getFilter (v) {
      return v
    },
    destroyOrder (parentId) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER_WITHHOLDING(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          parentIds: [parentId]
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
    isContactAddressMissing (contact) {
      return !contact.addresses[0]?.houseId
    }
  },
  watch: {
    'formData.approvedAt': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.options.toolbar = ''
        }
      }
    }
  },
  components: {
    DetailOrderWithholdingPayable,
    DetailOrderWithholdingCsv
  }
}
</script>

<style lang="css">
</style>
