<template>
  <div class="">
    <sgv-table
      :rKey="rKey"
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :toolbars="toolbars"
      :rowsPerPage="rowsPerPage">

      <template slot="option" v-if="options.toolbar === 'exportData'">
        <div class="form-group">
          <sgv-input-check
            label="ที่เก็บ"
            inline
            v-model="optionsFilter.isAddress">
          </sgv-input-check>

          <sgv-input-check
            label="บาร์โค๊ด"
            inline
            v-model="optionsFilter.isBarcode">
          </sgv-input-check>

          <sgv-input-check
            label="หมวดหมู่"
            inline
            v-model="optionsFilter.isCategory">
          </sgv-input-check>
        </div>

        <ReportPrinter
          class="mb-3"
          :inventoryType="inventoryType"
          :templateType="templateType"
          @print="printData"
          @download="downloadData">
          <ReportItemCsv
            :inventoryType="inventoryType"
            :items="items"
            class="ml-2">
          </ReportItemCsv>
        </ReportPrinter>
      </template>

      <template slot-scope="{item, hidden}">
        <tr class="">
          <td v-if="hidden.code">
            <router-link
              class="text-decoration-none no-wrap"
              :to="toDetail(item)">
              {{item.code}}
            </router-link>
          </td>
          <td v-if="hidden.name">{{item.name}}</td>
          <td v-if="hidden.unit">{{item.unit.name}}</td>
          <td v-if="hidden.address">{{item.address || ''}}</td>
          <td v-if="hidden.barcode">{{item.barcode || ''}}</td>
          <td v-if="hidden.categories">
            <div
              v-for="(category,idx) in item.categories"
              :key="category.id"
              class="no-wrap">
              {{idx+1}}. {{category.name}}
            </div>
          </td>
        </tr>
      </template>

      <button
        slot="action"
        class="btn btn-warning"
        @click="fetchData">
        <fa icon="search"></fa>
        <span class="ml-1">ค้นหา</span>
      </button>
    </sgv-table>
  </div>

</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_REPORT_ITEM } from '../graph/report'
import ReportPrinter from './ReportPrinter'
import ReportItemCsv from './ReportItemCsv'

export default {
  mixins: [retainMixin],
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}ReportItem`,
      rFields: [
        'optionsFilter',
        'filter',
        'options'
      ],
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
        {text: 'หน่วย', value: 'unit'},
        {text: 'ที่เก็บ', value: 'address'},
        {text: 'บาร์โค๊ด', value: 'barcode'},
        {text: 'หมวดหมู่', value: 'categories'},
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name', 'unit', 'address', 'barcode'],
        column: null,
        search: null,
        toolbar: null,
      },
      rowsPerPage: [
        {text: 'ทั้งหมด', value: null}
      ],

      optionsFilter: {
        isAddress: true,
        isBarcode: true,
        isCategory: true,
      },
      items: [],
      isFetching: false,
      url: `/inventory/${this.inventoryType}/print/report/item`,
      toolbars: [
        {value: 'filter', icon: 'cog', class: 'text-warning'},
        {value: 'exportData', icon: 'download', class: 'text-success'}
      ]
    }
  },
  methods: {
    serializeInput () {
      const filter = {
        search: this.options.search
      }

      const options = {}

      if (this.optionsFilter.isAddress) options.isAddress = true
      if (this.optionsFilter.isBarcode) options.isBarcode = true
      if (this.optionsFilter.isCategory) options.isCategory = true

      return {filter, options}
    },
    fetchData () {
      this.setRetaining()
      this.items = []

      this.$apollo.query({
        query: LIST_REPORT_ITEM(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          ...this.serializeInput()
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    downloadData () {
      const input = this.serializeInput()

      this.$axios.get(this.url, {
        params: {
          ...input.filter,
          ...input.options
        },
        responseType: 'blob'
      })
      .then(res => {
        let filename = `${this.inventoryType}_report_item.pdf`
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      }).catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    printData (deviceId) {
      if (!deviceId) {
        this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
        return
      }

      const input = this.serializeInput()

      this.$axios.get(this.url, {
        params: {
          ...input.filter,
          ...input.options,
          deviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ส่งไปยังเครื่องพิมพ์")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถพิมพ์หรือบันทึกไฟล์ได้")
      })
    },
    toDetail (item) {
      return {
        name: `Inventory${this.$form.capitalize(item.type)}Detail`,
        params: {inventoryId: item.id}
      }
    }
  },
  watch: {
    'options': 'setRetaining',
    'optionsFilter': {
      handler () {
        this.setRetaining()
      },
      deep: true
    }
  },
  components: {
    ReportPrinter,
    ReportItemCsv
  }
}
</script>

<style lang="css" scoped>
</style>
