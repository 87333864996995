<template>
  <div class="form-group">
    <label v-if="label" :for="uid">
      {{ label }}
    </label>

    <slot name="label"></slot>

    <input
      v-if="isNull"
      :id="uid"
      type="date"
      :placeholder="placeholder"
      class="form-control custom-height"
      :class="{'is-invalid': validate.value}"
      :value="value"
      v-bind="$attrs"
      v-on="listeners">

    <input
      v-else
      :id="uid"
      type="date"
      :placeholder="placeholder"
      class="form-control custom-height"
      :class="{'is-invalid': validate.value}"
      :value="value"
      v-bind="$attrs"
      v-on="listeners"
      onkeydown="return false">

    <div class="invalid-feedback">
      {{ validate.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SgvInputDate',
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'เลือกวันที่...'
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    focus: {
      type: Boolean,
      required: false
    },
    isNull: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      uid: this.$form.uid()
    }
  },
  computed: {
    validate () {
      return this.validations.find(v => v.value) || {value: false, text: ''}
    },
    listeners () {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.value)
        },
        change: event => {
          this.$emit('change', event.target.value)
        }
      }
    }
  },
  methods: {
    setFocus () {
      let el = document.getElementById(this.uid)
      el.focus()
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.focus) this.setFocus()
    })
  },
  watch: {
    focus (value) {
      if (value) this.setFocus()
    }
  }
}
</script>

<style lang="css" scope>
.custom-height {
  max-height: 35.5px;
  min-height: 35.5px;
}
</style>
