<template>
  <div id="app" class="mb-3">
    <router-view/>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    updateVersion () {
      const cur_ver = process.env.VUE_APP_VERSION
      const prev_ver = localStorage.getItem('version')

      if (cur_ver !== prev_ver) {
        this.removeRetaining()
      }

      localStorage.setItem('version', cur_ver)
    },
    removeRetaining () {
      let userName = localStorage.getItem(process.env.VUE_APP_USER_NAME)
      localStorage.setItem(userName, JSON.stringify({}))
    }
  },
  mounted () {
    this.updateVersion()
  }
}
</script>

<style>
button.text-decoration-none {
  /* color: inherit !important; */
  color: #cfd2da;
  text-decoration: none !important;
}

button.text-decoration-none:hover {
  /* color: inherit !important; */
  color: inherit;
}

a.text-decoration-none {
  /* color: inherit !important; */
  color: #cfd2da;
  text-decoration: none !important;
}

a.text-decoration-none:hover {
  /* color: inherit !important; */
  color: inherit;
}

a.text-decoration-none.disabled {
  pointer-events: none;
}

.noselect {
  user-select: none;
}
.pointer {
  cursor: pointer;
}
.no-wrap {
  white-space: nowrap;
}
.pre-line {
  white-space: pre-line;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.side-y-enter-active, .side-y-leave-active {
  transition: all 0.5s;
}
.side-y-enter {
  opacity: 0;
  transform: translateY(-30px);
}
.side-y-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}

.modal-open {
  overflow: hidden;
  padding-right: 0 !important;
}

pre {
  color: white !important;
}

.custom-select {
  max-height: 35.5px;
  border-width: 0px !important;
  border: 1px solid #434857 !important;
}

.custom-select:disabled {
  background-color: #30343e !important;
  color: #fff !important;
  opacity: 1 !important;
}

.blink {
  animation: blinker 1.5s ease-in-out infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
