<template>
  <sgv-table
    :rKey="rKey"
    :items="inventories"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden}">
      <tr class="">
        <td v-if="hidden.code">
          <router-link
            class="text-decoration-none no-wrap"
            :class="{'text-danger': !item.isActive}"
            :to="toDetail(item.id)">
            {{item.code}}
          </router-link>
        </td>
        <td v-if="hidden.name">{{item.name}}</td>
        <td v-if="hidden.balance">
          <div class="no-wrap">
            {{Number(item.balance.current)}}
            <span v-if="item.balance.current !== item.balance.future">
              <fa icon="long-arrow-alt-right"></fa>
              <span
                :class="{
                  'text-success': item.balance.future > item.balance.current,
                  'text-danger': item.balance.future < item.balance.current
                }">
                {{Number(item.balance.future)}}
              </span>
            </span>
            {{item.unit.name}}
          </div>
        </td>
        <td v-if="hidden.address">{{item.address}}</td>
        <td v-if="hidden.barcode">{{item.barcode}}</td>
        <td v-if="hidden.junctionParents">
          <div v-if="item.junctionParents">
            <router-link
              v-for="(row,idx) in item.junctionParents"
              :key="row.id"
              class="text-decoration-none no-wrap"
              :to="toInventory(row)">
              {{idx+1}}. {{row.code}}
              ({{Number(row.balance.current)}}) {{row.unit.name}}
              <div class="text-primary"><small>{{row.name}}</small></div>
            </router-link>
          </div>
        </td>
        <td v-if="hidden.junctionChildren">
          <div v-if="item.junctionChildren">
            <router-link
              v-for="(row,idx) in item.junctionChildren"
              :key="row.id"
              class="text-decoration-none no-wrap"
              :to="toInventory(row)">
              {{idx+1}}. {{row.code}}
              ({{Number(row.balance.current)}}) {{row.unit.name}}
              <div class="text-primary"><small>{{row.name}}</small></div>
            </router-link>
          </div>
        </td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`inventory:${inventoryType}:add`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_INVENTORY } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    categoryId: {
      type: Number,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      detailView: `Inventory${this.$form.capitalize(this.inventoryType)}Detail`,
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}ListCategory`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
        {text: 'สต๊อก', value: 'balance'},
        {text: 'ที่เก็บ', value: 'address', filter: true},
        {text: 'บาร์โค๊ด', value: 'barcode', filter: true},
        {text: 'สินค้าแม่', value: 'junctionParents'},
        {text: 'สินค้าลูก', value: 'junctionChildren'}
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name', 'balance'],
        column: null,
        search: null,
        toolbar: null,
      },
      inventories: []
    }
  },
  apollo: {
    inventories: {
      query () {
        return LIST_INVENTORY(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          inventoryType: this.inventoryType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      if (this.categoryId === -1) {
        filter.params.isActive = false
        delete filter.params.categoryId
      } else {
        filter.params.isActive = true
        filter.params.categoryId = this.categoryId
      }
      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {inventoryId: id}
      }
    },
    toInventory (item) {
      return {
        name: `Inventory${this.$form.capitalize(item.type)}Detail`,
        params: {inventoryId: item.id}
      }
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: `${this.group}`})
  }
}
</script>

<style lang="css" scoped>
</style>
