import { capitalize } from '@/libs/form'

export default ({assetType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `asset/${assetType}`,
      name: `Asset${capitalize(assetType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        assetType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `asset/${assetType}/:assetId`,
      name: `Asset${capitalize(assetType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        assetId: Number(route.params.assetId),
        templateType,
        assetType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
