<template>
  <span class="">
    <small>
      <fa
        icon="info-circle"
        class="text-secondary pointer"
        @click="showManual">
      </fa>
    </small>

    <b-modal
      :id="uid"
      title="คู่มือการใช้งาน"
      footer-class="d-none">
      <UserManualContent
        v-for="content in paper.contents"
        :key="content.id"
        :level="1"
        :paperId="paper.id"
        :content="content">
      </UserManualContent>
    </b-modal>
  </span>
</template>

<script>
import { USER_MANUAL } from './graph/user_manual'
import UserManualContent from './UserManualContent.vue'

export default {
  name: 'UserManual',
  props: {
    templateType: {
      type: String,
      required: true
    },
    inventoryStockType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      uid: this.$form.uid(),
      paper: ''
    }
  },
  methods: {
    showManual () {
      this.$apollo.query({
        query: USER_MANUAL(this.templateType),
        variables: {inventoryStockType: this.inventoryStockType}
      })
      .then(res => {
        this.paper = res.data.userManual
        this.$bvModal.show(this.uid)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  components: {
    UserManualContent
  }
}
</script>

<style lang="css" scoped>
</style>
