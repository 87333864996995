import gql from 'graphql-tag'


export const DP_JOBDESCRIPTIONS = (templateType) => gql`query DP_JOBDESCRIPTIONS ($contactType: String!, $q: FilterInput) {
  items: listContact${templateType}DropdownJobDescription (contactType: $contactType, q: $q) {
    id code position isActive
  }
}`

export const CREATE_JOBDESCRIPTION_JUNCTION = (templateType) => gql`mutation CREATE_JOBDESCRIPTION_JUNCTION ($contactType: String!, $jobDescriptionId: Int!, $contactId: Int!) {
  createJobDescriptionJunction: createContact${templateType}JobDescriptionJunction (contactType: $contactType, jobDescriptionId: $jobDescriptionId, contactId: $contactId) {
    jobDescriptionId contactId
  }
}`

export const DESTROY_JOBDESCRIPTION_JUNCTION = (templateType) => gql`mutation DESTROY_JOBDESCRIPTION_JUNCTION ($contactType: String!, $jobDescriptionId: Int!, $contactId: Int!) {
  destroyJobDescriptionJunction: destroyContact${templateType}JobDescriptionJunction (contactType: $contactType, jobDescriptionId: $jobDescriptionId, contactId: $contactId) {
    jobDescriptionId contactId
  }
}`

export const LIST_JOBDESCRIPTION = (templateType) => gql`query LIST_JOBDESCRIPTION ($contactType: String!, $contactId: Int!) {
  jobDescriptions: listContact${templateType}JobDescription (contactType: $contactType, contactId: $contactId) {
    id code position isActive
    abilities {
      id description name level isRequired
      monthExperience cron
      docs {
        id type code name isVoid remark
        createdBy approvedBy closedBy
      }
    }
  }
}`
