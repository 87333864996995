<template>
  <sgv-table
    :items="contacts"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">
    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.code">
          <router-link
            class="text-decoration-none no-wrap"
            :to="toDetail(item)">
            {{item.code}} ({{item.name}})
          </router-link>
        </td>
        <td v-if="hidden.message">
          {{item.message}}
        </td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import { LIST_VALIDATION } from '../graph/validation'

export default {
  props: {
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      detailView: `${this.group}Detail`,
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'รายละเอียด', value: 'message'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'message'],
        column: null,
        search: null,
        toolbar: null,
      },
      roles: {
        add: [],
        update: [],
        delete: []
      },
    }
  },
  apollo: {
    contacts: {
      query () {
        return LIST_VALIDATION(this.templateType)
      },
      variables () {
        return {contactType: this.contactType}
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    toDetail (item) {
      return {
        name: `Contact${this.$form.capitalize(item.type)}Detail`,
        params: {contactId: item.id}
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
