import { capitalize } from '@/libs/form'
import Notification from '../Notification.vue'


export default ({inventoryType, group, title, subTitle}) => {
  const templateType = 'General'
  return [
    {
      path: `inventory/notification/${inventoryType}`,
      name: `Inventory${capitalize(inventoryType)}Notification`,
      component: Notification,
      props: () => ({
        templateType,
        group,
        inventoryType,
        title,
        subTitle
      })
    }
  ]
}
