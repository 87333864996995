import { render, staticRenderFns } from "./ContactConfigCardDeviceLabelContactAddress.vue?vue&type=template&id=6e5b8714&scoped=true&"
import script from "./ContactConfigCardDeviceLabelContactAddress.vue?vue&type=script&lang=js&"
export * from "./ContactConfigCardDeviceLabelContactAddress.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e5b8714",
  null
  
)

export default component.exports