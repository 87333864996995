<template>
  <div>
    <div class="form-row">
      <sgv-input-month
        class="col-6"
        isNull
        v-model="year"
        :validations="[
          {text: 'required!', value: $v.year.$dirty && $v.year.$error}
        ]">
      </sgv-input-month>
    </div>

    <button 
      type="button"
      class="btn btn-warning" 
      @click="refetch">
      ค้นหา
    </button>

    <DetailAttendanceLeaveSummary 
      v-if="year"
      class="mt-3" 
      :templateType="templateType"
      :contactType="contactType"
      :contactId="contactId"
      :yearAt="yearAt"
      :items="attendances">
    </DetailAttendanceLeaveSummary>

    <sgv-table
      :items="attendances"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.attendedDate">
            {{item.attendedDate | date}}
          </td>
          <td v-if="hidden.tag">
            <span :class="getTextColor(item.tag, item.late)">
              {{getTagText(item.tag, item.late)}}
            </span>
          </td>
          <td v-if="hidden.late">
            {{item.late}}
          </td>
          <td v-if="hidden.description">
            <small>
              <div class="col-12 pre-line" v-if="item.description">
                <span class="text-info">USR:</span>
                {{item.description}}
              </div>
            </small>

            <small>
              <div class="col-12 pre-line" v-if="item.remark">
                <span class="text-success">HR:</span>
                {{item.remark}}
              </div>
            </small>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import { LIST_ATTENDANCE_LEAVE } from './graph/attendance'
import DetailAttendanceLeaveSummary from './DetailAttendanceLeaveSummary'


export default {
  mixins: [retainMixin],
  props: {
    contactId: {
      type: Number,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Contact${this.$form.capitalize(this.contactType)}DetailAttendanceLeave`,
      rFields: ['year'],
      year: '',
      attendances: [],
      tags: [
        {text: 'ไม่ระบุ', value: ''},
        {text: 'ลากิจ', value: 'leave'},
        {text: 'ลาป่วย', value: 'sick'},
        {text: 'ลาป่วย-มีใบรับรองแพทย์', value: 'sickCert'},
        {text: 'ลาพักร้อน', value: 'annualLeave'},
        {text: 'ขาดงาน', value: 'absent'},
        {text: 'ลาคลอด', value: 'maternity'},
        {text: 'อื่นๆ', value: 'other'},
      ],
      headers: [
        {text: 'วันที่', value: 'attendedDate', sort: false, filter: false},
        {text: 'ประเภท', value: 'tag', sort: false, filter: false},
        {text: 'สาย (นาที)', value: 'late', sort: false, filter: false},
        {text: 'รายละเอียด', value: 'description', sort: false, filter: false},
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['attendedDate', 'tag', 'late', 'description'],
        column: null,
        search: null,
        toolbar: null,
      },
      rowsPerPage: [
        {text: 'ทั้งหมด', value: null}
      ],
    }
  },
  validations: {
    year: { required },
  },
  computed: {
    yearAt () {
      if (!this.year) return ''

      return this.year.split('-')[0]
    }
  },
  methods: {
    refetch () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.query({
        query: LIST_ATTENDANCE_LEAVE(this.templateType),
        variables: {
          contactType: this.contactType,
          contactId: this.contactId,
          yearAt: this.yearAt,
        },
        fetchPolicy: 'no-cache',
      })
      .then(resp => {
        this.attendances = resp.data.attendances
      })
      .catch(err => this.$toasted.global.error(err))
    },
    getTextColor (tag, late) {
      if (late > 0) return ['text-warning']
      else if (tag === 'leave') return ['text-info']
      else if (tag === 'annualLeave') return ['text-success']
      else if (tag === 'sick') return ['text-warning']
      else if (tag === 'sickCert') return ['text-warning']
      else if (tag === 'absent') return ['text-danger']
      else if (tag === 'maternity') return ['text-success']
    },
    getTagText (tag, late) {
      if (late > 0) return `สาย`

      const found = this.tags.find(t => t.value === tag)

      return found?.value ? `${found.text}` : ''
    },
  },
  watch: {
    year: 'setRetaining'
  },
  components: {
    DetailAttendanceLeaveSummary
  }
}
</script>

<style scoped>

</style>