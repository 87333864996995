import { capitalize } from '@/libs/form'

export default ({roleType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `role/${roleType}`,
      name: `Role${capitalize(roleType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        roleType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `role/${roleType}/:roleId`,
      name: `Role${capitalize(roleType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        roleId: Number(route.params.roleId),
        templateType,
        roleType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
