<template>
  <sgv-input-autocomplete
    :search.sync="search"
    :display.sync="display"
    :options="options"
    @input="emitSelected"
    :validations="validations"
    v-bind="$attrs">
    <template slot-scope="option">
      {{option.name}}
    </template>
  </sgv-input-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import { LIST_DROPDOWN_UNIT } from './graph/dropdown'

export default {
  name: 'DropdownUnit',
  props: {
    inventoryCostType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    select: {
      type: String,
      required: false
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    oneWay: {
      type: Boolean,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    }
  },
  data () {
    return {
      display: null,
      options: [],
      search: ''
    }
  },
  methods: {
    getDisplay (option) {
      return `${option.name}`
    },
    emitSelected (option) {
      this.setDisplay(option)
      this.$emit('change', option)
      this.emitInput(this.optionByKey(option))
    },
    setDisplay (option) {
      if (option) this.display = this.getDisplay(option)
    },
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    },
    emitInput (value) {
      this.$emit('input', value)
    },
    refetch: debounce(function() {
      this.$apollo.query({
        query: LIST_DROPDOWN_UNIT(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          q: {
            params: {
              search: this.search
            }
          }
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.options = res.data.items
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
      })
    }, 300),
    fetchData () {
      this.$apollo.query({
        query: LIST_DROPDOWN_UNIT(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          q: {
            params: {
              id: +this.value
            }
          }
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.options = res.data.items
        this.setDisplay(res.data.items[0])
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
      })
    }
  },
  watch: {
    search () {
      this.refetch()
    },
    value: {
      handler (value) {
        if (!this.oneWay && value) {
          this.fetchData()
        } else if (!value) {
          this.display = null
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="css">
</style>
