import gql from 'graphql-tag'


const listResponse = `
  id type code name branch isActive
`

export const LIST_CONTACT = (templateType) => gql`query LIST_CONTACT ($contactType: String!, $q: FilterInput) {
  contacts: listContact${templateType} (contactType: $contactType, q: $q) {${listResponse}}
}`


const detailResponse = `
  id type code name branch taxCode
  isActive headOfficeId isUnknown
  categories {id name}
`

export const DETAIL_CONTACT = (templateType) => gql`query DETAIL_CONTACT ($contactType: String!, $contactId: Int!) {
  contact: detailContact${templateType} (contactType: $contactType, contactId: $contactId) {${detailResponse}}
}`

export const CREATE_CONTACT = (templateType) => gql`mutation CREATE_CONTACT ($contactType: String!, $input: Contact${templateType}Input!) {
  createContact: createContact${templateType} (contactType: $contactType, input: $input) {${detailResponse}}
}`

export const UPDATE_CONTACT = (templateType) => gql`mutation UPDATE_CONTACT ($contactType: String!, $contactId: Int!, $input: Contact${templateType}Input!) {
  updateContact: updateContact${templateType} (contactType: $contactType, contactId: $contactId, input: $input) {${detailResponse}}
}`

export const DESTROY_CONTACT = (templateType) => gql`mutation DESTROY_CONTACT ($contactType: String!, $contactId: Int!) {
  destroyContact: destroyContact${templateType} (contactType: $contactType, contactId: $contactId) {id}
}`
