<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <div class="form-row">
          <sgv-input-number
            label="จำนวนทดลอง"
            class="col-6 col-sm-4"
            v-model="sample"
            :precision="2">
          </sgv-input-number>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="row">
          <PropertyListUnit
            class="col-12 mb-3"
            :inventoryType="inventoryType"
            :templateType="templateType"
            :inventoryId="inventoryId"
            :sample="sample">
          </PropertyListUnit>

          <PropertyListCustom
            class="col-12 mb-3"
            :inventoryType="inventoryType"
            :templateType="templateType"
            :inventoryId="inventoryId"
            :sample="sample">
          </PropertyListCustom>
        </div>
      </div>

      <PropertyListBox
        class="col-12 col-sm-6 mb-3"
        :inventoryType="inventoryType"
        :templateType="templateType"
        :inventoryId="inventoryId"
        :sample="sample">
      </PropertyListBox>
    </div>
  </div>
</template>

<script>
import PropertyListUnit from './PropertyListUnit'
import PropertyListBox from './PropertyListBox'
import PropertyListCustom from './PropertyListCustom'

export default {
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    },
  },
  data () {
    return {
      sample: 0
    }
  },
  components: {
    PropertyListUnit,
    PropertyListBox,
    PropertyListCustom,
  }
}
</script>

<style lang="css" scoped>
</style>
