<template>
  <div class="">

    <DetailJobDescriptionSearch
      v-if="method === 'edit'"
      :contactId="contactId"
      :contactType="contactType"
      :templateType="templateType"
      @updated="$apollo.queries.jobDescriptions.refetch()">
    </DetailJobDescriptionSearch>

    <div
      class="card mb-3"
      v-for="jobDescription in jobDescriptions"
      :key="jobDescription.id">
      <h6 class="card-header">
        {{jobDescription.position}}
        <fa
          v-if="method ==='edit'"
          icon="trash"
          class="text-danger pointer float-right"
          @dblclick="deleteGroup(jobDescription.id)">
        </fa>
      </h6>
      <div class="card-body">
        <DetailJobDescriptionAbility
          class="mb-2"
          v-for="ability in jobDescription.abilities"
          :key="ability.id"
          :item="ability">
        </DetailJobDescriptionAbility>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DESTROY_JOBDESCRIPTION_JUNCTION,
  LIST_JOBDESCRIPTION
} from './graph'
import DetailJobDescriptionSearch from './DetailJobDescriptionSearch'
import DetailJobDescriptionAbility from './DetailJobDescriptionAbility'

export default {
  props: {
    contactId: {
      type: Number,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      jobDescriptions: []
    }
  },
  apollo: {
    jobDescriptions: {
      query () {
        return LIST_JOBDESCRIPTION(this.templateType)
      },
      variables () {
        return {
          contactType: this.contactType,
          contactId: this.contactId
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    deleteGroup (jobDescriptionId) {
      this.$apollo.mutate({
        mutation: DESTROY_JOBDESCRIPTION_JUNCTION(this.templateType),
        variables: {
          contactType: this.contactType,
          jobDescriptionId,
          contactId: this.contactId
        }
      })
      .then(() => {
        this.$apollo.queries.jobDescriptions.refetch()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    }
  },
  components: {
    DetailJobDescriptionSearch,
    DetailJobDescriptionAbility
  }
}
</script>

<style lang="css" scoped>
</style>
