import { capitalize } from '@/libs/form'
import DeadStock from '../DeadStock.vue'


export default ({inventoryType, group, title, subTitle}) => {
  const templateType = 'General'
  return [
    {
      path: `inventory/deadStock/${inventoryType}`,
      name: `Inventory${capitalize(inventoryType)}DeadStock`,
      component: DeadStock,
      props: () => ({
        templateType,
        group,
        inventoryType,
        title,
        subTitle
      })
    }
  ]
}
