import { capitalize } from '@/libs/form'
import { TEMPLATE_TYPE } from '../config'

export default ({deviceType, title, subTitle, group}) => {
  const templateType = TEMPLATE_TYPE
  return [
    {
      path: `device/${deviceType}`,
      name: `Device${capitalize(deviceType)}List`,
      component: () => import('../../device_core/components/List.vue'),
      props: () => ({
        templateType,
        deviceType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `device/${deviceType}/:deviceId`,
      component: () => import('../../device_core/components/Detail.vue'),
      children: [
        {
          path: '',
          name: `Device${capitalize(deviceType)}Detail`,
          components: {
            form: () => import('../../device_core/components/DetailForm.vue'),
            log: () => import('../../device_core/components/DetailLog.vue'),
            status: () => import('../DetailStatus.vue'),
            config: () => import('../DetailConfig.vue'),
          }
        }
      ],
      props: (route) => ({
        deviceId: Number(route.params.deviceId),
        templateType,
        deviceType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
