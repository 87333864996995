<template>
  <div class="form-row">
    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="เขียนที่"
      v-model="formData.kpi.writeAt">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="ชื่อลูกค้า/บริษัท"
      v-model="formData.kpi.customerName">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="เอกสารอ้างอิง"
      v-model="formData.kpi.documentRef">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="วันที่เอกสารอ้างอิง"
      v-model="formData.kpi.documentDate">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="หัวเรื่อง"
      v-model="formData.kpi.topic">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="พนักงานติดตั้ง"
      v-model="formData.kpi.employee">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="รายละเอียดอย่างย่อ"
      v-model="formData.kpi.description">
    </sgv-input-text>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="รายละเอียดสินค้า/บริการ"
      :rows="5"
      v-model="formData.kpi.productDetails">
    </sgv-input-textarea>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="จำนวนเงิน"
      v-model="formData.kpi.totalPrice">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="จำนวนเงินตัวหนังสือ"
      v-model="formData.kpi.totalPriceText">
    </sgv-input-text>
  </div>
</template>

<script>
export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>

</style>