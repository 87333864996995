<template>
  <div class="">
    <div class="">
      {{name}}
    </div>
    <div class="progress">
      <div
        class="progress-bar progress-bar-striped"
        :class="total.color"
        role="progressbar"
        :style="total.style"
        :aria-valuenow="total.percent"
        :aria-valuemin="total.minPercent"
        :aria-valuemax="total.maxPercent">
        <small class="label-center text-light">
          {{this.percent}} %
        </small>
      </div>
    </div>
    <div v-for="(remark, i) in remarks" :key="i">
      <small class="text-danger">{{remark}}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    remarks: {
      type: Array,
      required: true
    }
  },
  computed: {
    total () {
      let color = 'bg-danger'

      if (this.percent >= 75) color = 'bg-primary'
      else if (this.percent >= 25) color = 'bg-warning'

      return {
        style: {'width': this.percent + '%'},
        percent: this.percent,
        minPercent: 0,
        maxPercent: 100,
        color: color
      }
    }
  }
}
</script>

<style lang="css" scoped>
.label-center {
  position: absolute;
  text-align: center;
  overflow: hidden;
  width: 100%;
}
</style>
