import { capitalize } from '@/libs/form'

export default ({kioskDeviceType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `kioskDevice/${kioskDeviceType}`,
      name: `KioskDevice${capitalize(kioskDeviceType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        kioskDeviceType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `kioskDevice/${kioskDeviceType}/:kioskDeviceId`,
      name: `KioskDevice${capitalize(kioskDeviceType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        kioskDeviceId: Number(route.params.kioskDeviceId),
        templateType,
        kioskDeviceType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
