<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{section.name}}</h5>
      <div class="card-text">
        <component
          class="mb-3"
          v-for="(item,childIndex) in section.children" :key="item.code"
          :is="getFormComponent(item.formType)"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :item="item"
          :itemIndex="`${sectionIndex+1}.${childIndex+1}`"
          :canSave="!formData.closedAt && !!formData.approvedAt && !item.deviceId"
          :chunkSize="chunkSize">
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import DetailMeasurementCardTableFormChoice from './DetailMeasurementCardTableFormChoice'
import DetailMeasurementCardTableFormBetween from './DetailMeasurementCardTableFormBetween'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    section: {
      type: Object,
      required: true
    },
    sectionIndex: {
      type: Number,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formComps: [
        {formType: 'choice', comp: 'DetailMeasurementCardTableFormChoice'},
        {formType: 'between', comp: 'DetailMeasurementCardTableFormBetween'},
      ],
      chunkSize: 3
    }
  },
  methods: {
    getFormComponent (formType) {
      return this.formComps.find(v => v.formType === formType)?.comp || ''
    },
  },
  components: {
    DetailMeasurementCardTableFormChoice,
    DetailMeasurementCardTableFormBetween
  }
}
</script>

<style lang="css" scoped>
</style>
