import gql from 'graphql-tag'


export const DP_DEVICES = (templateType) => gql`query DP_DEVICES ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}MeasurementTemplateDropdownDevice (docType: $docType, q: $q) {
    id type code name
  }
}`

export const DP_DEVICE_TYPES = (templateType) => gql`query DP_DEVICE_TYPES ($docType: String!) {
  types: listDoc${templateType}MeasurementTemplateDropdownDeviceType (docType: $docType) {
    text value
  }
}`
