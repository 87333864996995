import gql from 'graphql-tag'
import { capitalize } from '@/libs/form'


const detailResponse = `
  id type name targetValue isDefault
  unitId unit {id name}
`

export const LIST_PROPERTY = (inventoryType) => gql`query LIST_PROPERTY ($inventoryType: String!, $inventoryId: Int!, $q: FilterInput) {
  items: listInventory${capitalize(inventoryType)}Property (inventoryType: $inventoryType, inventoryId: $inventoryId, q: $q) {${detailResponse}}
}`

export const CREATE_PROPERTY = (inventoryType) => gql`mutation CREATE_PROPERTY ($inventoryType: String!, $inventoryId: Int!, $propertyId: Int, $input: InventoryPropertyInput!) {
  createItem: createInventory${capitalize(inventoryType)}Property (inventoryType: $inventoryType, inventoryId: $inventoryId, propertyId: $propertyId, input: $input) {${detailResponse}}
}`

export const DESTROY_PROPERTY = (inventoryType) => gql`mutation DESTROY_PROPERTY ($inventoryType: String!, $propertyId: Int!) {
  deleteItem: destroyInventory${capitalize(inventoryType)}Property (inventoryType: $inventoryType, propertyId: $propertyId) {id}
}`
