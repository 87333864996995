<template>
  <div class="">
    <div class="form-row">
      <div class="col-auto">
        <button
          v-if="selected"
          type="button"
          class="btn btn-success mb-3 mr-2"
          @click="addForm">
          เพิ่ม
        </button>
        <button
          type="button"
          class="btn btn-primary mb-3"
          @click="toList">
          กลับ
        </button>
      </div>

      <div class="col"></div>

      <sgv-input-text
        class="col-sm-auto col-12"
        v-model="search"
        placeholder="ค้นหา...">
      </sgv-input-text>
    </div>

    <component
      key="form"
      class="mb-3"
      v-if="formComp"
      :is="formComp"
      :isForm="isForm"
      :contactType="contactType"
      :templateType="templateType"
      :type="selected"
      :item="formData"
      :menu="menu"
      @cancel="cancelForm"
      @update="updateData">
    </component>

    <div
      v-for="item in items"
      :key="item.id"
      class="mb-3">
      <template v-for="(comp, name) in comps">
        <component
          v-if="item.type === name"
          :key="name"
          :is="comp"
          :contactType="contactType"
          :templateType="templateType"
          :type="selected"
          :item="item"
          :menu="menu"
          @update="updateData"
          @child="createChildren"
          @destroy="destroyData">
        </component>
      </template>
    </div>
  </div>
</template>

<script>
import {
  LIST_CONTACT_CONFIG,
  CREATE_CONTACT_CONFIG,
  DESTROY_CONTACT_CONFIG,
  CREATE_CONTACT_CONFIG_CHILDREN
} from './graph/contact_config'
import CardUserManual from '@/views/contact_core/components/ContactConfigCardUserManual'
import CardCodeRegexExp from '@/views/contact_core/components/ContactConfigCardCodeRegexExp'
import CardNameRegexExp from '@/views/contact_core/components/ContactConfigCardNameRegexExp'
import CardDeviceLabelContactAddress from '@/views/contact_core/components/ContactConfigCardDeviceLabelContactAddress'
import CardJobApplication from '@/views/contact_core/components/ContactConfigCardJobApplication'
import CardJobEvaluation from '@/views/contact_core/components/ContactConfigCardJobEvaluation'


export default {
  props: {
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    selected: {
      type: String,
      required: false
    },
    menu: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      search: '',
      formComp: '',
      formData: {},
      isForm: false,
      comps: {
        userManual: 'CardUserManual',
        codeRegexExp: 'CardCodeRegexExp',
        nameRegexExp: 'CardNameRegexExp',
        taxCodeRegexExp: 'CardNameRegexExp',
        jobApplication: 'CardJobApplication',
        jobEvaluation: 'CardJobEvaluation',
        deviceLabelContactAddress: 'CardDeviceLabelContactAddress',
      },
      items: [],
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_CONTACT_CONFIG(this.templateType)
      },
      fetchPolicy: 'cache-and-network',
      variables () {
        return {
          contactType: this.contactType,
          q: {
            params: {
              type: this.selected,
              search: this.search,
            }
          }
        }
      },
      skip () {
        return !this.selected
      },
      debounce: 250
    }
  },
  methods: {
    toList () {
      this.$router.push({
        name: `Contact${this.$form.capitalize(this.contactType)}List`
      })
    },
    addForm () {
      if (this.isForm) return

      this.formComp = this.comps[this.selected]
      this.formData = {}
      this.isForm = true
    },
    cancelForm () {
      this.formComp = null
      this.formData = {}
      this.isForm = false
    },
    cacheQuery () {
      return {
        query: LIST_CONTACT_CONFIG(this.templateType),
        variables: {
          contactType: this.contactType,
          q: {
            params: {
              type: this.selected,
              search: this.search,
            }
          }
        }
      }
    },
    updateData ({id, input, cb}) {
      this.$apollo.mutate({
        mutation: CREATE_CONTACT_CONFIG(this.templateType),
        variables: {
          contactType: this.contactType,
          contactConfigId: id,
          input
        },
        update: (store, {data: {item}}) => {
          const data = store.readQuery(this.cacheQuery())

          if (id) {
            const idx = data.items.findIndex(v => v.id === item.id)
            if (idx !== -1) {
              data.items[idx] = item
            }
          } else {
            data.items.unshift(item)
          }
          store.writeQuery({...this.cacheQuery(), data})
        }
      })
      .then(() => {
        this.$toasted.global.success('เพิ่มสำเร็จ')
        this.cancelForm()
        cb()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    createChildren ({input, cb}) {
      this.$apollo.mutate({
        mutation: CREATE_CONTACT_CONFIG_CHILDREN(this.templateType),
        variables: {
          contactType: this.contactType,
          input
        },
        update: (store, {data: {item}}) => {
          const data = store.readQuery(this.cacheQuery())
          const idx = data.items.findIndex(v => v.id === item.parentId)
          if (idx !== -1) {
            data.items[idx].children.push(item)
          }
          store.writeQuery({...this.cacheQuery(), data})
        }
      })
      .then(() => {
        this.$toasted.global.success('เพิ่มสำเร็จ')
        cb()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData ({id, parentId}) {
      this.$apollo.mutate({
        mutation: DESTROY_CONTACT_CONFIG(this.templateType),
        variables: {
          contactType: this.contactType,
          contactConfigId: id
        },
        update: (store, {data: {item}}) => {
          const data = store.readQuery(this.cacheQuery())
          if (parentId) {
            const idx = data.items.findIndex(v => v.id === parentId)
            const chx = data.items[idx].children.findIndex(v => v.id === item.id)
            if (chx !== -1) {
              data.items[idx].children.splice(chx, 1)
            }
          } else {
            const idx = data.items.findIndex(v => v.id === item.id)
            if (idx !== -1) {
              data.items.splice(idx, 1)
            }
          }
          store.writeQuery({...this.cacheQuery(), data})
        }
      })
      .then(() => {
        this.$toasted.global.success('ลบสำเร็จ')
        this.cancelForm()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  components: {
    CardUserManual,
    CardCodeRegexExp,
    CardNameRegexExp,
    CardDeviceLabelContactAddress,
    CardJobApplication,
    CardJobEvaluation,
  }
}
</script>

<style lang="css">

</style>
