<template>
  <button
    @click="download"
    class="btn btn-warning">
    <fa icon="download"></fa>
    Excel
  </button>
</template>

<script>
import round from 'lodash/round'
import xlsx from 'json-as-xlsx'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    accruedDate: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      sheetname: '000000',
      columns: [
        {label: 'วันที่', value: 'closedAt'},
        {label: 'รหัสเอกสาร', value: 'docCode'},
        {label: 'ใบกำกับภาษี', value: 'invoiceRef'},
        {label: 'รายละเอียด', value: 'name'},
        {label: 'ยอดรวมทั้งสิ้น (เดบิต(+) / เครดิต(-))', value: 'amount'},
        {label: 'ชำระในรอบบัญชี (เดบิต(+) / เครดิต(-))', value: 'paid'},
        {label: 'ยอดยกไป (เดบิต(+) / เครดิต(-))', value: 'remaining'},
        {label: 'บัญชี', value: 'accountCode'},
        {label: 'หมายเหตุ', value: 'remark'},
      ]
    }
  },
  computed: {
    filename () {
      return `${this.title}_${this.accruedDate}`
    },
    rows () {
      const content = this.items.flatMap(v => {
        return {
          closedAt : this.$date.format(v.closedAt).displayThai,
          docCode : v.code,
          invoiceRef : v.invoiceRef || v.receiptRef || '',
          name : v.name,
          amount : round(v.amount, 2),
          paid : round(v.paid, 2),
          remaining : round(v.remaining, 2),
          accountCode : `${v.account.code} (${v.account.name})`,
          remark : v.remark,
        }
      })

      const data = [{
        sheet: this.sheetname,
        columns: this.columns,
        content
      }]

      return data
    }
  },
  methods: {
    download () {
      const settings = {
        fileName: this.filename,
      }

      xlsx(this.rows, settings)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
