<template>
  <div class="">
    <div class="form-row">
      <div class="col-12 form-group">
        <button
          v-for="option in options.filter(v => !v.disabled)"
          :key="option.text"
          :class="getHeaderClass(option)"
          @click="emitMethod(option)">
          {{option.text}}
        </button>
      </div>
    </div>

    <slot></slot>

    <div
      v-for="option in options.filter(v => v.method === method && !v.header)"
      :key="option.text">

      <slot :name="option.method"></slot>

      <div class="form-row">
        <div class="col-12 form-group">
          <button
            class="btn"
            :class="getFooterClass(option)"
            @click="option.func">
            {{option.text}}
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'SgvForm',
  props: {
    method: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  methods: {
    emitMethod (option) {
      if (option.header) {
        option.func()
      }
      if (!option.method) return
      if (this.method === option.method) {
        this.$emit('update:method', 'info')
      } else {
        this.$emit('update:method', option.method)
      }
    },
    getHeaderClass (option) {
      let arr = []
      if (option.variant === 'success') {
        arr = ['btn', 'btn-link', 'text-success']
      } else if (option.variant === 'warning') {
        arr = ['btn', 'btn-link', 'text-warning']
      } else if (option.variant === 'danger') {
        arr = ['btn', 'btn-link', 'text-danger']
      } else if (option.variant === 'info') {
        arr = ['btn', 'btn-link', 'text-info']
      } else if (option.variant === 'primary') {
        arr = ['btn', 'btn-link', 'text-primary']
      }
      if (option.align === 'right') arr.push('float-right')
      return arr
    },
    getFooterClass (option) {
      if (option.variant === 'success') {
        return ['btn', 'btn-success']
      } else if (option.variant === 'warning') {
        return ['btn', 'btn-warning']
      } else if (option.variant === 'danger') {
        return ['btn', 'btn-danger']
      } else if (option.variant === 'info') {
        return ['btn', 'btn-info']
      } else if (option.variant === 'primary') {
        return ['btn', 'btn-primary']
      }
    }
  }
}
</script>

<style lang="css" scoped>
.footer-height {
  height: 38.5px;
}
</style>
