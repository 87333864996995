<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="pre-line">
          {{item.jobDescription.code}} ({{item.jobDescription.position}})
        </div>
        <div class="pre-line">
          {{item.ability.name}}
        </div>
        <div class="pre-line">
          {{item.kpiTemplate.name}}
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <DocConfigFormJobDescription
            :docType="docType"
            :templateType="templateType"
            label="ตำแหน่งงาน"
            class="col-12"
            select="id"
            v-model="formData.jobDescriptionId"
            :validations="[
              {text: 'required!', value: $v.formData.jobDescriptionId.$dirty && !$v.formData.jobDescriptionId.required}
            ]">
          </DocConfigFormJobDescription>

          <DocConfigFormAbility
            v-if="formData.jobDescriptionId"
            :docType="docType"
            :templateType="templateType"
            label="ความสามารถ"
            class="col-12"
            select="id"
            v-model="formData.abilityId"
            :jobDescriptionId="formData.jobDescriptionId"
            :validations="[
              {text: 'required!', value: $v.formData.abilityId.$dirty && !$v.formData.abilityId.required}
            ]">
          </DocConfigFormAbility>

          <DocConfigFormKpiTemplate
            :docType="docType"
            :templateType="templateType"
            label="แบบฟอร์ม"
            class="col-12"
            select="id"
            v-model="formData.kpiTemplateId"
            :validations="[
              {text: 'required!', value: $v.formData.kpiTemplateId.$dirty && !$v.formData.kpiTemplateId.required}
            ]">
          </DocConfigFormKpiTemplate>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormJobDescription from '@/views/doc_core/components/DocConfigFormJobDescription'
import DocConfigFormAbility from '@/views/doc_core/components/DocConfigFormAbility'
import DocConfigFormKpiTemplate from '@/views/doc_core/components/DocConfigFormKpiTemplate'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        jobDescriptionId: null,
        abilityId: null,
        kpiTemplateId: null,
        isDefault: false
      }
    }
  },
  validations: {
    formData: {
      jobDescriptionId: {required},
      abilityId: {required},
      kpiTemplateId: {required},
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.$v.formData.$reset()
          this.toggle = false
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.jobDescriptionId = this.item.jobDescriptionId
          this.formData.abilityId = this.item.abilityId
          this.formData.kpiTemplateId = this.item.kpiTemplateId
          this.formData.isDefault = this.item.isDefault
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      } else {
        this.formData.jobDescriptionId = null
        this.formData.abilityId = null
        this.formData.kpiTemplateId = null
        this.formData.isDefault = false
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  },
  components: {
    DocConfigFormKpiTemplate,
    DocConfigFormJobDescription,
    DocConfigFormAbility
  }
}
</script>

<style lang="css" scoped>
</style>
