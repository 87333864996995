<template>
  <div class="hr-divider mb-3">
    <ul class="nav nav-pills hr-divider-content hr-divider-nav">
      <li
        class="nav-item"
        v-for="(option, index) in optionsByRole"
        :key="index"
        @click.prevent="emitInput(optionByKey(option))">

        <router-link
          v-if="link"
          class="nav-link"
          active-class="active"
          :to="option.to"
          :exact="option.exact">
          <slot v-bind="option">
            {{option.text}}
          </slot>
        </router-link>

        <a
          v-else
          class="nav-link"
          href="#"
          :class="{'active': getActive(option)}">
          <slot v-bind="option">
            {{option.text}}
          </slot>
        </a>

      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HrDivider',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [Number, String, Object],
      required: false
    },
    select: {
      type: String,
      required: false,
      default: 'value'
    },
    link: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    optionsByRole () {
      return this.options
      .filter(v => this.$auth.hasRole(v.role))
      .filter(v => !v.hidden)
    }
  },
  methods: {
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    },
    emitInput (value) {
      this.$emit('input', value)
    },
    getActive (option) {
      return this.value === this.optionByKey(option)
    }
  },
  watch: {
    value: {
      handler(value) {
        const items = this.select ? this.optionsByRole.map(v => v[this.select]) : this.optionsByRole
        const found = items.find(v => v === value)
        if (found) return
        this.emitInput(items[0])
      },
      immediate: true
    }
  }
}
</script>

<style lang="css">
</style>
