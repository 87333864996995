<template>
  <div class="row">
    <div class="col-12">
      <span class="float-left">
        <span class="mr-1">{{itemIndex}}</span>
        {{item.name}}
        <small>
          <div>
            {{item.minValue | comma(-1)}}
            <fa icon="less-than-equal" class="mx-1"></fa>
            {{item.actualValue | comma(-1)}}
            <fa icon="less-than-equal" class="ml-1"></fa>
            {{item.maxValue | comma(-1)}}
          </div>
          <div v-if="item.device" class="text-info">
            <fa icon="hdd"></fa>
            {{item.device.code}} ({{item.device.name}}) ({{item.deviceTopic}})
          </div>
        </small>
      </span>

      <DetailMeasurementFormBetweenModal
        class="float-right"
        :item="item"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :canSave="canSave">
      </DetailMeasurementFormBetweenModal>
    </div>

    <div
      class="col-12 mt-2 d-flex flex-row"
      v-if="item.children.length > 0"
      style="overflow-x: auto;">
      <ul v-for="(children,i) in childrenCols" :key="i" class="no-wrap">
        <li
          v-for="child in children"
          :key="child.id"
          class="my-1">
          <span :class="{'text-danger': !isInRange(child.actualValue, item.minValue, item.maxValue)}">
            {{child.actualValue | comma(-1)}}
          </span>
          <small v-if="child.remark" class="text-danger ml-1">
            ({{child.remark}})
          </small>
          <div>
            <small class="text-primary">
              {{child.createdAt | datetime}}: {{child.createdUser.name}}
            </small>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import chunk from 'lodash/chunk'
import { isNumberInRange } from '@/libs/form'
import DetailMeasurementFormBetweenModal from './DetailMeasurementFormBetweenModal'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    itemIndex: {
      type: String,
      required: true
    },
    canSave: {
      type: Boolean
    },
    chunkSize: {
      type: Number,
      required: true
    }
  },
  computed: {
    childrenCols () {
      return chunk(this.item.children, this.chunkSize)
    }
  },
  methods: {
    getChoice (choices, value) {
      return choices.find(v => v.value === value).text
    },
    isInRange (value, minValue, maxValue) {
      return isNumberInRange(value, minValue, maxValue)
    }
  },
  components: {
    DetailMeasurementFormBetweenModal
  }
}
</script>

<style lang="css" scoped>
</style>
