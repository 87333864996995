<template>
  <div class="card">
    <div class="card-body pb-1">
      <h5 class="card-title">
        สรุป
      </h5>

      <div class="mb-3">
        ยอดรวม:
        <span class="text-warning">
          {{netAmount | comma}}
        </span>

        เดบิต:
        <span class="text-success">
          {{debitAmount | comma}}
        </span>

        เครดิต:
        <span class="text-danger">
          {{creditAmount * -1 | comma}}
        </span>
      </div>

      <sgv-table
        headerless
        :items="items"
        :headers="headers"
        :options.sync="options">

        <template slot-scope="{item, hidden}">
          <tr>
            <td v-if="hidden.code">
              {{item.code}}
            </td>
            <td v-if="hidden.name">
              {{item.name}}
            </td>
            <td v-if="hidden.debit" class="text-success text-right">
              <span v-if="item.totalPrice >= 0">{{item.totalPrice | comma}}</span>
              <span v-else>-</span>
            </td>
            <td v-if="hidden.credit" class="text-danger text-right">
              <span v-if="item.totalPrice < 0">{{item.totalPrice * -1 | comma}}</span>
              <span v-else>-</span>
            </td>
          </tr>
        </template>
      </sgv-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
        {text: 'เดบิต', value: 'debit'},
        {text: 'เครดิต', value: 'credit'},
      ],
      options: {
        headers: ['code', 'name', 'debit', 'credit'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  computed: {
    netAmount () {
      return this.items.reduce((t,v) => t + +v.totalPrice, 0)
    },
    debitAmount () {
      return this.items
      .filter(v => v.totalPrice >= 0)
      .reduce((t,v) => t + +v.totalPrice, 0)
    },
    creditAmount () {
      return this.items
      .filter(v => v.totalPrice < 0)
      .reduce((t,v) => t + +v.totalPrice, 0)
    },
  },
}
</script>

<style lang="css" scoped>
</style>
