import gql from 'graphql-tag'


const orderResponse = `
  id type name qty price totalPrice
  unitId unit {id name}
  ledgers {
    id docId orderId amount
    accountId account {id code name}
    createdAt createdBy
    approvedAt approvedBy
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const LIST_ORDER_BY_TYPE = (templateType) => gql`query LIST_ORDER_BY_TYPE ($docType: String!, $docId: Int!, $orderType: String!, $q: FilterInput) {
  orders: listDoc${templateType}OrderByType (docType: $docType, docId: $docId, orderType: $orderType, q: $q) {${orderResponse}}
}`

export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const CREATE_ORDER_EXPENSE = (templateType) => gql`mutation CREATE_ORDER_EXPENSE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderExpense (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_DISCOUNT_PRICE = (templateType) => gql`mutation CREATE_ORDER_DISCOUNT_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderDiscountPrice (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_NET_PRICE = (templateType) => gql`mutation CREATE_ORDER_NET_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderNetPrice (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_CHANGE_ERROR = (templateType) => gql`mutation CREATE_ORDER_CHANGE_ERROR ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderChangeError (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`

export const DESTROY_ORDER_ALL = (templateType) => gql`mutation DESTROY_ORDER_ALL ($docType: String!, $docId: Int!, $orderType: String!) {
  destroyOrders: destroyDoc${templateType}OrderAll (docType: $docType, docId: $docId, orderType: $orderType) {id}
}`

export const LIST_ORDER_CONFIG = (templateType) => gql`query LIST_ORDER_CONFIG ($docType: String!) {
  configs: listDoc${templateType}DocConfig (docType: $docType) {
    id type name isDefault options
  }
}`

export const LIST_ORDER_PAYABLE_TYPE = (templateType) => gql`query LIST_ORDER_PAYABLE_TYPE ($docType: String!, $docConfigType: String!) {
  payableTypes: listDoc${templateType}OrderPayableType (docType: $docType, docConfigType: $docConfigType) {
    id name docType
  }
}`

export const LIST_ORDER_PAYABLE = (templateType) => gql`query LIST_ORDER_PAYABLE ($docType: String!, $docConfigType: String!, $docConfigId: Int, $search: String, $startDate: DateTime, $endDate: DateTime) {
  docs: listDoc${templateType}OrderPayable (docType: $docType, docConfigType: $docConfigType, docConfigId: $docConfigId, search: $search, startDate: $startDate, endDate: $endDate) {
    id type code name remark contactId
    orders {
      id type name total paid pending remaining
      account {
        id code name
      }
    }
  }
}`

export const CREATE_ORDER_PAYABLE = (templateType) => gql`mutation CREATE_ORDER_PAYABLE ($docType: String!, $docId: Int!, $docConfigType: String!, $orderType: String!, $input: [Doc${templateType}OrderPayableInput]!) {
  orders: createDoc${templateType}OrderPayable (docType: $docType, docId: $docId, docConfigType: $docConfigType, orderType: $orderType, input: $input) {
    id docId parentId
  }
}`

export const LIST_ORDER_PAYABLE_SALES_CSV = (templateType) => gql`query LIST_ORDER_PAYABLE_SALES_CSV ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}OrderPayableSalesCsv (docType: $docType, docId: $docId) {
    id closedAt docCode
    contactCode contactName contactTaxCode contactBranch
    totalPrice vatPrice remark
  }
}`
