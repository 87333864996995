import { capitalize } from '@/libs/form'

export default ({ paperType, title, subTitle, group }) => {
  const templateType = 'PaperWorkInstruction'

  return [
    {
      path: `paper/${paperType}`,
      name: `Paper${capitalize(paperType)}List`,
      component: () => import(`../List.vue`),
      props: () => ({
        templateType,
        paperType,
        title,
        subTitle,
        group,
      }),
    },
    {
      path: `paper/${paperType}/:paperId`,
      name: `Paper${capitalize(paperType)}Detail`,
      component: () => import(`../Detail.vue`),
      props: (route) => ({
        paperId: Number(route.params.paperId),
        templateType,
        paperType,
        title,
        subTitle,
        group,
      })
    }
  ]
}
