<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <h6>
          อื่นๆ
          <span
            v-if="$auth.hasRole(`inventory:${inventoryType}:add`)"
            class="text-warning px-1 pointer"
            @click="toggle = !toggle">
            <fa icon="pencil-alt"></fa>
          </span>
        </h6>
      </div>
      <div class="col-12 mb-3" v-if="toggle">
        <div class="form-row">
          <sgv-input-text
            class="col-12"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required},
            ]">
          </sgv-input-text>

          <sgv-input-number
            class="col-6"
            label="จำนวน"
            v-model="formData.targetValue"
            :precision="2"
            :validations="[
              {text: 'required!', value: $v.formData.targetValue.$dirty && !$v.formData.targetValue.required},
              {text: 'จำนวนมากกว่า 0', value: $v.formData.targetValue.$dirty && !$v.formData.targetValue.minValue}
            ]">
          </sgv-input-number>

          <DropdownUnit
            class="col-6"
            select="id"
            v-model="formData.unitId"
            label="หน่วย"
            :inventoryType="inventoryType"
            :templateType="templateType"
            :validations="[
              {text: 'required!', value: $v.formData.unitId.$dirty && $v.formData.unitId.$error}
            ]">
          </DropdownUnit>
        </div>

        <button
          type="button"
          class="btn btn-success"
          @click="addData">
          เพิ่ม
        </button>
      </div>

      <div class="col-12">
        <ul>
          <li
            v-for="item in items"
            :key="item.id">
            <span
              v-if="toggle"
              class="text-danger px-1 pointer"
              @click="destroyData(item.id)">
              <fa icon="trash"></fa>
            </span>
            {{item.name}}:
            {{item.targetValue}}
            <span v-if="item.unit">
              {{item.unit.name}}
            </span>

            <span
              v-if="sample !== 0"
              class="text-info">
              ({{sample * item.targetValue | comma}})
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DropdownUnit from './DropdownUnit'
import {
  LIST_PROPERTY,
  CREATE_PROPERTY,
  DESTROY_PROPERTY
} from '../graph/property'

export default {
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    },
    sample: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      toggle: false,
      items: [],
      propertyType: 'custom',
      formData: {
        name: '',
        targetValue: 0,
        unitId: null,
      }
    }
  },
  validations: {
    formData: {
      name: { required },
      targetValue: {
        required,
        minValue: (value) => value >= 0
      },
      unitId: { required },
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_PROPERTY(this.templateType)
      },
      variables () {
        return {
          inventoryType: this.inventoryType,
          inventoryId: this.inventoryId,
          q: {
            order: 'name',
            params: {
              type: this.propertyType
            }
          }
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    addData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_PROPERTY(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          inventoryId: this.inventoryId,
          input: {
            ...this.formData,
            type: this.propertyType
          }
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData (propertyId) {
      this.$apollo.mutate({
        mutation: DESTROY_PROPERTY(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          propertyId
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  components: {
    DropdownUnit
  }
}
</script>

<style lang="css" scoped>
</style>
