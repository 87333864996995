import gql from 'graphql-tag'


const detailResponse = `
  id type code name order parentId
  children {
    id type name order parentId
    formType isShowRemark
    actualPoint minPoint maxPoint
    choices {text value}
  }
`

export const CREATE_GROUP = (templateType) => gql`mutation CREATE_GROUP ($docType: String!, $parentId: Int!, $input: KpiTemplateGroupInput!) {
  item: createDoc${templateType}KpiTemplateGroup (docType: $docType, parentId: $parentId, input: $input) {${detailResponse}}
}`

export const UPDATE_GROUP = (templateType) => gql`mutation UPDATE_GROUP ($docType: String!, $id: Int!, $input: KpiTemplateGroupInput!) {
  item: updateDoc${templateType}KpiTemplateGroup (docType: $docType, id: $id, input: $input) {${detailResponse}}
}`

export const DESTROY_GROUP = (templateType) => gql`mutation DESTROY_GROUP ($docType: String!, $id: Int!) {
  item: destroyDoc${templateType}KpiTemplateGroup (docType: $docType, id: $id) {id}
}`

export const MOVE_GROUP = (templateType) => gql`mutation MOVE_GROUP ($docType: String!, $id: Int!, $step: Int!) {
  item: moveDoc${templateType}KpiTemplateGroup (docType: $docType, id: $id, step: $step) {id}
}`
