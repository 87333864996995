<template>
  <div class="">
    <div class="row mb-4" v-for="(topic, i) in $v.topics.$each.$iter" :key="i">
      <div class="col-12">
        <div class="card">
          <h6 class="card-header" v-if="!selectedTopics.includes(topic.$model.id)">
            {{topic.$model.appointment}}
            <span v-if="method === 'edit'">
              <fa
                icon="pencil-alt"
                class="text-warning ml-1 pointer"
                @click="toggleTopic(topic.$model.id)">
              </fa>
            </span>
          </h6>
          <div class="card-body pb-0" v-if="!selectedTopics.includes(topic.$model.id)">
            <div class="card-text">
              <p class="">
                {{topic.$model.name}}
              </p>
              <p class="pre-line">
                {{topic.$model.content}}
              </p>
            </div>
          </div>

          <div class="card-header" v-if="selectedTopics.includes(topic.$model.id)">
            <h6 class="card-title">
              <fa
                icon="pencil-alt"
                class="text-warning ml-1 pointer"
                @click="toggleTopic(topic.$model.id)">
              </fa>
            </h6>

            <div class="form-row">
              <sgv-input-text
                label="วันที่"
                v-model="topic.$model.appointment"
                class="col-12"
                :validations="[
                  {text: 'required!', value: !topic.appointment.required && topic.$dirty}
                ]">
              </sgv-input-text>

              <sgv-input-text
                label="หัวข้อ"
                v-model="topic.$model.name"
                class="col-12"
                :validations="[
                  {text: 'required!', value: !topic.name.required && topic.$dirty}
                ]">
              </sgv-input-text>

              <sgv-input-textarea
                label="รายละเอียด"
                v-model="topic.$model.content"
                class="col-12"
                :validations="[
                  {text: 'required!', value: !topic.content.required && topic.$dirty}
                ]">
              </sgv-input-textarea>
            </div>

            <button
              type="button"
              class="btn btn-warning"
              @click="updateTopic(topic.$model.id, topic)">
              แก้ไข
            </button>

            <button
              type="button"
              class="btn btn-danger float-right"
              @click="destroyTopic(topic.$model.id)">
              ลบ
            </button>
          </div>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-success mb-3"
      @click="createTopic"
      v-if="method === 'edit'">
      เพิ่มรายการ
    </button>
  </div>
</template>

<script>
import {
  LIST_ORDER,
  CREATE_ORDER, UPDATE_ORDER, DESTROY_ORDER
} from './graph'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      orders: [],
      selectedTopics: [],
      topics: []
    }
  },
  validations: {
    topics: {
      $each: {
        appointment: {required},
        name: {required},
        content: {required}
      }
    },
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables() {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
      result (res) {
        this.topics = res.data.orders.map(v => {
          return {
            id: v.id,
            appointment: v.appointment,
            name: v.name,
            content: v.content
          }
        })
      }
    },
  },
  computed: {
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    }
  },
  methods: {
    toggleTopic (id) {
      const index = this.selectedTopics.findIndex(v => v === id)
      if (index !== -1) this.selectedTopics.splice(index, 1)
      else this.selectedTopics.push(id)
    },
    createTopic () {
      this.$apollo.mutate({
        mutation: CREATE_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: {
            appointment: 'เวลา...',
            name: 'หัวข้อ...',
            content: 'รายละเอียด...',
          }
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.orders.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateTopic (id, topic) {
      topic.$touch()
      if (topic.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id,
          input: {
            appointment: topic.$model.appointment,
            name: topic.$model.name,
            content: topic.$model.content,
          }
        }
      })
      .then(() => {
        topic.$reset()
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$apollo.queries.orders.refetch()
        this.toggleTopic(id)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyTopic (id) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$apollo.queries.orders.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css">
</style>
