import { capitalize } from '@/libs/form'

export default ({procedureType, title, subTitle, group}) => {
  const templateType = 'ProcedureQuality'
  return [
    {
      path: `procedureQuality/${procedureType}/category/:categoryId`,
      name: `${templateType}${capitalize(procedureType)}CategoryDetail`,
      component: () => import(`@/views/procedure_core/CategoryDetail.vue`),
      props: (route) => ({
        templateType,
        categoryId: Number(route.params.categoryId),
        title,
        subTitle,
        group,
        procedureType
      })
    },
    {
      path: `procedureQuality/${procedureType}`,
      name: `${templateType}${capitalize(procedureType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        title,
        subTitle,
        group,
        procedureType
      })
    },
    {
      path: `procedureQuality/${procedureType}/:procedureQualityId`,
      name: `${templateType}${capitalize(procedureType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        templateType,
        procedureQualityId: Number(route.params.procedureQualityId),
        title,
        subTitle,
        group,
        procedureType
      })
    },
  ]
}
