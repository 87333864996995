import { capitalize } from '@/libs/form'

export default ({procedureType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `procedure/${procedureType}/general`,
      name: `Procedure${capitalize(procedureType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        title,
        subTitle,
        group,
        procedureType
      })
    },
    {
      path: `procedure/${procedureType}/general/:procedureId`,
      name: `Procedure${capitalize(procedureType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        templateType,
        procedureId: Number(route.params.procedureId),
        title,
        subTitle,
        group,
        procedureType
      })
    },
    {
      path: `procedure/${procedureType}/general/category/:categoryId`,
      name: `Procedure${capitalize(procedureType)}CategoryDetail`,
      component: () => import(`../CategoryDetail.vue`),
      props: (route) => ({
        templateType,
        categoryId: Number(route.params.categoryId),
        title,
        subTitle,
        group,
        procedureType
      })
    },
  ]
}
