<template>
  <div class="row">
    <DetailMeasurementCardSlider
      class="col-12"
      :docType="docType"
      :docId="docId"
      :templateType="templateType"
      :formData="formData"
      @xLabels="xLabels = $event"
      @startTime="startTime = $event"
      @endTime="endTime = $event"
      @displayType="displayType = $event">
    </DetailMeasurementCardSlider>

    <template v-if="startTime && endTime && displayType">
      <div
        class="col-12 mb-3"
        v-for="(section,idx) in measurements"
        :key="section.id">
        <component
          :is="displayType"
          :docType="docType"
          :docId="docId"
          :templateType="templateType"
          :section="section"
          :sectionIndex="idx"
          :formData="formData"
          :method="method"
          :xLabels="xLabels">
        </component>
      </div>
    </template>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailMeasurementCardTable from './DetailMeasurementCardTable'
import DetailMeasurementCardChart from './DetailMeasurementCardChart'
import DetailMeasurementCardSlider from './DetailMeasurementCardSlider'
import { LIST_MEASUREMENT, WATCH_MEASUREMENT_CREATED } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailMeasurement`,
      measurements: [],
      xLabels: [],
      displayType: '',
      startTime: null,
      endTime: null
    }
  },
  apollo: {
    measurements: {
      query () {
        return LIST_MEASUREMENT(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId,
          startDate: this.startTime,
          endDate: this.endTime,
          q: {
            order: '-createdAt'
          }
        }
      },
      skip () {
        return this.docId === 0 || !this.isRetainingLoaded || !this.startTime || !this.endTime
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      subscribeToMore: [
        {
          document () {
            return WATCH_MEASUREMENT_CREATED(this.templateType)
          },
          variables () {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const newMeasurement = subscriptionData.data.measurementCreated
            const measurements = [...previous.measurements]
            measurements.forEach(g => {
              const topic = g.children.find(c => c.id === newMeasurement.parentId)
              if (topic) {
                topic.children.unshift(newMeasurement)
              }
            })
            return {...previous, measurements}
          }
        }
      ]
    }
  },
  components: {
    DetailMeasurementCardTable,
    DetailMeasurementCardChart,
    DetailMeasurementCardSlider
  }
}
</script>

<style lang="css" scoped>
</style>
