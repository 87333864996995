<template>
  <sgv-table
    :rKey="rKey"
    :items="inventories"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden}">
      <tr class="">
        <td v-if="hidden.code">
          <router-link
            class="text-decoration-none no-wrap"
            :to="toDetail(item)">
            {{item.code}}
          </router-link>
          <div>
            <small>{{ item.name }}</small>
          </div>
        </td>
        <td v-if="hidden.turnoverRatio">
          <span class="text-danger">{{ item.outQty | comma }}</span> 
          / 
          <span>{{ item.balanceQty | comma }}</span>
          <span class="ml-2">{{ item.unit.name }}</span>
          <div>
            <small class="text-info">{{item.turnoverRatio | comma}} เดือน</small>
          </div>
        </td>
        <td v-if="hidden.deltaLimit">
          <span v-if="item.deltaLimit >= 0">{{item.deltaLimit | comma}}</span>
          <span v-else>-</span>
        </td>
        <td v-if="hidden.lowerLimit">
          <span v-if="item.lowerLimit >= 0">{{item.lowerLimit | comma}}</span>
          <span v-else>-</span>
        </td>
        <td v-if="hidden.upperLimit">
          <span v-if="item.upperLimit >= 0">{{item.upperLimit | comma}}</span>
          <span v-else>-</span>
        </td>
        <td v-if="hidden.purchaseLimit">
          <span v-if="item.purchaseLimit >= 0">{{item.purchaseLimit | comma}}</span>
          <span v-else>-</span>
        </td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_INVENTORY } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    inventoryStockType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      rKey: `InventoryStock${this.$form.capitalize(this.inventoryStockType)}ListStock`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'Turnover (เดือน)', value: 'turnoverRatio'},
        {text: 'ผลต่าง', value: 'deltaLimit'},
        {text: 'น้อยสุด', value: 'lowerLimit'},
        {text: 'สูงสุด', value: 'upperLimit'},
        {text: 'สั่งซื้อ', value: 'purchaseLimit'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: [
          'code', 
          'turnoverRatio', 
          'deltaLimit',
          'lowerLimit', 
          'upperLimit', 
          'purchaseLimit'
        ],
        column: null,
        search: null,
        toolbar: null,
      },
      inventories: []
    }
  },
  apollo: {
    inventories: {
      query () {
        return LIST_INVENTORY(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          inventoryStockType: this.inventoryStockType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      if (this.mode === 'lowerLimit') {
        filter.params.isLowerLimit = true
      } else {
        filter.params.isLowerLimit = false
      }
      return filter
    },
    toInventory (item) {
      return {
        name: `Inventory${this.$form.capitalize(item.type)}Detail`,
        params: {inventoryId: item.id}
      }
    },
    toDetail (item) {
      return {
        name: `Inventory${this.$form.capitalize(item.type)}Detail`,
        params: {inventoryId: item.id}
      }
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: `${this.group}`})
  }
}
</script>

<style lang="css" scoped>
</style>
