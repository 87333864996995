<template>
  <div>
    <div class="">
      <button
        type="button"
        class="btn btn-link"
        :disabled="!url || url === ''"
        @click="download">
        {{status}}
      </button>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SgvFile',
  props: {
    url: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      status: 'Download'
    }
  },
  methods: {
    download () {
      if (this.status !== 'Download') return
      this.status = 'Downloading...'
      this.$axios.get('api/space', {
        params: {
          path: this.url
        },
        responseType: 'blob'
      }).then(res => {
        let filename = this.url.split('/').slice(-1)[0]
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
        this.status = 'Download'
      }).catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
        this.status = 'Download'
      })
    }
  }
}
</script>

<style lang="css">

</style>
