<template>
  <div class="">
    สรุปยอดคงค้าง:
    <ul>
      <li>ยอดรวม: {{amount | comma}} บาท</li>
      <li class="text-success">ชำระในรอบบัญชี: {{totalPaid | comma}} บาท</li>
      <li class="text-danger">ยอดยกไป: {{totalRemaining | comma}} บาท</li>
      <li>
        บัญชีแยกประเภท:
        <sgv-input-check 
          label="เลือกทั้งหมด"
          :value="isAllSelected" 
          @change="emitSelectAll">
        </sgv-input-check>

        <sgv-input-check-array
          :options="totalAccount"
          v-model="accountIds"
          select="id">
          <template slot-scope="option">
            {{option.code}} ({{option.name}}) ---
            <span :class="[option.remaining > 0 ? 'text-success' : 'text-danger']">
              {{option.remaining | comma}} บาท
            </span>
          </template>
        </sgv-input-check-array>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true
    }
  },
  computed: {
    accountIds: {
      get () {
        return this.selected
      },
      set (v) {
        this.emitAccountIds(v)
      }
    },
    amount () {
      return this.items.reduce((t,v) => t += +v.amount, 0)
    },
    totalPaid () {
      return this.items.reduce((t,v) => t += +v.paid, 0)
    },
    totalRemaining () {
      return this.items.reduce((t,v) => t += +v.remaining, 0)
    },
    totalAccount () {
      const obj = this.items.reduce((t,l) => {
        if (!t[l.account.id]) {
          t[l.account.id] = {
            id: l.account.id,
            code: l.account.code,
            name: l.account.name,
            remaining: l.remaining
          }
        } else {
          t[l.account.id].remaining += +l.remaining
        }
        return t
      }, {})

      return Object.keys(obj).map(k => obj[k])
    },
    isAllSelected () {
      return [...new Set(this.selected)].length === [...new Set(this.items.map(l => l.account.id))].length
    }
  },
  methods: {
    emitAccountIds (v) {
      this.$emit('account', v)
    },
    emitSelectAll () {
      this.$emit('selectAll', null)
    }
  },
}
</script>

<style lang="css" scoped>
</style>
