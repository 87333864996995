<template>
  <div v-if="items.length > 0">
    <div
      class="mb-3"
      v-for="(section, i) in items"
      :key="section.code">

      <p class="">{{i+1}}. {{section.name}}</p>
      <ul class="list-unstyled">
        <li
          class="px-3 pb-1"
          v-for="(item, index) in section.children"
          :key="item.code">

          <div class="form-row" v-if="item.formType === 'text'">
            <sgv-input-text
              :disabled="$auth.disabled(method)"
              v-model="item.remark"
              class="col-12">
              <div slot="label" class="mb-2">
                <span class="mr-1 text-secondary">{{i+1}}.{{index+1}}</span>
                {{item.name}}
              </div>
            </sgv-input-text>
          </div>

          <div class="form-row" v-else-if="item.formType === 'choice'">
            <sgv-input-radio
              class="col-12 mb-2"
              :noClick="$auth.disabled(method)"
              :options="sortChoices(item.choices)"
              select="value"
              inline
              v-model="item.actualPoint">
              <div slot="label">
                <span class="mr-1 text-secondary">{{i+1}}.{{index+1}}</span>
                {{item.name}}
              </div>
              <template slot-scope="option">
                {{option.text}}
              </template>
            </sgv-input-radio>

            <sgv-input-text
              v-show="item.remark || method === 'edit'"
              :disabled="$auth.disabled(method)"
              placeholder="หมายเหตุ (ถ้ามี)"
              v-model="item.remark"
              class="col-12">
            </sgv-input-text>
          </div>

          <div class="form-row" v-else-if="item.formType === 'between'">
            <sgv-input-number
              class="col-12"
              minus
              :disabled="$auth.disabled(method)"
              v-model="item.actualPoint">
              <div slot="label" class="pb-2">
                <span class="mr-1 text-secondary">{{i+1}}.{{index+1}}.</span>
                {{item.minPoint | comma(-1)}}
                <fa icon="less-than-equal" class="mx-1"></fa>
                {{item.name}}
                <fa icon="less-than-equal" class="ml-1"></fa>
                {{item.maxPoint | comma(-1)}}
              </div>
            </sgv-input-number>

            <sgv-input-text
              v-show="item.remark || method === 'edit'"
              :disabled="$auth.disabled(method)"
              placeholder="หมายเหตุ (ถ้ามี)"
              v-model="item.remark"
              class="col-12">
            </sgv-input-text>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: []
    }
  },
  methods: {
    sortChoices (items) {
      return items.slice(0, items.length).sort((a,b) => a.value - b.value)
    },
    setFormData () {
      if (!this.formData.kpiTemplate) return []

      this.items = this.formData.kpiTemplate.children.map(section => {
        const obj = {...section}
        obj.children = obj.children.map(v => {
          const found = this.formData.kpis.find(x => x.code === v.code)
          const z = {...v}
          delete z.id
          if (found) {
            z.id = found.id
            z.actualPoint = found.actualPoint
            z.remark = found.remark
          }
          return z
        })
        return obj
      })
    }
  },
  watch: {
    'formData.kpis': {
      handler: 'setFormData',
      immediate: true
    },
    items: {
      handler (v) {
        this.$emit('kpis', v)
      },
      immediate: true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
