import gql from 'graphql-tag'


const listResponse = `
  id type name
  docType docId doc {id type code name}
  qty stdPrice totalPrice
  inputDocPercent inputDocPrice
  inputPredictPercent inputPredictPrice
  unitId unit {id name}
  inventoryId inventory {
    id type code name
    unitId unit {id name}
  }
  parentId parent {
    id type name
    inventoryCostId inventoryCost {id type code name}
  }
`

export const LIST_INVENTORY_COST_ORDER_INLET = (templateType) => gql`query LIST_INVENTORY_COST_ORDER_INLET ($inventoryCostType: String!, $inventoryCostId: Int!) {
  orders: listInventory${templateType}OrderInlet (inventoryCostType: $inventoryCostType, inventoryCostId: $inventoryCostId) {${listResponse}}
}`

export const CREATE_INVENTORY_COST_ORDER_INLET = (templateType) => gql`mutation CREATE_INVENTORY_COST_ORDER_INLET ($inventoryCostType: String!, $inventoryCostId: Int!, $input: Inventory${templateType}OrderInletInput!) {
  createOrder: createInventory${templateType}OrderInlet (inventoryCostType: $inventoryCostType, inventoryCostId: $inventoryCostId, input: $input) {${listResponse}}
}`

export const UPDATE_INVENTORY_COST_ORDER_INLET = (templateType) => gql`mutation UPDATE_INVENTORY_COST_ORDER_INLET ($inventoryCostType: String!, $inventoryCostOrderId: Int!, $input: Inventory${templateType}OrderInletInput!) {
  updateOrder: updateInventory${templateType}OrderInlet (inventoryCostType: $inventoryCostType, inventoryCostOrderId: $inventoryCostOrderId, input: $input) {${listResponse}}
}`

export const DESTROY_INVENTORY_COST_ORDER_INLET = (templateType) => gql`mutation DESTROY_INVENTORY_COST_ORDER_INLET ($inventoryCostType: String!, $inventoryCostOrderId: Int!) {
  destroyOrder: destroyInventory${templateType}OrderInlet (inventoryCostType: $inventoryCostType, inventoryCostOrderId: $inventoryCostOrderId) {id}
}`
