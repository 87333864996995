<template>
  <div class="form-group">
    <legend class="col-form-label pt-0" v-if="label">
      {{ label }}
    </legend>

    <slot name="label"></slot>

    <div
      class="form-check"
      v-for="(option, i) in options"
      :key="i"
      :class="{'form-check-inline': inline}">

      <input
        class="form-check-input"
        type="checkbox"
        :id="getUid(i)"
        v-model="selected"
        :value="optionByKey(option)"
        v-bind="$attrs"
        v-on="listeners">

      <label class="form-check-label" :for="getUid(i)" :class="{'text-success': selected.includes(optionByKey(option))}">
        <slot v-bind="option">{{ option }}</slot>
      </label>
    </div>

    <div class="text-danger" v-if="validate.value">
      <small>{{ validate.text }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SgvInputCheckArray',
  props: {
    options: {
      type: Array,
      required: true
    },
    select: {
      type: String,
      required: false
    },
    value: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    inline: {
      type: Boolean,
      required: false
    },
    label: {
      type: String,
      required: false,
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    }
  },
  data() {
    return {
      uid: this.$form.uid(),
      selected: [],
    }
  },
  computed: {
    validate () {
      return this.validations.find(v => v.value) || {value: false, text: ''}
    },
    listeners () {
      return {
        ...this.$listeners,
        input: () => {},
        change: () => {}
      }
    }
  },
  methods: {
    getUid (index) {
      return this.uid + '-' + index.toString()
    },
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    }
  },
  watch: {
    selected(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    value: {
      handler (value) {
        this.selected = value
      },
      immediate: true
    }
  }
}
</script>

<style lang="css">
</style>
