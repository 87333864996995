import { capitalize } from '@/libs/form'


export default ({jobDescriptionType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `ContactJobDescription/${jobDescriptionType}`,
      name: `ContactJobDescription${capitalize(jobDescriptionType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        jobDescriptionType,
        title,
        subTitle,
        group,
      })
    },
    {
      path: `ContactJobDescription/${jobDescriptionType}/:jobDescriptionId`,
      name: `ContactJobDescription${capitalize(jobDescriptionType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        jobDescriptionId: Number(route.params.jobDescriptionId),
        templateType,
        jobDescriptionType,
        title,
        subTitle,
        group,
      })
    },
  ]
}
