import gql from 'graphql-tag'


const detailResponse = `
  id type name order parentId
  formType
  actualValue minValue maxValue
  choices {text value}
  cron instrumentCode
  deviceId deviceTopic device {id type code name}
`

export const CREATE_GROUP_ITEM = (templateType) => gql`mutation CREATE_GROUP_ITEM ($docType: String!, $parentId: Int!, $input: MeasurementTemplateGroupItemInput!) {
  item: createDoc${templateType}MeasurementTemplateGroupItem (docType: $docType, parentId: $parentId, input: $input) {${detailResponse}}
}`

export const UPDATE_GROUP_ITEM = (templateType) => gql`mutation UPDATE_GROUP_ITEM ($docType: String!, $id: Int!, $input: MeasurementTemplateGroupItemInput!) {
  item: updateDoc${templateType}MeasurementTemplateGroupItem (docType: $docType, id: $id, input: $input) {${detailResponse}}
}`

export const DESTROY_GROUP_ITEM = (templateType) => gql`mutation DESTROY_GROUP_ITEM ($docType: String!, $id: Int!) {
  item: destroyDoc${templateType}MeasurementTemplateGroupItem (docType: $docType, id: $id) {id}
}`

export const MOVE_GROUP_ITEM = (templateType) => gql`mutation MOVE_GROUP_ITEM ($docType: String!, $id: Int!, $step: Int!) {
  item: moveDoc${templateType}MeasurementTemplateGroupItem (docType: $docType, id: $id, step: $step) {id}
}`
