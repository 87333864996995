<template>
  <div class="card">
    <div class="card-body pb-1">
      <h5 class="card-title">
        รายเอกสาร
      </h5>

      <div class="mb-3">
        ยอดรวม:
        <span class="text-warning">
          {{netAmount | comma}}
        </span>

        เดบิต:
        <span class="text-success">
          {{debitAmount | comma}}
        </span>

        เครดิต:
        <span class="text-danger">
          {{creditAmount * -1 | comma}}
        </span>
      </div>

      <sgv-table
        headerless
        :items="itemsMapped"
        :headers="headers"
        :options.sync="options">

        <template slot-scope="{item, hidden}">
          <tr>
            <td v-if="hidden.approvedAt">
              <span v-if="item.approvedAt">{{item.approvedAt | date}}</span>
            </td>
            <td v-if="hidden.doc">
              <span v-if="item.doc">
                <router-link
                  class="text-decoration-none"
                  :to="toDoc(item)">
                  {{item.doc.code}}
                </router-link>
                <small>
                  <div class="text-primary">{{item.doc.name}}</div>
                </small>
              </span>
            </td>
            <td v-if="hidden.account">
              {{item.account.code}}
              <small>
                <div class="text-info">{{item.account.name}}</div>
              </small>
            </td>
            <td v-if="hidden.debit" class="text-success text-right">
              <span v-if="item.amount >= 0">{{item.amount | comma}}</span>
              <span v-else>-</span>
            </td>
            <td v-if="hidden.credit" class="text-danger text-right">
              <span v-if="item.amount < 0">{{item.amount * -1 | comma}}</span>
              <span v-else>-</span>
            </td>
          </tr>
        </template>
      </sgv-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {text: 'วันที่', value: 'approvedAt'},
        {text: 'เอกสาร', value: 'doc'},
        {text: 'บัญชี', value: 'account'},
        {text: 'เดบิต', value: 'debit'},
        {text: 'เครดิต', value: 'credit'},
      ],
      options: {
        headers: ['approvedAt', 'doc', 'account', 'debit', 'credit'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  computed: {
    itemsMapped () {
      return this.items.map((item,idx,items) => {
        const obj = {
          id: item.id,
          approvedAt: item.approvedAt,
          doc: {
            id: item.doc.id,
            type: item.doc.type,
            code: item.doc.code,
            name: item.doc.name,
          },
          account: {
            id: item.account.id,
            code: item.account.code,
            name: item.account.name,
          },
          amount: item.amount
        }

        if (idx > 0 && item.doc.id === items[idx-1].doc.id) {
          obj.approvedAt = null
          obj.doc = null
        }

        return obj
      })
    },
    netAmount () {
      return this.items.reduce((t,v) => t + +v.amount, 0)
    },
    debitAmount () {
      return this.items
      .filter(v => v.amount >= 0)
      .reduce((t,v) => t + +v.amount, 0)
    },
    creditAmount () {
      return this.items
      .filter(v => v.amount < 0)
      .reduce((t,v) => t + +v.amount, 0)
    },
  },
  methods: {
    toDoc (item) {
      return {
        name: `Doc${this.$form.capitalize(item.doc.type)}Detail`,
        params: {docId: item.doc.id}
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
