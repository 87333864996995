import gql from 'graphql-tag'


export const LIST_CATEGORY = (templateType) => gql`query LIST_CATEGORY ($contactType: String!, $q: FilterInput) {
  categories: listContact${templateType}Category (contactType: $contactType, q: $q) {
    id type code name
  }
}`

export const DETAIL_CATEGORY = (templateType) => gql`query DETAIL_CATEGORY ($contactType: String!, $categoryId: Int!) {
  category: detailContact${templateType}Category (contactType: $contactType, categoryId: $categoryId) {
    id type code name
  }
}`

export const CREATE_CATEGORY = (templateType) => gql`mutation CREATE_CATEGORY ($contactType: String!, $input: ContactCategoryInput!) {
  createCategory: createContact${templateType}Category (contactType: $contactType, input: $input) {
    id type code name
  }
}`

export const UPDATE_CATEGORY = (templateType) => gql`mutation UPDATE_CATEGORY ($contactType: String!, $categoryId: Int!, $input: ContactCategoryInput!) {
  updateCategory: updateContact${templateType}Category (contactType: $contactType, categoryId: $categoryId, input: $input) {
    id type code name
  }
}`

export const DESTROY_CATEGORY = (templateType) => gql`mutation DESTROY_CATEGORY ($contactType: String!, $categoryId: Int!) {
  destroyCategory: destroyContact${templateType}Category (contactType: $contactType, categoryId: $categoryId) {id}
}`

export const SYNC_CATEGORY = (templateType) => gql`mutation SYNC_CATEGORY ($contactType: String!, $docId: Int!, $categories: [Int]!) {
  syncCategory: syncContact${templateType}Category (contactType: $contactType, docId: $docId, categories: $categories)
}`
