<template>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="form-row">
        <sgv-input-text
          class="col-12 col-md-4"
          label="ค้นหา"
          v-model="search"
          placeholder="ตัวกรอง">
        </sgv-input-text>

        <sgv-input-date
          class="col-6 col-md-4"
          label="วันที่เริ่มต้น"
          v-model="startDate"
          isNull>
        </sgv-input-date>

        <sgv-input-date
          class="col-6 col-md-4"
          label="วันที่สิ้นสุด"
          v-model="endDate"
          isNull>
        </sgv-input-date>
      </div>

      <button
        type="button"
        class="btn btn-warning mr-2"
        @click="refetchList">
        ค้นหา
      </button>

      <button
        :disabled="addPending"
        type="button"
        class="btn btn-success mr-2"
        @dblclick="addAll">
        เพิ่มทั้งหมด
      </button>

      <button
        type="button"
        class="btn btn-danger float-right"
        @dblclick="destroyAll">
        ลบทั้งหมด
      </button>
    </div>

    <div class="col-12" v-if="docs.length > 0">
      <ol>
        <li v-for="item in docs" :key="item.id" class="mb-2">
          <span
            class="text-success px-1 pointer"
            @click="addOrder(item.id)">
            <fa icon="plus"></fa>
          </span>
          {{item.code}}: {{item.name}}
          <small>
            <ul>
              <li v-for="order in item.orders" :key="order.id">
                <span v-if="order.account" class="text-info">
                  {{order.account.code}} ({{order.account.name}}):
                </span>
                <span :class="{
                  'text-success': order.remaining > 0,
                  'text-danger': order.remaining < 0,
                }">{{order.remaining | comma}} บาท</span>
              </li>
            </ul>
          </small>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import {
  LIST_ORDER_SOCIAL_SECURITY_PAYABLE,
  CREATE_ORDER_SOCIAL_SECURITY,
  DESTROY_ORDER_SOCIAL_SECURITY
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    parentIds: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      docs: [],
      search: '',
      addPending: false,
      startDate: null,
      endDate: null
    }
  },
  methods: {
    refetchList () {
      this.$apollo.query({
        query: LIST_ORDER_SOCIAL_SECURITY_PAYABLE(this.templateType),
        variables: {
          docType: this.docType,
          search: this.search,
          startDate: this.$date.format(this.startDate).utc,
          endDate: this.$date.format(this.endDate, 0, 1).utc,
        },
        fetchPolicy: 'no-cache',
      }).then(res => {
        this.docs = res.data.docs
      })
    },
    addOrder (docId) {
      const doc = this.docs.find(d => d.id === docId)

      if (!doc) return

      const input = doc.orders.map(o => {
        return {
          orderId: o.id,
          totalPrice: o.remaining
        }
      })

      return this.$apollo.mutate({
        mutation: CREATE_ORDER_SOCIAL_SECURITY(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.refetchList()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    addAll () {
      const input = this.docs.flatMap(doc => doc.orders)
      .map(o => ({
        orderId: o.id,
        totalPrice: o.remaining
      }))

      if (input.length === 0) return

      this.addPending = true

      return this.$apollo.mutate({
        mutation: CREATE_ORDER_SOCIAL_SECURITY(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.refetchList()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
      .finally(() => {
        this.addPending = false
      })
    },
    destroyAll () {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER_SOCIAL_SECURITY(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          parentIds: this.parentIds
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
