import { capitalize } from '@/libs/form'

export default ({ assetType, title, subTitle, group }) => {
  const templateType = 'ChronowoodthAsset'
  return [
    {
      path: `chronowoodth/asset/${assetType}/`,
      name: `ChronowoodthAsset${capitalize(assetType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        assetType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
