import Vue from 'vue'

import App from './App.vue'
import store from './store'
import router from './router'

import VueClipboard from 'vue-clipboard2'
import Vuelidate from 'vuelidate'
import TextareaAutosize from 'vue-textarea-autosize'
import VueMeta from 'vue-meta'
import VuePapaParse from 'vue-papa-parse'

import './plugins/fontawesome'
import './plugins/bootstrap-vue'
import './plugins/axios'
import './plugins/toast'
import './plugins/components'
import './plugins/auth'

import './registerServiceWorker'
import { apolloProvider } from './plugins/apollo'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(VueClipboard)
Vue.use(Vuelidate)
Vue.use(TextareaAutosize)
Vue.use(VuePapaParse)

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
