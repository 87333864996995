import { capitalize } from '@/libs/form'


export default ({docType, title, subTitle, group}) => {
  const templateType = 'PurchaseExpenseCreditNote'
  return [
    {
      path: `doc/${docType}/config`,
      name: `Doc${capitalize(docType)}ConfigList`,
      component: () => import('../DocConfig.vue'),
      props: () => ({
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}/category/:categoryId`,
      name: `Doc${capitalize(docType)}CategoryDetail`,
      component: () => import(`@/views/doc_core/components/CategoryDetail.vue`),
      props: (route) => ({
        templateType,
        categoryId: Number(route.params.categoryId),
        docType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `doc/${docType}`,
      name: `Doc${capitalize(docType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        docType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `doc/${docType}/:docId`,
      name: `Doc${capitalize(docType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        templateType,
        docId: Number(route.params.docId),
        docType,
        title,
        subTitle,
        group
      })
    },
  ]
}
