export default {
  routers: [
    {
      title: 'ฝ่ายบุคคล',
      header: true,
      sections: [
        {
          title: 'พนักงาน',
          group: 'HrEmployee',
          sections: [
            {
              title: 'พนักงาน',
              template: 'ContactEmployee',
              options: { contactType: 'employee' }
            },
            {
              title: 'ตำแหน่งงาน',
              template: 'ContactJobDescriptionGeneral',
              options: { jobDescriptionType: 'general' }
            },
            {
              title: 'ใบร้องขอบุคคล',
              template: 'DocEmployeeRequest',
              options: { docType: 'emr' }
            },
            {
              title: 'ใบสมัครงาน',
              template: 'DocEmployeeApplication',
              options: { docType: 'emf' }
            },
            {
              title: 'ใบประเมินผลงาน',
              template: 'DocEmployeeEvaluation',
              options: { docType: 'emp' }
            },
            {
              title: 'ใบฝึกอบรม',
              template: 'DocEmployeeTraining',
              options: { docType: 'emt' }
            },
            {
              title: 'ใบขออนุมัติการฝึกอบรม',
              template: 'DocEmployeeTrainingRequest',
              options: { docType: 'ema' }
            },
            {
              title: 'ใบสำรวจความต้องการฝึกอบรม',
              template: 'DocEmployeeSurvey',
              options: { docType: 'emz' }
            },
            {
              title: 'ร้องเรียนพนักงาน',
              template: 'DocContactComplaint',
              options: { docType: 'hrc' }
            },
            {
              title: 'ลงทะเบียนพนักงาน',
              template: 'DocContactRegistration',
              options: { docType: 'emu' }
            },
            {
              title: 'บรรจุพนักงาน',
              template: 'DocContactRegistration',
              options: { docType: 'emy' }
            },
            {
              title: 'บันทึกสวัสดิการ',
              template: 'DocEmployeeWelfare',
              options: { docType: 'emw' }
            },
          ],
        },
        {
          title: 'เงินเดือน',
          group: 'HrPayroll',
          sections: [
            {
              title: 'ค่าใช้จ่าย',
              template: 'InventoryGeneral',
              options: { inventoryType: 'payroll' }
            },
            {
              title: 'ใบเงินเดือน',
              template: 'DocAttendancePayroll',
              options: {
                docType: 'eca',
                contactTypes: ['employee']
              }
            },
            {
              title: 'ใบจ่ายเงิน',
              template: 'DocPurchaseExpensePayment',
              options: { docType: 'epa' }
            },
            {
              title: 'ภ.ง.ด.1ก',
              template: 'DocAttendanceWithholding',
              options: { docType: 'hwth' }
            },
          ],
        }
      ]
    },
  ]
}
