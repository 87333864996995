import { capitalize } from '@/libs/form'
import List from '../List.vue'
import DocConfig from '../DocConfig.vue'


export default ({docType, title, subTitle, group}) => {
  const templateType = 'AccountAccruedCreditor'
  return [
    {
      path: `doc/${docType}/config`,
      name: `Doc${capitalize(docType)}ConfigList`,
      component: DocConfig,
      props: () => ({
        templateType,
        docType,
        title, subTitle, group,
      })
    },
    {
      path: `doc/${docType}`,
      name: `Doc${capitalize(docType)}List`,
      component: List,
      props: () => ({
        templateType,
        docType,
        title, subTitle, group,
      })
    }
  ]
}
