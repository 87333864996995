<template>
  <sgv-table
    :rKey="rKey"
    :items="items"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden}">
      <tr class="">
        <td v-if="hidden.code">
          <router-link
            class="text-decoration-none no-wrap"
            :to="toDetail(item.id)">
            {{item.code}}
          </router-link>
        </td>
        <td v-if="hidden.name">
          {{item.name}}
        </td>
        <td v-if="hidden.predictOutRangeOrders">
          <ol class="mb-0">
            <li v-for="order in item.predictOutRangeOrders" :key="order.id">
              {{order.name}}:
              <span class="text-danger">{{order.outputPredictPercent}}</span>
            </li>
          </ol>
        </td>
        <td v-if="hidden.docOutRangeOrders">
          <ol class="mb-0">
            <li v-for="order in item.docOutRangeOrders" :key="order.id">
              {{order.name}}:
              <span class="text-danger">{{order.outputDocPercent}}</span>
            </li>
          </ol>
        </td>
      </tr>
    </template>

    <span slot="action">
      <router-link
        v-if="$auth.hasRole(`inventory:${inventoryCostType}:add`)"
        :to="toDetail(0)">
        <button
          type="button"
          class="btn btn-link text-success">
          เพิ่ม
        </button>
      </router-link>

      <button
        type="button"
        class="btn btn-link text-info"
        @click="getCalculations">
        อัพเดทต้นทุน
      </button>
    </span>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_INVENTORY_COST,
  LIST_INVENTORY_COST_CALCULATION,
  CALCULATE_INVENTORY_COST
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    inventoryCostType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      detailView: `InventoryCost${this.$form.capitalize(this.inventoryCostType)}Detail`,
      rKey: `InventoryCost${this.$form.capitalize(this.inventoryCostType)}ListCategory`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
        {text: 'simulate', value: 'predictOutRangeOrders'},
        {text: 'เอกสาร', value: 'docOutRangeOrders'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name', 'predictOutRangeOrders', 'docOutRangeOrders'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_INVENTORY_COST(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          inventoryCostType: this.inventoryCostType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}

      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {inventoryCostId: id}
      }
    },
    getCalculations () {
      this.$apollo.query({
        query: LIST_INVENTORY_COST_CALCULATION(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType
        },
        fetchPolicy: 'no-cache'
      })
      .then(async res => {
        const items = res.data.items
        for (let i = 0; i < items.length; i++) {
          await this.calculateData(items[i])
        }
      })
      .catch(this.$toasted.global.error)
    },
    async calculateData (inventoryCostId) {
      return this.$apollo.mutate({
        mutation: CALCULATE_INVENTORY_COST(this.templateType),
        variables: {
          inventoryCostType: this.inventoryCostType,
          inventoryCostId
        }
      })
      .then((res) => {
        const item = res.data.calculateItem
        this.$toasted.global.success(`อัพเดท ${item.code} สำเร็จ`)
      })
      .catch(this.$toasted.global.error)
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: `${this.group}`})
  }
}
</script>

<style lang="css" scoped>
</style>
