<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="mb-2" v-if="item.inventory">
          {{item.inventory.code}} ({{item.inventory.name}})
          <span v-if="item.isDefault" class="text-success">
            --- ค่าเริ่มต้น
          </span>
        </div>
        <div>
          <small>
            <div class="text-warning">จำนวน: {{item.options.qty}} {{item.inventory ? item.inventory.unit.name : '-'}}</div>
            <div class="text-success">ราคา/หน่วย: {{item.options.price}} บาท</div>
            <div class="text-info">บัญชี: {{item.account.code}} ({{item.account.name}})</div>
          </small>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <DocConfigFormInventory
            :docType="docType"
            :templateType="templateType"
            ref="inventory"
            class="col-12"
            select="id"
            v-model="formData.inventoryId"
            :types="menu.inventoryTypes"
            :validations="[
              {text: 'required!', value: $v.formData.inventoryId.$dirty && !$v.formData.inventoryId.required}
            ]">
          </DocConfigFormInventory>

          <sgv-input-number
            class="col-6"
            label="จำนวน"
            minus
            v-model="formData.options.qty"
            :precision="2"
            :validations="[
              {text: 'required!', value: $v.formData.options.qty.$dirty && !$v.formData.options.qty.required}
            ]">
          </sgv-input-number>

          <sgv-input-currency
            class="col-6"
            label="ราคา"
            minus
            :precision="2"
            v-model="formData.options.price"
            :validations="[
              {text: 'required!', value: $v.formData.options.price.$dirty && !$v.formData.options.price.required}
            ]">
          </sgv-input-currency>

          <DocConfigFormAccount
            label="บัญชี"
            class="col-12"
            select="id"
            :docType="docType"
            :templateType="templateType"
            v-model="formData.accountId"
            :validations="[
              {text: 'required!', value: $v.formData.accountId.$dirty && !$v.formData.accountId.required}
            ]">
          </DocConfigFormAccount>
        </div>

        <div class="form-group">
          <sgv-input-check
            class="form"
            label="ค่าเริ่มต้น"
            inline
            v-model="formData.isDefault">
          </sgv-input-check>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormAccount from '@/views/doc_core/components/DocConfigFormAccount'
import DocConfigFormInventory from '@/views/doc_core/components/DocConfigFormInventory'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    inventory: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        inventoryId: null,
        accountId: null,
        options: {
          qty: '',
          price: '',
        },
        isDefault: false
      }
    }
  },
  validations: {
    formData: {
      inventoryId: {required},
      accountId: {required},
      options: {
        qty: {required},
        price: {required}
      }
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.$v.formData.$reset()
          this.toggle = false
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.accountId = this.item.accountId
          this.formData.options.qty = this.item.options.qty
          this.formData.options.price = this.item.options.price
          this.formData.isDefault = this.item.isDefault

          this.formData.inventoryId = this.item.inventory.id
          this.$refs.inventory.setGroup(this.item.inventory.type)
        })
      } else if (value && this.isForm) {
        this.$nextTick(() => {
          if (this.inventory) {
            this.formData.inventoryId = this.inventory.id
            this.$refs.inventory.setGroup(this.inventory.type)
          }
        })
      } else {
        this.formData.inventoryId = null
        this.formData.accountId = null
        this.formData.options.qty = ''
        this.formData.options.price = ''
        this.formData.isDefault = false
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  },
  components: {
    DocConfigFormAccount,
    DocConfigFormInventory
  }
}
</script>

<style lang="css" scoped>
</style>
