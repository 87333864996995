import gql from 'graphql-tag'


const orderResponse = `
  id type code remark invoiceRef
  approvedAt closedAt
  contact {id code name taxCode branch}
  totalPrice vatPrice
`

export const LIST_ORDER_PURCHASE = (templateType) => gql`query LIST_ORDER_PURCHASE ($docType: String!, $docId: Int!, $q: FilterInput) {
  orders: listDoc${templateType}OrderPurchase (docType: $docType, docId: $docId, q: $q) {${orderResponse}}
}`

export const LIST_ORDER_PURCHASE_PAYABLE = (templateType) => gql`query LIST_ORDER_PURCHASE_PAYABLE ($docType: String!, $search: String, $startDate: DateTime, $endDate: DateTime) {
  docs: listDoc${templateType}OrderPurchasePayable (docType: $docType, search: $search, startDate: $startDate, endDate: $endDate) {
    id type code name remark contactId
    orders {
      id type name total paid pending remaining
      account {
        id code name
      }
    }
  }
}`


export const CREATE_ORDER_PURCHASE = (templateType) => gql`mutation CREATE_ORDER_PURCHASE ($docType: String!, $docId: Int!, $input: [Doc${templateType}OrderPayableInput]!) {
  orders: createDoc${templateType}OrderPurchase (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const DESTROY_ORDER_PURCHASE = (templateType) => gql`mutation DESTROY_ORDER_PURCHASE ($docType: String!, $docId: Int!, $parentIds: [Int!]!) {
  destroyOrders: destroyDoc${templateType}OrderPurchase (docType: $docType, docId: $docId, parentIds: $parentIds) {id}
}`
