import { render, staticRenderFns } from "./DetailOrderWithholdingPayable.vue?vue&type=template&id=425b2a48&scoped=true&"
import script from "./DetailOrderWithholdingPayable.vue?vue&type=script&lang=js&"
export * from "./DetailOrderWithholdingPayable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "425b2a48",
  null
  
)

export default component.exports