import { capitalize } from '@/libs/form'

export default ({stockAssetType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `stockAsset/${stockAssetType}`,
      name: `StockAsset${capitalize(stockAssetType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        stockAssetType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `stockAsset/${stockAssetType}/:stockAssetId`,
      name: `StockAsset${capitalize(stockAssetType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        stockAssetId: Number(route.params.stockAssetId),
        templateType,
        stockAssetType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
