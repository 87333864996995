var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[(!_vm.toggle)?_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"card-text"},[_c('span',{staticClass:"mx-1 pointer float-right",on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('fa',{staticClass:"text-warning",attrs:{"icon":"pencil-alt"}})],1),(_vm.item.inventory)?_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.item.inventory.code)+" ("+_vm._s(_vm.item.inventory.name)+") "),(_vm.item.isDefault)?_c('span',{staticClass:"text-success"},[_vm._v(" --- ค่าเริ่มต้น ")]):_vm._e()]):_vm._e(),_c('div',[_c('small',[_c('div',{staticClass:"text-warning"},[_vm._v("จำนวน: "+_vm._s(_vm.item.options.qty)+" "+_vm._s(_vm.item.inventory ? _vm.item.inventory.unit.name : '-'))]),_c('div',{staticClass:"text-success"},[_vm._v("ราคา/หน่วย: "+_vm._s(_vm.item.options.price)+" บาท")]),_c('div',{staticClass:"text-info"},[_vm._v("บัญชี: "+_vm._s(_vm.item.account.code)+" ("+_vm._s(_vm.item.account.name)+")")])])])])]):_vm._e(),(_vm.toggle)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text"},[_c('div',{staticClass:"form-row"},[_c('DocConfigFormInventory',{ref:"inventory",staticClass:"col-12",attrs:{"docType":_vm.docType,"templateType":_vm.templateType,"select":"id","types":_vm.menu.inventoryTypes,"validations":[
            {text: 'required!', value: _vm.$v.formData.inventoryId.$dirty && !_vm.$v.formData.inventoryId.required}
          ]},model:{value:(_vm.formData.inventoryId),callback:function ($$v) {_vm.$set(_vm.formData, "inventoryId", $$v)},expression:"formData.inventoryId"}}),_c('sgv-input-number',{staticClass:"col-6",attrs:{"label":"จำนวน","minus":"","precision":2,"validations":[
            {text: 'required!', value: _vm.$v.formData.options.qty.$dirty && !_vm.$v.formData.options.qty.required}
          ]},model:{value:(_vm.formData.options.qty),callback:function ($$v) {_vm.$set(_vm.formData.options, "qty", $$v)},expression:"formData.options.qty"}}),_c('sgv-input-currency',{staticClass:"col-6",attrs:{"label":"ราคา","minus":"","precision":2,"validations":[
            {text: 'required!', value: _vm.$v.formData.options.price.$dirty && !_vm.$v.formData.options.price.required}
          ]},model:{value:(_vm.formData.options.price),callback:function ($$v) {_vm.$set(_vm.formData.options, "price", $$v)},expression:"formData.options.price"}}),_c('DocConfigFormAccount',{staticClass:"col-12",attrs:{"label":"บัญชี","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
            {text: 'required!', value: _vm.$v.formData.accountId.$dirty && !_vm.$v.formData.accountId.required}
          ]},model:{value:(_vm.formData.accountId),callback:function ($$v) {_vm.$set(_vm.formData, "accountId", $$v)},expression:"formData.accountId"}})],1),_c('div',{staticClass:"form-group"},[_c('sgv-input-check',{staticClass:"form",attrs:{"label":"ค่าเริ่มต้น","inline":""},model:{value:(_vm.formData.isDefault),callback:function ($$v) {_vm.$set(_vm.formData, "isDefault", $$v)},expression:"formData.isDefault"}})],1),(_vm.isForm)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.updateData}},[_vm._v(" เพิ่ม ")]):_vm._e(),(!_vm.isForm)?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.updateData}},[_vm._v(" แก้ไข ")]):_vm._e(),(_vm.toggle)?_c('button',{staticClass:"btn btn-primary ml-2",attrs:{"type":"button"},on:{"click":_vm.cancelData}},[_vm._v(" ยกเลิก ")]):_vm._e(),(!_vm.isForm)?_c('button',{staticClass:"btn btn-danger ml-2 float-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyData(_vm.item.id)}}},[_vm._v(" ลบ ")]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }