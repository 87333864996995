import { capitalize } from '@/libs/form'

export default ({ productType, assetType, priceType, title, subTitle, group }) => {
  const templateType = 'ChronowoodthProduct'
  return [
    {
      path: `chronowoodth/product/${productType}/`,
      name: `ChronowoodthProduct${capitalize(productType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        productType,
        assetType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `chronowoodth/product/${productType}/:productId`,
      name: `ChronowoodthProduct${capitalize(productType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        templateType,
        productType,
        assetType,
        priceType,
        title, subTitle, group,
        productId: Number(route.params.productId)
      })
    },
  ]
}
