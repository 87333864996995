<template>
  <div class="row">
    <component
      class="col-12 col-sm-6 mb-4"
      v-for="label in labels"
      :key="label.component"
      :is="label.component"
      :inventoryId="inventoryId"
      :inventoryType="inventoryType"
      :templateType="templateType">
    </component>
  </div>
</template>

<script>
import PrintLabelContactCompany from './PrintLabelContactCompany'
import PrintLabelInventoryCaution from './PrintLabelInventoryCaution'
import PrintLabelInventoryBarcodeDetail from './PrintLabelInventoryBarcodeDetail'
import PrintLabelInventoryBarcodeCaution from './PrintLabelInventoryBarcodeCaution'

export default {
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      labels: [
        {value: 'inventoryBarcodeDetail', component: 'PrintLabelInventoryBarcodeDetail'},
        {value: 'inventoryBarcodeCaution', component: 'PrintLabelInventoryBarcodeCaution'},
        {value: 'inventoryCaution', component: 'PrintLabelInventoryCaution'},
        {value: 'contactCompany', component: 'PrintLabelContactCompany'},
      ]
    }
  },
  components: {
    PrintLabelContactCompany,
    PrintLabelInventoryCaution,
    PrintLabelInventoryBarcodeDetail,
    PrintLabelInventoryBarcodeCaution,
  }
}
</script>

<style lang="css">
</style>
