import gql from 'graphql-tag'

export const DP_PARENTS = (templateType) => gql`query DP_PARENTS ($inventoryType: String!, $q: FilterInput) {
  items: listInventory${templateType}DropdownParent (inventoryType: $inventoryType, q: $q) {
    id type code name
  }
}`

export const LIST_INVENTORY_JUNCTION = (templateType) => gql`query LIST_INVENTORY_JUNCTION ($inventoryType: String!, $q: FilterInput) {
  inventories: listInventory${templateType}Balance (inventoryType: $inventoryType, q: $q) {
    id type
    junctionParents {
      id type code name
      balance {current future}
      unitId unit {id name}
    }
    junctionChildren {
      id type code name
      balance {current future}
      unitId unit {id name}
    }
  }
}`

export const CREATE_INVENTORY_JUNCTION_RELATION = (templateType) => gql`mutation CREATE_INVENTORY_JUNCTION_RELATION ($inventoryType: String!, $parentId: Int!, $childId: Int!) {
  junction: createInventory${templateType}JunctionRelation (inventoryType: $inventoryType, parentId: $parentId, childId: $childId) {parentId childId}
}`

export const DESTROY_INVENTORY_JUNCTION_RELATION = (templateType) => gql`mutation DESTROY_INVENTORY_JUNCTION_RELATION ($inventoryType: String!, $parentId: Int!, $childId: Int!) {
  junction: destroyInventory${templateType}JunctionRelation (inventoryType: $inventoryType, parentId: $parentId, childId: $childId) {parentId childId}
}`
