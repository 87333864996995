export default {
  routers: [
    {
      title: 'ฝ่ายขาย',
      header: true,
      sections: [
        {
          title: 'ร้านค้า',
          group: 'DocSaleWholesale',
          sections: [
            {
              title: 'ใบราคาขาย',
              template: 'DocSalePriceList',
              options: { docType: 'plt' }
            },
            {
              title: 'ใบเสนอราคา',
              template: 'DocSaleQuotation',
              options: { docType: 'qt' }
            },
            {
              title: 'ใบสั่งขาย',
              template: 'DocSaleSalesorder',
              options: {
                docType: 'so',
                invoiceType: 'iv',
                salesPriceType: 'sop',
                receiptType: 're',
                billingType: 'bi'
              }
            },
            {
              title: 'ใบส่งสินค้า',
              template: 'DocSaleInvoice',
              options: {
                docType: 'iv',
                salesOrderType: 'so',
                billType: 'bi',
                receiptType: 're'
              }
            },
            {
              title: 'ใบวางบิล',
              template: 'DocSaleBilling',
              options: { docType: 'bi' }
            },
            {
              title: 'ใบเสร็จรับเงิน',
              template: 'DocSaleReceipt',
              options: { docType: 're' }
            },
            {
              title: 'ใบเสร็จรับเงินมัดจำ',
              template: 'DocSaleReceiptDeposit',
              options: { docType: 'red' }
            },
            {
              title: 'ใบรับคืน/ลดหนี้',
              template: 'DocSaleCreditNote',
              options: { docType: 'sr' }
            },
            {
              title: 'ใบรับคืน/เพิ่มหนี้',
              template: 'DocSaleDebitNote',
              options: { docType: 'cp' }
            },
            {
              title: 'ใบเบิกอุปกรณ์ติดตั้ง',
              template: 'DocSaleEquipment',
              options: { docType: 'soe' }
            },
            {
              title: 'ใบส่งสินค้าชั่วคราว',
              template: 'DocSaleTemporaryInvoice',
              options: {
                docType: 'tiv',
              }
            },
          ],
        },
        {
          title: 'ค้าปลีก',
          group: 'DocSaleRetail',
          sections: [
            {
              title: 'ใบราคาขาย',
              template: 'DocSalePriceList',
              options: { docType: 'rplt' }
            },
            {
              title: 'ใบเสนอราคา',
              template: 'DocSaleQuotation',
              options: { docType: 'rqt' }
            },
            {
              title: 'ใบสั่งขาย',
              template: 'DocSaleSalesorder',
              options: {
                docType: 'rso',
                invoiceType: 'riv',
                salesPriceType: 'rsop',
                receiptType: 'rre',
                billingType: 'rbi'
              }
            },
            {
              title: 'ใบส่งสินค้า',
              template: 'DocSaleInvoice',
              options: {
                docType: 'riv',
                salesOrderType: 'rso',
                billType: 'rbi',
                receiptType: 'rre'
              }
            },
            {
              title: 'ใบวางบิล',
              template: 'DocSaleBilling',
              options: { docType: 'rbi' }
            },
            {
              title: 'ใบเสร็จรับเงิน',
              template: 'DocSaleReceipt',
              options: { docType: 'rre' }
            },
            {
              title: 'ใบเสร็จรับเงินมัดจำ',
              template: 'DocSaleReceiptDeposit',
              options: { docType: 'rred' }
            },
            {
              title: 'ใบรับคืน/ลดหนี้',
              template: 'DocSaleCreditNote',
              options: { docType: 'rsr' }
            },
            {
              title: 'ใบรับคืน/เพิ่มหนี้',
              template: 'DocSaleDebitNote',
              options: { docType: 'rcp' }
            },
            {
              title: 'ใบเบิกอุปกรณ์ติดตั้ง',
              template: 'DocSaleEquipment',
              options: { docType: 'rsoe' }
            },
            {
              title: 'ค่าจ้างติดตั้ง',
              template: 'DocPurchaseCash',
              options: { docType: 'pco' }
            },
            {
              title: 'ใบส่งสินค้าชั่วคราว',
              template: 'DocSaleTemporaryInvoice',
              options: {
                docType: 'rtiv',
              }
            },
          ],
        },
        {
          title: 'Dohome',
          group: 'DocSaleDohome',
          sections: [
            {
              title: 'ใบราคาขาย',
              template: 'DocSalePriceList',
              options: { docType: 'dplt' }
            },
            {
              title: 'ใบเสนอราคา',
              template: 'DocSaleQuotation',
              options: { docType: 'dqt' }
            },
            {
              title: 'ใบสั่งขาย',
              template: 'DocSaleSalesorder',
              options: {
                docType: 'dso',
                invoiceType: 'div',
                salesPriceType: 'dsop',
                receiptType: 'dre',
                billingType: 'dbi'
              }
            },
            {
              title: 'ใบส่งสินค้า',
              template: 'DocSaleInvoice',
              options: {
                docType: 'div',
                salesOrderType: 'dso',
                billType: 'dbi',
                receiptType: 'dre'
              }
            },
            {
              title: 'ใบวางบิล',
              template: 'DocSaleBilling',
              options: { docType: 'dbi' }
            },
            {
              title: 'ใบเสร็จรับเงิน',
              template: 'DocSaleReceipt',
              options: { docType: 'dre' }
            },
            {
              title: 'ใบรับคืน/ลดหนี้',
              template: 'DocSaleCreditNote',
              options: { docType: 'dsr' }
            },
            {
              title: 'ใบรับคืน/เพิ่มหนี้',
              template: 'DocSaleDebitNote',
              options: { docType: 'dcp' }
            },
          ],
        },
        {
          title: 'GlobalHouse',
          group: 'DocSaleGlobalhouse',
          sections: [
            {
              title: 'ใบราคาขาย',
              template: 'DocSalePriceList',
              options: { docType: 'gplt' }
            },
            {
              title: 'ใบเสนอราคา',
              template: 'DocSaleQuotation',
              options: { docType: 'gqt' }
            },
            {
              title: 'ใบสั่งขาย',
              template: 'DocSaleSalesorder',
              options: {
                docType: 'gso',
                invoiceType: 'giv',
                salesPriceType: 'gsop',
                receiptType: 'gre',
                billingType: 'gbi'
              }
            },
            {
              title: 'ใบส่งสินค้า',
              template: 'DocSaleInvoice',
              options: {
                docType: 'giv',
                salesOrderType: 'gso',
                billType: 'gbi',
                receiptType: 'gre'
              }
            },
            {
              title: 'ใบวางบิล',
              template: 'DocSaleBilling',
              options: { docType: 'gbi' }
            },
            {
              title: 'ใบเสร็จรับเงิน',
              template: 'DocSaleReceipt',
              options: { docType: 'gre' }
            },
            {
              title: 'ใบรับคืน/ลดหนี้',
              template: 'DocSaleCreditNote',
              options: { docType: 'gsr' }
            },
            {
              title: 'ใบรับคืน/เพิ่มหนี้',
              template: 'DocSaleDebitNote',
              options: { docType: 'gcp' }
            },
          ],
        },
        {
          title: 'Software',
          group: 'DocSaleSoftware',
          sections: [
            {
              title: 'ใบราคาขาย',
              template: 'DocSalePriceList',
              options: { docType: 'wplt' }
            },
            {
              title: 'ใบเสนอราคา',
              template: 'DocSaleQuotation',
              options: { docType: 'wqt' }
            },
            {
              title: 'ใบสั่งขาย',
              template: 'DocSaleSalesorder',
              options: {
                docType: 'wso',
                invoiceType: 'wiv',
                salesPriceType: 'wsop',
                receiptType: 'wre',
                billingType: 'wbi'
              }
            },
            {
              title: 'ใบส่งสินค้า',
              template: 'DocSaleInvoice',
              options: {
                docType: 'wiv',
                salesOrderType: 'wso',
                billType: 'wbi',
                receiptType: 'wre'
              }
            },
            {
              title: 'ใบวางบิล',
              template: 'DocSaleBilling',
              options: { docType: 'wbi' }
            },
            {
              title: 'ใบเสร็จรับเงิน',
              template: 'DocSaleReceipt',
              options: { docType: 'wre' }
            },
            {
              title: 'ใบรับคืน/ลดหนี้',
              template: 'DocSaleCreditNote',
              options: { docType: 'wsr' }
            },
            {
              title: 'ใบรับคืน/เพิ่มหนี้',
              template: 'DocSaleDebitNote',
              options: { docType: 'wcp' }
            },
          ],
        },
        {
          title: 'SPC',
          group: 'SPC',
          sections: [
            {
              title: 'แบบสำรวจหน้างาน',
              template: 'DocSpcSiteSurvey',
              options: {
                docType: 'spca'
              }
            },
            {
              title: 'หนังสือแจ้งการเข้าติดตั้ง',
              template: 'DocSpcInstallation',
              options: {
                docType: 'spcb'
              }
            },
            {
              title: 'ใบแจ้งการปรับปรุงพื้นที่',
              template: 'DocSpcNotice',
              options: {
                docType: 'spcn'
              }
            },
            {
              title: 'ใบส่งมอบงาน',
              template: 'DocSpcDeliveryNote',
              options: {
                docType: 'spcd'
              }
            }
          ]
        },
        {
          title: 'อื่นๆ',
          group: 'DocSaleOther',
          sections: [
            {
              title: 'เช็คค้างรับ',
              template: 'DocSaleReceipt',
              options: { docType: 'rchq' }
            },

            {
              title: 'ส่งจดมหาย',
              template: 'DocSaleThaipost',
              options: { docType: 'thpd' }
            },
            {
              title: 'บันทึกการเข้าพบลูกค้า',
              template: 'DocContactSurvey',
              options: { docType: 'ssi' }
            },

            {
              title: 'แบบสำรวจความพึงพอใจร้านค้า',
              template: 'DocContactSatisfaction',
              options: { docType: 'syc' }
            },
            {
              title: 'แบบสำรวจความพึงพอใจงานติดตั้ง',
              template: 'DocContactSatisfaction',
              options: { docType: 'syi' }
            },
            {
              title: 'แบบประเมินงานตั้งติดตั้ง',
              template: 'DocContactSurvey',
              options: { docType: 'ssc' }
            },
            {
              title: 'คูปองสุ่ม LuckyDraw',
              template: 'CouponLuckyDraw',
              options: { couponType: 'copl' }
            },
          ],
        }
      ]
    },
  ]
}
