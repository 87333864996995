<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}} - ตั้งค่า</h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="">
      <div class="form-row">
        <sgv-input-select
          class="col-12"
          label="ประเภท"
          :options="options"
          select="id"
          v-model="selected">
          <template slot-scope="option">
            {{option.id}} ({{option.description}})
          </template>
        </sgv-input-select>
      </div>

      <InventoryConfigList
        v-if="selected && menus[selected]"
        :inventoryType="inventoryType"
        :templateType="templateType"
        :selected="selected"
        :menu="menus[selected]">
      </InventoryConfigList>
    </div>
  </div>
</template>

<script>
import InventoryConfigList from './InventoryConfigList.vue'
import { LIST_INVENTORY_CONFIG_MENU } from '../graph/inventory_config'


export default {
  metaInfo () {
    return {
      title: `${this.title}-ตั้งค่า`
    }
  },
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      inventoryConfigs: [],
      items: [],
      menus: {},
      selected: 'userManual'
    }
  },
  apollo: {
    menus: {
      query () {
        return LIST_INVENTORY_CONFIG_MENU(this.templateType)
      },
      variables () {
        return {inventoryType: this.inventoryType}
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    options () {
      return Object.keys(this.menus).map(v => {
        return {
          id: v,
          attributes: this.menus[v].attributes,
          description: this.menus[v].description,
          optionals: this.menus[v].optionals,
          inventoryTypes: this.menus[v].inventoryTypes || [],
          deviceTypes: this.menus[v].deviceTypes || []
        }
      })
    },
  },
  components: {
    InventoryConfigList
  }
}
</script>

<style lang="css">
</style>
