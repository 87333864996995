<template>
  <div class="input-group">
    <select
      class="custom-select custom-height"
      v-model="selected">
      <option v-for="(item,idx) in items" :key="idx" :value="item.text">
        {{item.text}}
      </option>
    </select>
    <div
      v-if="$auth.hasRole(`doc:${docType}:add`)"
      class="input-group-append">
      <label class="input-group-text pointer" @click="toDetail">
        <fa icon="pencil-alt" class="text-warning" v-if="foundCategory"></fa>
        <fa icon="plus" class="text-success" v-else></fa>
      </label>
    </div>
  </div>
</template>

<script>
import { LIST_CATEGORY } from '../graph/category'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      items: [
        ...this.options
      ],
      selected: 'ทั้งหมด',
      categories: []
    }
  },
  apollo: {
    categories: {
      query () {
        return LIST_CATEGORY(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          q: {
            order: 'code'
          }
        }
      },
      fetchPolicy: 'network-only',
      result (res) {
        this.items = [
          ...this.options.filter(v => !v.role || this.$auth.hasRole(v.role)),
          ...res.data.categories.map(v => ({
            text: v.name,
            docStatus: null,
            categoryId: v.id
          }))
        ]

        const found = this.items.find(v => v.text === this.value)

        if (!found) {
          this.$emit('input', 'ทั้งหมด')
          this.$emit('docStatus', null)
          this.$emit('categoryId', 0)
        } else {
          this.$emit('input', found.text)
          this.$emit('docStatus', found.docStatus)
          this.$emit('categoryId', found.categoryId)
        }
      }
    },
  },
  computed: {
    foundCategory () {
      return this.categories.find(v => v.name === this.value)
    }
  },
  methods: {
    toDetail () {
      this.$router.push({
        name: `Doc${this.$form.capitalize(this.docType)}CategoryDetail`,
        params: {categoryId: this.foundCategory ? this.foundCategory.id : 0},
        query: {redirect: this.$route.name}
      })
    }
  },
  watch: {
    value: {
      handler (value) {
        this.selected = value
      },
      immediate: true
    },
    selected (value) {
      const found = this.items.find(v => v.text === value)
      this.$emit('input', value)
      this.$emit('docStatus', found?.docStatus)
      this.$emit('categoryId', found?.categoryId)
    }
  }
}
</script>

<style lang="css" scoped>
.custom-height {
  max-height: 35.5px;
}
</style>
