export default {
  routers: [
    {
      title: 'ทั่วไป',
      header: true,
      sections: [
        {
          title: 'ผู้ใช้',
          group: 'Profile',
          sections: [
            {
              title: 'ข่าวสาร',
              template: 'ProfileAnnouncement',
              options: { profileType: 'hr' }
            },
            {
              title: 'ข้อมูลผู้ใช้',
              template: 'ProfileUser',
              options: { profileType: 'user' }
            },
          ],
        },
        {
          title: 'บัญชี',
          group: 'Account',
          sections: [
            {
              title: 'ทรัพย์สินทั่วไป',
              template: 'StockAssetGeneral',
              options: { stockAssetType: 'general' }
            },
            {
              title: 'ใบบันทึกค่าเสื่อม',
              template: 'DocStockAssetDeduction',
              options: { docType: 'ast' }
            },
            {
              title: 'ใบบันทึกทั่วไป',
              template: 'DocAccountGeneral',
              options: { docType: 'cts' }
            },
            {
              title: 'ใบปรับสต๊อคสินค้า',
              template: 'DocAccountGeneral',
              options: { docType: 'zs' }
            },
            {
              title: 'ผังบัญชี',
              template: 'AccountGeneral',
              options: { accountType: 'general' }
            },
            {
              title: 'หน่วยนับ',
              template: 'UnitGeneral',
              options: { unitType: 'general' }
            },
            {
              title: 'ประเทศ',
              template: 'CountryGeneral',
              options: { countryType: 'general' }
            },
            {
              title: 'สรุปงานซื้อ',
              template: 'DocAccountSummary',
              options: { docType: 'sump' }
            }
          ],
        },
        {
          title: 'ภาษี',
          group: 'Tax',
          sections: [
            {
              title: 'ภ.พ.30',
              template: 'DocAccountVat',
              options: { docType: 'vat' }
            },
            {
              title: 'ภ.ง.ด.1',
              template: 'DocAccountPersonalTax',
              options: { docType: 'era' }
            },
            {
              title: 'ภ.ง.ด.3',
              template: 'DocAccountWithholdingPerson',
              options: { docType: 'pwht' }
            },
            {
              title: 'ภ.ง.ด.53',
              template: 'DocAccountWithholdingCompany',
              options: { docType: 'swht' }
            },
            {
              title: 'ประกันสังคม',
              template: 'DocAccountSocialSecurity',
              options: { docType: 'erb' }
            },
            {
              title: 'กยศ',
              template: 'DocAccountStudentLoan',
              options: { docType: 'erc' }
            },
            {
              title: 'นำเงินส่งรัฐ',
              template: 'DocPurchaseExpensePayment',
              options: { docType: 'ptv' }
            },
          ],
        },
        {
          title: 'ธนาคาร',
          group: 'BankStatement',
          sections: [
            {
              title: 'ใบโอนเงินภายใน',
              template: 'DocAccountGeneral',
              options: { docType: 'ctf' }
            },
            {
              title: 'ธนาคารกรุงศรี (BAY)',
              template: 'BankStatementGeneral',
              options: { bankStatementType: 'BAYSME-CUSTOMER' }
            },
            {
              title: 'ธนาคารกรุงเทพ (BBL)',
              template: 'BankStatementGeneral',
              options: { bankStatementType: 'BBLSME-SGV' }
            },
            {
              title: 'ธนาคารกสิกรไทย (KBANK)',
              template: 'BankStatementGeneral',
              options: { bankStatementType: 'KBANKSME-SGV' }
            },
            {
              title: 'ธนาคารไทยพาณิชย์ (SCB)',
              template: 'BankStatementGeneral',
              options: { bankStatementType: 'SCBSME-SGV' }
            },
            {
              title: 'ค่าใช้จ่าย (BAY)',
              template: 'BankStatementGeneral',
              options: { bankStatementType: 'BAYSME-EXPENSE' }
            },
            {
              title: 'ปิดยอด (BAY)',
              template: 'BankStatementGeneral',
              options: { bankStatementType: 'BAYSME-SAVING' }
            },
          ],
        },
        {
          title: 'ผู้ตรวจบัญชี',
          group: 'AccountAuditor',
          sections: [
            {
              title: 'งบทดลอง',
              template: 'AccountTrialBalance',
              options: { accountTrialBalanceType: 'general' }
            },
            {
              title: 'เจ้าหนี้คงค้าง',
              template: 'DocAccountAccruedCreditor',
              options: { docType: 'sumc' }
            },
            {
              title: 'ลูกหนี้คงค้าง',
              template: 'DocAccountAccruedDebitor',
              options: { docType: 'sumd' }
            },
            {
              title: 'ภาษีซื้อคงค้าง',
              template: 'DocAccountAccruedDebitor',
              options: { docType: 'sumv' }
            },
            {
              title: 'ใบปรับปรุงบัญชี',
              template: 'DocAccountGeneral',
              options: { docType: 'adj' }
            },
            {
              title: 'Ledger Clearing',
              template: 'DocAccountLedgerBalance',
              options: { docType: 'adb' }
            },
          ],
        },
      ]
    }
  ]
}
