<template>
  <div class="card">
    <div class="card-body">
      <span class="card-text">
        <div class="row">
          <div class="col-12 col-sm-6 mb-2">
            <span
              :class="{'text-warning': item.isDefault}">
              {{item.name}}
              <span v-if="item.isDefault"> - ค่าเริ่มต้น</span>
            </span>
          </div>

          <div class="col-12 col-sm-6 mb-2 text-right">
            <AddressCardAddress
              v-if="item.addressTag"
              title="ป้ายที่อยู่"
              class="mr-2"
              :docType="docType"
              :templateType="templateType"
              :addressId="item.id">
            </AddressCardAddress>

            <AddressCardBranch
              title="ป้ายสาขา"
              v-if="printBranch"
              :docType="docType"
              :templateType="templateType"
              :contactId="contactId">
            </AddressCardBranch>
          </div>
        </div>

        <dl class="row mb-0 mt-2">
          <template v-for="(fData, idx) in formDataMapp">
            <dt class="col-3 col-sm-2 col-md-2 font-weight-normal text-right" :key="idx + '0'">{{fData.text}}:</dt>
            <dd class="col-9 col-sm-10 col-md-10 pre-line" :key="idx + '1'">
              {{fData.value}}
              <component
                v-if="fData.comp && fData.childId"
                :is="fData.comp"
                :title="fData.value"
                :templateType="templateType"
                :docType="docType"
                addressType="main"
                :childId="fData.childId">
              </component>
            </dd>
          </template>
        </dl>
      </span>
    </div>
  </div>
</template>

<script>
import AddressCardAddress from './AddressCardAddress'
import AddressCardBranch from './AddressCardBranch'
import AddressCardChildModal from './AddressCardChildModal'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    printBranch: {
      type: Boolean,
      required: false
    },
  },
  data () {
    return {

    }
  },
  computed: {
    formDataMapp () {
      const attributes = [
        {text: 'ผู้ติดต่อ', key: 'attendance'},
        {text: 'Email', key: 'email'},
        {text: 'โทรศัพท์', key: 'telephone'},
        {text: 'มือถือ', key: 'mobile'},
        {text: 'แฟกซ์', key: 'fax'},
        {text: 'Line App', key: 'lineApp'},
        {text: 'ขนส่ง', key: 'logistic', comp: 'AddressCardChildModal'},
        {text: 'ยานพาหนะ', key: 'vehicle'},
        {text: 'ที่อยู่', key: 'address'},
        {text: 'พิมพ์', key: 'addressTag'}
      ]

      return attributes
      .filter(attr => this.item[attr.key])
      .map(attr => {
        let value = this.item[attr.key]

        if (attr.key === 'logistic') {
          value = `${this.item[attr.key].code} (${this.item[attr.key].name})`
        }

        if (attr.key === 'vehicle') {
          value = `${this.item[attr.key].code} (${this.item[attr.key].name})`
        }

        return {
          text: attr.text,
          value,
          comp: attr.comp,
          childId: this.item[attr.key]?.id
        }
      })
    }
  },
  components: {
    AddressCardAddress,
    AddressCardBranch,
    AddressCardChildModal
  }
}
</script>

<style lang="css">
</style>
