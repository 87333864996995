<template>
  <div>
    {{item.name}}
    <span
      class="mr-1"
      v-for="i in maxStar"
      :key="i">
      <fa
        icon="star"
        :class="{'text-warning': item.level >= i}">
      </fa>
    </span>

    <span class="float-right text-info">
      <fa icon="user-clock"></fa>
      {{item.monthExperience}} M
    </span>

    <div v-if="item.docs.length > 0">
      <DetailJobDescriptionAbilityDoc
        v-for="doc in item.docs"
        :key="doc.id"
        :item="doc">
      </DetailJobDescriptionAbilityDoc>
    </div>

    <div v-else class="pl-3">
      -
    </div>

  </div>
</template>

<script>
import DetailJobDescriptionAbilityDoc from './DetailJobDescriptionAbilityDoc'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      maxStar: 3
    }
  },
  components: {
    DetailJobDescriptionAbilityDoc
  }
}
</script>

<style lang="css" scoped>
</style>
