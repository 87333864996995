<template>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-text
          label="เลขที่โอนเงิน"
          v-model="paymentRef"
          class="col-12 col-md-4"
          :validations="[
            {text: 'required!', value: $v.paymentRef.$dirty && $v.paymentRef.$error}
          ]">
        </sgv-input-text>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  CLOSE_DOC
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      paymentRef: '',
    }
  },
  validations: {
    paymentRef: { required }
  },
  methods: {
    closeData () {
      this.$v.paymentRef.$touch()
      if (this.$v.paymentRef.$invalid) return

      this.$apollo.mutate({
        mutation: CLOSE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          paymentRef: this.paymentRef,
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.$toasted.global.success("จบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
