<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">ค้นหา</h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <sgv-input-text
            :focus="barcodeFocus"
            type="search"
            placeholder="รหัสสต๊อก..."
            v-model="barcode"
            @keyup.enter="getInfo">
          </sgv-input-text>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <div class="">
      <div v-if="stock" class="mb-4">
        <StockInfoLabelDevice
          :stockId="stock.id"
          :templateType="this.templateType"
          :inventoryType="this.inventoryType">
        </StockInfoLabelDevice>
      </div>

      <template v-if="stock">
        <ul class="list-group">
          <li class="list-group-item">
            รหัส: {{stock.id}}
          </li>

          <li class="list-group-item">
            จำนวน:
            <span :class="{'text-success': stock.qty > 0, 'text-danger': stock.qty < 0}">
              {{ stock.qty }}
            </span>
            {{stock.inventory.unit.name}}

            <span class="float-right" v-if="stock.qty > 0">
              คงเหลือ:
              <span class="text-warning">{{ stock.remaining }}</span>
              {{stock.inventory.unit.name}}
            </span>
          </li>

          <li class="list-group-item">
            สร้าง: {{stock.createdAt | date}}
            <span class="float-right">{{stock.createdUser.name}}</span>
          </li>

          <li class="list-group-item">
            อนุมัติ: {{stock.approvedAt | date}}
            <span class="float-right" v-if="stock.approvedUser">{{stock.approvedUser.name}}</span>
          </li>

          <li class="list-group-item">
            <router-link
              class="text-decoration-none"
              :class="{'disabled': !isInventoryAuth(stock.inventory)}"
              :to="toInventory(stock.inventory)">
              สินค้า: {{stock.inventory.code}} ({{stock.inventory.name}})
            </router-link>
            <div class="text-primary" v-if="stock.inventory.address">
              <small>ที่เก็บ: {{stock.inventory.address}}</small>
            </div>
          </li>

          <li class="list-group-item">
            <router-link
              class="text-decoration-none"
              :class="{'disabled': !isDocAuth(stock.doc)}"
              :to="toDoc(stock.doc)">
              เอกสาร: {{stock.doc.code}}
            </router-link>
            <div>
              <small class="text-primary">{{stock.doc.name}}</small>
            </div>
          </li>

          <li
            v-if="stock.id !== stock.parentId && stock.parent"
            class="list-group-item">
            <router-link
              class="text-decoration-none"
              :class="{'disabled': !isDocAuth(stock.parent.doc)}"
              :to="toDoc(stock.parent.doc)">
              แหล่งที่มา: {{stock.parent.doc.code}}
            </router-link>
            <div class="">
              <small class="text-primary">{{stock.parent.doc.name}}</small>
            </div>
          </li>

          <li
            v-if="stock.id == stock.parentId && stock.children.length > 1"
            class="list-group-item">
            เกี่ยวข้อง:
            <ul>
              <li
                v-for="child in stock.children.filter(v => v.id !== stock.id)"
                :key="child.id">
                <router-link
                  class="text-decoration-none"
                  :class="{'disabled': !isDocAuth(child.doc)}"
                  :to="toDoc(child.doc)">
                  {{child.doc.code}}
                </router-link>
                <div class="">
                  <small class="text-primary">{{child.doc.name}}</small>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </div>
  </div>

</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import StockInfoLabelDevice from './StockInfoLabelDevice.vue'
import { DETAIL_STOCK_INFO } from './graph/stock'


export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-ค้นหา`
    }
  },
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      barcode: '',
      barcodeFocus: false,
      stock: null,
      stockId: null,
      rKey: `${this.group}StockInfo`,
      rFields: ['stockId'],
      stockGraph: `inventory${this.$form.capitalize(this.inventoryType)}StockInfo`,
      devicesGraph: `inventory${this.$form.capitalize(this.inventoryType)}LabelInventoryStockDevices`,
      printGraph: `printInventory${this.$form.capitalize(this.inventoryType)}LabelInventoryStock`
    }
  },
  methods: {
    isDocAuth (v) {
      return this.$auth.hasRole(`doc:${v.type}:read`)
    },
    isInventoryAuth (v) {
      return this.$auth.hasRole(`inventory:${v.type}:read`)
    },
    classifyBarcode (txt) {
      if (txt.indexOf('stockId,') === -1) {
        if (this.$form.isNumber(txt)) {
          return +txt
        } else {
          this.$toasted.global.error('บาร์โค๊ดไม่ถูกต้อง')
        }
      } else {
        return +txt.replace('stockId,', '')
      }
    },
    getInfo () {
      const barcode = this.classifyBarcode(this.barcode)
      this.barcodeFocus = false

      this.$apollo.query({
        query: DETAIL_STOCK_INFO(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          stockId: barcode
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.stock = res.data.stockInfo
        this.stockId = res.data.stockInfo.id
      })
      .catch(err => {
        this.stock = null
        this.stockId = null
        this.barcodeFocus = true
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.barcode = ''
        this.setRetaining()
      })
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id, docType: doc.type},
        query: {redirect: this.$route.name}
      }
    },
    toInventory (inv) {
      return {
        name: `Inventory${this.$form.capitalize(inv.type)}Detail`,
        params: {inventoryId: inv.id, inventoryType: inv.type},
        query: {redirect: this.$route.name}
      }
    }
  },
  watch: {
    isRetainingLoaded (value) {
      if (value && this.$route.query?.stockId) {
        this.barcode = this.$route.query?.stockId.toString()
        this.getInfo()
      } else if (value && this.stockId) {
        this.barcode = this.stockId.toString()
        this.getInfo()
      }
    }
  },
  mounted () {
    this.barcodeFocus = true
  },
  created () {
    this.$store.commit(
      'path/setCurrent',
      {to: this.$route, group: this.group}
    )
  },
  components: {
    StockInfoLabelDevice
  }
}
</script>

<style lang="css" scoped>
</style>
