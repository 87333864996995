import { capitalize } from '@/libs/form'


export default ({couponType, title, subTitle, group}) => {
  const templateType = 'CouponLuckyDraw'
  return [
    {
      path: `coupon/${couponType}`,
      name: `Coupon${capitalize(couponType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        couponType,
        title,
        subTitle,
        group
      })
    },
    {
      path: `coupon/${couponType}/:couponId`,
      name: `Coupon${capitalize(couponType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        templateType,
        couponId: route.params.couponId.toString(),
        couponType,
        title,
        subTitle,
        group
      })
    },
  ]
}
