<template>
  <sgv-csv
    :items="csvData"
    :labels="csvLabels"
    :filename="csvFilename">
    <button
      :disabled="csvData.length === 0"
      type="button"
      class="btn btn-success form-group">
      EXCEL
    </button>
  </sgv-csv>
</template>

<script>
export default {
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      csvLabels: {
        id: { title: 'ลำดับ' },
        code: { title: 'รหัส' },
        name: { title: 'ชื่อ' },
        unit: { title: 'หน่วย' },
        address: { title: 'ที่เก็บ' },
        barcode: { title: 'บาร์โค๊ด' },
        categories: { title: 'หมวดหมู่' },
      },
      csvFilename: `${this.inventoryType}-รายชื่อ`
    }
  },
  computed: {
    csvData () {
      return this.items.map((v,idx) => {
        return {
          id: idx+1,
          code: v.code,
          name: v.name,
          unit: v.unit.name,
          address: v.address || '',
          barcode: v.barcode || '',
          categories: v.categories
          .map((c,cidx) => {
            return `${cidx+1}. ${c.name}`
          })
          .join('\n')
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
