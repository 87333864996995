var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-file',{ref:"fileUpload",staticClass:"col-12",attrs:{"label":"ไฟล์ CSV"},on:{"change":function($event){return _vm.changeToJSON($event)}}})],1),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.reset}},[_vm._v(" ยกเลิก ")]),_c('sgv-csv',{attrs:{"items":_vm.csvData,"labels":_vm.csvLabels,"filename":_vm.csvFilename}},[_c('button',{staticClass:"btn btn-info ml-2",attrs:{"type":"button"}},[_vm._v(" ตัวอย่าง CSV ")])]),(_vm.unitRows.length > 0)?_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"text-danger"},[_vm._v("ไม่พบหน่วย:")]),_c('sgv-table',{attrs:{"items":_vm.unitRows,"headers":_vm.headers,"options":_vm.options,"headerless":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var hidden = ref.hidden;
return [_c('tr',{},[(hidden.code)?_c('td',[_vm._v(_vm._s(item.code))]):_vm._e(),(hidden.name)?_c('td',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.unit)+")")]):_vm._e(),(hidden.description)?_c('td'):_vm._e()])]}}],null,false,2320656598)})],1):_vm._e(),(_vm.categoryRows.length > 0)?_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"text-danger"},[_vm._v("ไม่พบหมวดหมู่:")]),_c('sgv-table',{attrs:{"items":_vm.categoryRows,"headers":_vm.headers,"options":_vm.options,"headerless":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var hidden = ref.hidden;
return [_c('tr',{},[(hidden.code)?_c('td',[_vm._v(_vm._s(item.code))]):_vm._e(),(hidden.name)?_c('td',[_vm._v(_vm._s(item.name))]):_vm._e(),(hidden.description)?_c('td'):_vm._e()])]}}],null,false,108922700)})],1):_vm._e(),(_vm.addRows.length > 0)?_c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"btn btn-success btn-sm mb-2",attrs:{"type":"button"},on:{"dblclick":_vm.addAll}},[_vm._v(" เพิ่มทั้งหมด ")]),_c('sgv-table',{attrs:{"items":_vm.addRows,"headers":_vm.headers,"options":_vm.options,"headerless":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var hidden = ref.hidden;
return [_c('tr',{},[(hidden.code)?_c('td',[_c('button',{staticClass:"btn btn-success btn-sm mr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.addData(item)}}},[_vm._v(" เพิ่ม ")]),_vm._v(" "+_vm._s(item.code)+" ")]):_vm._e(),(hidden.name)?_c('td',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.unit.name)+")")]):_vm._e(),(hidden.description)?_c('td',[_c('small',[(item.categories && item.categories.length > 0)?_c('div',{staticClass:"text-info"},[_vm._v("หมวดหมู่: "+_vm._s(item.categories[0].name))]):_vm._e(),(item.address)?_c('div',[_vm._v("ที่เก็บ: "+_vm._s(item.address))]):_vm._e(),(item.barcode)?_c('div',[_vm._v("บาร์โค๊ด: "+_vm._s(item.barcode))]):_vm._e(),(item.supplierSku)?_c('div',[_vm._v("รหัสซัพพลายเออร์: "+_vm._s(item.supplierSku))]):_vm._e(),(item.description)?_c('div',[_vm._v("รายละเอียด: "+_vm._s(item.description))]):_vm._e(),(item.packing)?_c('div',[_vm._v("จำนวนบรรจุ: "+_vm._s(item.packing))]):_vm._e(),(item.lowerLimit)?_c('div',[_vm._v("จำนวนน้อยสุด: "+_vm._s(item.lowerLimit))]):_vm._e(),(item.upperLimit)?_c('div',[_vm._v("จำนวนสูงสุด: "+_vm._s(item.upperLimit))]):_vm._e(),(item.purchaseLimit)?_c('div',[_vm._v("จำนวนสั่งซื้อ: "+_vm._s(item.purchaseLimit))]):_vm._e()])]):_vm._e()])]}}],null,false,3242298484)})],1):_vm._e(),(_vm.updateRows.length > 0)?_c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"btn btn-warning btn-sm mb-2",attrs:{"type":"button"},on:{"dblclick":_vm.updateAll}},[_vm._v(" แก้ไขทั้งหมด ")]),_c('sgv-table',{attrs:{"items":_vm.updateRows,"headers":_vm.headers,"options":_vm.options,"headerless":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var hidden = ref.hidden;
return [_c('tr',{},[(hidden.code)?_c('td',[_c('button',{staticClass:"btn btn-warning btn-sm mr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.updateData(item)}}},[_vm._v(" แก้ไข ")]),_vm._v(" "+_vm._s(item.code)+" ")]):_vm._e(),(hidden.name)?_c('td',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.unit.name)+")")]):_vm._e(),(hidden.description)?_c('td',[_c('small',[(item.categories && item.categories.length > 0)?_c('div',{staticClass:"text-info"},[_vm._v("หมวดหมู่: "+_vm._s(item.categories[0].name))]):_vm._e(),(item.address)?_c('div',[_vm._v("ที่เก็บ: "+_vm._s(item.address))]):_vm._e(),(item.barcode)?_c('div',[_vm._v("บาร์โค๊ด: "+_vm._s(item.barcode))]):_vm._e(),(item.supplierSku)?_c('div',[_vm._v("รหัสซัพพลายเออร์: "+_vm._s(item.supplierSku))]):_vm._e(),(item.description)?_c('div',[_vm._v("รายละเอียด: "+_vm._s(item.description))]):_vm._e(),(item.packing)?_c('div',[_vm._v("จำนวนบรรจุ: "+_vm._s(item.packing))]):_vm._e(),(item.lowerLimit)?_c('div',[_vm._v("จำนวนน้อยสุด: "+_vm._s(item.lowerLimit))]):_vm._e(),(item.upperLimit)?_c('div',[_vm._v("จำนวนสูงสุด: "+_vm._s(item.upperLimit))]):_vm._e(),(item.purchaseLimit)?_c('div',[_vm._v("จำนวนสั่งซื้อ: "+_vm._s(item.purchaseLimit))]):_vm._e()])]):_vm._e()])]}}],null,false,2043272737)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }