<template>
  <ul class="nav nav-bordered">
    <li
      class="nav-item"
      v-for="(option, index) in optionsByRole"
      :key="index"
      @click.prevent="emitInput(option)">

      <router-link
        v-if="link"
        class="nav-link nowrap"
        active-class="active"
        :to="option.to"
        :exact="option.exact">
        <slot v-bind="option">
          {{option.text}}
        </slot>
      </router-link>

      <a
        v-else
        class="nav-link nowrap"
        href="#"
        :class="{'active': getActive(option)}">
        <slot v-bind="option">
          {{option.text}}
        </slot>
      </a>

    </li>
  </ul>
</template>

<script>
export default {
  name: 'TabDivider',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [Number, String, Object],
      required: false
    },
    select: {
      type: String,
      required: false,
      default: 'value'
    },
    link: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      // width: 0
    }
  },
  computed: {
    optionsByRole () {
      return this.options.filter(v => this.$auth.hasRole(v.role))
    },
  },
  methods: {
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    },
    emitInput (option) {
      this.$emit('input', this.optionByKey(option))
    },
    getActive (option) {
      return this.value === this.optionByKey(option)
    },
    // getWindowWidth () {
    //   this.width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    // }
  },
  watch: {
    value: {
      handler(value) {
        let items = this.select ? this.optionsByRole.map(v => v[this.select]) : this.optionsByRole
        let found = items.find(v => v === value)
        if (found) return
        this.emitInput(items[0])
      },
      immediate: true
    }
  },
  // mounted () {
	// 	this.$nextTick(function() {
	// 			window.addEventListener('resize', () => {
  //         this.getWindowWidth()
  //       })
	// 	})
	// },
}
</script>

<style lang="css" scoped>
.tab-scroll {
  overflow-x: auto;
  overflow-y:hidden;
  flex-wrap: nowrap;
}

.nowrap {
  white-space: nowrap;
}
</style>
