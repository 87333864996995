<template>
  <div class="col-10 col-md-6 col-lg-5 mt-4">
    <h2 class="text-center my-4">{{appName}}</h2>
  </div>
</template>

<script>
import { logout } from '@/plugins/axios'
import { onLogout } from '@/plugins/apollo'

export default {
  data() {
    return {
      appName: process.env.VUE_APP_NAME
    }
  },
  created () {
    logout()
    .then(() => {
      return onLogout(this.$apollo.provider.clients.defaultClient)
    })
    .finally(() => {
      this.$router.push({ name: 'Login' })
    })
  }
}
</script>

<style lang="css" scoped>

</style>
