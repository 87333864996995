<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="รหัส"
        v-model="formData.code"
        :validations="[
          {text: 'required!', value: v.formData.code.$dirty && v.formData.code.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  props: {
    inventoryCostType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      toggleDetail: false,
      rKey: `InventoryCost${this.$form.capitalize(this.inventoryCostType)}Form`,
      rFields: []
    }
  },
  watch: {
    toggleDetail () {
      this.setRetaining()
    }
  }
}
</script>

<style lang="css">
</style>
