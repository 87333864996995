import gql from 'graphql-tag'


const detailResponse = `
  id type code name isActive
  children {
    id type name order parentId
    children {
      id type name order parentId
      formType
      actualValue minValue maxValue
      choices {text value}
      cron instrumentCode
      deviceId deviceTopic device {id type code name}
    }
  }
`

export const LIST_MEASUREMENT_TEMPLATE = (templateType) => gql`query LIST_MEASUREMENT_TEMPLATE ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}MeasurementTemplate (docType: $docType, q: $q) {
    id type code name isActive
  }
}`

export const DETAIL_MEASUREMENT_TEMPLATE = (templateType) => gql`query DETAIL_MEASUREMENT_TEMPLATE ($docType: String!, $id: Int!) {
  item: detailDoc${templateType}MeasurementTemplate (docType: $docType, id: $id) {${detailResponse}}
}`

export const CREATE_MEASUREMENT_TEMPLATE = (templateType) => gql`mutation CREATE_MEASUREMENT_TEMPLATE ($docType: String!, $input: MeasurementTemplateInput!) {
  item: createDoc${templateType}MeasurementTemplate (docType: $docType, input: $input) {${detailResponse}}
}`

export const UPDATE_MEASUREMENT_TEMPLATE = (templateType) => gql`mutation UPDATE_MEASUREMENT_TEMPLATE ($docType: String!, $id: Int!, $input: MeasurementTemplateInput!) {
  item: updateDoc${templateType}MeasurementTemplate (docType: $docType, id: $id, input: $input) {${detailResponse}}
}`

export const DESTROY_MEASUREMENT_TEMPLATE = (templateType) => gql`mutation DESTROY_MEASUREMENT_TEMPLATE ($docType: String!, $id: Int!) {
  item: destroyDoc${templateType}MeasurementTemplate (docType: $docType, id: $id) {id}
}`
