<template>
  <vue-json-to-csv :json-data="items"
  :labels="labels"
  :csv-title="filename">
    <slot></slot>
  </vue-json-to-csv>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  name: 'SgvCsv',
  props: {
    items: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    labels: {
      type: Object,
      required: true
    },
    filename: {
      type: String,
      required: true
    }
  },
  data () {
    return {

    }
  },
  components: {
    VueJsonToCsv
  }
}
</script>

<style lang="css" scoped>
</style>
