import { capitalize } from '@/libs/form'


export default ({docType, title, subTitle, group, contactTypes}) => {
  const templateType = 'AttendancePayroll'
  return [
    {
      path: `doc/${docType}/docConfig`,
      name: `Doc${capitalize(docType)}DocConfigList`,
      component: () => import('../DocConfig.vue'),
      props: () => ({
        templateType,
        docType: docType,
        title: title,
        subTitle: subTitle,
        group: group
      }),
      children: [
        {
          path: `discount/list`,
          name: `Doc${capitalize(docType)}DocConfigListDiscount`,
          component: () => import('../DocConfigListDiscount.vue'),
          props: () => ({
            templateType,
            docType,
          })
        },
        {
          path: `discount/:docConfigId`,
          name: `Doc${capitalize(docType)}DocConfigDetailDiscount`,
          component: () => import('../DocConfigDetailDiscount.vue'),
          props: (route) => ({
            templateType,
            docConfigId: Number(route.params.docConfigId),
            docType,
          })
        },
        {
          path: `employee/list`,
          name: `Doc${capitalize(docType)}DocConfigListEmployee`,
          component: () => import('../DocConfigListEmployee.vue'),
          props: () => ({
            templateType,
            docType,
          })
        },
        {
          path: `employee/:docConfigId`,
          name: `Doc${capitalize(docType)}DocConfigDetailEmployee`,
          component: () => import('../DocConfigDetailEmployee.vue'),
          props: (route) => ({
            templateType,
            docConfigId: Number(route.params.docConfigId),
            docType,
            contactTypes
          })
        },
        {
          path: `jobDescription/list`,
          name: `Doc${capitalize(docType)}DocConfigListJobDescription`,
          component: () => import('../DocConfigListJobDescription.vue'),
          props: () => ({
            templateType,
            docType,
          })
        },
        {
          path: `jobDescription/:docConfigId`,
          name: `Doc${capitalize(docType)}DocConfigDetailJobDescription`,
          component: () => import('../DocConfigDetailJobDescription.vue'),
          props: (route) => ({
            templateType,
            docConfigId: Number(route.params.docConfigId),
            docType,
          })
        },
        {
          path: `netPrice/list`,
          name: `Doc${capitalize(docType)}DocConfigListNetPrice`,
          component: () => import('../DocConfigListNetPrice.vue'),
          props: () => ({
            templateType,
            docType,
          })
        },
        {
          path: `netPrice/:docConfigId`,
          name: `Doc${capitalize(docType)}DocConfigDetailNetPrice`,
          component: () => import('../DocConfigDetailNetPrice.vue'),
          props: (route) => ({
            templateType,
            docConfigId: Number(route.params.docConfigId),
            docType,
          })
        },
        {
          path: `socialSecurity/list`,
          name: `Doc${capitalize(docType)}DocConfigListSocialSecurity`,
          component: () => import('../DocConfigListSocialSecurity.vue'),
          props: () => ({
            templateType,
            docType,
          })
        },
        {
          path: `socialSecurity/:docConfigId`,
          name: `Doc${capitalize(docType)}DocConfigDetailSocialSecurity`,
          component: () => import('../DocConfigDetailSocialSecurity.vue'),
          props: (route) => ({
            templateType,
            docConfigId: Number(route.params.docConfigId),
            docType,
          })
        },
        {
          path: `tax/list`,
          name: `Doc${capitalize(docType)}DocConfigListTax`,
          component: () => import('../DocConfigListTax.vue'),
          props: () => ({
            templateType,
            docType,
          })
        },
        {
          path: `tax/:docConfigId`,
          name: `Doc${capitalize(docType)}DocConfigDetailTax`,
          component: () => import('../DocConfigDetailTax.vue'),
          props: (route) => ({
            templateType,
            docConfigId: Number(route.params.docConfigId),
            docType,
          })
        },
        {
          path: `reportGroup/list`,
          name: `Doc${capitalize(docType)}DocConfigListReportGroup`,
          component: () => import('../DocConfigListGeneral.vue'),
          props: () => ({
            templateType,
            docType,
            selected: 'reportGroup'
          })
        },
        {
          path: `payrollPdf/list`,
          name: `Doc${capitalize(docType)}DocConfigListPayrollPdf`,
          component: () => import('../DocConfigListGeneral.vue'),
          props: () => ({
            templateType,
            docType,
            selected: 'payrollPdf'
          })
        },
        {
          path: `userManual/list`,
          name: `Doc${capitalize(docType)}DocConfigListUserManual`,
          component: () => import('../DocConfigListGeneral.vue'),
          props: () => ({
            templateType,
            docType,
            selected: 'userManual'
          })
        },
      ]
    },
    {
      path: `doc/${docType}/category/:categoryId`,
      name: `Doc${capitalize(docType)}CategoryDetail`,
      component: () => import(`@/views/doc_core/components/CategoryDetail.vue`),
      props: (route) => ({
        templateType,
        categoryId: Number(route.params.categoryId),
        docType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `doc/${docType}`,
      name: `Doc${capitalize(docType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        docType: docType,
        title: title,
        subTitle: subTitle,
        group: group
      })
    },
    {
      path: `doc/${docType}/:docId`,
      name: `Doc${capitalize(docType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        templateType,
        docId: Number(route.params.docId),
        docType: docType,
        title: title,
        subTitle: subTitle,
        group: group
      })
    },
  ]
}
