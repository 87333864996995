import { capitalize } from '@/libs/form'
import List from '../List.vue'
import Detail from '../Detail.vue'


export default ({inventoryCostType, title, subTitle, group}) => {
  const templateType = 'Cost'
  return [
    {
      path: `inventoryCost/${inventoryCostType}`,
      name: `InventoryCost${capitalize(inventoryCostType)}List`,
      component: List,
      props: () => ({
        templateType,
        inventoryCostType,
        title, subTitle, group,
      })
    },
    {
      path: `inventoryCost/${inventoryCostType}/:inventoryCostId`,
      name: `InventoryCost${capitalize(inventoryCostType)}Detail`,
      component: Detail,
      props: (route) => ({
        templateType,
        inventoryCostType,
        title, subTitle, group,
        inventoryCostId: Number(route.params.inventoryCostId),
      })
    },
  ]
}
