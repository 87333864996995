export default {
  routers: [
    {
      title: 'ผู้ติดต่อ',
      header: true,
      sections: [
        {
          title: 'ลูกค้า',
          group: 'ContactCustomer',
          sections: [
            {
              title: 'ลูกค้า',
              template: 'ContactGeneral',
              options: {contactType: 'customer'}
            },
            {
              title: 'พื้นที่/ขอบเขต',
              template: 'CountryAreaGeneral',
              options: {countryAreaType: 'customer'}
            },
            {
              title: 'ร้องเรียน/แนะนำ',
              template: 'DocContactComplaint',
              options: {docType: 'syr'}
            },
          ],
        },
        {
          title: 'ซัพพลายเออร์',
          group: 'ContactSupplier',
          sections: [
            {
              title: 'ซัพพลายเออร์',
              template: 'ContactGeneral',
              options: {contactType: 'supplier'}
            },
            {
              title: 'ใบคัดเลือกผู้ขาย',
              template: 'DocContactEvaluation',
              options: {docType: 'sys'}
            },
          ],
        },
        {
          title: 'ผู้รับจ้าง',
          group: 'ContactContractor',
          sections: [
            {
              title: 'ผู้รับจ้าง',
              template: 'ContactGeneral',
              options: {contactType: 'contractor'}
            },
            {
              title: 'ใบคัดเลือกผู้รับจ้าง',
              template: 'DocContactEvaluation',
              options: {docType: 'syt'}
            },
          ],
        },
        {
          title: 'ขนส่ง',
          group: 'ContactLogistic',
          sections: [
            {
              title: 'ขนส่ง',
              template: 'ContactGeneral',
              options: {contactType: 'logistic'}
            },
            {
              title: 'ใบคัดเลือกขนส่ง',
              template: 'DocContactEvaluation',
              options: {docType: 'syl'}
            },
          ],
        }
      ]
    },
  ]
}
