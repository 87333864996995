import { capitalize } from '@/libs/form'

export default ({labelType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `label/${labelType}`,
      name: `Label${capitalize(labelType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        labelType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `label/${labelType}/:labelId`,
      name: `Label${capitalize(labelType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        labelId: Number(route.params.labelId),
        templateType,
        labelType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
