import gql from 'graphql-tag'


const detailResponse = `
  id type name
  attendance
  telephone mobile fax email lineApp

  mapUrl mapLocation
  countryProvinceId countryDistrictId countrySubdistrictId

  logisticId logistic {id type code name}
  vehicleId vehicle {id type code name}

  address addressTag
  houseId building roomId floorId
  village villageId laneId road subdistrict
  district province postcode

  isDefault
`

export const LIST_ADDRESS_TYPE = (templateType) => gql`query LIST_ADDRESS_TYPE ($contactType: String!) {
  addressTypes: listContact${templateType}AddressType (contactType: $contactType) {
    text value
  }
}`

export const LIST_ADDRESS = (templateType) => gql`query LIST_ADDRESS ($contactType: String!, $contactId: Int!) {
  addresses: listContact${templateType}Address (contactType: $contactType, contactId: $contactId) {${detailResponse}}
}`

export const LIST_ADDRESS_CHILD = (templateType) => gql`query LIST_ADDRESS_CHILD ($contactType: String!, $addressType: String!, $childId: Int!) {
  addresses: listContact${templateType}AddressChild (contactType: $contactType, addressType: $addressType, childId: $childId) {${detailResponse}}
}`

export const CREATE_ADDRESS = (templateType) => gql`mutation CREATE_ADDRESS ($contactType: String!, $contactId: Int!, $input: ContactAddressInput!) {
  item: createContact${templateType}Address (contactType: $contactType, contactId: $contactId, input: $input) {${detailResponse}}
}`

export const UPDATE_ADDRESS = (templateType) => gql`mutation UPDATE_ADDRESS ($contactType: String!, $addressId: Int!, $input: ContactAddressInput!) {
  item: updateContact${templateType}Address (contactType: $contactType, addressId: $addressId, input: $input) {${detailResponse}}
}`

export const DESTROY_ADDRESS = (templateType) => gql`mutation DESTROY_ADDRESS ($contactType: String!, $addressId: Int!) {
  item: destroyContact${templateType}Address (contactType: $contactType, addressId: $addressId) {id}
}`

export const LIST_ADDRESS_DEVICE = (templateType) => gql`query LIST_ADDRESS_DEVICE ($contactType: String!) {
  devices: listContact${templateType}LabelContactAddressDevice (contactType: $contactType) {
    id type code name
  }
}`

export const PRINT_ADDRESS = (templateType) => gql`mutation PRINT_ADDRESS ($contactType: String!, $deviceId: Int!, $addressId: Int!, $qty: Int!) {
  print: printContact${templateType}LabelContactAddress (contactType: $contactType, deviceId: $deviceId, addressId: $addressId, qty: $qty)
}`

export const LIST_BRANCH_DEVICE = (templateType) => gql`query LIST_BRANCH_DEVICE ($contactType: String!) {
  devices: listContact${templateType}LabelContactBranchDevice (contactType: $contactType) {
    id type code name
  }
}`

export const PRINT_BRANCH = (templateType) => gql`mutation PRINT_BRANCH ($contactType: String!, $deviceId: Int!, $contactId: Int!, $qty: Int!) {
  print: printContact${templateType}LabelContactBranch (contactType: $contactType, deviceId: $deviceId, contactId: $contactId, qty: $qty)
}`

export const DP_PROVINCES = (templateType) => gql`query DP_PROVINCES ($contactType: String!, $q: FilterInput) {
  items: listContact${templateType}DropdownCountryProvince (contactType: $contactType, q: $q) {
    id name
  }
}`

export const DP_DISTRICTS = (templateType) => gql`query DP_DISTRICTS ($contactType: String!, $countryProvinceId: Int!, $q: FilterInput) {
  items: listContact${templateType}DropdownCountryDistrict (contactType: $contactType, countryProvinceId: $countryProvinceId, q: $q) {
    id name
  }
}`

export const DP_SUBDISTRICTS = (templateType) => gql`query DP_SUBDISTRICTS ($contactType: String!, $countryDistrictId: Int!, $q: FilterInput) {
  items: listContact${templateType}DropdownCountrySubdistrict (contactType: $contactType, countryDistrictId: $countryDistrictId, q: $q) {
    id name
  }
}`
