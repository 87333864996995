<template>
  <sgv-input-autocomplete
    :search.sync="search"
    :display.sync="display"
    :options="options"
    @input="emitSelected"
    :validations="validations"
    v-bind="$attrs">

    <label slot="label">
      <span
        v-for="group in groupsByFilter"
        :key="group.value"
        class="pointer mr-1"
        :class="{'text-muted': group.value !== selectedGroup}"
        @click="setGroup(group.value)">
        {{group.text}}
      </span>
    </label>

    <template slot-scope="option">
      {{option.code}} ({{option.name}})
    </template>
  </sgv-input-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import { DP_VEHICLES } from '../graph/dropdown'


export default {
  name: 'DropdownVehicle',
  props: {
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    select: {
      type: String,
      required: false
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    oneWay: {
      type: Boolean,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    types: {
      type: Array,
      required: false,
      default() {
        return ['vehicle']
      }
    }
  },
  data () {
    return {
      display: null,
      options: [],
      search: '',
      groups: [
        {text: 'ยานพาหนะ', value: 'vehicle'},
      ],
      selectedGroup: this.types[0]
    }
  },
  computed: {
    groupsByFilter () {
      return this.groups.filter(v => this.types.includes(v.value))
    },
  },
  methods: {
    setGroup (value) {
      this.selectedGroup = value
    },
    getDisplay (option) {
      return `${option.code} (${option.name})`
    },
    emitSelected (option) {
      this.setDisplay(option)
      this.$emit('change', option)
      this.emitInput(this.optionByKey(option))
    },
    setDisplay (option) {
      if (option) this.display = this.getDisplay(option)
    },
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    },
    emitInput (value) {
      this.$emit('input', value)
    },
    refetch: debounce(function() {
      this.$apollo.query({
        query: DP_VEHICLES(this.templateType),
        variables: {
          contactType: this.contactType,
          q: {
            limit: 10,
            params: {
              type: this.selectedGroup,
              search: this.search
            }
          }
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.options = res.data.inventories
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
      })
    }, 300),
    fetchData () {
      this.$apollo.query({
        query: DP_VEHICLES(this.templateType),
        variables: {
          contactType: this.contactType,
          q: {
            params: {
              id: +this.value
            }
          }
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.options = res.data.inventories
        this.setDisplay(res.data.inventories[0])
        if (res.data.inventories[0]) {
          this.setGroup(res.data.inventories[0].type)
        }
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
      })
    }
  },
  watch: {
    search () {
      this.refetch()
    },
    value: {
      handler (value) {
        if (!this.oneWay && value) {
          this.fetchData()
        } else if (!value) {
          this.display = null
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="css">
</style>
