<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          <span class="mr-2">{{title}}</span>
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <ListCategory
      :inventoryCostType="inventoryCostType"
      :templateType="templateType"
      :group="group">
    </ListCategory>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ListCategory from './ListCategory'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-รายการ`
    }
  },
  props: {
    inventoryCostType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `InventoryCost${this.$form.capitalize(this.inventoryCostType)}List`,
      rFields: []
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: `${this.group}`})
  },
  components: {
    ListCategory
  }
}
</script>

<style lang="css" scoped>
</style>
