export default {
  routers: [
    {
      title: 'ฝ่ายจัดซื้อ',
      header: true,
      sections: [
        {
          title: 'สินค้า',
          group: 'PurchaseProduct',
          sections: [
            {
              title: 'ใบสั่งซื้อสินค้า',
              template: 'DocPurchaseExpense',
              options: {docType: 'pgo'}
            },
            {
              title: 'ใบรับสินค้า',
              template: 'DocPurchaseExpenseInvoice',
              options: {docType: 'pgiv'}
            },
            {
              title: 'ใบสำคัญจ่าย',
              template: 'DocPurchaseExpensePayment',
              options: {docType: 'pgv'}
            },
          ]
        },
        {
          title: 'วัตถุดิบ',
          group: 'PurchaseMaterial',
          sections: [
            {
              title: 'ใบสั่งซื้อวัตถุดิบ',
              template: 'DocPurchaseExpense',
              options: {docType: 'pmo'}
            },
            {
              title: 'ใบรับสินค้า',
              template: 'DocPurchaseExpenseInvoice',
              options: {docType: 'pmiv'}
            },
            {
              title: 'ใบสำคัญจ่าย',
              template: 'DocPurchaseExpensePayment',
              options: {docType: 'pmv'}
            },
          ]
        },
        {
          title: 'อื่นๆ',
          group: 'PurchaseGeneral',
          sections: [
            {
              title: 'ใบสำคัญจ่าย',
              template: 'DocPurchaseExpensePayment',
              options: {docType: 'pv'}
            },
            {
              title: 'เช็คค้างจ่าย',
              template: 'DocPurchaseExpensePayment',
              options: { docType: 'pchq' }
            },
            {
              title: 'ใบมัดจำค่าสินค้า',
              template: 'DocPurchaseExpenseDeposit',
              options: {docType: 'pvd'}
            },
            {
              title: 'ใบส่งคืนวัตถุดิบ/ลดหนี้',
              template: 'DocPurchaseExpenseCreditNote',
              options: {docType: 'pcn'}
            },
            {
              title: 'ใบลดหนี้อื่นๆ',
              template: 'DocPurchaseExpenseCreditNote',
              options: {docType: 'pcnx'}
            },
            {
              title: 'หัก ณ ที่จ่าย',
              template: 'DocPurchaseWithholding',
              options: {docType: 'wht'}
            },
          ]
        }
      ]
    }
  ]
}
