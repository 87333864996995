import { capitalize } from '@/libs/form'

export default ({ promotionType, assetType, title, subTitle, group }) => {
  const templateType = 'ChronowoodthPromotion'
  return [
    {
      path: `chronowoodth/promotion/${promotionType}/`,
      name: `ChronowoodthPromotion${capitalize(promotionType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        promotionType,
        assetType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `chronowoodth/promotion/${promotionType}/:promotionId`,
      name: `ChronowoodthPromotion${capitalize(promotionType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        templateType,
        promotionType,
        assetType,
        title, subTitle, group,
        promotionId: Number(route.params.promotionId)
      })
    },
  ]
}
