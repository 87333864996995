<template>
  <div class="">
    <div v-if="method === 'edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <div v-if="items.length > 0">
        <ul>
          <li v-for="item in items" :key="item.id">
            {{item.code}} ({{item.name}})
            <span
              v-if="method === 'edit'"
              class="pl-1 text-success pointer"
              @click="createParent(item.id)">
              เพิ่มแม่
            </span>

            <span
              v-if="method === 'edit'"
              class="pl-1 text-warning pointer"
              @click="createChild(item.id)">
              เพิ่มลูก
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        รายการแม่:
        <ol class="mt-2">
          <li v-for="parent in junctionParents" :key="parent.id">
            <span
              v-if="method === 'edit'"
              class="px-1 text-danger pointer"
              @click="destroyData(parent.id, inventoryId)">
              <fa icon="trash"></fa>
            </span>
            <router-link
              class="text-decoration-none no-wrap"
              :to="toDetail(parent)">
              {{parent.code}}
            </router-link>
            ({{Number(parent.balance.current)}}) {{parent.unit.name}}
            <div class="text-primary"><small>{{parent.name}}</small></div>
          </li>
        </ol>
      </div>

      <div class="col-12 col-md-6">
        รายการลูก:
        <ol class="mt-2">
          <li v-for="child in junctionChildren" :key="child.id">
            <span
              v-if="method === 'edit'"
              class="px-1 text-danger pointer"
              @click="destroyData(inventoryId, child.id)">
              <fa icon="trash"></fa>
            </span>
            <router-link
              class="text-decoration-none no-wrap"
              :to="toDetail(child)">
              {{child.code}}
            </router-link>
            ({{Number(child.balance.current)}}) {{child.unit.name}}
            <div class="text-primary"><small>{{child.name}}</small></div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DP_PARENTS,
  LIST_INVENTORY_JUNCTION,
  CREATE_INVENTORY_JUNCTION_RELATION,
  DESTROY_INVENTORY_JUNCTION_RELATION,
} from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    inventoryType: {
      type: String,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      search: '',
      inventories: [],
      items: [],
    }
  },
  apollo: {
    items: {
      query () {
        return DP_PARENTS(this.templateType)
      },
      variables () {
        return {
          inventoryType: this.inventoryType,
          q: {
            params: {
              search: this.search
            },
            limit: 10,
            order: 'code'
          },
        }
      },
      fetchPolicy: 'no-cache'
    },
    inventories: {
      query () {
        return LIST_INVENTORY_JUNCTION(this.templateType)
      },
      variables () {
        return {
          inventoryType: this.inventoryType,
          q: {
            params: {
              id: this.inventoryId
            },
            limit: 1000
          },
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    junctionParents () {
      return this.inventories.flatMap(v => v.junctionParents)
    },
    junctionChildren () {
      return this.inventories.flatMap(v => v.junctionChildren)
    }
  },
  methods: {
    createParent (id) {
      this.$apollo.mutate({
        mutation: CREATE_INVENTORY_JUNCTION_RELATION(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          parentId: id,
          childId: this.inventoryId,
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.inventories.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    createChild (id) {
      this.$apollo.mutate({
        mutation: CREATE_INVENTORY_JUNCTION_RELATION(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          parentId: this.inventoryId,
          childId: id,
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.inventories.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData (parentId, childId) {
      this.$apollo.mutate({
        mutation: DESTROY_INVENTORY_JUNCTION_RELATION(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          parentId,
          childId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$apollo.queries.inventories.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    toDetail (item) {
      return {
        name: `Inventory${this.$form.capitalize(item.type)}Detail`,
        params: {inventoryId: item.id}
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
