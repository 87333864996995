var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{},[(!_vm.inventoryCostOrderId)?_c('span',{staticClass:"px-1 pointer",on:{"click":function($event){_vm.toggle = true}}},[_c('fa',{staticClass:"text-success",attrs:{"icon":"plus"}})],1):_vm._e(),(_vm.inventoryCostOrderId)?_c('span',{staticClass:"px-1 pointer",on:{"click":function($event){_vm.toggle = true}}},[_c('fa',{staticClass:"text-warning",attrs:{"icon":"pencil-alt"}})],1):_vm._e(),_c('b-modal',{attrs:{"title":"Output"},model:{value:(_vm.toggle),callback:function ($$v) {_vm.toggle=$$v},expression:"toggle"}},[_c('div',{staticClass:"form-row"},[_c('sgv-input-radio',{staticClass:"col-12",attrs:{"options":_vm.tabs,"select":"value","inline":""},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" ")]}}]),model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}})],1),(_vm.selectedTab === 'custom')?_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-8",attrs:{"label":"ชื่อ","validations":[
          {text: 'required!', value: _vm.$v.formData.name.$dirty && _vm.$v.formData.name.$error}
        ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('DropdownUnit',{staticClass:"col-4",attrs:{"select":"id","label":"หน่วย","inventoryCostType":_vm.inventoryCostType,"templateType":_vm.templateType,"validations":[
          {text: 'required!', value: _vm.$v.formData.unitId.$dirty && _vm.$v.formData.unitId.$error}
        ]},model:{value:(_vm.formData.unitId),callback:function ($$v) {_vm.$set(_vm.formData, "unitId", $$v)},expression:"formData.unitId"}})],1):(_vm.selectedTab === 'inventory')?_c('div',{staticClass:"form-row"},[_c('DropdownInventory',{staticClass:"col-12",attrs:{"select":"id","label":"สินค้า","inventoryCostType":_vm.inventoryCostType,"templateType":_vm.templateType,"validations":[
          {text: 'required!', value: _vm.$v.formData.inventoryId.$dirty && _vm.$v.formData.inventoryId.$error}
        ]},model:{value:(_vm.formData.inventoryId),callback:function ($$v) {_vm.$set(_vm.formData, "inventoryId", $$v)},expression:"formData.inventoryId"}})],1):_vm._e(),_c('div',{staticClass:"form-row"},[_c('sgv-input-number',{staticClass:"col-6",attrs:{"label":"จำนวน","precision":2,"validations":[
          {text: 'required!', value: _vm.$v.formData.qty.$dirty && _vm.$v.formData.qty.$error}
        ]},model:{value:(_vm.formData.qty),callback:function ($$v) {_vm.$set(_vm.formData, "qty", $$v)},expression:"formData.qty"}}),_c('sgv-input-number',{staticClass:"col-6",attrs:{"label":"ราคา","precision":2,"validations":[
          {text: 'required!', value: _vm.$v.formData.stdPrice.$dirty && _vm.$v.formData.stdPrice.$error}
        ]},model:{value:(_vm.formData.stdPrice),callback:function ($$v) {_vm.$set(_vm.formData, "stdPrice", $$v)},expression:"formData.stdPrice"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"แจ้งเตือนราคา (%)","placeholder":"ex. 90% 110%"},model:{value:(_vm.formData.priceLimit),callback:function ($$v) {_vm.$set(_vm.formData, "priceLimit", $$v)},expression:"formData.priceLimit"}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12"},[_c('sgv-input-check',{attrs:{"label":"คงที่","inline":""},model:{value:(_vm.formData.isFixPrice),callback:function ($$v) {_vm.$set(_vm.formData, "isFixPrice", $$v)},expression:"formData.isFixPrice"}})],1)]),_c('div',{staticClass:"col-12 px-0",attrs:{"slot":"modal-footer"},slot:"modal-footer"},[(!_vm.inventoryCostOrderId)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.addOrder}},[_vm._v(" เพิ่ม ")]):_vm._e(),(_vm.inventoryCostOrderId)?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.updateOrder}},[_vm._v(" อัพเดท ")]):_vm._e(),(_vm.inventoryCostOrderId)?_c('button',{staticClass:"btn btn-danger float-right",attrs:{"type":"button"},on:{"dblclick":_vm.deleteOrder}},[_vm._v(" ลบ ")]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }