import gql from 'graphql-tag'


export const LIST_CATEGORY = (templateType) => gql`query LIST_CATEGORY ($docType: String!, $q: FilterInput) {
  categories: listDoc${templateType}Category (docType: $docType, q: $q) {
    id type code name
  }
}`

export const DETAIL_CATEGORY = (templateType) => gql`query DETAIL_CATEGORY ($docType: String!, $id: Int!) {
  category: detailDoc${templateType}Category (docType: $docType, id: $id) {
    id type code name
  }
}`

export const CREATE_CATEGORY = (templateType) => gql`mutation CREATE_CATEGORY ($docType: String!, $input: DocCategoryInput!) {
  createCategory: createDoc${templateType}Category (docType: $docType, input: $input) {
    id type code name
  }
}`

export const UPDATE_CATEGORY = (templateType) => gql`mutation UPDATE_CATEGORY ($docType: String!, $id: Int!, $input: DocCategoryInput!) {
  updateCategory: updateDoc${templateType}Category (docType: $docType, id: $id, input: $input) {
    id type code name
  }
}`

export const DESTROY_CATEGORY = (templateType) => gql`mutation DESTROY_CATEGORY ($docType: String!, $id: Int!) {
  destroyCategory: destroyDoc${templateType}Category (docType: $docType, id: $id) {id}
}`

export const SYNC_CATEGORY = (templateType) => gql`mutation SYNC_CATEGORY ($docType: String!, $docId: Int!, $categories: [Int]!) {
  syncCategory: syncDoc${templateType}Category (docType: $docType, docId: $docId, categories: $categories)
}`
