<template>
  <div class="">
    <div v-if="method ==='edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <ul>
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addDoc(item.id)">
          </fa>&nbsp;
          {{item.code}} <span v-show="item.name">({{item.name}})</span>
        </li>
      </ul>
    </div>

    <ul class="list-group">
      <li
        class="list-group-item"
        v-for="(child, idx) in formData.children"
        :key="child.id">
        <fa
          v-if="method ==='edit'"
          icon="trash"
          class="text-danger pointer"
          @click="deleteDoc(child.id)">
        </fa>&nbsp;
        {{idx+1}}. {{child.code}} ({{child.name}})
      </li>
    </ul>
  </div>
</template>

<script>
import {
  LIST_TRAINING,
  CREATE_CHILD,
  DESTROY_CHILD
} from './graph'
import debounce from 'lodash/debounce'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_TRAINING(this.templateType),
        variables: {
          docType: this.docType,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    addDoc (childId) {
      this.$apollo.mutate({
        mutation: CREATE_CHILD(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          childId
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.getList()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    deleteDoc (childId) {
      this.$apollo.mutate({
        mutation: DESTROY_CHILD(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          childId
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.getList()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  },
  watch: {
    search (value) {
      if (value) {
        this.getList({
          limit: 20,
          params: {
            search: value
          },
          order: 'code'
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
