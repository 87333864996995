import gql from 'graphql-tag'


export const LIST_DEADSTOCK = (templateType) => gql`query LIST_DEADSTOCK ($inventoryType: String!, $q: FilterInput) {
  items: listInventory${templateType}DeadStock (inventoryType: $inventoryType, q: $q) {
    id type code name
    unitId unit {id name}
    doc {id type code name}
    approvedAt qty
  }
}`
