<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">รายการ</h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-12">
        <sgv-form :method.sync="method" :options="options">
          <Profile
            type="profile"
            v-if="inventoryId > 0"
            class="row"
            :inventoryType="inventoryType"
            :templateType="templateType"
            :inventoryId="inventoryId"
            :method="method">
          </Profile>

          <DetailForm
            :inventoryType="inventoryType"
            :templateType="templateType"
            :formData="formData"
            :method="method"
            :v="$v">
          </DetailForm>
        </sgv-form>
      </div>
    </div>

    <HrDivider :options="tabs" v-model="selectedTab" class="mt-3"></HrDivider>

    <keep-alive>
      <component
        :is="selectedTab"
        v-if="inventoryId > 0"
        :inventoryType="inventoryType"
        :templateType="templateType"
        :inventoryId="inventoryId"
        :method="method"
        :formData="formData"
        :labels="labels"
        :stockcountTemplateType="stockcountTemplateType"
        :stockcountDocType="stockcountDocType"
        isCompress>
      </component>
    </keep-alive>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm.vue'
import DetailJunctionRelation from './DetailJunctionRelation.vue'
import Attachment from '@/views/inventory_core/components/Attachment.vue'
import Profile from '@/views/inventory_core/components/Profile.vue'
import DocList from '@/views/inventory_core/components/DocList.vue'
import Label from '@/views/inventory_core/components/Label.vue'
import StockList from '@/views/inventory_core/components/StockList.vue'
import PropertyList from '@/views/inventory_core/components/PropertyList.vue'
import {
  DETAIL_INVENTORY,
  CREATE_INVENTORY,
  UPDATE_INVENTORY,
  DESTROY_INVENTORY
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-${this.formData.code || 'รายการ'}`
    }
  },
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    stockcountTemplateType: {
      type: String,
      required: false
    },
    stockcountDocType: {
      type: String,
      required: false
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      listView: `Inventory${this.$form.capitalize(this.inventoryType)}List`,
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}Detail`,
      rFields: ['selectedTab'],
      formData: {
        code: '',
        name: '',
        unitId: null,
        barcode: '',
        supplierSku: '',
        address: '',
        packing: 1,
        lowerLimit: -1,
        upperLimit: -1,
        purchaseLimit: -1,
        description: '',
        infoTag: '',
        productTag: '',
        cautionTag: '',
        categories: [],
        isActive: true,
        isNoLogo: false
      },
      selectedTab: 'DocList',
      labels: [
        'inventoryBarcode',
        'inventoryDetail',
        'inventoryCaution',
        'contactCompany',
        'inventoryBarcodeDetail',
      ]
    }
  },
  validations: {
    formData: {
      code: { required },
      name: { required },
      unitId: { required },
    }
  },
  computed: {
    tabs () {
      return [
        {text: 'เอกสาร', value: 'DocList'},
        {text: 'เชื่อมโยง', value: 'DetailJunctionRelation'},
        {text: 'คุณสมบัติ', value: 'PropertyList'},
        {text: 'พิมพ์', value: 'Label'},
        {text: 'นับสต๊อก', value: 'StockList', hidden: !(this.stockcountTemplateType && this.stockcountDocType)},
        {text: 'ไฟล์', value: 'Attachment'},
      ]
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.inventoryId === 0 || !this.$auth.hasRole(`inventory:${this.inventoryType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.inventoryId === 0 || !this.$auth.hasRole(`inventory:${this.inventoryType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_INVENTORY(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          inventoryId: this.inventoryId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.inventory)})
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'categories') {
          this.formData[key] = v[key].map(x => x.id)
          return
        }
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const obj = {...v}
      return obj
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_INVENTORY(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, inventoryId: res.data.createInventory.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_INVENTORY(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          inventoryId: this.inventoryId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateInventory)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_INVENTORY(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          inventoryId: this.inventoryId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  watch: {
    toggleDetail () {
      this.setRetaining()
    },
    selectedTab () {
      this.setRetaining()
    }
  },
  created () {
    if (this.inventoryId === 0) {
      this.method = 'add'
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
    Attachment,
    Profile,
    DocList,
    Label,
    StockList,
    PropertyList,
    DetailJunctionRelation
  }
}
</script>

<style lang="css" scoped>
</style>
