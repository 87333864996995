import gql from 'graphql-tag'


export const DP_UNITS = (templateType) => gql`query DP_UNITS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownUnit (docType: $docType, q: $q) {
    id name
  }
}`

export const DP_MANAGES = (templateType) => gql`query DP_MANAGES ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownManage (docType: $docType, q: $q) {
    id code name
  }
}`

export const DP_ACCOUNTS = (templateType) => gql`query DP_ACCOUNTS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownAccount (docType: $docType, q: $q) {
    id code name
  }
}`

export const DP_CONTACTS = (templateType) => gql`query DP_CONTACTS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownContact (docType: $docType, q: $q) {
    id type code name branch
  }
}`

export const DP_CONTACT_TYPES = (templateType) => gql`query DP_CONTACT_TYPES ($docType: String!) {
  types: listDoc${templateType}DocConfigDropdownContactType (docType: $docType) {
    text value
  }
}`

export const DP_INVENTORIES = (templateType) => gql`query DP_INVENTORIES ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownInventory (docType: $docType, q: $q) {
    id type code name
  }
}`

export const DP_INVENTORY_TYPES = (templateType) => gql`query DP_INVENTORY_TYPES ($docType: String!) {
  types: listDoc${templateType}DocConfigDropdownInventoryType (docType: $docType) {
    text value
  }
}`

export const DP_KPI_TEMPLATES = (templateType) => gql`query DP_KPI_TEMPLATES ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownKpiTemplate (docType: $docType, q: $q) {
    id type code name isActive
    children {
      id code name
      children {
        id code name formType
        actualPoint minPoint maxPoint
        choices {
          text value
        }
      }
    }
  }
}`

export const DP_MEASUREMENT_TEMPLATES = (templateType) => gql`query DP_MEASUREMENT_TEMPLATES ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}MeasurementTemplate (docType: $docType, q: $q) {
    id type code name isActive
    children {
      id code name
      children {
        id code name formType
        actualValue minValue maxValue
        choices {
          text value
        }
      }
    }
  }
}`

export const DP_EMAILS = (templateType) => gql`query DP_EMAILS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownEmail (docType: $docType, q: $q) {
    id type code name
  }
}`

export const DP_PAPER_WORK_INSTRUCTIONS = (templateType) => gql`query DP_PAPER_WORK_INSTRUCTIONS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownPaperWorkInstruction (docType: $docType, q: $q) {
    id type code name
  }
}`

export const DP_PAPER_TARGET_GRAPHS = (templateType) => gql`query DP_PAPER_TARGET_GRAPHS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownPaperTargetGraph (docType: $docType, q: $q) {
    id type code name
  }
}`

export const DP_PAPER_RISK_ASSESSMENTS = (templateType) => gql`query DP_PAPER_RISK_ASSESSMENTS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownPaperRiskAssessment (docType: $docType, q: $q) {
    id type code name
  }
}`

export const DP_PAPER_ACTION_PLANS = (templateType) => gql`query DP_PAPER_ACTION_PLANS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownPaperActionPlan (docType: $docType, q: $q) {
    id name yearAt
    paper {id code name}
  }
}`

export const DP_DOC_CATEGORIES = (templateType) => gql`query DP_DOC_CATEGORIES ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownDocCategory (docType: $docType, q: $q) {
    id code name
  }
}`

export const DP_JOB_DESCRIPTIONS = (templateType) => gql`query DP_JOB_DESCRIPTIONS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownJobDescription (docType: $docType, q: $q) {
    id code position
  }
}`

export const DP_ABILITIES = (templateType) => gql`query DP_ABILITIES ($docType: String!, $jobDescriptionId: Int!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownAbility (docType: $docType, jobDescriptionId: $jobDescriptionId, q: $q) {
    id name
  }
}`

export const DP_DEVICES = (templateType) => gql`query DP_DEVICES ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownDevice (docType: $docType, q: $q) {
    id type code name
  }
}`

export const DP_STOCK_ASSETS = (templateType) => gql`query DP_STOCK_ASSETS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownStockAsset (docType: $docType, q: $q) {
    id type code
  }
}`

export const DP_STOCK_ASSET_TYPES = (templateType) => gql`query DP_STOCK_ASSET_TYPES ($docType: String!) {
  types: listDoc${templateType}DocConfigDropdownStockAssetType (docType: $docType) {
    text value
  }
}`
