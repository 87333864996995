import Vue from 'vue'

import * as dateLib from '@/libs/date'
import * as formLib from '@/libs/form'

import SgvTable from '@/components/table/SgvTable'
import SgvManual from '@/components/table/SgvManual'

import SgvForm from '@/components/form/SgvForm'

import SgvInputText from '@/components/form/input/SgvInputText.vue'
import SgvInputNumber from '@/components/form/input/SgvInputNumber.vue'
import SgvInputCurrency from '@/components/form/input/SgvInputCurrency.vue'
import SgvInputDate from '@/components/form/input/SgvInputDate.vue'
import SgvInputMonth from '@/components/form/input/SgvInputMonth.vue'
import SgvInputYear from '@/components/form/input/SgvInputYear.vue'
import SgvInputDatetime from '@/components/form/input/SgvInputDatetime.vue'
import SgvInputFile from '@/components/form/input/SgvInputFile.vue'
import SgvInputTextarea from '@/components/form/input/SgvInputTextarea.vue'
import SgvInputPassword from '@/components/form/input/SgvInputPassword.vue'
import SgvInputCheck from '@/components/form/input/SgvInputCheck.vue'
import SgvInputCheckArray from '@/components/form/input/SgvInputCheckArray.vue'
import SgvInputRadio from '@/components/form/input/SgvInputRadio.vue'
import SgvInputSelect from '@/components/form/input/SgvInputSelect.vue'
import SgvInputAutocomplete from '@/components/form/input/SgvInputAutocomplete.vue'
import SgvInputTextSpeech from '@/components/form/input/SgvInputTextSpeech.vue'
import SgvInputBarcode from '@/components/form/input/SgvInputBarcode.vue'

import SgvImage from '@/components/file/SgvImage.vue'
import SgvSpaceAws from '@/components/file/SgvSpaceAws.vue'
import SgvObjectStorage from '@/components/file/SgvObjectStorage.vue'
import SgvAttachment from '@/components/file/SgvAttachment.vue'
import SgvFile from '@/components/file/SgvFile.vue'
import SgvCsv from '@/components/file/SgvCsv.vue'
import SgvPdf from '@/components/file/SgvPdf.vue'

import TabDivider from '@/components/nav/TabDivider'
import HrDivider from '@/components/nav/HrDivider'

const myPlugin = {
  install(Vue) {
    Vue.directive('focus', {
      inserted: function (el) {
        el.focus()
      }
    })

    Vue.filter('date', function (value) {
      return dateLib.format(value).display
    })

    Vue.filter('month', function (value) {
      return dateLib.format(value).month
    })

    Vue.filter('datetime', function (value) {
      return `${dateLib.format(value).display || '-'} (${dateLib.format(value).time})`
    })

    Vue.filter('round', function (value) {
      return formLib.round(value)
    })

    Vue.filter('comma', function (value, decimal) {
      return formLib.addComma(value, decimal)
    })


    Vue.prototype.$date = dateLib
    Vue.prototype.$form = formLib

    Vue.component('sgv-table', SgvTable)
    Vue.component('sgv-manual', SgvManual)

    Vue.component('sgv-form', SgvForm)

    Vue.component('sgv-input-text', SgvInputText)
    Vue.component('sgv-input-number', SgvInputNumber)
    Vue.component('sgv-input-currency', SgvInputCurrency)
    Vue.component('sgv-input-date', SgvInputDate)
    Vue.component('sgv-input-month', SgvInputMonth)
    Vue.component('sgv-input-year', SgvInputYear)
    Vue.component('sgv-input-datetime', SgvInputDatetime)
    Vue.component('sgv-input-file', SgvInputFile)
    Vue.component('sgv-input-textarea', SgvInputTextarea)
    Vue.component('sgv-input-password', SgvInputPassword)
    Vue.component('sgv-input-check', SgvInputCheck)
    Vue.component('sgv-input-check-array', SgvInputCheckArray)
    Vue.component('sgv-input-radio', SgvInputRadio)
    Vue.component('sgv-input-select', SgvInputSelect)
    Vue.component('sgv-input-autocomplete', SgvInputAutocomplete)
    Vue.component('sgv-input-text-speech', SgvInputTextSpeech)
    Vue.component('sgv-input-barcode', SgvInputBarcode)

    Vue.component('sgv-image', SgvImage)
    Vue.component('sgv-space-aws', SgvSpaceAws)
    Vue.component('sgv-object-storage', SgvObjectStorage)
    Vue.component('sgv-attachment', SgvAttachment)
    Vue.component('sgv-file', SgvFile)
    Vue.component('sgv-csv', SgvCsv)
    Vue.component('sgv-pdf', SgvPdf)

    Vue.component('TabDivider', TabDivider)
    Vue.component('HrDivider', HrDivider)
  }
}

Vue.use(myPlugin)

export default myPlugin
