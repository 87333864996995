<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">รายงาน</h3>
      </div>

      <div class="dashhead-toolbar">
        <div class="dashhead-toolbar-item">
          <sgv-input-select
            :options="reports"
            v-model="selectedReport"
            select="value">
            <template slot-scope="item">{{item.text}}</template>
          </sgv-input-select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <component
      :is="selectedReport"
      :inventoryType="inventoryType"
      :templateType="templateType">
    </component>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ReportStockBalanceByInterval from '@/views/inventory_core/components/ReportStockBalanceByInterval'
import ReportStockAuditedByInterval from '@/views/inventory_core/components/ReportStockAuditedByInterval'
import ReportStockInByInterval from '@/views/inventory_core/components/ReportStockInByInterval'
import ReportStockOutByInterval from '@/views/inventory_core/components/ReportStockOutByInterval'
import ReportItem from '@/views/inventory_core/components/ReportItem'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-รายงาน`
    }
  },
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      reports: [
        {text: 'รายชื่อ', value: 'ReportItem', role: `inventory:${this.inventoryType}:report:item`},
        {text: 'รับเข้า', value: 'ReportStockInByInterval', role: `inventory:${this.inventoryType}:read`},
        {text: 'จำหน่ายออก', value: 'ReportStockOutByInterval', role: `inventory:${this.inventoryType}:read`},
        {text: 'ยอดคงเหลือ ณ ช่วงเวลา', value: 'ReportStockBalanceByInterval', role: `inventory:${this.inventoryType}:read`},
        {text: 'นับสต๊อก', value: 'ReportStockAuditedByInterval', role: `inventory:${this.inventoryType}:read`},
      ],
      selectedReport: 'ReportStockBalanceByInterval',
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}Report`,
      rFields: ['selectedReport'],
    }
  },
  watch: {
    selectedReport () {
      this.setRetaining()
    }
  },
  created () {
    this.$store.commit(
      'path/setCurrent',
      {to: this.$route, group: this.group}
    )
  },
  components: {
    ReportStockBalanceByInterval,
    ReportStockAuditedByInterval,
    ReportStockInByInterval,
    ReportStockOutByInterval,
    ReportItem,
  }
}
</script>

<style lang="css" scoped>
</style>
