import gql from 'graphql-tag'


const childrenResponse = `
  id type remark formType parentId
  actualValue minValue maxValue
  createdAt createdBy createdUser {id name}
`

export const LIST_MEASUREMENT = (templateType) => gql`query LIST_MEASUREMENT ($docType: String!, $docId: Int!, $q: FilterInput, $startDate: DateTime, $endDate: DateTime) {
  measurements: listDoc${templateType}Measurement (docType: $docType, docId: $docId) {
    id type code name
    children {
      id type code name formType
      actualValue minValue maxValue
      choices {text value}
      deviceId deviceTopic device {id type code name}
      children(q: $q, startDate: $startDate, endDate: $endDate) {${childrenResponse}}
    }
  }
}`

export const CREATE_MEASUREMENT = (templateType) => gql`mutation CREATE_MEASUREMENT ($docType: String!, $parentId: Int!, $input: Doc${templateType}MeasurementInput!) {
  createMeasurement: createDoc${templateType}Measurement (docType: $docType, parentId: $parentId, input: $input) {${childrenResponse}}
}`

export const WATCH_MEASUREMENT_CREATED = (templateType) => gql`subscription WATCH_MEASUREMENT_CREATED ($docType: String!, $docId: Int!) {
  measurementCreated: watchDoc${templateType}MeasurementCreated (docType: $docType, docId: $docId) {${childrenResponse}}
}`
