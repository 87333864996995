import { capitalize } from '@/libs/form'

export default ({ projectType, assetType, productType, title, subTitle, group }) => {
  const templateType = 'ChronowoodthProject'
  return [
    {
      path: `chronowoodth/project/${projectType}/`,
      name: `ChronowoodthProject${capitalize(projectType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        projectType,
        assetType,
        productType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `chronowoodth/project/${projectType}/:projectId`,
      name: `ChronowoodthProject${capitalize(projectType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        templateType,
        projectType,
        assetType,
        productType,
        title, subTitle, group,
        projectId: Number(route.params.projectId)
      })
    },
  ]
}
