<template>
  <div>
    <div class="row">
      <div class="col-12">
        <sgv-input-radio
          label="ประเภท"
          inline
          :options="addressTypes"
          select="value"
          v-model="addressType">
          <template slot-scope="option">
            {{option.text}}
          </template>

          <span
            slot="label"
            @click="addForm"
            class="px-1 text-success pointer"
            v-if="$auth.hasRole(`contact:${contactType}:add`) && method === 'edit'">
            <fa icon="plus"></fa>
          </span>
        </sgv-input-radio>
      </div>
      <div
        class="col-12"
        v-for="(addrType) in addressTypes"
        :key="addrType.value">
        <div v-if="addressType === addrType.value">
          <AddressCard
            key="form"
            class="mb-3"
            v-if="formComp"
            :isForm="isForm"
            :contactType="contactType"
            :contactId="0"
            :type="addrType.value"
            :item="formData"
            :templateType="templateType"
            @cancel="cancelForm"
            @create="createData">
          </AddressCard>

          <AddressCard
            v-for="addr in addressesByType(addrType.value)"
            :key="addr.id"
            :type="addrType.value"
            :contactType="contactType"
            :contactId="contactId"
            :item="addr"
            :templateType="templateType"
            :printBranch="printBranch"
            :canEdit="method === 'edit'"
            @update="updateData"
            @destroy="destroyData"
            class="mb-3">
          </AddressCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import AddressCard from './AddressCard'
import {
  LIST_ADDRESS_TYPE,
  LIST_ADDRESS,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  DESTROY_ADDRESS,
} from '../graph/address'

export default {
  mixins: [retainMixin],
  props: {
    contactId: {
      type: Number,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    printBranch: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      rKey: `Contact${this.$form.capitalize(this.contactType)}ContactAddress`,
      rFields: ['addressType'],
      addressTypes: [],
      addressType: 'main',
      addresses: [],
      formComp: false,
      formData: {},
      isForm: false,
    }
  },
  apollo: {
    addressTypes: {
      query () {
        return LIST_ADDRESS_TYPE(this.templateType)
      },
      variables () {
        return {contactType: this.contactType}
      },
      fetchPolicy: 'no-cache',
      result (res) {
        if (!res.data.addressTypes.find(v => v.value === this.addressType)) {
          this.addressType = res.data.addressTypes[0]?.value
        }
      }
    },
    addresses: {
      query () {
        return LIST_ADDRESS(this.templateType)
      },
      variables () {
        return {
          contactType: this.contactType,
          contactId: this.contactId
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  },
  methods: {
    addressesByType (type) {
      return this.addresses.filter(v => v.type === type)
    },
    addForm () {
      if (this.isForm) return

      this.formComp = true
      this.formData = {}
      this.isForm = true
    },
    cancelForm () {
      this.formComp = false
      this.formData = {}
      this.isForm = false
    },
    cacheQuery () {
      return {
        query: LIST_ADDRESS(this.templateType),
        variables: {
          contactType: this.contactType,
          contactId: this.contactId
        }
      }
    },
    createData ({input, cb}) {
      this.$apollo.mutate({
        mutation: CREATE_ADDRESS(this.templateType),
        variables: {
          contactType: this.contactType,
          contactId: this.contactId,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.cancelForm()
        this.$apollo.queries.addresses.refetch()
        cb()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData ({id, input, cb}) {
      this.$apollo.mutate({
        mutation: UPDATE_ADDRESS(this.templateType),
        variables: {
          contactType: this.contactType,
          addressId: id,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success('แก้ไขสำเร็จ')
        this.cancelForm()
        this.$apollo.queries.addresses.refetch()
        cb()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData (id) {
      this.$apollo.mutate({
        mutation: DESTROY_ADDRESS(this.templateType),
        variables: {
          contactType: this.contactType,
          addressId: id
        }
      })
      .then(() => {
        this.$toasted.global.success('ลบสำเร็จ')
        this.cancelForm()
        this.$apollo.queries.addresses.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  watch: {
    addressType () {
      this.setRetaining()
    }
  },
  components: {
    AddressCard
  }
}
</script>

<style lang="css">
</style>
