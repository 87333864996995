import { capitalize } from '@/libs/form'
import List from '../List.vue'


export default ({ inventoryStockType, title, subTitle, group }) => {
  const templateType = 'InventoryStock'
  return [
    {
      path: `${templateType}/${inventoryStockType}/config`,
      name: `${templateType}${capitalize(inventoryStockType)}ConfigList`,
      component: () => import(`../InventoryConfig.vue`),
      props: () => ({
        templateType,
        inventoryStockType,
        title, subTitle, group,
      })
    },
    {
      path: `${templateType}/${inventoryStockType}`,
      name: `${templateType}${capitalize(inventoryStockType)}List`,
      component: List,
      props: () => ({
        templateType,
        inventoryStockType,
        title, subTitle, group,
      })
    }
  ]
}
