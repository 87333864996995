import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns';


export const CHART_COLORS = {
  TEXT_LIGHT: 'rgb(248, 249, 250, 1)',

  LINE_PRIMARY: 'rgba(25, 151, 198, 0.5)',
  LINE_SUCCESS: 'rgba(27,201,142, 0.5)',
  LINE_WARNING: 'rgba(228, 216, 54, 0.5)',
  LINE_DANGER: 'rgba(230, 71, 89, 0.5)',

  POINT_PRIMARY: 'rgba(25, 151, 198, 1)',
  POINT_SUCCESS: 'rgba(27,201,142, 1)',
  POINT_WARNING: 'rgba(228, 216, 54, 1)',
  POINT_DANGER: 'rgba(230, 71, 89, 1)',
}

export const CHART_MONTHS_TH = [
  {full: 'มกราคม', short: 'ม.ค.'},
  {full: 'กุมภาพันธ์', short: 'ก.พ.'},
  {full: 'มีนาคม', short: 'มี.ค.'},
  {full: 'เมษายน', short: 'เม.ย.'},
  {full: 'พฤษภาคม', short: 'พ.ค.'},
  {full: 'มิถุนายน', short: 'มิ.ย.'},
  {full: 'กรกฎาคม', short: 'ก.ค.'},
  {full: 'สิงหาคม', short: 'ส.ค.'},
  {full: 'กันยายน', short: 'ก.ย.'},
  {full: 'ตุลาคม', short: 'ต.ค.'},
  {full: 'พฤศจิกายน', short: 'พ.ย.'},
  {full: 'ธันวาคม', short: 'ธ.ค.'},
]

export const CHART_MONTHS_EN = [
  {full: 'January', short: 'Jan'},
  {full: 'February', short: 'Feb'},
  {full: 'March', short: 'Mar'},
  {full: 'April', short: 'Apr'},
  {full: 'May', short: 'May'},
  {full: 'June', short: 'Jun'},
  {full: 'July', short: 'Jul'},
  {full: 'August', short: 'Aug'},
  {full: 'September', short: 'Sep'},
  {full: 'October', short: 'Oct'},
  {full: 'November', short: 'Nov'},
  {full: 'December', short: 'Dec'}
]

export const labelMonths = (config={}) => {
  let lang = config.lang || 'TH'
  let count = config.count || 12
  let isFull = config.isFull || false
  let values = []
  let i, value

  const arr = lang === 'TH' ? CHART_MONTHS_TH : CHART_MONTHS_EN

  for (i = 0; i < count; ++i) {
    value = arr[Math.ceil(i) % 12]

    if (isFull) values.push(value.full)
    else values.push(value.short)
  }

  return values
}

Chart.defaults.color = CHART_COLORS.TEXT_LIGHT

export const CustomChart = Chart
