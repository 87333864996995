import gql from 'graphql-tag'


const listResponse = `
  id type code name remark
  contactId contact {id type code name branch}
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  categories {id name}
`

export const LIST_DOC = (templateType) => gql`query LIST_DOC ($docType: String!, $q: FilterInput) {
  docs: listDoc${templateType} (docType: $docType, q: $q) {${listResponse}}
}`
