<template>
  <div class="form-group">
    <label v-if="label" :for="uid">
      <fa
        class="text-danger"
        icon="exclamation"
        v-if="!isBarcodeValid">
        &nbsp;
      </fa>
      {{ label }}
      <span v-for="type in types" :key="type">
        <span
          :class="{'text-warning': selectedType == type, 'text-muted': selectedType !== type}"
          class="pointer"
          @click="toggleType(type)">
          {{type}}
        </span>
      </span>
    </label>

    <input
      :id="uid"
      type="text"
      class="form-control"
      :class="{'is-invalid': validate.value}"
      :value="value"
      v-bind="$attrs"
      v-on="listeners">

    <div class="invalid-feedback">
      {{ validate.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SgvInputBarcode',
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    label: {
      type: String,
      required: false,
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    focus: {
      type: Boolean,
      required: false
    },
    types: {
      type: Array,
      required: false,
      default() {
        return ['EAN13']
      }
    }
  },
  data () {
    return {
      uid: this.$form.uid(),
      selectedType: null
    }
  },
  computed: {
    validate () {
      return this.validations.find(v => v.value) || {value: false, text: ''}
    },
    listeners () {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.value.trim())
        },
        change: event => {
          this.$emit('change', event.target.value.trim())
        }
      }
    },
    isBarcodeValid () {
      const str = this.value || ''
      if (this.selectedType === 'EAN13') {
        let res = this.isLastDigitValid(str)
        return res
      } else {
        return true
      }
    }
  },
  methods: {
    isLastDigitValid (str) {
      if (str.length !== 13) {
        return false
      } else if (str.split('').some(v => isNaN(v))) {
        return false
      }

      const checkSum = str.split('').reduce((p,v,i) => {
        return i % 2 == 0 ? p + 1 * v : p + 3 * v
      }, 0)
      return checkSum % 10 != 0 ? false : true
    },
    toggleType (type) {
      if (this.selectedType === type) this.selectedType = null
      else this.selectedType = type
    },
    setFocus () {
      let el = document.getElementById(this.uid)
      el.focus()
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.focus) this.setFocus()
    })
  },
  watch: {
    focus (value) {
      if (value) this.setFocus()
    }
  }
}
</script>

<style lang="css">
</style>
