import { capitalize } from '@/libs/form'

export default ({groupType, title, subTitle, group}) => {
  const templateType = 'General'
  return [
    {
      path: `group/${groupType}`,
      name: `Group${capitalize(groupType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        groupType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `group/${groupType}/:groupId`,
      name: `Group${capitalize(groupType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        groupId: Number(route.params.groupId),
        templateType,
        groupType,
        group,
        subTitle,
        title,
      })
    }
  ]
}
