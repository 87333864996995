export default {
  routers: [
    {
      title: 'ระบบ',
      header: true,
      sections: [
        {
          title: 'admin',
          group: 'Admin',
          sections: [
            {
              title: 'User',
              template: 'UserEmployee',
              options: {userType: 'employee'}
            },
            {
              title: 'Device',
              template: 'UserDevice',
              options: {userType: 'device'}
            },
            {
              title: 'Kiosk',
              template: 'UserKioskDevice',
              options: {userType: 'kioskDevice'}
            },
            {
              title: 'Hr',
              template: 'UserGeneral',
              options: {userType: 'hr'}
            },
            {
              title: 'Group',
              template: 'GroupGeneral',
              options: {groupType: 'general'}
            },
            {
              title: 'Role',
              template: 'RoleGeneral',
              options: {roleType: 'general'}
            },
            {
              title: 'Audit',
              template: 'AuditGeneral',
              options: {auditType: 'general'}
            },
            {
              title: 'Asset',
              template: 'AssetGeneral',
              options: {assetType: 'general'}
            }
          ],
        },
        {
          title: 'อุปกรณ์ IoT',
          group: 'Device',
          sections: [
            {
              title: 'Label',
              template: 'LabelGeneral',
              options: {labelType: 'general'}
            },
            {
              title: 'Facility',
              template: 'DeviceFacility',
              options: {deviceType: 'facility'}
            },
            {
              title: 'Barcode Scanner',
              template: 'DeviceBarcodeScanner',
              options: {deviceType: 'barcodeScanner'}
            },
            {
              title: 'Godex Printer',
              template: 'DeviceGodex',
              options: {deviceType: 'godex'}
            },
            {
              title: 'Window Printer',
              template: 'DeviceWindowPrinter',
              options: {deviceType: 'windowPrinter'}
            },
            {
              title: 'Gmail',
              template: 'DeviceGmail',
              options: {deviceType: 'gmail'}
            },
            {
              title: 'Epson Cloud',
              template: 'DeviceEpsonCloud',
              options: {deviceType: 'epsonCloud'}
            },
            {
              title: 'Line App',
              template: 'DeviceLineApp',
              options: {deviceType: 'lineApp'}
            },
            {
              title: 'BAY SME',
              template: 'DeviceBaySme',
              options: {deviceType: 'baySme'}
            },
            {
              title: 'BBL SME',
              template: 'DeviceBblSme',
              options: {deviceType: 'bblSme'}
            },
            {
              title: 'KBANK SME',
              template: 'DeviceKbankSme',
              options: {deviceType: 'kbankSme'}
            },
            {
              title: 'SCB SME',
              template: 'DeviceScbSme',
              options: {deviceType: 'scbSme'}
            },
            {
              title: 'Mixer',
              template: 'DeviceMixer',
              options: {deviceType: 'mixer'}
            },
            {
              title: 'Extruder',
              template: 'DeviceExtruder',
              options: {deviceType: 'extruder'}
            },
            {
              title: 'OpcUA',
              template: 'DeviceOpcua',
              options: {deviceType: 'opcua'}
            },
            {
              title: 'Modbus',
              template: 'DeviceModbus',
              options: {deviceType: 'modbus'}
            },
            {
              title: 'Trello',
              template: 'DeviceTrello',
              options: {deviceType: 'trello'}
            },
          ],
        },
        {
          title: 'Kiosk',
          group: 'Kiosk',
          sections: [
            {
              title: 'Device',
              template: 'KioskDeviceGeneral',
              options: {kioskDeviceType: 'default'}
            },
            {
              title: 'Employee Scanner',
              template: 'KioskViewEmployeeScanner',
              options: {kioskViewType: 'employeeScanner'}
            },
            {
              title: 'Employee Attendance',
              template: 'KioskViewEmployeeAttendance',
              options: {kioskViewType: 'employeeAttendance'}
            },
            {
              title: 'Employee Validation',
              template: 'KioskViewEmployeeValidation',
              options: {kioskViewType: 'employeeValidation'}
            },
            {
              title: 'Trello Board',
              template: 'KioskViewTrello',
              options: {kioskViewType: 'trello'}
            },
            {
              title: 'Doc Status',
              template: 'KioskViewDocStatus',
              options: {kioskViewType: 'docStatus'}
            },
            {
              title: 'Paper Status',
              template: 'KioskViewPaperStatus',
              options: {kioskViewType: 'paperStatus'}
            },
          ],
        },
      ]
    },
  ]
}
