<template>
  <div class="form-row">
    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="เอกสารอ้างอิง"
      v-model="formData.kpi.documentRef">
    </sgv-input-text>
    
    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="ชื่อลูกค้า/โครงการ"
      v-model="formData.kpi.customerName">
    </sgv-input-text>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="ที่อยู่หน้างาน"
      :rows="2"
      v-model="formData.kpi.customerAddr">
    </sgv-input-textarea>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-6"
      label="เบอร์ติดต่อ"
      v-model="formData.kpi.customerTel">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-6"
      label="อีเมล"
      v-model="formData.kpi.customerEmail">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="วันที่และเวลาที่ต้องการเริ่มติดตั้ง"
      v-model="formData.kpi.desiredInstallationDate">
    </sgv-input-text>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="พื้นที่ติดตั้ง (ขนาดพื้นที่, รูปแบบห้อง)"
      :rows="3"
      v-model="formData.kpi.installationArea">
    </sgv-input-textarea>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="สภาพพื้นเดิม"
      v-model="formData.kpi.existingFloorCondition">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="ต้องการการปรับระดับหรือไม่"
      v-model="formData.kpi.floorLevelAssessment">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="อุปสรรค/สิ่งกีดขวางในการติดตั้ง"
      v-model="formData.kpi.obstacles">
    </sgv-input-text>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="ข้อมูลสินค้าที่ต้องใช้"
      :rows="4"
      v-model="formData.kpi.productDetails">
    </sgv-input-textarea>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="รูปแบบการติดตั้ง"
      v-model="formData.kpi.installationPattern">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="ต้องการอุปกรณ์เสริมพิเศษหรือไม่"
      v-model="formData.kpi.specialAccessories">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="การเตรียมพื้นที่ก่อนการติดตั้ง"
      v-model="formData.kpi.preInstallation">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="การจัดเก็บวัสดุเหลือใช้หลังการติดตั้ง"
      v-model="formData.kpi.postInstallation">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="การบำรุงรักษาหลังการติดตั้ง"
      v-model="formData.kpi.postInstallationRequirements">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12 col-md-6"
      label="คำถามหรือข้อกังวลของลูกค้า"
      v-model="formData.kpi.customerSpecificConcerns">
    </sgv-input-text>
  </div>
</template>

<script>
export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>

</style>