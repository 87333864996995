import gql from 'graphql-tag'


export const LIST_ATTACHMENT = (templateType) => gql`query LIST_ATTACHMENT ($inventoryType: String!, $type: String!, $inventoryId: Int!) {
  items: listInventory${templateType}Attachment (inventoryType: $inventoryType, type: $type, inventoryId: $inventoryId) {
    id filename thumbnail
  }
}`

export const LIST_ATTACHMENT_TYPE = (templateType) => gql`query LIST_ATTACHMENT_TYPE ($inventoryType: String!) {
  types: listInventory${templateType}AttachmentType (inventoryType: $inventoryType) {
    text value role
  }
}`

export const DETAIL_ATTACHMENT = (templateType) => gql`query DETAIL_ATTACHMENT ($inventoryType: String!, $type: String!, $attachmentId: Int!, $isDownload: Boolean) {
  url: detailInventory${templateType}Attachment (inventoryType: $inventoryType, type: $type, attachmentId: $attachmentId, isDownload: $isDownload)
}`

export const CREATE_ATTACHMENT = (templateType) => gql`mutation CREATE_ATTACHMENT ($inventoryType: String!, $type: String!, $inventoryId: Int!, $input: InventoryAttachmentInput!) {
  createItem: createInventory${templateType}Attachment (inventoryType: $inventoryType, type: $type, inventoryId: $inventoryId, input: $input)
}`

export const DESTROY_ATTACHMENT = (templateType) => gql`mutation DESTROY_ATTACHMENT ($inventoryType: String!, $type: String!, $attachmentId: Int!) {
  deleteItem: destroyInventory${templateType}Attachment (inventoryType: $inventoryType, type: $type, attachmentId: $attachmentId)
}`
