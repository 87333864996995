<template>
  <div>
    <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

    <ul>
      <li v-for="item in items" :key="item.id">
        <fa
          icon="check"
          class="text-success pointer"
          @click="addGroup(item)">
        </fa>&nbsp;
        {{item.position}} ({{item.code}})
      </li>
    </ul>
  </div>
</template>

<script>
import {
  DP_JOBDESCRIPTIONS,
  CREATE_JOBDESCRIPTION_JUNCTION,
} from './graph'

export default {
  props: {
    contactId: {
      type: Number,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      items: [],
      search: '',
    }
  },
  apollo: {
    items: {
      query () {
        return DP_JOBDESCRIPTIONS(this.templateType)
      },
      variables () {
        return {
          contactType: this.contactType,
          q: {
            params: {
              search: this.search,
              isActive: true
            }
          }
        }
      },
      debounce: 150,
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    addGroup (jobDescription) {
      this.$apollo.mutate({
        mutation: CREATE_JOBDESCRIPTION_JUNCTION(this.templateType),
        variables: {
          contactType: this.contactType,
          jobDescriptionId: jobDescription.id,
          contactId: this.contactId
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
