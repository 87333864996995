import gql from 'graphql-tag'


export const LIST_DOC = (templateType) => gql`query LIST_DOC ($inventoryType: String!, $inventoryId: Int!, $q: FilterInput){
  docs: listInventory${templateType}Doc (inventoryType: $inventoryType, inventoryId: $inventoryId, q: $q) {
    id type code name remark
    createdBy createdAt createdUser {id name}
    approvedBy approvedAt approvedUser {id name}
    closedBy closedAt closedUser {id name}
  }
}`
