<template>
  <div class="">
    <div class="form-row">
      <sgv-input-file
        class="col-12"
        ref="fileUpload"
        label="ไฟล์ CSV"
        @change="changeToJSON($event)">
      </sgv-input-file>
    </div>

    <button
      class="btn btn-primary"
      type="button"
      @click="reset">
      ยกเลิก
    </button>

    <sgv-csv
      :items="csvData"
      :labels="csvLabels"
      :filename="csvFilename">
      <button
        type="button"
        class="btn btn-info ml-2">
        ตัวอย่าง CSV
      </button>
    </sgv-csv>

    <div class="mt-3" v-if="unitRows.length > 0">
      <span class="text-danger">ไม่พบหน่วย:</span>
      <sgv-table
        :items="unitRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">{{item.code}}</td>
            <td v-if="hidden.name">{{item.name}} ({{item.unit}})</td>
            <td v-if="hidden.description"></td>
          </tr>
        </template>
      </sgv-table>
    </div>

    <div class="mt-3" v-if="categoryRows.length > 0">
      <span class="text-danger">ไม่พบหมวดหมู่:</span>
      <sgv-table
        :items="categoryRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">{{item.code}}</td>
            <td v-if="hidden.name">{{item.name}}</td>
            <td v-if="hidden.description"></td>
          </tr>
        </template>
      </sgv-table>
    </div>

    <div class="mt-3" v-if="addRows.length > 0">
      <button
        type="button"
        class="btn btn-success btn-sm mb-2"
        @dblclick="addAll">
        เพิ่มทั้งหมด
      </button>
      <sgv-table
        :items="addRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">
              <button
                type="button"
                class="btn btn-success btn-sm mr-1"
                @click="addData(item)">
                เพิ่ม
              </button>
              {{item.code}}
            </td>
            <td v-if="hidden.name">{{item.name}} ({{item.unit.name}})</td>
            <td v-if="hidden.description">
              <small>
                <div v-if="item.categories && item.categories.length > 0" class="text-info">หมวดหมู่: {{item.categories[0].name}}</div>
                <div v-if="item.address">ที่เก็บ: {{item.address}}</div>
                <div v-if="item.barcode">บาร์โค๊ด: {{item.barcode}}</div>
                <div v-if="item.supplierSku">รหัสซัพพลายเออร์: {{item.supplierSku}}</div>
                <div v-if="item.description">รายละเอียด: {{item.description}}</div>
                <div v-if="item.packing">จำนวนบรรจุ: {{item.packing}}</div>
                <div v-if="item.lowerLimit">จำนวนน้อยสุด: {{item.lowerLimit}}</div>
                <div v-if="item.upperLimit">จำนวนสูงสุด: {{item.upperLimit}}</div>
                <div v-if="item.purchaseLimit">จำนวนสั่งซื้อ: {{item.purchaseLimit}}</div>
              </small>
            </td>
          </tr>
        </template>
      </sgv-table>
    </div>

    <div class="mt-3" v-if="updateRows.length > 0">
      <button
        type="button"
        class="btn btn-warning btn-sm mb-2"
        @dblclick="updateAll">
        แก้ไขทั้งหมด
      </button>
      <sgv-table
        :items="updateRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">
              <button
                type="button"
                class="btn btn-warning btn-sm mr-1"
                @click="updateData(item)">
                แก้ไข
              </button>
              {{item.code}}
            </td>
            <td v-if="hidden.name">{{item.name}} ({{item.unit.name}})</td>
            <td v-if="hidden.description">
              <small>
                <div v-if="item.categories && item.categories.length > 0" class="text-info">หมวดหมู่: {{item.categories[0].name}}</div>
                <div v-if="item.address">ที่เก็บ: {{item.address}}</div>
                <div v-if="item.barcode">บาร์โค๊ด: {{item.barcode}}</div>
                <div v-if="item.supplierSku">รหัสซัพพลายเออร์: {{item.supplierSku}}</div>
                <div v-if="item.description">รายละเอียด: {{item.description}}</div>
                <div v-if="item.packing">จำนวนบรรจุ: {{item.packing}}</div>
                <div v-if="item.lowerLimit">จำนวนน้อยสุด: {{item.lowerLimit}}</div>
                <div v-if="item.upperLimit">จำนวนสูงสุด: {{item.upperLimit}}</div>
                <div v-if="item.purchaseLimit">จำนวนสั่งซื้อ: {{item.purchaseLimit}}</div>
              </small>
            </td>
          </tr>
        </template>
      </sgv-table>
    </div>
  </div>

</template>

<script>
import debounce from 'lodash/debounce'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_IMPORT_VALIDATION,
  CREATE_INVENTORY,
  UPDATE_INVENTORY
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Inventory${this.$form.capitalize(this.inventoryType)}ListImport`,
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
        {text: 'รายละเอียด', value: 'description'}
      ],
      options: {
        headers: ['code', 'name', 'description'],
        column: null,
        search: null,
        toolbar: null,
      },
      csvFilename: 'ตัวอย่างนำเข้าสินค้า',
      csvLabels: {
        code: { title: 'code' },
        name: { title: 'name' },
        unit: { title: 'unit' },
        category: { title: 'category' },
        description: { title: 'description' },
        barcode: { title: 'barcode' },
        supplierSku: { title: 'supplierSku' },
        address: { title: 'address' },
        packing: { title: 'packing' },
        lowerLimit: { title: 'lowerLimit' },
        upperLimit: { title: 'upperLimit' },
        purchaseLimit: { title: 'purchaseLimit' },
      },
      csvData: [
        {
          code: 'รหัส',
          name: 'ชื่อ',
          unit: 'หน่วย',
          category: 'หมวดหมู่',
          description: 'รายละเอียด',
          barcode: 'บาร์โค๊ด',
          supplierSku: 'รหัสสินค้าซัพพลายเออร์',
          address: 'ที่เก็บ',
          packing: 'จำนวน/กล่อง',
          lowerLimit: 'จำนวนน้อยสุด',
          upperLimit: 'จำนวนมากสุด',
          purchaseLimit: 'จำนวนสั่งซื้อ',
        },
        {
          code: 'DK01',
          name: 'โดรายากิ',
          unit: 'ชิ้น',
          category: '',
          description: 'โดรายากิแสนอร่อย',
          barcode: '11112222333345',
          supplierSku: 'DKG-01-0001',
          address: 'F1-10',
          packing: '12',
          lowerLimit: '100',
          upperLimit: '1000',
          purchaseLimit: '-1',
        },
        {
          code: 'C01',
          name: 'Kiwi 01',
          unit: 'ชิ้น',
          category: 'ผลไม้ไทย',
          description: '',
          barcode: '',
          supplierSku: '',
          address: 'F1-11',
          packing: '',
          lowerLimit: '100',
          upperLimit: '200',
          purchaseLimit: '20',
        },
        {
          code: 'F01',
          name: 'Falcon',
          unit: 'กลีบ',
          category: '',
          description: '',
          barcode: '',
          supplierSku: '',
          address: '',
          packing: '',
          lowerLimit: '',
          upperLimit: '',
          purchaseLimit: '',
        }
      ],
      unitRows: [],
      categoryRows: [],
      addRows: [],
      updateRows: [],
      inputRows: []
    }
  },
  methods: {
    changeToJSON (e) {
      var fileInput = e.target.files[0]
      const options = {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          this.inputRows = results.data.slice(1)
          this.getValidation()
        }
      }
      this.$papa.parse(fileInput, options)
    },
    reset () {
      this.setDefault ()
      this.$refs.fileUpload.reset()
    },
    serializeInput (v) {
      return {
        code: v.code,
        name: v.name,
        unit: v.unit,
        category: v.category,
        description: v.description,
        barcode: v.barcode,
        supplierSku: v.supplierSku,
        address: v.address,
        packing: +v.packing,
        lowerLimit: +v.lowerLimit,
        upperLimit: +v.upperLimit,
        purchaseLimit: +v.purchaseLimit,
      }
    },
    getValidation: debounce(function() {
      this.$apollo.query({
        query: LIST_IMPORT_VALIDATION(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          input: this.inputRows.map(v => this.serializeInput(v))
        },
        fetchPolicy: 'no-cache',
      })
      .then(result => {
        this.unitRows = result.data.result?.units || []
        this.categoryRows = result.data.result?.categories || []
        this.addRows = result.data.result?.adds || []
        this.updateRows = result.data.result?.updates || []
      })
      .catch(err => {
        this.setDefault ()
        this.$toasted.global.error(err)
      })
    }, 300),
    setDefault() {
      this.unitRows = []
      this.categoryRows = []
      this.addRows = []
      this.updateRows = []
    },
    addData (item) {
      const input = {
        ...this.serializeInput(item),
        unitId: item.unitId,
        categories: item.categories.map(x => x.id)
      }
      delete input.unit

      this.$apollo.mutate({
        mutation: CREATE_INVENTORY(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.getValidation()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData (item) {
      const input = {
        ...this.serializeInput(item),
        unitId: item.unitId,
        categories: item.categories.map(x => x.id)
      }
      delete input.unit
      delete input.id

      this.$apollo.mutate({
        mutation: UPDATE_INVENTORY(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          inventoryId: item.id,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("อัพเดทสำเร็จ")
        this.getValidation()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    async addAll () {
      const items = this.addRows
      for (let i = 0; i < items.length; i++) {
        await this.addData(items[i])
      }
    },
    async updateAll () {
      const items = this.updateRows
      for (let i = 0; i < items.length; i++) {
        await this.updateData(items[i])
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
