<template>
  <div class="form-group">
    <label v-if="label" :for="uid">
      {{ label }}
    </label>

    <slot name="label"></slot>

    <div class="input-group">
      <input
        :id="uid"
        type="text"
        class="form-control"
        :class="{'is-invalid': validate.value}"
        :value="value"
        v-bind="$attrs"
        v-on="listeners">
      <div class="input-group-append pointer" @click="toggleRecord">
        <span
          class="input-group-text"
          :class="{'text-success': !isRecord, 'text-warning': isRecord}">
          <fa icon="microphone-alt"></fa>
        </span>
      </div>
    </div>

    <div class="invalid-feedback">
      {{ validate.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SgvInputText',
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    label: {
      type: String,
      required: false,
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    focus: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      uid: this.$form.uid(),
      isRecord: false,
      msg: '',
      canSpeech: false
    }
  },
  computed: {
    validate () {
      return this.validations.find(v => v.value) || {value: false, text: ''}
    },
    listeners () {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.value)
        },
        change: event => {
          this.$emit('change', event.target.value)
        }
      }
    }
  },
  methods: {
    setFocus () {
      let el = document.getElementById(this.uid)
      el.focus()
    },
    initSpeech () {
      if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
        // eslint-disable-next-line
        let SpeechRecognition = webkitSpeechRecognition || SpeechRecognition
        let recognition = new SpeechRecognition()
        recognition.lang = 'th-TH'
        recognition.interimResults = true
        recognition.maxAlternatives = 1
        recognition.continuous = false
        recognition.onstart = () => {
          this.msg = ''
          this.isRecord = true
        }
        recognition.onspeechend = () => {
          this.$emit('speechend', this.msg)
          recognition.stop()
          this.msg = ''
          this.isRecord = false
        }
        recognition.onresult = (event) => {
          if (event) {
            let msg = []
            for (let i = 0; i < event.results.length; i++){
              let speechRecognitionResult = event.results[i]
              for (let j = 0; j < speechRecognitionResult.length; j++){
                let txt = speechRecognitionResult[j]
                if (txt.confidence > 0) {
                  if (txt.transcript === 'ลบ') {
                    msg.pop()
                  } else {
                    msg.push(txt.transcript)
                  }
                }
              }
            }
            let data = msg.join('').replace(/เว้นวรรค/g, ' ')
            this.msg = data
            this.$emit('speech', data)
          }
        }
        recognition.start()
      }
    },
    toggleRecord () {
      this.initSpeech()
    },
    checkSpeech () {
      if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
        this.canSpeech = true
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.focus) this.setFocus()
    })
  },
  watch: {
    focus (value) {
      if (value) this.setFocus()
    }
  },
  created () {
    this.checkSpeech()
  }
}
</script>

<style lang="css">
</style>
