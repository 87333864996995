<template>
  <div class="row">
    <div class="col-12">
      <span class="float-left">
        <span class="mr-1">{{itemIndex}}</span>
        {{item.name}}
        <small>
          <div>
            <span
              v-for="(choice,choiceIndex) in sortChoices"
              :key="choiceIndex"
              class="mr-2">
              {{choice.text}} ({{choice.value}})
            </span>
          </div>
          <div v-if="item.device" class="text-info">
            <fa icon="hdd"></fa>
            {{item.device.code}} ({{item.device.name}}) ({{item.deviceTopic}})
          </div>
        </small>
      </span>

      <DetailMeasurementFormChoiceModal
        class="float-right"
        :item="item"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :canSave="canSave">
      </DetailMeasurementFormChoiceModal>
    </div>

    <div
      class="col-12 mt-2 d-flex flex-row"
      v-if="item.children.length > 0"
      style="overflow-x: auto;">
      <ul v-for="(children,i) in childrenCols" :key="i" class="no-wrap">
        <li
          v-for="child in children"
          :key="child.id">
          <span :class="{
            'text-success': child.actualValue == highestChoice,
            'text-danger': child.actualValue == lowestChoice,
          }">
            {{getChoice(item.choices, child.actualValue)}}
          </span>
          <small v-if="child.remark" class="text-info ml-1">
            ({{child.remark}})
          </small>
          <div>
            <small class="text-primary">
              {{child.createdAt | datetime}}: {{child.createdUser.name}}
            </small>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import chunk from 'lodash/chunk'
import DetailMeasurementFormChoiceModal from './DetailMeasurementFormChoiceModal'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    itemIndex: {
      type: String,
      required: true
    },
    canSave: {
      type: Boolean
    },
    chunkSize: {
      type: Number,
      required: true
    }
  },
  computed: {
    childrenCols () {
      return chunk(this.item.children, this.chunkSize)
    },
    sortChoices () {
      return [...this.item.choices].sort((a,b) => a.value - b.value)
    },
    lowestChoice () {
      return this.sortChoices[0]?.value || 0
    },
    highestChoice () {
      return this.sortChoices[this.sortChoices.length - 1]?.value || 1
    }
  },
  methods: {
    getChoice (choices, value) {
      return choices.find(v => v.value === value).text
    },
  },
  components: {
    DetailMeasurementFormChoiceModal
  }
}
</script>

<style lang="css" scoped>
</style>
