<template>
  <div class="">
    <DetailOrderGroupInlet
      class="mb-3"
      :templateType="templateType"
      :inventoryCostType="inventoryCostType"
      :inventoryCostId="inventoryCostId">
    </DetailOrderGroupInlet>

    <DetailOrderGroupOutlet
      class="mb-3"
      :templateType="templateType"
      :inventoryCostType="inventoryCostType"
      :inventoryCostId="inventoryCostId">
    </DetailOrderGroupOutlet>
  </div>
</template>

<script>
import DetailOrderGroupInlet from './DetailOrderGroupInlet'
import DetailOrderGroupOutlet from './DetailOrderGroupOutlet'

export default {
  props: {
    inventoryCostType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryCostId: {
      type: Number,
      required: true
    },
  },
  components: {
    DetailOrderGroupInlet,
    DetailOrderGroupOutlet
  }
}
</script>

<style lang="css" scoped>
</style>
