import general from './general'
import production from './production'
import sale from './sale'
import hr from './hr'
import contact from './contact'
import inventory from './inventory'
import admin from './admin'
import purchase from './purchase'
import paper from './paper'
import chronowoodth from './chronowoodth'

export default {
  home: 'ProfileHrList',
  routers: [
    ...general.routers,
    ...purchase.routers,
    ...production.routers,
    ...sale.routers,
    ...hr.routers,
    ...contact.routers,
    ...inventory.routers,
    ...chronowoodth.routers,
    ...paper.routers,
    ...admin.routers,
  ]
}
