import { capitalize } from '@/libs/form'

export default ({procedureType, title, subTitle, group}) => {
  const templateType = 'Planning'
  return [
    {
      path: `procedure/${procedureType}/planning`,
      name: `Procedure${capitalize(procedureType)}Planning`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        procedureType,
        title,
        subTitle,
        group,
      })
    },
  ]
}
