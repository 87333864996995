import gql from 'graphql-tag'


const listResponse = `
  id type code name remark
  invoiceRef receiptRef
  contactId contact {id type code name branch}
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  categories {id name}
  relevance {
    payments {id type code}
  }
  orders {
    id children {
      id type totalPrice
      docId doc {id type code closedAt}
      ledgers {
        id amount
        accountId account {id code name}
      }
    }
  }
  accountId account {id code name}
  amount paid remaining
`

export const LIST_DOC = (templateType) => gql`query LIST_DOC ($docType: String!, $accruedDate: DateTime!, $q: FilterInput) {
  docs: listDoc${templateType} (docType: $docType, accruedDate: $accruedDate, q: $q) {${listResponse}}
}`
