<template>
  <div class="">
    <div class="form-row">
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-success mb-3 mr-2"
          @click="addForm">
          เพิ่ม
        </button>
        <button
          type="button"
          class="btn btn-primary mb-3"
          @click="toList">
          กลับ
        </button>
      </div>

      <div class="col"></div>

      <sgv-input-text
        class="col-sm-auto col-12"
        v-model="search"
        placeholder="ค้นหา...">
      </sgv-input-text>
    </div>

    <DocConfigListCardReport
      class="mb-3"
      v-if="isForm"
      :isForm="isForm"
      :docType="docType"
      :templateType="templateType"
      :item="formData"
      @cancel="cancelForm"
      @update="updateData">
    </DocConfigListCardReport>

    <div
      v-for="item in items"
      :key="item.id"
      class="mb-3">
      <DocConfigListCardReport
        :docType="docType"
        :templateType="templateType"
        :item="item"
        @update="updateData"
        @destroy="destroyData">
      </DocConfigListCardReport>
    </div>
  </div>
</template>

<script>
import {
  LIST_DOC_CONFIG_REPORT,
  CREATE_DOC_CONFIG_REPORT,
  DESTROY_DOC_CONFIG_REPORT,
} from '../graph/doc_config_report'
import DocConfigListCardReport from './DocConfigListCardReport.vue'


export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    reportType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      search: '',
      formComp: '',
      formData: {},
      isForm: false,
      items: [],
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_DOC_CONFIG_REPORT(this.templateType, this.reportType)
      },
      fetchPolicy: 'cache-and-network',
      variables () {
        return {
          docType: this.docType,
          q: {
            params: {
              search: this.search,
            }
          }
        }
      },
      debounce: 250
    }
  },
  methods: {
    toList () {
      this.$router.push({
        name: `Doc${this.$form.capitalize(this.docType)}List`
      })
    },
    addForm () {
      if (this.isForm) return
      this.formData = {}
      this.isForm = true
    },
    cancelForm () {
      this.formData = {}
      this.isForm = false
    },
    cacheQuery () {
      return {
        query: LIST_DOC_CONFIG_REPORT(this.templateType, this.reportType),
        variables: {
          docType: this.docType,
          q: {
            params: {
              search: this.search,
            }
          }
        }
      }
    },
    updateData ({id, input, cb}) {
      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_REPORT(this.templateType, this.reportType),
        variables: {
          docType: this.docType,
          docConfigId: id,
          input
        },
        update: (store, {data: {item}}) => {
          const data = store.readQuery(this.cacheQuery())

          if (id) {
            const idx = data.items.findIndex(v => v.id === item.id)
            if (idx !== -1) {
              data.items[idx] = item
            }
          } else {
            data.items.unshift(item)
          }
          store.writeQuery({...this.cacheQuery(), data})
        }
      })
      .then(() => {
        this.$toasted.global.success('เพิ่มสำเร็จ')
        this.cancelForm()
        cb()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData ({id}) {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_REPORT(this.templateType, this.reportType),
        variables: {
          docType: this.docType,
          docConfigId: id
        },
        update: (store, {data: {item}}) => {
          const data = store.readQuery(this.cacheQuery())
          const idx = data.items.findIndex(v => v.id === item.id)
          if (idx !== -1) {
            data.items.splice(idx, 1)
          }
          store.writeQuery({...this.cacheQuery(), data})
        }
      })
      .then(() => {
        this.$toasted.global.success('ลบสำเร็จ')
        this.cancelForm()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  components: {
    DocConfigListCardReport
  }
}
</script>

<style lang="css">

</style>
