<template>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-date
          label="วันที่ประเมิน"
          v-model="closedDate"
          class="col-12 col-md-4"
          :validations="[
            {text: 'required!', value: $v.closedDate.$dirty && $v.closedDate.$error}
          ]">
        </sgv-input-date>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CLOSE_DOC } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      closedDate: this.$date.current().date,
    }
  },
  validations: {
    closedDate: { required }
  },
  methods: {
    closeData () {
      this.$v.closedDate.$touch()
      if (this.$v.closedDate.$invalid) return

      this.$apollo.mutate({
        mutation: CLOSE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          closedAt: this.$date.format(this.closedDate).utc
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.$toasted.global.success("จบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
