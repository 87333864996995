<template>
  <div class="">
    <div class="form-row">
      <sgv-input-radio
        label="แสดงผล"
        class="col-6"
        inline
        :options="displayTypes"
        select="value"
        v-model="displayType">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-radio>

      <sgv-input-select
        label="ระยะเวลา"
        class="col-6"
        :options="intervalOptions"
        select="value"
        v-model="intervalSelected">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-select>

      <sgv-input-datetime
        :disabled="intervalSelected !== 'custom'"
        label="เริ่มต้น"
        class="col-6"
        isNull
        v-model="startDate">
      </sgv-input-datetime>

      <sgv-input-datetime
        :disabled="intervalSelected !== 'custom'"
        label="สิ้นสุด"
        class="col-6"
        isNull
        v-model="endDate">
      </sgv-input-datetime>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import debounce from 'lodash/debounce'

export default {
  mixins: [retainMixin],
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailMeasurementCardSlider`,
      rFields: ['displayType', 'intervalSelected'],
      displayTypes: [
        {text: 'ตาราง', value: 'DetailMeasurementCardTable'},
        {text: 'กราฟ', value: 'DetailMeasurementCardChart'},
      ],
      intervalOptions: [
        {text: '6 ชั่วโมง', value: '6hrs'},
        {text: '12 ชั่วโมง', value: '12hrs'},
        {text: '24 ชั่วโมง', value: '24hrs'},
        {text: 'ทั้งหมด', value: 'all'},
        {text: 'กำหนดเอง', value: 'custom'},
      ],
      displayType: 'DetailMeasurementCardTable',
      intervalSelected: 'all',

      startDate: '',
      endDate: '',
      labels: []
    }
  },
  computed: {
    lowestTime () {
      return this.getDateTruncate(this.$date.format(this.startDate))
    },
    highestTime () {
      return this.getDateTruncate(this.$date.format(this.endDate))
    },
  },
  methods: {
    getTimeSeries: function () {
      this.labels = this.getLabels(this.lowestTime, this.highestTime)
    },
    getDateTruncate (d) {
      const hour = d.time.split(':')[0]
      const str = `${d.date}T${hour}:00:00`

      return new Date(str)
    },
    getInterval (lowestTime, highestTime) {
      const delta = highestTime - lowestTime
      const hr = 60 * 60 * 1000

      if (delta <= 8 * hr) return 60 * 15
      else if (delta <= 14 * hr) return 60 * 30
      else if (delta <= 26 * hr) return 60 * 60
      else if (delta <= 51 * hr) return 60 * 60 * 3
      else return 60 * 60 * 24
    },
    getLabels (lowestTime, highestTime) {
      const interval = this.getInterval(lowestTime, highestTime)
      return this.$date.timeSeries(this.lowestTime, this.highestTime, interval).map(v => {
        const d = this.$date.format(v)
        return {
          label: d.displayDatetime,
          datetime: v
        }
      })
    }
  },
  watch: {
    intervalSelected: {
      handler (v) {
        const d = new Date()
        if (v === '6hrs') {
          this.startDate = this.$date.format(d, -3600*6).datetime
          this.endDate = this.$date.format(d, 3600*1).datetime
        } else if (v === '12hrs') {
          this.startDate = this.$date.format(d, -3600*12).datetime
          this.endDate = this.$date.format(d, 3600*1).datetime
        } else if (v === '24hrs') {
          this.startDate = this.$date.format(d, -3600*24).datetime
          this.endDate = this.$date.format(d, 3600*1).datetime
        } else if (v === 'all') {
          const st = this.$date.format(this.formData.approvedAt)
          const ed = this.$date.format(this.formData.closedAt || new Date())
          const interval = this.getInterval(
            this.getDateTruncate(st),
            this.getDateTruncate(ed)
          )

          if (interval === 86400) {
            this.startDate = `${st.date}T00:00`
            this.endDate = this.$date.format(ed.raw, 0, 1).datetime
          } else {
            this.startDate = st.datetime
            this.endDate = this.$date.format(ed.raw, interval).datetime
          }
        }
      },
      immediate: true
    },
    labels: {
      handler (v) {
        this.setRetaining()
        this.$emit('xLabels', v)
        this.$emit('startTime', this.lowestTime)
        this.$emit('endTime', this.highestTime)
      }
    },
    lowestTime () {
      this.debounceGetTimeSeries()
    },
    highestTime () {
      this.debounceGetTimeSeries()
    },
    displayType: {
      handler (v) {
        this.setRetaining()
        this.$emit('displayType', v)
      },
      immediate: true
    }
  },
  created () {
    this.debounceGetTimeSeries = debounce(this.getTimeSeries, 300)

    this.debounceGetTimeSeries()
  }
}
</script>

<style lang="css" scoped>
</style>
