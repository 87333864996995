import gql from 'graphql-tag'


export const LIST_CATEGORY = (templateType) => gql`query LIST_CATEGORY ($inventoryType: String!, $q: FilterInput) {
  categories: listInventory${templateType}Category (inventoryType: $inventoryType, q: $q) {
    id type code name
  }
}`

export const DETAIL_CATEGORY = (templateType) => gql`query DETAIL_CATEGORY ($inventoryType: String!, $categoryId: Int!) {
  category: detailInventory${templateType}Category (inventoryType: $inventoryType, categoryId: $categoryId) {
    id type code name
  }
}`

export const CREATE_CATEGORY = (templateType) => gql`mutation CREATE_CATEGORY ($inventoryType: String!, $input: InventoryCategoryInput!) {
  createCategory: createInventory${templateType}Category (inventoryType: $inventoryType, input: $input) {
    id type code name
  }
}`

export const UPDATE_CATEGORY = (templateType) => gql`mutation UPDATE_CATEGORY ($inventoryType: String!, $categoryId: Int!, $input: InventoryCategoryInput!) {
  updateCategory: updateInventory${templateType}Category (inventoryType: $inventoryType, categoryId: $categoryId, input: $input) {
    id type code name
  }
}`

export const DESTROY_CATEGORY = (templateType) => gql`mutation DESTROY_CATEGORY ($inventoryType: String!, $categoryId: Int!) {
  destroyCategory: destroyInventory${templateType}Category (inventoryType: $inventoryType, categoryId: $categoryId) {id}
}`

export const SYNC_CATEGORY = (templateType) => gql`mutation SYNC_CATEGORY ($inventoryType: String!, $docId: Int!, $categories: [Int]!) {
  syncCategory: syncInventory${templateType}Category (inventoryType: $inventoryType, docId: $docId, categories: $categories)
}`
