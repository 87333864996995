<template>
  <div class="">
    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :toolbars="toolbars"
      :options.sync="options">

      <DetailOrderPersonalTaxCsv
        slot="action"
        :items="items"
        :formData="formData">
      </DetailOrderPersonalTaxCsv>

      <template slot="option" v-if="options.toolbar === 'other'">
        <div class="mb-3">
          <DetailOrderPersonalTaxPayable
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :parentIds="parentIds">
          </DetailOrderPersonalTaxPayable>
        </div>
      </template>

      <template slot-scope="{item, hidden, idx}">
        <tr>
          <td v-if="hidden.id">
            <span
              v-if="!formData.approvedAt"
              class="text-danger pointer px-1"
              @click="destroyOrder(item.doc.id)">
              <fa icon="trash"></fa>
            </span>
            <span>{{idx+1}}.</span>
          </td>
          <td v-if="hidden.doc">
            {{item.doc.closedAt | date}}
            <small>
              <div>
                <router-link
                  class="text-decoration-none text-success"
                  :to="toDoc(item.doc)">
                  {{item.doc.code}}
                </router-link>
              </div>
            </small>
          </td>
          <td v-if="hidden.contact">
            {{item.contact.code}}
            <span
              v-if="isContactMissing(item.contact)"
              class="text-danger px-1 blink">
              <fa icon="map-marker-alt"></fa>
            </span>
            <small>
              <div class="text-primary">
                {{item.contact.name}}
                <span v-if="item.contact.branch && item.contact.branch !== 'สำนักงานใหญ่'">
                  {{item.contact.branch}}
                </span>
              </div>
            </small>
          </td>
          <td v-if="hidden.totalPrice">
            {{item.totalPrice | comma}}
            <small>
              <div
                class="text-info"
                v-for="ledger in item.ledgers"
                :key="ledger.id">
                {{ledger.account.code}} ({{ledger.account.name}})
              </div>
            </small>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import { round } from 'lodash'
import DetailOrderPersonalTaxPayable from './DetailOrderPersonalTaxPayable.vue'
import DetailOrderPersonalTaxCsv from './DetailOrderPersonalTaxCsv.vue'
import {
  LIST_ORDER_PERSONAL_TAX,
  DESTROY_ORDER_PERSONAL_TAX,
  WATCH_ORDER_CREATED,
  WATCH_ORDER_DESTROYED,
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'doc', 'contact', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: []
    }
  },
  computed: {
    headers () {
      return [
        {text: 'ลำดับ', value: 'id'},
        {text: 'วันที่', value: 'doc'},
        {text: 'ผู้ติดต่อ', value: 'contact'},
        {text: `ภาษีที่หัก (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      const arr = []

      if (!this.formData.approvedAt) {
        arr.push({value: 'other', icon: 'plus', class: 'text-success'})
      }

      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    ledgersSum () {
      const total = this.items
      .flatMap(v => v.ledgers)
      .reduce((t,v) => t += +v.amount, 0)

      return round(total, 2)
    },
    parentIds () {
      return this.items.map(o => o.doc.id)
    },
    items () {
      return this.orders.map(o => {
        return {
          id: o.id,
          doc: o.parent.doc,
          contact: o.parent.doc.contact,
          totalPrice: o.totalPrice,
          ledgers: o.ledgers,
          withholdingPrice: o.withholdingPrice
        }
      })
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER_PERSONAL_TAX(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 250,
      fetchPolicy: 'no-cache'
    },
    $subscribe: {
      orderCreated: {
        query () {
          return WATCH_ORDER_CREATED(this.templateType)
        },
        variables() {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.$apollo.queries.orders.refetch()
        },
      },
      orderDestroyed: {
        query () {
          return WATCH_ORDER_DESTROYED(this.templateType)
        },
        variables() {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.$apollo.queries.orders.refetch()
        },
      },
    }
  },
  methods: {
    getFilter (v) {
      return v
    },
    destroyOrder (parentId) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER_PERSONAL_TAX(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          parentIds: [parentId]
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
    isContactMissing (contact) {
      return !contact.prefix || !contact.firstname
    }
  },
  watch: {
    'formData.approvedAt': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.options.toolbar = ''
        }
      }
    }
  },
  components: {
    DetailOrderPersonalTaxPayable,
    DetailOrderPersonalTaxCsv
  }
}
</script>

<style lang="css">
</style>
