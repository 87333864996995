<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          <span class="mr-2">{{title}}</span>
          <router-link
            class="text-decoration-none text-secondary mr-2"
            v-if="$auth.hasRole(`inventoryStock:${this.inventoryStockType}:config`)"
            :to="toInventoryConfig">
            <small><fa icon="cog"></fa></small>
          </router-link>
          <UserManual
            :inventoryStockType="inventoryStockType"
            :templateType="templateType">
          </UserManual>
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <sgv-input-select
            :options="options"
            select="value"
            v-model="selectedOption">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <ListStock
      :inventoryStockType="inventoryStockType"
      :templateType="templateType"
      :group="group"
      :mode="selectedOption">
    </ListStock>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ListStock from './ListStock'
import UserManual from './UserManual.vue'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-รายการ`
    }
  },
  props: {
    inventoryStockType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      inventoryConfigView : `InventoryStock${this.$form.capitalize(this.inventoryStockType)}ConfigList`,
      rKey: `InventoryStock${this.$form.capitalize(this.inventoryStockType)}List`,
      options: [
        {text: 'ทั้งหมด', value: 'all'},
        {text: 'น้อยกว่ากำหนด', value: 'lowerLimit'},
      ],
      selectedOption: 'all',
      rFields: ['selectedOption']
    }
  },
  computed: {
    toInventoryConfig () {
      return {name: this.inventoryConfigView}
    },
  },
  watch: {
    selectedOption: 'setRetaining'
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: `${this.group}`})
  },
  components: {
    ListStock,
    UserManual,
  }
}
</script>

<style lang="css" scoped>
</style>
