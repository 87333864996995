import gql from 'graphql-tag'


export const LIST_INVENTORY_COST = (templateType) => gql`query LIST_INVENTORY_COST ($inventoryCostType: String!, $q: FilterInput) {
  items: listInventory${templateType} (inventoryCostType: $inventoryCostType, q: $q) {
    id type code name
    docOutRangeOrders {id type name outputDocPercent}
    predictOutRangeOrders {id type name outputPredictPercent}
  }
}`

const detailResponse = `
  id type code name
`

export const DETAIL_INVENTORY_COST = (templateType) => gql`query DETAIL_INVENTORY_COST ($inventoryCostType: String!, $inventoryCostId: Int!) {
  item: detailInventory${templateType} (inventoryCostType: $inventoryCostType, inventoryCostId: $inventoryCostId) {${detailResponse}}
}`

export const CREATE_INVENTORY_COST = (templateType) => gql`mutation CREATE_INVENTORY_COST ($inventoryCostType: String!, $input: Inventory${templateType}Input!) {
  createItem: createInventory${templateType} (inventoryCostType: $inventoryCostType, input: $input) {${detailResponse}}
}`

export const UPDATE_INVENTORY_COST = (templateType) => gql`mutation UPDATE_INVENTORY_COST ($inventoryCostType: String!, $inventoryCostId: Int!, $input: Inventory${templateType}Input!) {
  updateItem: updateInventory${templateType} (inventoryCostType: $inventoryCostType, inventoryCostId: $inventoryCostId, input: $input) {${detailResponse}}
}`

export const DESTROY_INVENTORY_COST = (templateType) => gql`mutation DESTROY_INVENTORY_COST ($inventoryCostType: String!, $inventoryCostId: Int!) {
  destroyItem: destroyInventory${templateType} (inventoryCostType: $inventoryCostType, inventoryCostId: $inventoryCostId) {id}
}`


export const LIST_INVENTORY_COST_CALCULATION = (templateType) => gql`query LIST_INVENTORY_COST_CALCULATION ($inventoryCostType: String!) {
  items: listInventory${templateType}Calculation (inventoryCostType: $inventoryCostType)
}`

export const CALCULATE_INVENTORY_COST = (templateType) => gql`mutation CALCULATE_INVENTORY_COST ($inventoryCostType: String!, $inventoryCostId: Int!) {
  calculateItem: calculateInventory${templateType} (inventoryCostType: $inventoryCostType, inventoryCostId: $inventoryCostId) {id code}
}`

export const WATCH_INVENTORY_COST_CALCULATED = (templateType) => gql`subscription WATCH_INVENTORY_COST_CALCULATED ($inventoryCostType: String!, $inventoryCostId: Int) {
  inventoryCostCalculated: watchInventory${templateType}Calculated (inventoryCostType: $inventoryCostType, inventoryCostId: $inventoryCostId) {id}
}`
