import gql from 'graphql-tag'


export const LIST_PLANNING = (templateType) => gql`query LIST_PLANNING ($docType: String!, $startDate: Date!, $endDate: Date!) {
  plannings: listDoc${templateType}Planning (docType: $docType, startDate: $startDate, endDate: $endDate) {
    date
    crons {
      inventoryId name datetime
      measurementTemplateId
      measurementTemplateName
    }
    docs {
      id type code name remark measurementName
      createdAt approvedAt closedAt
    }
  }
}`

export const CREATE_DOC_BY_CRON = (templateType) => gql`mutation CREATE_DOC_BY_CRON ($docType: String!, $startDate: Date!, $endDate: Date!) {
  docs: createDoc${templateType}ByCron (docType: $docType, startDate: $startDate, endDate: $endDate) {
    id type code name remark measurementName
    createdAt approvedAt closedAt
  }
}`
