<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <h6>
          ขนาดสินค้า
          <span
            v-if="$auth.hasRole(`inventory:${inventoryType}:add`)"
            class="text-warning px-1 pointer"
            @click="toggle = !toggle">
            <fa icon="pencil-alt"></fa>
          </span>
        </h6>
      </div>

      <PropertyListBoxForm
        class="col-12"
        v-for="item in items"
        :key="item.name"
        :item="item"
        :inventoryType="inventoryType"
        :templateType="templateType"
        :toggle="toggle"
        :sample="sample">
      </PropertyListBoxForm>
    </div>

    <button
      v-if="toggle"
      type="button"
      class="btn btn-warning"
      @click="updateData">
      อัพเดท
    </button>
  </div>
</template>

<script>
import PropertyListBoxForm from './PropertyListBoxForm'
import {
  LIST_PROPERTY,
  CREATE_PROPERTY,
} from '../graph/property'

export default {
  props: {
    inventoryType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    },
    sample: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      toggle: false,
      propertyType: 'boxSize',
      items: [
        {name: 'กว้าง', type: '', targetValue: 0, unitId: null, unit: null},
        {name: 'ยาว', type: '', targetValue: 0, unitId: null, unit: null},
        {name: 'สูง / หนา', type: '', targetValue: 0, unitId: null, unit: null},
        {name: 'น้ำหนัก', type: '', targetValue: 0, unitId: null, unit: null},
      ],
    }
  },
  methods: {
    setFormData (items) {
      this.items.forEach(v => {
        const found = items.find(x => x.name === v.name)

        v.type = this.propertyType

        if (!found) return

        v.targetValue = found.targetValue
        v.unitId = found.unitId || null
        v.unit = found.unit
      })
    },
    fetchData () {
      this.$apollo.query({
        query: LIST_PROPERTY(this.templateType),
        variables: {
          inventoryType: this.inventoryType,
          inventoryId: this.inventoryId,
          q: {
            params: {
              type: this.propertyType
            }
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.setFormData(res.data.items)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData () {
      const tasks = this.items.map(v => {
        return this.$apollo.mutate({
          mutation: CREATE_PROPERTY(this.templateType),
          variables: {
            inventoryType: this.inventoryType,
            inventoryId: this.inventoryId,
            propertyId: v.id,
            input: {
              name: v.name,
              type: v.type,
              targetValue: v.targetValue,
              unitId: v.unitId
            }
          }
        })
      })

      Promise.all(tasks).then(() => {
        this.toggle = false
        this.$toasted.global.success("อัพเดทสำเร็จ")
        this.fetchData()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  created () {
    this.fetchData()
  },
  components: {
    PropertyListBoxForm
  }
}
</script>

<style lang="css" scoped>
</style>
