<template>
  <button
    @click="download"
    class="btn btn-warning"
    :disabled="isDisabled">
    <fa icon="download"></fa>
    ไฟล์นำส่ง
  </button>
</template>

<script>
import round from 'lodash/round'
import xlsx from 'json-as-xlsx'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sheetname: '000000',
      columns: [
        {label: 'เลขที่ประจำตัวผู้เสียภาษี', value: 'taxCode'},
        {label: 'คำนำหน้าชื่อ', value: 'prefix'},
        {label: 'ชื่อ', value: 'firstname'},
        {label: 'ชื่อสกุล', value: 'surname'},
        {label: 'ค่าจ้าง', value: 'withholdingPrice'},
        {label: 'เงินสมทบ', value: 'totalPrice'},
      ]
    }
  },
  computed: {
    isDisabled () {
      return this.items.some(v => {
        return !v.contact.prefix || !v.contact.firstname
      })
    },
    filename () {
      return this.formData.code
    },
    rows () {
      const content = this.items.flatMap(v => {
        return {
          taxCode : v.contact.taxCode,
          prefix : v.contact.prefix,
          firstname : v.contact.firstname,
          surname : v.contact.surname,
          withholdingPrice : round(v.withholdingPrice, 2),
          totalPrice : round(v.totalPrice, 2),
        }
      })

      const data = [{
        sheet: this.sheetname,
        columns: this.columns,
        content
      }]

      return data
    }
  },
  methods: {
    download () {
      const settings = {
        fileName: this.filename,
      }
      xlsx(this.rows, settings)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
