import gql from 'graphql-tag'


export const LIST_REPORT_KPI = (templateType) => gql`query LIST_REPORT_KPI ($docType: String!, $startDate: DateTime!, $endDate: DateTime!, $contactId: Int) {
  items: listDoc${templateType}ReportKpi (docType: $docType, startDate: $startDate, endDate: $endDate, contactId: $contactId) {
    id type code name
    kpis {
      name percent remarks
    }
  }
}`

export const SYNC_REPORT_KPI = (templateType) => gql`mutation SYNC_REPORT_KPI ($docType: String!, $month: String!) {
  result: syncDoc${templateType}ReportKpi (docType: $docType, month: $month)
}`
