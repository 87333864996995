<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-8"
        label="หมายเหตุ"
        v-model="formData.remark">
      </sgv-input-text>
    </div>

    <div class="form-row" v-if="formData.parent">
      <sgv-input-text
        disabled
        class="col-12"
        label="ใบขออนุมัติ"
        :value="`${formData.parent.code} (${formData.parent.name})`">
      </sgv-input-text>
    </div>

    <div class="form-row" v-if="method === 'add'">
      <DetailFormInputEmployee
        label="พนักงาน"
        class="col-12 col-md-6"
        select="id"
        v-model="formData.contactId"
        :docType="docType"
        :templateType="templateType"
        :validations="[
          {text: 'required!', value: v.formData.contactId.$dirty && v.formData.contactId.$error}
        ]">
      </DetailFormInputEmployee>

      <DetailFormInputJobDescription
        v-if="formData.contactId"
        :contactId="formData.contactId"
        label="ตำแหน่ง"
        class="col-12 col-md-6"
        select="id"
        v-model="formData.jobDescriptionId"
        :docType="docType"
        :templateType="templateType"
        :validations="[
          {text: 'required!', value: v.formData.jobDescriptionId.$dirty && v.formData.jobDescriptionId.$error}
        ]">
      </DetailFormInputJobDescription>

      <DetailFormInputAbility
        v-if="formData.jobDescriptionId"
        :jobDescriptionId="formData.jobDescriptionId"
        label="ความสามารถ"
        class="col-12 col-md-6"
        select="id"
        v-model="formData.abilityId"
        :docType="docType"
        :templateType="templateType"
        :validations="[
          {text: 'required!', value: v.formData.abilityId.$dirty && v.formData.abilityId.$error}
        ]">
      </DetailFormInputAbility>

      <DetailFormInputAuditor
        v-if="formData.jobDescriptionId"
        :jobDescriptionId="formData.jobDescriptionId"
        placeholder="..."
        label="ผู้ประเมิน"
        class="col-12 col-md-6"
        select="id"
        v-model="formData.auditorId"
        :docType="docType"
        :templateType="templateType"
        :validations="[
          {text: 'required!', value: v.formData.auditorId.$dirty && v.formData.auditorId.$error}
        ]">
      </DetailFormInputAuditor>
    </div>

    <div class="form-row" v-else>
      <sgv-input-text
        disabled
        class="col-12 col-md-6"
        label="พนักงาน"
        :value="`${formData.contact.code} (${formData.contact.name})`">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-12 col-md-6"
        label="ตำแหน่ง"
        :value="`${formData.jobDescription.code} (${formData.jobDescription.position})`">
      </sgv-input-text>

      <sgv-input-text
        disabled
        v-if="formData.ability"
        class="col-12 col-md-6"
        label="ความสามารถ"
        :value="`${formData.ability.name}`">
      </sgv-input-text>

      <sgv-input-text
        disabled
        label="ผู้ประเมิน"
        class="col-12 col-md-6"
        :value="`${formData.auditor.code} (${formData.auditor.name})`">
      </sgv-input-text>

      <sgv-input-text
        disabled
        v-if="formData.kpiTemplate"
        class="col-12"
        label="แบบฟอร์ม"
        :value="`${formData.kpiTemplate.code} (${formData.kpiTemplate.name})`">
      </sgv-input-text>
    </div>

    <CategoryCheck
      :docId="docId"
      :docType="docType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      :method="method"
      :isClosed="!!formData.closedAt || !!formData.approvedAt"
      v-model="formData.categories"
      @updated="emitUpdated">
    </CategoryCheck>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-4"
        label="สร้าง"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="ผู้ประเมิน"
        :value="getState('approved')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="จบ"
        :value="getState('closed')">
      </sgv-input-text>
    </div>

    <div class="" v-if="formData.ability && formData.ability.papers.length > 0">
      เอกสารคู่มือ:
      <ul>
        <li
          v-for="paper in formData.ability.papers"
          :key="paper.id">
          {{paper.code}} ({{paper.name}})
        </li>
      </ul>
    </div>

    <p>
      กรณีที่พบการประเมิน
      <span class="text-danger">ต่ำกว่ามาตรฐาน</span>
      ให้เจ้าหน้าที่ฝึกอบรมทำการฝึกอบรมใหม่ แล้วทำการประเมินใหม่ภายใน 30 วัน หลังจากวันประเมิน
    </p>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ไม่ผ่าน"
          inline
          v-model="formData.isVoid">
        </sgv-input-check>
      </div>
    </div>
  </div>
</template>

<script>
import DetailFormInputJobDescription from './DetailFormInputJobDescription'
import DetailFormInputAbility from './DetailFormInputAbility'
import DetailFormInputEmployee from './DetailFormInputEmployee'
import DetailFormInputAuditor from './DetailFormInputAuditor'
import CategoryCheck from '@/views/doc_core/components/CategoryCheck'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {

    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  components: {
    DetailFormInputJobDescription,
    DetailFormInputEmployee,
    DetailFormInputAuditor,
    DetailFormInputAbility,
    CategoryCheck
  }
}
</script>

<style lang="css">
</style>
