<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="reportFilter.startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.reportFilter.startDate.$dirty && $v.reportFilter.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="reportFilter.endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.reportFilter.endDate.$dirty && $v.reportFilter.endDate.$error}
        ]">
      </sgv-input-date>

      <DocConfigFormDocCategory
        class="col-12"
        v-model="reportFilter.categoryId"
        :templateType="templateType"
        :docType="docType"
        label="กลุ่มเอกสาร"
        placeholder="ทั้งหมด"
        select="id">
      </DocConfigFormDocCategory>
    </div>

    <button class="btn btn-warning" @click="fetchData">
      ค้นหา
    </button>

    <div class="form-row mt-3">
      <sgv-input-month
        class="col-6"
        v-model="syncFilter.month"
        label="เดือน"
        isNull
        :validations="[
          {text: 'required!', value: $v.syncFilter.month.$dirty && $v.syncFilter.month.$error}
        ]">
      </sgv-input-month>
    </div>

    <button class="btn btn-info" @click="syncReport">
      Sync
    </button>

    <sgv-table
      class="mt-3"
      ref="docList"
      :rKey="rKey"
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      headerless>

      <template slot-scope="{item, hidden, idx}">
        <tr>
          <td v-if="hidden.code">
            {{idx+1}}.
            <router-link
              class="text-decoration-none"
              :to="toDetail(item.id)">
              <span :class="getStatus(item)">
                {{item.code}}
              </span>
            </router-link>
          </td>
          <td v-if="hidden.name">
            {{item.name}}
            <small>
              <div class="text-primary pre-line" v-if="item.remark">
                {{item.remark}}
              </div>
            </small>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import {
  LIST_REPORT_GROUP,
  SYNC_REPORT_GROUP
} from '../graph/report'
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import DocConfigFormDocCategory from './DocConfigFormDocCategory'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}ListReportDelay`,
      detailView: `Doc${this.$form.capitalize(this.docType)}Detail`,
      reportFilter: {
        startDate: '',
        endDate: '',
        categoryId: null
      },
      syncFilter: {
        month: '',
      },
      rFields: ['reportFilter', 'syncFilter'],
      items: [],
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'รายละเอียด', value: 'name'}
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['deliveredAt', 'code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  validations: {
    reportFilter: {
      startDate: {required},
      endDate: {required},
    },
    syncFilter: {
      month: {required}
    }
  },
  methods: {
    fetchData () {
      this.$v.reportFilter.$touch()
      if (this.$v.reportFilter.$invalid) return

      this.setRetaining()
      this.items = []
      this.$apollo.query({
        query: LIST_REPORT_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          startDate: this.$date.format(this.reportFilter.startDate).utc,
          endDate: this.$date.format(this.reportFilter.endDate, 0, 1).utc,
          categoryId: this.reportFilter.categoryId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    syncReport () {
      this.$v.syncFilter.$touch()
      if (this.$v.syncFilter.$invalid) return

      this.setRetaining()

      this.$apollo.mutate({
        mutation: SYNC_REPORT_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          month: this.syncFilter.month
        },
        fetchPolicy: 'no-cache'
      })
      .then(() => {
        this.$toasted.global.success('อัพเดทสำเร็จ')
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {docId: id}
      }
    },
    getStatus (item) {
      if (item.approvedAt && !item.closedAt) return ['text-warning']
      else if (item.closedAt) return ['text-success']
    },
  },
  components: {
    DocConfigFormDocCategory
  }
}
</script>

<style lang="css" scoped>
</style>
