<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <div v-if="isLoaded">
      <sgv-form
        :method.sync="method"
        :options="options">

        <DetailForm
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :formData="formData"
          :method="method"
          :v="$v"
          @updated="dataDetail">
        </DetailForm>

        <DetailFormApprove
          slot="approve"
          ref="approveForm"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :method.sync="method">
        </DetailFormApprove>
      </sgv-form>

      <template v-if="docId > 0">
        <HrDivider :options="tabs" v-model="selectedTab"></HrDivider>

        <template v-for="tab in tabs">
          <component
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :key="tab.value"
            :is="tab.value"
            v-if="tab.value === selectedTab && docId > 0"
            :method="method"
            :formData="formData"
            @kpis="kpiItems = $event"
            :url="`/doc/${docType}/${docId}/print`"
            :filename="`${formData.code}.pdf`">
          </component>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailForm from './DetailForm'
import DetailFormApprove from './DetailFormApprove'
import Attachment from '@/views/doc_core/components/Attachment'
import DetailKpi from '@/views/doc_core_kpi_template/View'
import DetailPrint from '@/views/doc_core/components/PrintPdf'
import {
  DETAIL_DOC, WATCH_DOC_UPDATED,
  CREATE_DOC, UPDATE_DOC, CLOSE_DOC,
  CANCEL_DOC, ROLLBACK_DOC, DESTROY_DOC
} from './graph'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.formData.code}` || `${this.title}`
    }
  },
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}List`,
      formData: {
        code: '',
        remark: '',
        createdAt: null,
        createdUser: null,
        approvedAt: null,
        approvedUser: null,
        closedAt: null,
        closedUser: null,
        contactId: null,
        contact: null,
        jobDescriptionId: null,
        jobDescription: null,
        abilityId: null,
        ability: null,
        auditorId: null,
        auditor: null,
        kpis: [],
        kpiTemplate: null,
        isVoid: false,
        categories: []
      },
      isLoaded: false,
      selectedTab: 'DetailKpi',
      kpiItems: []
    }
  },
  validations: {
    formData: {
      contactId: {required},
      jobDescriptionId: {required},
      abilityId: {required},
      auditorId: {required},
    }
  },
  apollo: {
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result (res) {
          this.setFormData(res.data.docUpdated)
        }
      },
    },
  },
  computed: {
    tabs () {
      let arr = [
        {text: 'รายการ', value: 'DetailKpi'},
        {text: 'พิมพ์', value: 'DetailPrint'},
        {text: 'ไฟล์', value: 'Attachment'},
      ]
      return arr
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || this.formData.closedAt || !this.$auth.hasRole(`doc:${this.docType}:read`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || this.formData.closedAt || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'อนุมัติ',
          variant: 'warning',
          method: 'approve',
          func: this.approveData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`doc:${this.docType}:read`)
        },
        {
          text: 'ยกเลิกอนุมัติ',
          variant: 'info',
          method: 'cancel',
          func: this.cancelData,
          disabled: this.method === 'add' || !(this.formData.approvedAt && !this.formData.closedAt) || !this.$auth.hasRole(`doc:${this.docType}:read`)
        },
        {
          text: 'จบ',
          variant: 'success',
          method: 'close',
          func: this.closeData,
          disabled: this.method === 'add' || !(this.formData.approvedAt && !this.formData.closedAt) || !this.$auth.hasRole(`doc:${this.docType}:approve`)
        },
        {
          text: 'ย้อนกลับ',
          variant: 'info',
          method: 'rollback',
          func: this.rollbackData,
          disabled: this.method === 'add' || !(this.formData.approvedAt && this.formData.closedAt) || !this.$auth.hasRole(`doc:${this.docType}:approve`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.doc)
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'categories') {
          this.formData[key] = v[key].map(x => x.id)
          return
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    serializeInput (v) {
      const input = {...v}
      const removeKeys = [
        'type', 'createdAt', 'approvedAt', 'closedAt',
        'createdUser', 'approvedUser', 'closedUser',
        'kpis', 'kpiTemplate',
        'contact', 'jobDescription', 'ability', 'auditor'
      ]
      removeKeys.forEach(x => delete input[x])
      return input
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, docId: res.data.doc.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      const kpis = this.kpiItems
      .flatMap(v => v.children)
      .map(v => {
        return {
          code: v.code,
          actualPoint: v.actualPoint,
          remark: v.remark
        }
      })

      this.$apollo.mutate({
        mutation: UPDATE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input,
          kpis
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    approveData () {
      this.$refs.approveForm.approveData()
    },
    cancelData () {
      this.$apollo.mutate({
        mutation: CANCEL_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("ย้อนรายการสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    closeData () {
      this.$apollo.mutate({
        mutation: CLOSE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("จบรายการสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    rollbackData () {
      this.$apollo.mutate({
        mutation: ROLLBACK_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("ย้อนรายการสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    }
  },
  created () {
    if (this.docId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
    Attachment,
    DetailKpi,
    DetailPrint,
    DetailFormApprove
  }
}
</script>

<style lang="css">
</style>
