import gql from 'graphql-tag'


export const LIST_ADDRESS_TYPE = (templateType) => gql`query LIST_ADDRESS_TYPE ($docType: String!) {
  addressTypes: listDoc${templateType}ContactAddressType (docType: $docType) {
    text value
  }
}`

export const LIST_ADDRESS = (templateType) => gql`query LIST_ADDRESS ($docType: String!, $docId: Int!) {
  addresses: listDoc${templateType}ContactAddress (docType: $docType, docId: $docId) {
    id type name contactId
    attendance address addressTag
    telephone mobile fax email lineApp
    logisticId logistic {id code name}
    vehicleId vehicle {id code name}
    isDefault
  }
}`

export const LIST_ADDRESS_CHILD = (templateType) => gql`query LIST_ADDRESS_CHILD ($docType: String!, $addressType: String!, $childId: Int!) {
  addresses: listDoc${templateType}ContactAddressChild (docType: $docType, addressType: $addressType, childId: $childId) {
    id type name contactId
    attendance address addressTag
    telephone mobile fax email lineApp
    logisticId logistic {id code name}
    vehicleId vehicle {id code name}
    isDefault
  }
}`

export const LIST_DEVICE_LABEL_CONTACT_ADDRESS = (templateType) => gql`query LIST_DEVICE_LABEL_CONTACT_ADDRESS ($docType: String!) {
  devices: listDoc${templateType}LabelContactAddressDevice (docType: $docType) {
    id type code name
  }
}`

export const PRINT_LABEL_CONTACT_ADDRESS = (templateType) => gql`mutation PRINT_LABEL_CONTACT_ADDRESS ($docType: String!, $deviceId: Int!, $addressId: Int!, $qty: Int!) {
  print: printDoc${templateType}LabelContactAddress (docType: $docType, deviceId: $deviceId, addressId: $addressId, qty: $qty)
}`

export const LIST_DEVICE_LABEL_CONTACT_BRANCH = (templateType) => gql`query LIST_DEVICE_LABEL_CONTACT_BRANCH ($docType: String!) {
  devices: listDoc${templateType}LabelContactBranchDevice (docType: $docType) {
    id type code name
  }
}`

export const PRINT_LABEL_CONTACT_BRANCH = (templateType) => gql`mutation PRINT_LABEL_CONTACT_BRANCH ($docType: String!, $deviceId: Int!, $contactId: Int!, $qty: Int!) {
  print: printDoc${templateType}LabelContactBranch (docType: $docType, deviceId: $deviceId, contactId: $contactId, qty: $qty)
}`
