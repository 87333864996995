<template>
  <div class="">
    <ListCategorySummary
      v-if="docs.length > 0"
      :items="docs"
      :selected="selectedAccountIds"
      @account="selectedAccountIds = $event"
      @selectAll="onSelectAll">
    </ListCategorySummary>

    <sgv-table
      :rKey="rKey"
      :items="docsFilter"
      :headers="headers"
      :toolbars="toolbars"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot="option" v-if="options.toolbar === 'excel'">
        <div class="mb-3">
          <ListCategoryByDocXlsx
            v-if="accruedDate"
            :items="docsFilter"
            :accruedDate="accruedDate"
            :title="title">
          </ListCategoryByDocXlsx>
        </div>
      </template>

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code">
            <router-link
              class="text-decoration-none"
              :to="toDoc(item)">
              <span :class="getStatus(item)">
                {{item.code}}
              </span>
            </router-link>
            <div v-if="item.invoiceRef">
              <small class="text-warning">
                <fa icon="truck"></fa> {{item.invoiceRef}}
              </small>
            </div>

            <div v-if="item.relevance && item.relevance.payments.length > 0">
              <div v-if="item.receiptRef">
                <small class="text-warning">
                  <fa icon="truck"></fa> {{item.receiptRef}}
                </small>
              </div>
              <div v-for="doc in item.relevance.payments" :key="doc.id">
                <router-link
                  :to="toDoc(doc)"
                  class="text-decoration-none">
                  <small style="white-space: nowrap;" class="text-info">
                    <fa icon="dollar-sign"></fa>
                    <span class="ml-1">{{doc.code}}</span>
                  </small>
                </router-link>
              </div>
            </div>
          </td>
          <td v-if="hidden.orders" class="no-wrap">
            <span :class="[item.remaining > 0 ? 'text-success' : 'text-danger']">
              {{item.remaining | comma}}
            </span>
            /
            <span :class="[item.amount > 0 ? 'text-success' : 'text-danger']">
              {{item.amount | comma}} บาท
            </span>

            <small v-if="item.account">
              <div class="text-info">
                {{item.account.code}} ({{item.account.name}})
              </div>
            </small>

            <small v-if="item.orders.length > 0">
              <div v-for="order in item.orders" :key="order.id">
                <div v-for="(child,chIdx) in order.children" :key="child.id">
                  <div
                    class="text-info"
                    v-for="ledger in child.ledgers"
                    :key="ledger.id">
                    {{chIdx+1}}. 
                    <router-link
                      :to="toDoc(child.doc)"
                      class="text-decoration-none text-info">
                      {{child.doc.code}}
                    </router-link> 
                    ->
                    <span :class="[ledger.amount > 0 ? 'text-success' : 'text-danger']">
                      {{ledger.amount | comma}} บาท
                    </span>
                  </div>
                </div>
              </div>
            </small>
          </td>

          <td v-if="hidden.createdAt">
            {{ item.createdAt | date }}<br>
            <small class="text-primary">{{item.createdUser.name}}</small>
          </td>
          <td v-if="hidden.approvedAt">
            <span v-if="item.approvedAt">
              <span class="text-warning">{{item.approvedAt | date}}</span><br>
              <small class="text-primary">{{item.approvedUser.name}}</small>
            </span>
          </td>
          <td v-if="hidden.closedAt">
            <span v-if="item.closedAt">
              <span class="text-success">{{item.closedAt | date}}</span><br>
              <small class="text-primary">{{item.closedUser.name}}</small>
            </span>
          </td>
          <td v-if="hidden.name" class="no-wrap">
            {{item.name}}
            <small>
              <div class="">
                {{item.contact.code}} ({{item.contact.name}})
                <span
                  class="px-1"
                  v-if="item.contact.branch && item.contact.branch !== 'สำนักงานใหญ่'">
                  สาขา: {{item.contact.branch}}
                </span>
              </div>
              <div
                class="text-primary pre-line"
                v-if="item.remark">
                {{item.remark}}
              </div>
              <div class="text-info" v-if="item.categories.length > 0">
                <fa icon="object-group"></fa>
                <span class="ml-1">{{mapCategoryText(item.categories)}}</span>
              </div>
            </small>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import ListCategorySummary from './ListCategorySummary.vue'
import ListCategoryByDocXlsx from './ListCategoryByDocXlsx.vue'
import retainMixin from '@/mixins/retain-mixin'
import { LIST_DOC } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    accruedDate: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      detailView: `Doc${this.$form.capitalize(this.docType)}Detail`,
      rKey: `Doc${this.$form.capitalize(this.docType)}ListCategoryByDoc`,
      filter: {
        limit: 10,
        offset: 0,
        order: 'closedAt',
        params: null
      },
      options: {
        headers: [
          'code', 'orders',
          'closedAt', 'name',
        ],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['filter', 'options'],
      docs: [],
      selectedAccountIds: []
    }
  },
  computed: {
    headers () {
      return [
        {text: 'รหัส', value: 'code', filter: true},
        {text: `ยอดรวม`, value: 'orders'},
        {text: 'วันที่สร้าง', value: 'createdAt'},
        {text: 'วันทีอนุมัติ', value: 'approvedAt'},
        {text: 'วันที่เสร็จ', value: 'closedAt', sort: true},
        {text: 'รายละเอียด', value: 'name', filter: true},
      ]
    },
    toolbars () {
      const arr = [
        {value: 'filter', icon: 'cog', class: 'text-warning'},
      ]

      if (this.accruedDate) {
        arr.push({value: 'excel', icon: 'download', class: 'text-success'})
      }
      return arr
    },
    rowsPerPage () {
      return [
        { text: 'ทั้งหมด', value: null },
      ]
    },
    docsFilter () {
      return this.docs.filter(d => this.selectedAccountIds.includes(d.accountId))
    }
  },
  methods: {
    fetchData () {
      if (!this.accruedDate) return

      this.docs = []

      this.setRetaining()

      this.$apollo.query({
        query: LIST_DOC(this.templateType),
        variables: {
          docType: this.docType,
          q: this.getFilter(this.filter),
          accruedDate: new Date(this.accruedDate + 'T00:00:00')
        },
        fetchPolicy: 'network-only',
      }).then(res => {
        this.docs = res.data.docs
        this.selectedAccountIds = [...new Set(res.data.docs.map(l => l.accountId))]
      })
    },
    getFilter (v) {
      const filter = {
        ...v,
        params: {
          ...v.params
        }
      }

      return filter
    },
    getStatus (item) {
      if (item.approvedAt && !item.closedAt) return ['text-warning']
      else if (item.closedAt) return ['text-success']
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
    mapCategoryText (categories) {
      return categories.map(v => v.name).join(', ')
    },
    onSelectAll () {
      const selectedAccountIds = [...new Set(this.selectedAccountIds)]
      const allAccountIds = [...new Set(this.docs.map(l => l.accountId))]

      if (selectedAccountIds.length === allAccountIds.length) {
        this.selectedAccountIds = []
      } else {
        this.selectedAccountIds = allAccountIds
      }
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ListCategoryByDocXlsx,
    ListCategorySummary
  }
}
</script>

<style lang="css" scoped>
</style>
