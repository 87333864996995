import gql from 'graphql-tag'


export const DETAIL_GOOGLE_MAP = (templateType) => gql`query DETAIL_GOOGLE_MAP ($contactType: String!) {
  token: detailContact${templateType}GoogleMap (contactType: $contactType)
}`

export const LIST_LOCATION = (templateType) => gql`query LIST_LOCATION ($contactType: String!, $country: ContactCountryLocationFilterInput $q: FilterInput) {
  locations: listContact${templateType}Location (contactType: $contactType, country: $country q: $q) {
    id type name address
    telephone mobile
    mapUrl mapLocation
    contactId contact {
      id type code name branch
    }
  }
}`
