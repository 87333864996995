import gql from 'graphql-tag'


export const LIST_MEASUREMENT_TEMPLATE = (templateType) => gql`query LIST_MEASUREMENT_TEMPLATE ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}MeasurementTemplate ($docType: String!, q: $q) {
    id type code name isActive
    children {
      id code name
      children {
        id code name formType isShowRemark
        actualPoint minPoint maxPoint
        choices {
          text value
        }
      }
    }
  }
}`

export const MEASUREMENT_TEMPLATE = (templateType) => gql`query MEASUREMENT_TEMPLATE ($docType: String!, $id: Int!) {
  item: detailDoc${templateType}MeasurementTemplate ($docType: String!, id: $id) {
    id type code name isActive
    children {
      id code name
      children {
        id code name formType isShowRemark
        actualPoint minPoint maxPoint
        choices {
          text value
        }
      }
    }
  }
}`

export const CREATE_MEASUREMENT_TEMPLATE = (templateType) => gql`mutation CREATE_MEASUREMENT_TEMPLATE ($docType: String!, $input: MeasurementTemplateInput!) {
  item: createDoc${templateType}MeasurementTemplate ($docType: String!, input: $input) {
    id
  }
}`

export const UPDATE_MEASUREMENT_TEMPLATE = (templateType) => gql`mutation UPDATE_MEASUREMENT_TEMPLATE ($docType: String!, $id: Int!, $input: MeasurementTemplateInput!) {
  item: updateDoc${templateType}MeasurementTemplate ($docType: String!, id: $id, input: $input) {
    id
  }
}`

export const DESTROY_MEASUREMENT_TEMPLATE = (templateType) => gql`mutation DESTROY_MEASUREMENT_TEMPLATE ($docType: String!, $id: Int!) {
  item: destroyDoc${templateType}MeasurementTemplate ($docType: String!, id: $id) {
    id
  }
}`
