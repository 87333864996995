<template>
  <div class="form-group">
    <label v-if="label" :for="uid">
      {{ label }}
    </label>

    <slot name="label"></slot>

    <input
      :id="uid"
      type="password"
      class="form-control"
      :class="{'is-invalid': validate.value}"
      :value="value"
      v-bind="$attrs"
      v-on="listeners">

    <div class="invalid-feedback">
      {{ validate.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SgvInputPassword',
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    label: {
      type: String,
      required: false,
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    focus: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      uid: this.$form.uid()
    }
  },
  computed: {
    validate () {
      return this.validations.find(v => v.value) || {value: false, text: ''}
    },
    listeners () {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.value.trim())
        },
        change: event => {
          this.$emit('change', event.target.value.trim())
        }
      }
    }
  },
  methods: {
    setFocus () {
      let el = document.getElementById(this.uid)
      el.focus()
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.focus) this.setFocus()
    })
  },
  watch: {
    focus (value) {
      if (value) this.setFocus()
    }
  }
}
</script>

<style lang="css">
</style>
