<template>
  <button
    @click="download"
    class="btn btn-warning"
    :disabled="isDisabled">
    <fa icon="download"></fa>
    ไฟล์นำส่ง
  </button>
</template>

<script>
import round from 'lodash/round'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  computed: {
    isDisabled () {
      return this.items.some(v => {
        return !v.contact.prefix || !v.contact.firstname
      })
    },
    filename () {
      return this.formData.code
    },
    rows () {
      const arr = this.items.flatMap((v,idx) => {
        return {
          "ลำดับที่" : idx,
          'เลขที่ประจำตัวผู้เสียภาษี' : v.contact.taxCode,
          'คำนำหน้าชื่อ' : v.contact.prefix,
          'ชื่อ' : v.contact.firstname,
          'ชื่อกลาง' : v.contact.middlename,
          'ชื่อสกุล' : v.contact.surname,

          'วันเดือนปี' : this.$date.format(v.doc.closedAt).displayThai,
          'ประเภทเงินได้' : '401N',
          'จำนวนเงิน' : round(v.withholdingPrice, 2),
          'จำนวนภาษีที่หัก' : round(v.totalPrice, 2),
          'เงื่อนไข' : 1,
        }
      })

      return arr
    }
  },
  methods: {
    download () {
      const csv = this.$papa.unparse(this.rows)
      const blob = new Blob(["\uFEFF", csv], {type: "text/csv;charset=utf-8;"}, this.filename)

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", this.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<style lang="css" scoped>
</style>
