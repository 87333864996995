import { capitalize } from '@/libs/form'

export default ({ unitType, title, subTitle, group }) => {
  const templateType = 'ChronowoodthUnit'
  return [
    {
      path: `chronowoodth/unit/${unitType}/`,
      name: `ChronowoodthUnit${capitalize(unitType)}List`,
      component: () => import('../List.vue'),
      props: () => ({
        templateType,
        unitType,
        group,
        subTitle,
        title,
      })
    },
    {
      path: `chronowoodth/unit/${unitType}/:unitId`,
      name: `ChronowoodthUnit${capitalize(unitType)}Detail`,
      component: () => import('../Detail.vue'),
      props: (route) => ({
        templateType,
        unitType,
        title, subTitle, group,
        unitId: Number(route.params.unitId)
      })
    },
  ]
}
