import { capitalize } from '@/libs/form'
import List from '../List.vue'
import Detail from '../Detail.vue'
import Config from '@/views/inventory_core/components/InventoryConfig.vue'


export default ({inventoryType, title, subTitle, group, stockcountTemplateType, stockcountDocType}) => {
  const templateType = 'General'
  return [
    {
      path: `inventory/${inventoryType}/config`,
      name: `Inventory${capitalize(inventoryType)}ConfigList`,
      component: Config,
      props: () => ({
        templateType,
        inventoryType,
        title, subTitle, group,
      })
    },
    {
      path: `inventory/${inventoryType}/category/:categoryId`,
      name: `Inventory${capitalize(inventoryType)}CategoryDetail`,
      component: () => import(`@/views/inventory_core/components/CategoryDetail.vue`),
      props: (route) => ({
        categoryId: Number(route.params.categoryId),
        templateType,
        inventoryType,
        group, subTitle, title,
      })
    },
    {
      path: `inventory/${inventoryType}`,
      name: `Inventory${capitalize(inventoryType)}List`,
      component: List,
      props: () => ({
        templateType,
        inventoryType,
        title, subTitle, group,
      })
    },
    {
      path: `inventory/${inventoryType}/:inventoryId`,
      name: `Inventory${capitalize(inventoryType)}Detail`,
      component: Detail,
      props: (route) => ({
        templateType,
        inventoryType,
        title, subTitle, group,
        inventoryId: Number(route.params.inventoryId),
        stockcountTemplateType,
        stockcountDocType
      })
    },
  ]
}
