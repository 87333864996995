import gql from 'graphql-tag'


export const DP_PARENTS = (templateType) => gql`query DP_PARENTS ($inventoryType: String!, $q: FilterInput) {
  inventories: listInventory${templateType}DropdownParent (inventoryType: $inventoryType, q: $q) {
    id type code name
  }
}`

export const DP_UNITS = (templateType) => gql`query DP_UNITS ($inventoryType: String!, $q: FilterInput) {
  units: listInventory${templateType}DropdownUnit (inventoryType: $inventoryType, q: $q) {
    id name
  }
}`
