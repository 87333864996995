<template>
  <sgv-csv
    :items="csvData"
    :labels="csvLabels"
    :filename="csvFilename">
    <button
      :disabled="csvData.length === 0"
      type="button"
      class="btn btn-success">
      CSV รายงานภาษีซื้อ
    </button>
  </sgv-csv>
</template>

<script>
import {
  LIST_ORDER_PURCHASE
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    docCode: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      csvData: [],
      csvLabels: {
        id: { title: 'ลำดับ' },
        approvedAt: { title: 'วันที่รับ' },
        closedAt: { title: 'วันที่ใบกำกับ' },
        invoiceRef: { title: 'ใบกำกับภาษี' },
        contactName: { title: 'ชื่อผู้ขายสินค้า/บริการ' },
        contactTaxCode: { title: 'เลขประจำตัวผู้เสียภาษี' },
        contactBranch: { title: 'สาขา' },
        totalPrice: { title: 'มูลค่าสินค้า/บริการ' },
        vatPrice: { title: 'ภาษีมูลค่าเพิ่ม' },
        remark: { title: 'หมายเหตุ' },
      },
      csvFilename: `${this.docCode}-ภาษีซื้อ`
    }
  },
  methods: {
    serializeData (orders) {
      return orders.map((v,idx) => {
        return {
          id: idx+1,
          approvedAt: this.$date.format(v.approvedAt).displayThai,
          closedAt: this.$date.format(v.closedAt).displayThai,
          invoiceRef: v.invoiceRef || '-',
          contactName: v.contact.name,
          contactTaxCode: v.contact.taxCode,
          contactBranch: v.contact.branch && v.contact.branch !== 'สำนักงานใหญ่' ? v.contact.branch : 'X',
          totalPrice: this.$form.addComma(v.totalPrice),
          vatPrice: this.$form.addComma(v.vatPrice * -1),
          remark: v.remark
        }
      })
    },
    fetchData () {
      this.$apollo.query({
        query: LIST_ORDER_PURCHASE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.csvData = this.serializeData(res.data.orders)
      })
      .catch(this.$toasted.global.error)
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
</style>
