<template>
  <div class="form-row">
    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="เอกสารอ้างอิง"
      v-model="formData.kpi.documentRef">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="ชื่อลูกค้า/ผู้ควบคุมงาน"
      v-model="formData.kpi.customerName">
    </sgv-input-text>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="เบอร์ติดต่อ"
      v-model="formData.kpi.customerTel">
    </sgv-input-text>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="สถานที่ติดตั้ง"
      :rows="2"
      v-model="formData.kpi.customerAddr">
    </sgv-input-textarea>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="กำหนดการติดตั้ง"
      v-model="formData.kpi.installationDate">
    </sgv-input-text>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="รายละเอียดการติดตั้ง"
      :rows="3"
      v-model="formData.kpi.installationDetails">
    </sgv-input-textarea>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="การเตรียมตัวของลูกค้า"
      :rows="3"
      v-model="formData.kpi.customerPreparation">
    </sgv-input-textarea>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="ข้อตกลงและเงื่อนไข"
      :rows="3"
      v-model="formData.kpi.termsAndConditions">
    </sgv-input-textarea>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="หมายเหตุ"
      :rows="3"
      v-model="formData.kpi.remark">
    </sgv-input-textarea>
  </div>
</template>

<script>
export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>

</style>