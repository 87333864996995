<template>
  <div class="row">
    <div class="col-12 col-sm-6" v-if="welfare">
      <ul>
        <li class="" v-if="welfare.registrationAt">
          วันที่เริ่มทำงาน: {{ welfare.registrationAt | date }}
        </li>
        <li class="" v-if="welfare.fillingWorkAt">
          วันที่บรรจุ: {{ welfare.fillingWorkAt | date }}
        </li>
        <li class="" v-if="welfare.yearOfExperience > 0">
          อายุงาน: {{ welfare.yearOfExperience }} วัน ({{ welfare.yearOfExperience / 365 | comma }} ปี)
        </li>
      </ul>
    </div>

    <div class="col-12">
      <sgv-table
        :items="summary"
        :headers="headers"
        :options="options"
        headerless>
        <template slot-scope="{item, hidden}">
          <tr>
            <td v-if="hidden.title">
              <span :class="item.class">{{ item.title }}</span>
            </td>
            <td v-if="hidden.broughtForward">
              {{ item.broughtForward }}
            </td>
            <td v-if="hidden.betweenPeriod">
              {{ item.betweenPeriod }}
            </td>
            <td v-if="hidden.total">
              <span :class="item.txtClass">{{ item.total }}</span>
            </td>
          </tr>
        </template>
      </sgv-table>
    </div>
  </div>
</template>

<script>
import { DETAIL_CONTACT_WELFARE } from './graph/attendance'

export default {
  props: {
    contactId: {
      type: Number,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    yearAt: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {text: 'ประเภท', value: 'title'},
        {text: 'ยอดยกมา', value: 'broughtForward'},
        {text: 'ระหว่างปี', value: 'betweenPeriod'},
        {text: 'คงเหลือ', value: 'total'},
      ],
      options: {
        headers: ['title', 'broughtForward', 'betweenPeriod', 'total'],
        column: null,
        search: null,
        toolbar: null,
      },
      welfare: null
    }
  },
  apollo: {
    welfare: {
      query() {
        return DETAIL_CONTACT_WELFARE(this.templateType)
      },
      variables() {
        return {
          contactId: this.contactId,
          contactType: this.contactType,
          yearAt: this.yearAt
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    welfareLimit () {
      return {
        leaveLimit: this.welfare?.welfare?.leaveLimit || 0,
        sickLimit: this.welfare?.welfare?.sickLimit || 0,
        sickCertLimit: this.welfare?.welfare?.sickCertLimit || 0,
        maternityLimit: this.welfare?.welfare?.maternityLimit || 0,
        broughtForwardAnnualLeaveLimit: this.welfare?.welfare?.broughtForwardAnnualLeaveLimit || 0,
        annualLeaveLimit: this.welfare?.welfare?.annualLeaveLimit || 0,
        absentLimit: this.welfare?.welfare?.absentLimit || 0,
        otherLimit: this.welfare?.welfare?.otherLimit || 0,
      }
    },
    summary () {
      const result = this.items.reduce((t,v) => {
        if (v.tag === 'leave') {
          t.leaves.push(v)
        } else if (v.tag === 'sick') {
          t.sicks.push(v)
        } else if (v.tag === 'sickCert') {
          t.sickCerts.push(v)
        } else if (v.tag === 'maternity') {
          t.maternities.push(v)
        } else if (v.tag === 'annualLeave') {
          t.annualLeaves.push(v)
        } else if (v.tag === 'absent') {
          t.absents.push(v)
        }  else if (v.tag === 'other') {
          t.others.push(v)
        } else if (v.late > 0) {
          t.lates.push(v)
        }

        return t
      }, {
        leaves: [],
        sicks: [],
        sickCerts: [],
        maternities: [],
        annualLeaves: [],
        absents: [],
        others: [],
        lates: []
      })

      return [
        {
          title: 'สาย',
          broughtForward: '0 ครั้ง',
          betweenPeriod: `${result.lates.length} ครั้ง รวม ${this.totalLate} นาที`,
          total: '-',
          class: ['text-warning']
        },
        {
          title: 'ขาดงาน',
          broughtForward: `${this.welfareLimit.absentLimit} วัน`,
          betweenPeriod: `${result.absents.length} วัน`,
          total: `${this.welfareLimit.absentLimit - result.absents.length} วัน`,
          class: ['text-danger'],
          txtClass: this.welfareLimit.absentLimit - result.absents.length < 0 ? ['text-danger'] : []
        },
        {
          title: 'ลาป่วย (ไม่มีใบรับรองแพทย์)',
          broughtForward: `${this.welfareLimit.sickLimit} วัน`,
          betweenPeriod: `${result.sicks.length} วัน`,
          total: `${this.welfareLimit.sickLimit - result.sicks.length} วัน`,
          class: ['text-warning'],
          txtClass: this.welfareLimit.sickLimit - result.sicks.length < 0 ? ['text-danger'] : []
        },
        {
          title: 'ลาป่วย (มีใบรับรองแพทย์)',
          broughtForward: `${this.welfareLimit.sickCertLimit} วัน`,
          betweenPeriod: `${result.sickCerts.length} วัน`,
          total: `${this.welfareLimit.sickCertLimit - result.sickCerts.length} วัน`,
          class: ['text-warning'],
          txtClass: this.welfareLimit.sickCertLimit - result.sickCerts.length < 0 ? ['text-danger'] : []
        },
        {
          title: 'ลากิจ',
          broughtForward: `${this.welfareLimit.leaveLimit} วัน`,
          betweenPeriod: `${result.leaves.length} วัน`,
          total: `${this.welfareLimit.leaveLimit - result.leaves.length} วัน`,
          class: ['text-info'],
          txtClass: this.welfareLimit.otherLimit - result.others.length < 0 ? ['text-danger'] : []
        },
        {
          title: 'ลาคลอด',
          broughtForward: `${this.welfareLimit.maternityLimit} วัน`,
          betweenPeriod: `${result.maternities.length} วัน`,
          total: `${this.welfareLimit.maternityLimit - result.maternities.length} วัน`,
          class: ['text-success'],
          txtClass: this.welfareLimit.maternityLimit - result.maternities.length < 0 ? ['text-danger'] : []
        },
        {
          title: 'ลาพักร้อน',
          broughtForward: `${this.welfareLimit.annualLeaveLimit} วัน + ปีที่แล้ว ${this.welfareLimit.broughtForwardAnnualLeaveLimit} วัน`,
          betweenPeriod: `${result.annualLeaves.length} วัน`,
          total: `${this.welfareLimit.annualLeaveLimit + this.welfareLimit.broughtForwardAnnualLeaveLimit - result.annualLeaves.length} วัน`,
          class: ['text-success'],
          txtClass: this.welfareLimit.annualLeaveLimit + this.welfareLimit.broughtForwardAnnualLeaveLimit - result.annualLeaves.length < 0 ? ['text-danger'] : []
        },
        {
          title: 'อื่นๆ',
          broughtForward: `${this.welfareLimit.otherLimit} วัน`,
          betweenPeriod: `${result.others.length} วัน`,
          total: `${this.welfareLimit.otherLimit - result.others.length} วัน`,
          txtClass: this.welfareLimit.otherLimit - result.others.length < 0 ? ['text-danger'] : []
        },
      ]
    },
    totalLate () {
      return this.items.reduce((t,v) => t + +v.late, 0)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
