<template>
  <b-container>
    <b-row>
      <b-col md="3">
        <SideNav :options="options"></SideNav>
      </b-col>
      <b-col md="9">
        <transition name="fade" mode="out-in">
          <router-view :key="$route.fullPath"></router-view>
        </transition>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SideNav from './SideNav.vue'

export default {
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  components: {
    SideNav
  }
}
</script>

<style scoped>

</style>
