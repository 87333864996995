<template>
  <div class="col-10 col-md-6 col-lg-5 mt-5">
    <h2 class="text-center my-4">{{appName}}</h2>

    <sgv-input-text
      label="ผู้ใช้"
      name="username"
      placeholder="ผู้ใช้"
      v-model="formData.username"
      :validations="[
        {text: 'โปรดระบุ username', value: $v.formData.username.$dirty && $v.formData.username.$error},
      ]">
    </sgv-input-text>

    <sgv-input-password
      label="รหัสผ่าน"
      name="password"
      placeholder="รหัสผ่าน"
      v-model="formData.password"
      :validations="[
        {text: 'โปรดระบุรหัสผ่าน', value: $v.formData.password.$dirty && $v.formData.password.$error},
      ]"
      @keyup.enter="login">
    </sgv-input-password>

    <button
      type="button"
      class="btn btn-block btn-outline-primary mb-4"
      @click="login">
      ลงชื่อเข้าใช้
    </button>

    <div
      class="hr-divider mb-3">
      <h3 class="hr-divider-content hr-divider-heading">
        Social
      </h3>
    </div>

    <div class="col-12 mt-4 pb-3">
      <div class="row">
        <div class="col-12" id="googleContainer">
          <div id="googleBtn"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { usernameLogin, verifyGoogle } from '@/plugins/axios'
import { onLogin } from '@/plugins/apollo'
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      formData: {
        username: '',
        password: ''
      },
      appName: process.env.VUE_APP_NAME,
      google: null,
      googleCheckInterval: null
    }
  },
  validations: {
    formData: {
      username: {required},
      password: {required}
    }
  },
  methods: {
    login () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      usernameLogin(this.formData)
      .then(() => {
        return onLogin(this.$apollo.provider.clients.defaultClient)
      })
      .then(() => {
        this.$router.push({name: 'Home'})
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    handleCredentialResponse (resp) {
      const token = resp.credential
      verifyGoogle({token})
      .then(() => {
        onLogin(this.$apollo.provider.clients.defaultClient)
      })
      .then(() => {
        this.$router.push({name: 'Home'})
      })
      .catch((err) => {
        if (err.response.status === 301) {
          this.$router.push({
            name: 'Register',
            query: {
              token: err.response.data.token
            }
          })
        } else {
          this.$toasted.global.error(err)
        }
      })
    },
    initGoogle () {
      if (!this.google) return

      if (this.googleCheckInterval) {
        clearInterval(this.googleCheckInterval)
        this.googleCheckInterval = null
      }

      this.$nextTick(() => {
        this.google.accounts.id.initialize({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          callback: (resp) => this.handleCredentialResponse(resp),

          // login_uri: `${process.env.VUE_APP_API_URL}/api/auth/google/callback`,
          // ux_mode: 'redirect'
        });
        const btnCotainer = document.getElementById("googleContainer")

        this.google.accounts.id.renderButton(document.getElementById("googleBtn"), {
          theme: "outline",
          size: "large",
          width: btnCotainer.clientWidth -20,
        })
      })
    },
    debounceInitGoogle: debounce(function () {
      this.initGoogle()
    }, 50)
  },
  watch: {
    'google': {
      handler (v) {
        if (v) this.debounceInitGoogle()
      }
    }
  },
  mounted () {
    this.googleCheckInterval = setInterval(() => {
      this.google = window.google
    }, 25)
    window.addEventListener("resize", this.debounceInitGoogle)
  },
  destroyed () {
    window.removeEventListener("resize", this.debounceInitGoogle)
  }
}
</script>

<style lang="css" scoped>

</style>
