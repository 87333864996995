<template>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="form-row">
        <sgv-input-text
          class="col-12"
          label="ค้นหา"
          v-model="search"
          placeholder="ตัวกรอง">
        </sgv-input-text>
      </div>

      <button
        :disabled="addPending"
        type="button"
        class="btn btn-success"
        @dblclick="addAll">
        เพิ่มทั้งหมด
      </button>
    </div>
    <div
      class="col-12 col-sm-6 mb-3"
      v-for="item in docsFilter"
      :key="item.id">
      <DetailOrderPayableAvailableCard
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :item="item"
        @updated="refetchList">
      </DetailOrderPayableAvailableCard>
    </div>
  </div>
</template>

<script>
import {
  LIST_ORDER_PAYABLE,
  CREATE_ORDER_PAYABLE
} from './graph'
import DetailOrderPayableAvailableCard from './DetailOrderPayableAvailableCard.vue'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    payableType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      docs: [],
      search: '',
      addPending: false
    }
  },
  apollo: {
    docs: {
      query () {
        return LIST_ORDER_PAYABLE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          orderTypes: [this.payableType],
          q: {
            params: {
              search: this.search
            }
          }
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    docsFilter () {
      return this.docs
    }
  },
  methods: {
    refetchList () {
      this.$apollo.queries.docs.refetch()
    },
    addAll () {
      const input = this.docs.flatMap(doc => doc.orders)
      .map(order => ({
        orderId: order.id,
        totalPrice: order.remaining
      }))

      if (input.length === 0) return

      this.addPending = true

      this.$apollo.mutate({
        mutation: CREATE_ORDER_PAYABLE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.refetchList()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
      .finally(() => {
        this.addPending = false
      })
    }
  },
  components: {
    DetailOrderPayableAvailableCard
  }
}
</script>

<style lang="css" scoped>
</style>
