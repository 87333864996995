import gql from 'graphql-tag'


export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {
    id type name docId
    appointment content
  }
}`

export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}Order (docType: $docType, docId: $docId, input: $input) {id}
}`

export const UPDATE_ORDER = (templateType) => gql`mutation UPDATE_ORDER ($docType: String!, $orderId: Int!, $input: Doc${templateType}OrderInput!) {
  updateOrder: updateDoc${templateType}Order (docType: $docType, orderId: $orderId, input: $input) {id}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`
