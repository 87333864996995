<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-4 col-sm-4"
        label="รหัส"
        v-model="formData.code"
        :validations="[
          {text: 'required!', value: v.formData.code.$dirty && v.formData.code.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-4 col-sm-4"
        label="เลขประจำตัวผู้เสียภาษี"
        v-model="formData.taxCode">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-4 col-sm-4"
        label="สาขา"
        v-model="formData.branch">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12 col-sm-12"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <DropdownBranch
        :disabled="$auth.disabled(method)"
        class="col-12"
        select="id"
        label="สำนักงานใหญ่ (กรณีสาขาย่อย)"
        v-model="formData.headOfficeId"
        :contactType="contactType"
        :templateType="templateType">
      </DropdownBranch>
    </div>

    <CategoryCheck
      :contactType="contactType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      :method="method"
      v-model="formData.categories">
    </CategoryCheck>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ใช้งาน"
          inline
          v-model="formData.isActive">
        </sgv-input-check>

        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ไม่ระบุตัวตน"
          inline
          v-model="formData.isUnknown">
        </sgv-input-check>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryCheck from '@/views/contact_core/components/CategoryCheck.vue'
import DropdownBranch from '@/views/contact_core/components/DropdownBranch.vue'

export default {
  props: {
    contactType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  components: {
    CategoryCheck,
    DropdownBranch
  }
}
</script>

<style lang="css">
</style>
